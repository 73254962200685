<ng-container *ngIf="!noData && multiDataset">
  <button
    tooltipPosition="top"
    showDelay="250"
    life="3"
    pTooltip="{{ tooltipLabels.lineChart }}"
    pButton
    type="button"
    icon="fas fa-chart-line"
    (click)="swicthToLineChart()"
    class="p-menu-bar-button"
  ></button>
  <button
    life="3"
    pTooltip="{{ tooltipLabels.radarChart }}"
    tooltipPosition="top"
    showDelay="250"
    pButton
    type="button"
    icon="far fa-snowflake"
    (click)="swicthToRadarChart()"
    class="p-menu-bar-button"
  ></button>
  <button
    life="3"
    pTooltip="{{ tooltipLabels.barChart }}"
    tooltipPosition="top"
    showDelay="250"
    pButton
    type="button"
    icon="far fa-chart-bar"
    (click)="swicthToBarChart()"
    class="p-menu-bar-button"
  ></button>
</ng-container>
<ng-container *ngIf="!noData && !multiDataset">
  <button
    tooltipPosition="top"
    showDelay="250"
    life="3"
    pTooltip="{{ tooltipLabels.pieChart }}"
    pButton
    type="button"
    icon="fas fa-chart-pie"
    (click)="swicthToPieChart()"
    class="p-menu-bar-button"
  ></button>
  <button
    tooltipPosition="top"
    showDelay="250"
    life="3"
    pTooltip="{{ tooltipLabels.polarChart }}"
    pButton
    type="button"
    icon="fas fa-bullseye"
    (click)="swicthToPolarChart()"
    class="p-menu-bar-button"
  ></button>
  <button
    tooltipPosition="top"
    showDelay="250"
    life="3"
    pTooltip="{{ tooltipLabels.doughnutChart }}"
    pButton
    type="button"
    icon="fas fa-dot-circle"
    (click)="swicthToDoughnutChart()"
    class="p-menu-bar-button"
  ></button>
</ng-container>
<button
  *ngIf="chart"
  tooltipPosition="top"
  showDelay="250"
  life="3"
  pTooltip="{{ tooltipLabels.showLegend }}"
  pButton
  type="button"
  icon="{{ (lastLegendDisplayState ? 'far fa-eye' : 'far fa-eye-slash') || '' }}"
  (click)="changeLegendView()"
  class="p-menu-bar-button"
></button>
<p-chart
  #chart
  *ngIf="!noData && widget.viewType === 'chart'"
  [type]="widget.chartType"
  [data]="widget.data"
  [options]="options"
  [plugins]="plugins"
></p-chart>
<div *ngIf="noData" class="no-data">
  <p class="no-data-label">{{ 'widget.noData' | translate }}</p>
</div>
