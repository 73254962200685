import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { DataStore, DataStoreName, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { MenuItem } from 'primeng/api';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'iu-stepper-ui',
  templateUrl: './stepper-ui.component.html',
  styleUrls: ['./stepper-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StepperUiComponent extends AbstractDataContainer implements OnInit {
  @Output()
  refreshEdit: EventEmitter<any> = new EventEmitter();
  @Input()
  items: MenuItem[] = [];
  @Input()
  activeIndex = 0;

  @Input()
  isIndexNumber = true;

  @Input()
  preventPrevious = false;
  @Input()
  parent: EditViewUiComponent;

  @Input()
  editTab: EditTabUiComponent;
  @Input() changingStepper: Subject<any>;
  oldIndex: number;
  forbiddenStatus: string[] = ['CL', 'VO', 'RE'];
  cancelNext = false;
  subscription;
  isDisplay: boolean;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreator: UICreatorService,
    public cmService: ContextMenuService,
    private workflowService: CompiereWorkflowService,
    renderer: Renderer2,
    private messageManager: MessageManagerService,
    private translateService: TranslateService,
    private dataStoreService: DataStoreService,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreator, renderer, po);
  }

  ngOnInit() {
    if (this.changingStepper) {
      this.subscriptions.push(
        this.changingStepper.subscribe((currentStore) => {
          this.dataStored = currentStore;
          if (currentStore) {
            this.getNextStatus(currentStore);
          } else {
            this.oldIndex = 0;
            this.items = [];
          }
        })
      );
    }
    if (this.parent && this.parent.currentDataStoreKey) {
      const currentStore = this.store.getStore(this.parent.currentDataStoreKey, DataStoreName.CURRENT);
      this.dataStored = <DataStore>currentStore;
      this.getNextStatus(currentStore);
      this.oldIndex = this.activeIndex;
    }
  }

  /**
   * Récupère les status suivants disponible par rapport au status actuel ssi ce n'est pas un nouveau record
   */
  getNextStatus(currentStore?: any) {
    this.checkReadAndDisplay(currentStore);
    if (this.isDisplay) {
      if (!this.cancelNext) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.items = [];
        if (this.isDisplay) {
          if (this.isReadOnly) {
            if (!(currentStore.data.DocStatus instanceof Object)) {
              this.store
                .getAutocompleteDataById(this.fieldType, this.data['docStatusField']['fieldId'], currentStore.data.DocStatus)
                .subscribe((item) => {
                  this.items.push({ label: item[0].displayValue, id: item[0].id });
                });
            } else {
              this.items.push({ label: currentStore.data.DocStatus.displayValue, id: currentStore.data.DocStatus.id });
            }
          } else {
            this.subscription = this.workflowService
              .getNextStatus({
                docAction: currentStore.data.DocAction.id,
                docStatus: currentStore.data.DocStatus.id,
                isSOTrx: currentStore.data.IsSOTrx,
                orderType: '',
                processing: currentStore.data.Processing,
                record_ID: this.parent.currentDataStoreKey.recordId.split(',').pop(),
                table_ID: String(this.parent.editTabs[0].data.ADTableID)
              })
              .subscribe((nextStatus) => {
                this.items.push({
                  label: currentStore.data.DocStatus.displayValue,
                  id: currentStore.data.DocStatus.id
                });
                this.items.push(...nextStatus.map((status) => ({ label: status.Name, id: status.Value })));
                this.activeIndex = this.items.findIndex((status) => status.id === currentStore.data.DocStatus.id);
              });
          }
        }
      } else {
        this.cancelNext = false;
      }
    }
  }
  /**
   * Lance le workflow vers le status sélectionner
   * @param index
   */
  changeItem(index) {
    if (!this.isReadOnly && index > this.activeIndex) {
      this.activeIndex = index;
      this.isReadOnly = true;
      const itemId = this.items[index].id;
      if (this.parent && !this.dataStoreService.checkDataBeforeNewLine(this.parent.currentDataStoreKey)) {
        this.cancelNext = true;
        this.parent.beforeSave(null).then((result) => {
          this.runWF(itemId, this.parent.getCurrentContext());
        });
      } else {
        this.runWF(itemId, this.parent.getCurrentContext());
      }
    }
  }

  runWF(itemId, windowCtx: any) {
    const ad_process_id = this.parent.editTabs.find((editTab) => editTab.tabId === this.parent.tabId).data.workflowStatus
      .AD_Process_ID;
    const dataStored = this.parent.editTabs.find((editTab) => editTab.tabId === this.parent.tabId).dataStored;
    const sub = this.workflowService
      .runWF({
        record_id: String(this.parent.currentDataStoreKey.recordId.split(',').pop()),
        windowCtx: windowCtx,
        action: String(itemId),
        table_id: String(this.parent.editTabs[0].data.ADTableID),
        ad_process_id: String(ad_process_id)
      })
      .subscribe((response) => {
        if (response) {
          if (response.Success === true) {
            const initialStatus = this.items[0];
            if (
              (response.Processed === 'N' && response.Message) ||
              (initialStatus && initialStatus.id === response.DocStatus.id)
            ) {
              this.messageManager.newMessage(
                new IupicsMessage(this.translateService.instant('generic.warning'), response.Message, 'warning')
              );
            }
          } else {
            this.activeIndex = this.oldIndex;
            this.messageManager.newMessage(
              new IupicsMessage(this.translateService.instant('generic.warning'), response.Message || ' no response')
            );
          }
          this.refreshEdit.emit();
        }
        this.checkReadAndDisplay(dataStored);
        sub.unsubscribe();
      });
  }
  onChildUpdate(event): void {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}

  /**
   * check si le champ doit être affiché ou readonly
   * @param dataStored
   */
  checkReadAndDisplay(dataStored: DataStore) {
    this.isFieldDisplay(dataStored).subscribe((displayed) => {
      this.isDisplay = displayed;
    });
    this.isReadOnly = this.isFieldReadOnly(dataStored);
  }

  isFieldDisplay(dataStored: DataStore, changedColumns?: any): Observable<boolean> {
    if (this.data['docActionField']['data'].DisplayLogic) {
      const dataMapToTest = this.getCurrentContext(dataStored, false);
      if (this.data['docActionField']['data'].DisplayLogic.trim().toLowerCase().startsWith('@sql=')) {
        return this.uiCreatorService
          .getDBSelect(
            LogicEvaluator.replaceVariables(
              this.data['docActionField']['data'].DisplayLogic.slice(5),
              this.connectorService.getIupicsUserContext(),
              this.getCurrentContext(dataStored, false)
            ),
            [],
            []
          )
          .pipe(
            map((data) => {
              return data.length > 0;
            })
          );
      } else {
        return of(LogicEvaluator.evaluateLogic(dataMapToTest, this.data['docActionField']['data'].DisplayLogic));
      }
    } else {
      return of(true);
    }
  }

  isFieldReadOnly(dataStored: DataStore): boolean {
    if (this.forbiddenStatus.includes(dataStored.data.DocStatus.id) || dataStored.status === DataStoreStatus.NEWRECORD) {
      return true;
    }

    if (
      this.data['docActionField']['data'].isAlwaysUpdatable !== undefined &&
      this.data['docActionField']['data'].isAlwaysUpdatable !== null &&
      this.data['docActionField']['data'].isAlwaysUpdatable === true
    ) {
      return false;
    }
    // Chez les scouts une fenetre avec processed true est readonly
    if (
      this.data['docActionField']['data'].IsReadOnly !== undefined &&
      this.data['docActionField']['data'].IsReadOnly !== null &&
      this.data['docActionField']['data'].IsReadOnly === true
    ) {
      return true;
    }

    // Gestion de la readonly lors de la modification
    if (this.data['docActionField']['data'].isUpdateable === false && dataStored) {
      return true;
    }

    if (!this.canUpdate(dataStored)) {
      return true;
    }

    if (
      this.data['docActionField']['data'].ReadOnlyLogic &&
      this.data['docActionField']['data'].ReadOnlyLogic.trim().toLowerCase().startsWith('@sql=')
    ) {
      this.uiCreatorService
        .getDBSelect(
          LogicEvaluator.replaceVariables(
            this.data['docActionField']['data'].ReadOnlyLogic.slice(5),
            this.connectorService.getIupicsUserContext(),
            this.getCurrentContext(dataStored, false)
          ),
          [],
          []
        )
        .subscribe((data) => {
          if (data.length > 0) {
            this.isReadOnly = true;
          } else {
            this.isReadOnly = false;
          }
        });
    } else if (
      this.data['docActionField']['data'].ReadOnlyLogic &&
      LogicEvaluator.evaluateLogic(this.getCurrentContext(dataStored, false), this.data['docActionField']['data'].ReadOnlyLogic)
    ) {
      return true;
    }
    if (
      dataStored.data.IsActive !== undefined &&
      dataStored.data.IsActive !== null &&
      dataStored.data.IsActive === 'N' &&
      !this.isAccordion
    ) {
      return true;
    }
    return false;
  }
}
