<div class="iu-login-portal">
  <div class="header-login">
    <img *ngIf="!isDefaultLogo; else defaultLogo" [src]="logoSrc" alt="Apiz" width="200" height="100" />
    <ng-template #defaultLogo>
      <a
        href="https://www.apizsolutions.com/"
        title="https://www.apizsolutions.com/"
        target="_blank"
        style="width: 200px; height: 100"
      >
        <img class="version-logo" [src]="logoSrc" alt="Apiz" />
      </a>
    </ng-template>
  </div>
  <div class="iu-login-content">
    <div class="iu-login-content-form">
      <div *ngIf="accounts" class="ui-row">
        <iu-account-selector
          #accountsRegistered
          [accounts]="accounts"
          (clickAnotherAccount)="cleanAccounts()"
        ></iu-account-selector>
      </div>
      <div *ngIf="!accounts" class="ui-row">
        <span class="group-field">
          <label class="inputLoginPageFloatLabel" for="float-input">{{ 'homepage.login' | translate }}</label>
          <input
            #inputLogin
            [ngClass]="[getErrorMessage() ? 'inputError' : '', 'inputLoginPage']"
            type="text"
            pInputText
            [(ngModel)]="login"
            (keyup.enter)="confirmLogin($any($event))"
            autofocus
            data-cy="input-login"
          />
          <i class="iconField icon-ico-tiers"></i>
        </span>
      </div>
      <div class="ui-row">
        <span class="group-field">
          <label class="inputLoginPageFloatLabel" for="float-input">{{ 'homepage.password' | translate }}</label>
          <input
            #inputPassword
            [ngClass]="[getErrorMessage() ? 'inputError' : '', 'inputLoginPage']"
            type="password"
            pInputText
            [(ngModel)]="password"
            (keyup.enter)="confirmLogin($any($event))"
            autofocus
            data-cy="input-password"
          />
          <i class="iconField icon-ico-locked"></i>
        </span>
      </div>
      <div *ngIf="getErrorMessage() && getErrorMessage().length > 0" class="ui-row">
        <span *ngIf="getErrorMessage() === 'Error'; else authDown" class="invalidCredential">
          {{ 'homepage.invalidCredential' | translate }}
        </span>
        <ng-template #authDown>
          <span class="invalidCredential" [innerHTML]="'homepage.authDown' | translate"> </span>
        </ng-template>
      </div>
      <!-- <div *ngIf="!accounts" class="ui-row">
        <span class="group-field">
          <p-checkbox
            class="inputLoginPage"
            name="rememberme"
            label="{{ 'homepage.rememberme' | translate }}"
            binary="true"
            [(ngModel)]="isRememberUser"
            (keyup.enter)="confirmLogin($event)"
          ></p-checkbox>
        </span>
      </div> -->
      <div class="ui-row">
        <span class="group-field buttonRow" style="float: right">
          <!-- <a class="iu-button iu-button-password"> {{ 'homepage.forgotPassword' | translate }} </a> -->
          <a class="iu-button iu-button-login" (click)="confirmLogin($event)" data-cy="input-button-login">
            {{ 'homepage.signin' | translate }}
          </a>
        </span>
      </div>
    </div>
  </div>

  <div [ngClass]="['version', isCusto ? 'custo' : '']">
    <a *ngIf="isCusto && !isDefaultLogo" [href]="officialApizWebSiteUrl" target="_blank">
      <img class="version-logo" src="assets/iupics/img/logo-apiz.svg" alt="Apiz" />
    </a>
    <span class="versionName" data-cy="app-version-number">© APIZ Advanced ERP Solutions — {{ version }}</span>
  </div>
</div>
