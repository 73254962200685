export enum ViewType {
  GRID = 'grid',
  KANBAN = 'kanban',
  CALENDAR = 'calendar',
  CHART = 'chart',
  TREE = 'tree',
  LIST = 'list'
}

export enum ChartType {
  PIE = 'pie',
  LINE = 'line',
  POLARAREA = 'polarArea',
  RADAR = 'radar',
  BAR = 'bar',
  HORIZONTAL_BAR = 'horizontalBar',
  DOUGHNUT = 'doughnut',
  OTHER = 'other'
}
