import { formatNumber } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { NumberType } from '@iupics-manager/models/iupics-data';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { Utils } from '@iupics-util/tools/util';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'iu-input-number-ui',
  templateUrl: './input-number-ui.component.html',
  styleUrls: ['./input-number-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputNumberUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @ViewChild('inputChips', { static: false, read: ElementRef }) inputChipsRef: ElementRef;
  @ViewChild('input', { static: false, read: ElementRef }) inputRef: ElementRef;
  @ViewChild('currencySpan', { static: true }) currencySpanRef: ElementRef;
  @ViewChild('opConflict', { static: true }) opConflict: OverlayPanel;

  @Input()
  columnName: string;
  @Input() fieldStep: string;
  @Input() hasBtn = true;
  @Input() multiple = false;

  @Output() enterKey = new EventEmitter<KeyboardEvent>();
  @Output() inputBlur = new EventEmitter<FocusEvent>();
  regexNB: RegExp = /^[0-9]*(\,{1}|\.{1})?[0-9]*$/;
  btn1Disabled = false;
  btn2Disabled = false;

  dataContainers: AbstractDataContainerCallout;
  min: number;
  max: number;
  isCurrencyDisplay = false;
  lang;
  numberFormat = '1.0-7';
  @Input()
  _precision = -1;
  set precision(value: any) {
    this._precision = value;
    if (value >= 0) {
      if (this.isMoneyField) {
        const stdPrecicion = this.connectorService.getIupicsUserContext()['#StdPrecision'];
        if (stdPrecicion != null && stdPrecicion != undefined && value > stdPrecicion) {
          this.numberFormat = `1.${stdPrecicion}-${value}`;
        } else {
          this.numberFormat = `1.${value}-${value}`;
        }
      }
      this.fieldStep = 1 / Math.pow(10, this.precision) + '';
    }
  }

  get precision() {
    return this._precision;
  }
  @Input() overridedCSS: OverridedCSS;
  @Input() placeHolder: string;
  @Input() currency: string;
  @Input()
  set fieldValue(value: any) {
    this._fieldValue = value;
  }

  get fieldValue() {
    return this._fieldValue;
  }

  decimalPattern;
  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    private config: AppConfig,
    renderer: Renderer2,
    protected po: PoService,
    private translateService: TranslateService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }

  ngOnInit() {
    if (this.data && this.data.numberType) {
      if (this.data.numberType === NumberType.MONTANT) {
        this.isCurrencyDisplay = true;
        this.isMoneyField = true;
      } else if (this.data.numberType === NumberType.INTEGER) {
        this.precision = 0;
      }
    }

    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
    this.setFieldMandatory();

    if (this.label?.endsWith('_From')) {
      this.label = this.translateService.instant('ranged-value.number.from') + this.label.replace('_From', '');
    } else if (this.label?.endsWith('_To')) {
      this.label = this.translateService.instant('ranged-value.number.to') + this.label.replace('_To', '');
    }

    this.min = this.data && this.data.min !== null && this.data.min !== undefined ? this.data.min : '';
    this.max = this.data && this.data.max !== null && this.data.max !== undefined ? this.data.max : '';
    this.lang = this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-');
    switch (this.lang) {
      case 'fr-FR':
        this.decimalPattern = [',', '.'];
        break;
      case 'en-US':
        this.decimalPattern = ['.'];
        break;
      case 'de-CH':
        this.decimalPattern = ['.'];
        break;
      default:
        this.decimalPattern = [',', '.'];
        break;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      if (this.inputRef) {
        this.data.callouts.callouts.forEach((callout) => {
          this.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
            const calloutFn = new Function('Field', 'Fields', 'DBSelect', 'Utils', callout.script);
            calloutFn(this, this.dataContainers, this.uiCreatorService, Utils);
          });
        });
      }
    }
  }

  readOnlyNumberComponent() {
    this.btn1Disabled = true;
    this.btn2Disabled = true;
  }
  getNumberPattern(reverse = false) {
    let pattern = reverse ? /[^0-9-.,\s]/g : /[0-9-.,\s]/g;
    switch (this.lang) {
      case 'fr-FR':
        pattern = reverse ? /[^0-9-.,\s]/g : /[0-9-.,\s]/g;
        break;
      case 'en-US':
        pattern = reverse ? /[^0-9-.,]/g : /[0-9-.,]/g;
        break;
      case 'de-CH':
        pattern = reverse ? /[^0-9-.]/g : /[0-9-.']/g;
        break;
      default:
        pattern = reverse ? /[^0-9-.,\s]/g : /[0-9-.,\s]/g;
        break;
    }
    return pattern;
  }
  isNumber(event: KeyboardEvent) {
    if (this.decimalPattern.includes(event.key)) {
      let decimalok = true;
      this.decimalPattern.forEach((pattern) => {
        if (this.inputRef.nativeElement.value.includes(pattern)) {
          decimalok = false;
        }
      });
      if (!decimalok) {
        return false;
      }
    }
    return event.key.match(this.getNumberPattern()) ? true : false;
  }
  _parseFloat(value: any): number {
    if (!this.multiple) {
      if (typeof value == 'string') {
        value = value.replace(/\s+/g, '');
        value = value.replace(this.getNumberPattern(true), '');
      }
      value = value ? new NumberParser(this.lang).parse(value + '') : value;
    }

    let floatValue = value && value.replace ? parseFloat(value.replace(',', '.')) : value;
    if (floatValue === null || floatValue === undefined || isNaN(parseFloat(floatValue))) {
      floatValue = this.fieldValue;
      return null;
    } else {
      if (!this.isStandalone && this.data && this.data.numberType === NumberType.INTEGER) {
        floatValue = parseFloat(floatValue.toFixed());
      } else if (
        typeof floatValue === 'number' &&
        this.data &&
        this.data.numberType !== NumberType.INTEGER &&
        this.data.numberType !== NumberType.FLOAT &&
        this.precision >= 0
      ) {
        floatValue = parseFloat(floatValue.toFixed(this.precision));
      }

      this.fieldValue = floatValue;

      if (!this.multiple && this.inputRef != undefined) {
        this.inputRef.nativeElement.value = formatNumber(value, this.lang, this.numberFormat);
      }
      return floatValue;
    }
  }
  showConflictPanel(ev) {
    ev.target.getBoundingClientRect = function () {
      return { top: this.offsetTop, left: this.offsetLeft };
    };
    this.opConflict.toggle(ev);
  }
  decrease() {
    if (this.inputRef) {
      this.inputRef.nativeElement.stepUp();
      this.dataChange(parseFloat(this.inputRef.nativeElement.value));
    }
  }
  increase() {
    if (this.inputRef) {
      this.inputRef.nativeElement.stepDown();
      this.dataChange(parseFloat(this.inputRef.nativeElement.value));
    }
  }

  focus() {
    if (this.inputRef) {
      this.inputRef.nativeElement.focus();
    }
  }
  dataChange(value) {
    super.dataChange(value);
  }
  OnWheel(e) {
    this.inputRef.nativeElement.blur();
  }
  updateDisplay(dataStored: DataStore, changedColumns?: any) {
    super.updateDisplay(dataStored, changedColumns);
  }
}
class NumberParser {
  private _group;
  private _decimal;
  private _numeral;
  private _index;
  constructor(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(`[${parts.find((d) => d.type === 'group').value}]`, 'g');
    this._decimal = new RegExp(`[${parts.find((d) => d.type === 'decimal').value}]`);
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this._index = (d) => index.get(d);
  }
  parse(string) {
    return (string = string.trim().replace(this._group, '').replace(this._decimal, '.').replace(this._numeral, this._index))
      ? +string
      : NaN;
  }
}
