import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  CompiereDataGridType,
  DataStore,
  DataStoreKey,
  DataStoreRequest,
  DataStoreStatus
} from '@compiere-ws/models/compiere-data-json';
import { RecentItem } from '@compiere-ws/models/compiere-recent-item';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import {
  SpecificGridQuery,
  SpecificWindowUiComponent
} from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { GridTabInfinityScrollUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { ModalUiComponent } from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { MenuBarUiComponent } from '@iupics-components/standard/menu/menu-bar-ui/menu-bar-ui.component';
import {
  GridViewGetFormIDByTabID,
  GridViewGetFormIDByWindowID,
  GridViewGetProcessIDByTabID,
  GridViewGetProcessIDByWindowID
} from '@iupics-components/standard/menu/utils/menu.utils';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { KeybindStateManagerService } from '@iupics-manager/managers/keybind-state-manager/keybind-state-manager.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { RecentItemsManagerService } from '@iupics-manager/managers/recent-items-manager/recent-items-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { ObjectUtils } from 'primeng/utils';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CalendarViewUiComponent } from '../calendar/calendar-view-ui/calendar-view-ui.component';
import { ChartUiComponent } from '../chart-ui/chart-ui.component';
import { KanbanUiComponent } from '../kanban/kanban-ui/kanban-ui.component';
import { TreeUiComponent } from '../tree-ui/tree-ui.component';
@Component({
  selector: 'iu-grid-view-ui',
  templateUrl: './grid-view-ui.component.html',
  styleUrls: ['./grid-view-ui.component.css'],
  animations: [...Global.overlayAnimationAutocomplete, ...Global.overlayAnimationCalendar]
})
export class GridViewUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit {
  constructor(
    private dataStoreService: DataStoreService,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private riManager: RecentItemsManagerService,
    private keybindStateManager: KeybindStateManagerService,
    private messageManager: MessageManagerService,
    private connectorService: SecurityManagerService,
    private uiCreatorService: UICreatorService,
    private resolver: ComponentFactoryResolver,
    private config: AppConfig,
    public elementRef: ElementRef
  ) {
    super();
  }
  @Input()
  gridTitle: string;
  @Input()
  isSpecificGrid = false;
  @Input()
  rowSelection: RowSelectionType = 'multiple';
  @Input()
  hasUniversalFilter = true;
  @Input()
  fromForm = false;
  @Input()
  hasCheckbox = true;
  public isGridCollapsed = false;

  parentFormID: number;
  @ViewChild(MenuBarUiComponent)
  menuBarUiComponent: MenuBarUiComponent;
  @ViewChild(GridTabInfinityScrollUiComponent)
  GridTabInfinityScrollUiComponent: GridTabInfinityScrollUiComponent;
  @ViewChild(CalendarViewUiComponent)
  calendarViewUiComponent: CalendarViewUiComponent;
  @ViewChild(KanbanUiComponent)
  kanbanUiComponent: KanbanUiComponent;
  @ViewChild(ChartUiComponent)
  chartUiComponent: ChartUiComponent;
  @ViewChild(TreeUiComponent)
  treeUiComponent: TreeUiComponent;
  @ViewChild('panelCalendar')
  panelCalendar: ElementRef;
  @ViewChild('exportDataModal')
  exportDataModal: ModalUiComponent;
  displayProcessUI = false;
  displayFormUI = false;
  processId: number;
  formId: number;
  initFromSwitch = false;
  isTree: boolean;
  isDisplayed = true;
  whereClause = '';
  visibleButtons = true;
  specificWindowTitle: string;
  /**
   * permet de savoir si il faut lancer une recherche au démarrage de la fenetre
   */
  isLaunchSearchGrid = true;
  editViewParent: any;
  columnNames;
  @Output() gridCellEditingStopped = new EventEmitter<any>();
  @Output() gridViewCellClicked = new EventEmitter<any>();
  @Output() multiSelectEmitter = new EventEmitter<any>();
  @Output() gridRefreshed = new EventEmitter<any>();
  @Output() updateFilterEmitter = new EventEmitter<CompiereDataGridRequestJSON>();
  @Output() gridPrefLoaded = new EventEmitter();
  @Output() gridTabAfterViewInitEmitter = new EventEmitter<any>();

  @ViewChild('divContent')
  divContent: ElementRef;
  @ViewChild('vcrSpecific', { read: ViewContainerRef, static: true })
  vcrSpecific: ViewContainerRef;
  mapValidator;
  @Input() windowType = IupicsMenuType.WINDOW;
  _viewType: ViewType = ViewType.GRID;
  set viewType(value: any) {
    this._viewType = value;
    // notify
    if (!(this.DOMParentComponent.DOMParentComponent instanceof EditTabUiComponent)) {
      (<BladeUiComponent>this.DOMParentComponent).notifyUrlChange();
    }
  }
  get viewType() {
    return this._viewType;
  }
  oldWidth: number;
  private _selectedRecordId;
  set selectedRecordId(value: any) {
    const isDiff = this._selectedRecordId !== value;
    this._selectedRecordId = value;
    // notify
    if (isDiff && !(this.DOMParentComponent instanceof EditTabUiComponent)) {
      (<BladeUiComponent>this.DOMParentComponent).notifyUrlChange();
    }
  }
  get selectedRecordId() {
    return this._selectedRecordId;
  }
  @Input()
  isMenuDisplay = true;
  exportDataOptions: any;
  displayExportDataModal = false;

  isTabTopLevel = true;
  isSplitView = false;
  exportIds: any[];

  noColumnToDisplay = false;

  filter: CompiereDataGridRequestJSON;

  overlayVisible = false;
  label = '';
  isLabelDisplay = true;
  scrollHeight;
  suggestions;
  field;
  ObjectUtils = ObjectUtils;
  itemTemplate;
  noResults;
  emptyMessage;
  highlightOption;

  topPosition;
  leftPosition;
  widthPosition;

  panelStyleClass;
  panelStyle;
  inline;
  documentClickListener: () => void;
  @Input() suppressRowClickSelection = false;

  modalClass = 'p-col-10 p-md-6 p-lg-4';
  // * searchPanel pour universal filter
  displaySearch: boolean;
  searchLinkedComponent: any;
  // *
  isLoading = false;

  infoWindowId?: number;
  searchPanelValidation: string;

  isChangelog = false;

  @Input() isZoomTargetGrid = false;

  forcePaginationAutoPageSize = false;
  overridedTableHeight = false;
  tableHeight: string;

  ngOnInit() {
    this.vcr = this.DOMParentComponent.vcr;
    this.editViewParent = this.DOMParentComponent;
    if (this.editViewParent !== this.container) {
      while (!(this.editViewParent instanceof EditViewUiComponent)) {
        this.editViewParent = this.editViewParent.DOMParentComponent;
      }
    } else {
      this.editViewParent = undefined;
    }
    this.isTree = this.data.hasTree && this.data.urlTree !== undefined && this.data.urlTree !== null;
    if (this.data !== undefined && this.data.isTabTopLevel !== undefined) {
      this.isTabTopLevel = this.data.isTabTopLevel;
    }
    this.keybindStateManager.splitViewEmitter.subscribe((value: boolean) => (this.isSplitView = value));
    if (
      this.container.activeTab &&
      this.container.activeTab.othersRecordId &&
      this.container.activeTab.othersRecordId[0] &&
      this.container.activeTab.othersRecordId[0].tabId == this.tabId
    ) {
      if (this.container.activeTab.othersRecordId[0].recordId === 'newRecord') {
        this.openNew();
      } else {
        const filterModel = this.createFilterModelWithRecordId(this.container.activeTab.othersRecordId[0].recordId);
        this.initRequest = { filterModel };
      }
    }
    if (this.data.columnsTableHeader.length === 0 && (!this.data.searchColumns || this.data.searchColumns.length === 0)) {
      this.noColumnToDisplay = true;
    }
    this.visibleButtons = this.windowType === IupicsMenuType.WINDOW;
  }
  createFilterModelWithRecordId(recordId: string) {
    const filterModel = {};
    const splittedRecord = recordId.split(',');
    if (splittedRecord.length > 1) {
      for (let i = 0; i < splittedRecord.length; i += 2) {
        let filterType = null;
        if (splittedRecord[i + 1]) {
          if (!splittedRecord[i].includes('_ID') && this.tabId) {
            filterType = this.dataStoreService.getColumnCompiereDataGridFilterType(this.tabId, splittedRecord[i]);
          }
          const filter: CompiereDataGridFilterModel = {
            filterType: filterType ? filterType : CompiereDataGridFilterType.NUMBER,
            operators: [OperatorFilterType.EQUALS],
            values: [[splittedRecord[i + 1]]]
          };
          filterModel[splittedRecord[i]] = filter;
        }
      }
      return filterModel;
    }
  }
  ngAfterViewInit() {
    this.updateAllLogic();
    const columnElement = document.getElementsByClassName('ag-side-bar');
    if (columnElement && columnElement[0]) {
      this.renderer.listen(columnElement[0], 'swipeleft', () => {
        this.startAnimation('slideOutLeft');
      });
      this.renderer.listen(columnElement[0], 'swiperight', () => {
        this.startAnimation('slideOutRight');
      });
    }
  }
  openRemainingEditView() {
    /* Continuation du zoomAcross */
    if (this.editViewParent && this.editViewParent.zoomInfo && this.editViewParent.zoomInfo.children) {
      this.zoomAcrossPropagation(this.editViewParent.zoomInfo);
    }
    /*divers edit à ouvrir*/
    if (
      this.container &&
      this.container.activeTab &&
      this.container.activeTab.othersRecordId &&
      this.container.activeTab.othersRecordId.length > 0
    ) {
      this.openOtherEditViews();
    }
  }

  forceOpenEditView(record_ID, zoomInfo?) {
    const dataStoreRequest: DataStoreRequest = {
      windowId: this.data.AD_window_ID,
      record_id: record_ID,
      parent_constraint: this.parentTab ? this.parentTab.dataStoreKey.recordId : undefined,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 1
      }
    };
    if (this.editViewParent) {
      let validation;
      if (this.data.validationCode) {
        validation = this.data.validationCode;
      }
      if (this.gridTabValidator && this.gridTabValidator.length > 0) {
        validation = validation ? validation + ' and ' + this.gridTabValidator[0] : this.gridTabValidator[0];
      }
      dataStoreRequest.compiereRequest.validation = LogicEvaluator.parseLogic(
        this.editViewParent.editTabs[0].dataStored.data,
        validation,
        this.connectorService.getIupicsUserContext()
      );
    }
    this.subscriptions.push(
      this.dataStoreService.getWindowSingleData(dataStoreRequest).subscribe((data) => {
        if (data) {
          const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
          if (parentComp) {
            (<EditViewUiComponent>parentComp).gridTabIdSelected = this.tabId;
          }
          this.openEditView(zoomInfo);
          if (this.isTabTopLevel) {
            const ri = new RecentItem(
              `${this.data.label}: ${this.riManager.getInfoLabel(data.data)}`,
              data.key.tabId,
              data.key.windowId,
              data.data.Data_UUID
            );
            this.riManager.pushRecentItem(ri).subscribe();
          }
          this.updateData(data.key);
        }
      })
    );
  }

  onRowSelectedOnGridTab(dataStoreKey: DataStoreKey, isOneColumn) {
    // if (this.parentTab) {
    //   // * if parent add parent to windowCtx
    //   this.dataStoreService.addToWindowCtx(
    //     this.data.AD_window_ID,
    //     this.container.activeTab.domWinId,
    //     this.parentTab.dataStoreKey
    //   );
    // }
    // // * add child to windowCtx
    // this.dataStoreService.addToWindowCtx(this.data.AD_window_ID, this.container.activeTab.domWinId, dataStoreKey);
    this.gridViewCellClicked.emit(dataStoreKey); // smart button
    if (this.checkEditViewCreated()) {
      const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
      if (parentComp) {
        (<EditViewUiComponent>parentComp).gridTabIdSelected = this.tabId;
      }
    }
    this.openEditView();
    if (this.isTabTopLevel) {
      const request: DataStoreRequest = {
        windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
        record_id: dataStoreKey.recordId,
        parent_constraint: '',
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.tabId,
          startRow: 0,
          endRow: 1
        }
      };
      this.subscriptions.push(this.riManager.addRecentItem(request, this.data.label).subscribe());
    }
    this.updateData(dataStoreKey);
  }
  remove(event: MouseEvent): void {
    const dataUUIDs = this.dataStoreService.getDataUUIDFromTabID(this.data.ADTabID);
    const recordIds = [];
    this.GridTabInfinityScrollUiComponent.agGrid.api.getSelectedNodes().forEach((node) => {
      const recordId = {};
      dataUUIDs.forEach((dataUUID) => {
        const data = node.data[dataUUID];
        if (data instanceof Object) {
          recordId[dataUUID] = data.id;
        } else {
          recordId[dataUUID] = data;
        }
      });
      recordIds.push(recordId);
    });
    const dataStoreKey: DataStoreKey = {
      windowId: this.data.AD_window_ID,
      tabId: this.data.ADTabID,
      recordId: '',
      parentId: this.editViewParent
        ? this.editViewParent.currentDataStoreKey
          ? this.editViewParent.currentDataStoreKey.recordId
          : ''
        : ''
    };
    this.isLoading = true;
    this.dataStoreService
      .deleteWindowData(dataStoreKey, recordIds)
      .pipe(
        switchMap((res) =>
          res.success > 0 ? this.riManager.deleteRecentItems(dataStoreKey, recordIds).pipe(map(() => res)) : of(res)
        )
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res) {
            if (this.GridTabInfinityScrollUiComponent) {
              this.GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();
              this.refreshGridAndParent();
            }
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('gridView.deleteMessageTitle'),
                this.translateService.instant('gridView.deletedLines') +
                  ' : ' +
                  JSON.stringify(res.success) +
                  (res.errors.length === 0
                    ? ''
                    : ' / ' + this.translateService.instant('gridView.errors') + ' : ' + res.errors.length),
                res.errors.length === 0 ? 'success' : res.success > 0 ? 'warning' : 'error'
              )
            );
          } else {
            throw new Error(this.translateService.instant('gridView.deleteErrorMessage'));
          }
        },
        (error) => {
          this.isLoading = false;
          if (error && error.error) {
            throw new Error(error.error.message);
          } else {
            throw new Error(error.message);
          }
        }
      );
  }

  openNew(isOneColumn = false) {
    if (!this.checkAndExecuteOverride('new')) {
      if (this.editViewParent === null || this.editViewParent === undefined) {
        this.DOMParentComponent.notifyUrlChange('newRecord');
      }
      if (this.GridTabInfinityScrollUiComponent) {
        if (!this.GridTabInfinityScrollUiComponent.isTabTopLevel) {
          const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
          if (parentComp) {
            if (!this.dataStoreService.checkDataBeforeNewLine((<EditViewUiComponent>parentComp).currentDataStoreKey)) {
              (<EditViewUiComponent>parentComp).beforeSave(null).then((resutl) => {
                (<EditViewUiComponent>parentComp).gridTabIdSelected = this.tabId;
                this.openEditView();
                this.updateData(
                  this.dataStoreService.newWindowData(
                    this.container.infoComponent.windowId,
                    this.tabId,
                    null,
                    EditViewUtils.getParentDatastoreKeyFromGrid(this)
                  ).key
                );
              });
            } else {
              this.openEditView();
              this.updateData(
                this.dataStoreService.newWindowData(
                  this.container.infoComponent.windowId,
                  this.tabId,
                  null,
                  EditViewUtils.getParentDatastoreKeyFromGrid(this)
                ).key
              );
            }
            // }
          }
        } else {
          if (this.checkEditViewCreated()) {
            const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
            if (parentComp) {
              (<EditViewUiComponent>parentComp).gridTabIdSelected = this.tabId;
            }
          }
          this.openEditView();
          this.updateData(
            this.dataStoreService.newWindowData(
              this.container.infoComponent.windowId,
              this.tabId,
              null,
              EditViewUtils.getParentDatastoreKeyFromGrid(this)
            ).key
          );
        }
      }
    }
  }

  /*
   * emailEditor: { display: { key: 'displayEmailEditor', value: displayEmailEditor }}
   * joinFilesPanel: { display: { key: 'displayJoinFilesPanel', value: displayJoinFilesPanel }}
   * processModal: { display: { key: 'displayProcessUI', value: displayProcessUI }, id: { key: 'processId', value: processId } }
   * formModal: { display: { key: 'displayFormUI', value: displayFormUI }, id: { key: 'formId', value: formId } }
   */
  updateModalDisplay(display: { key: string; value: boolean }, id?: { key: string; value: number }) {
    this.displayModal(display, id);
  }
  displayModal(display: { key: string; value: boolean }, id?: { key: string; value: number }) {
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value);
      }
    }
  }

  createSpecificWindow(formId: number) {
    this.subscriptions.push(
      this.uiCreatorService.getSpecificWindow(formId).subscribe((specificWindow) => {
        let component;
        if (specificWindow.angularClass && specificWindow.angularClass.length > 0 && specificWindow.angularClass !== 'default') {
          component = Global.iupics_specific_window.get(specificWindow.angularClass);
        }
        if (!component) {
          component = Global.iupics_specific_window.get('default');
        }
        const factory = this.resolver.resolveComponentFactory(component);
        this.vcrSpecific.clear();
        const componentRef = this.vcrSpecific.createComponent(factory);
        this.specificWindowTitle = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).name = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).title = specificWindow.title;
        (<SpecificWindowUiComponent>componentRef.instance).description = specificWindow.description;
        (<SpecificWindowUiComponent>componentRef.instance).help = specificWindow.help;
        // (<SpecificWindowUiComponent>componentRef.instance).iconClass = specificWindow.iconClass;
        (<SpecificWindowUiComponent>componentRef.instance).componentRef = componentRef;
        // (<SpecificWindowUiComponent>componentRef.instance).id = specificWindow.id;
        (<SpecificWindowUiComponent>componentRef.instance).isModal = true;
        (<SpecificWindowUiComponent>componentRef.instance).formId = formId;
        (<SpecificWindowUiComponent>componentRef.instance).vcrwindow = this.vcrSpecific;
        (<SpecificWindowUiComponent>componentRef.instance).parentComponent = this;
        (<SpecificWindowUiComponent>componentRef.instance).index = this.vcrSpecific.length - 1;
        (<SpecificWindowUiComponent>componentRef.instance).closeModalEmitter.subscribe(() =>
          this.updateModalDisplay({ key: 'displayFormUI', value: false })
        );
      })
    );
  }
  getFirstEditViewParent(component): EditViewUiComponent {
    // find out if the row is from the main gridview or not
    let parentComp = component;
    while (parentComp && !(parentComp instanceof EditViewUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    return <EditViewUiComponent>parentComp;
  }

  deleteLinkedEditView() {
    const editViewParent = this.getFirstEditViewParent(this.DOMParentComponent);
    if (editViewParent) {
      editViewParent.gridTabIdSelected = null;
    }
  }

  checkEditViewCreated(): boolean {
    const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
    // check if edit view has already been opened earlier
    const shouldOpen = parentComp
      ? (<EditViewUiComponent>parentComp).gridTabIdSelected !== null &&
        (<EditViewUiComponent>parentComp).gridTabIdSelected !== undefined
        ? false
        : true
      : this.isEditExist()
      ? false
      : true;
    return shouldOpen;
  }

  openEditView(zoomInfo?) {
    if (!this.isEditExist()) {
      const linkedComponent = this;
      const item: DynamicComponent = {
        container: this.container,
        DOMParentComponent: this.container,
        linkedComponents: [linkedComponent],
        component: 'EditViewUiComponent',
        cssClass: 'iupics-blade-content',
        isCssOnComponent: false,
        tabId: this.tabId,
        zoomInfo: zoomInfo,
        parentStore: this.editViewParent ? this.editViewParent.editTabs[0].dataStored : null
      };
      let i = 1;
      let find = false;
      while (i < this.container.DOMChildrenComponent.length && !find) {
        if (this.container.DOMChildrenComponent[i] === this.editViewParent && ++i < this.container.DOMChildrenComponent.length) {
          (<EditViewUiComponent>this.container.DOMChildrenComponent[i]).removeComponent();
          find = true;
        } else {
          i++;
        }
      }
      this.componentEmitter.emit({
        type: IupicsTypeEvent.showEditView,
        item: item
      });
    } else {
      // check si on doit agrandir l'editview existante
      if (this.container && this.container.breadcrumbComponent) {
        const breadCrumb = (<BladeUiComponent>this.container).breadcrumbComponent;
        if (breadCrumb) {
          const currentTabId = this.editViewParent ? this.editViewParent.tabId : 0;
          const index = breadCrumb.model.findIndex((item) => parseInt(item.id, 10) === currentTabId && !item.disabled);
          if (index > -1 && breadCrumb.model.length > index + 1) {
            breadCrumb.itemClick(null, breadCrumb.model[index + 1]);
          }
        }
      }
    }
  }

  updateData(dataStoreKey: DataStoreKey, fromNew = false) {
    if (fromNew) {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectDataChange,
        item: {
          container: null,
          dataStoreKey: dataStoreKey
        }
      });
    } else {
      // si une edit view n'est pas encore créer on n'appelle pas de popup
      if (this.isEditExist()) {
        this.notifierLinkedComponent.next({
          type: IupicsTypeEvent.checkBeforeChange,
          item: {
            container: null,
            dataStoreKey: dataStoreKey
          }
        });
      } else {
        this.notifierLinkedComponent.next({
          type: IupicsTypeEvent.selectDataChange,
          item: {
            container: null,
            dataStoreKey: dataStoreKey
          }
        });
      }
    }
  }
  // }

  checkBeforeChange(editView: EditViewUiComponent, dataStoreKey: DataStoreKey) {
    const isOK = editView.checkData();
    if (!isOK) {
      const ctx = this.connectorService.getIupicsUserContext();
      if (ctx['AutoCommit'] === 'Y') {
        editView.beforeSave(null);
      } else {
        Global.infoDialog.message = {
          summary: this.translateService.instant('infodialog.dialogs.checkBefore.close.title'),
          detail: this.translateService.instant('infodialog.dialogs.checkBefore.close.message')
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
        Global.infoDialog.showInfoDialog();

        const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
          editView.beforeSave(null);
          if (
            editView.conflictsResult &&
            editView.conflictsResult.hasConflicts === false &&
            editView.conflictsResult.mustRefresh === false
          ) {
            if (!editView.transmitDataChange(dataStoreKey, this.tabId)) {
              this.onRowSelectedOnGridTab(dataStoreKey, false);
            }
          }
          confirm.unsubscribe();
          cancel.unsubscribe();
        });

        const cancel = Global.infoDialog.cancel.subscribe((e: any) => {
          if (editView.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
            this.subscriptions.push(this.dataStoreService.syncWithRemoteWindowData(editView.currentDataStoreKey).subscribe());
          } else {
            this.dataStoreService.deleteDataFromStoreOnly(editView.currentDataStoreKey);
          }
          if (!editView.transmitDataChange(dataStoreKey, this.tabId)) {
            this.onRowSelectedOnGridTab(dataStoreKey, false);
          }
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
      }
    } else {
      if (!editView.transmitDataChange(dataStoreKey, this.tabId)) {
        this.onRowSelectedOnGridTab(dataStoreKey, false);
      }
    }
  }

  onChildUpdate(event): void {
    // resize grid when its opened
    if (event.type === IupicsTypeEvent.showGridView) {
      if (this.GridTabInfinityScrollUiComponent) {
        setTimeout(() => {
          if (!this.isGridCollapsed) {
            this.GridTabInfinityScrollUiComponent.refresh();
          }
        }, 50);
      }
    }
  }

  onSiblingUpdate(event: IupicsEvent) {
    if (event.type === IupicsTypeEvent.collapseEvent) {
      this.rowSelection = 'single';
      this.menuBarUiComponent.setVisibleButton(false);
      this.isGridCollapsed = true;
      if (!Global.isMobile()) {
        this.oldWidth = this.oldWidth != null ? this.oldWidth : this.divContent.nativeElement.clientWidth;
        if (!this.editViewParent && !this.isZoomTargetGrid) {
          this.cssWidth = 'max-content';
        }
        if (this.GridTabInfinityScrollUiComponent) {
          this.isTree = false;
          this.GridTabInfinityScrollUiComponent.onlyOneColumn();
        }
      } else {
        // grid fill width of device
        this.oldWidth = Global.getDeviceWidth();
      }
      if (this.editViewParent && this.editViewParent.editTabs && this.editViewParent.editTabs.length > 1) {
        this.editViewParent.editTabs.forEach((tab) => {
          tab.collaspTab();
        });
        this.editViewParent.smartButtons.resizeSmartButton(IupicsTypeEvent.collapseEvent);
      }
    } else if (event.type === IupicsTypeEvent.expandEvent) {
      this.rowSelection = 'multiple';
      this.menuBarUiComponent.setVisibleButton(true);
      this.isGridCollapsed = false;
      if (!Global.isMobile()) {
        if (this.GridTabInfinityScrollUiComponent) {
          this.isTree = this.data.hasTree;
          let parentComp = this.DOMParentComponent;
          while (parentComp && !(parentComp instanceof EditViewUiComponent)) {
            parentComp = parentComp.DOMParentComponent;
          }
          if (event.item && event.item.data && event.item.data.label === 'select') {
            this.GridTabInfinityScrollUiComponent.allColumns(true);
          } else {
            this.GridTabInfinityScrollUiComponent.allColumns(false);
          }
        }
      } else {
        this.cssWidth = '100%';
      }
      if (this.editViewParent && this.editViewParent.editTabs && this.editViewParent.editTabs.length > 1) {
        this.editViewParent.editTabs.forEach((tab) => {
          tab.expandTab();
        });
        this.editViewParent.smartButtons.resizeSmartButton(IupicsTypeEvent.expandEvent);
      }
    } else if (event.type === IupicsTypeEvent.REFRESH_GRID) {
      this.refreshGridAndParent();
    } else if (event.type === IupicsTypeEvent.triggerAfterChange) {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectDataChange,
        item: event.item
      });
    } else if (event.type === IupicsTypeEvent.UPDATE_ROWSAVED) {
      if (this.editViewParent) {
        this.editViewParent.updateAll(false, this.tabId);
      }
      this.updateRow(event.item ? event.item.dataStoreKey.recordId : this.selectedRecordId);
    }
  }

  refreshGridAndParent() {
    if (this.GridTabInfinityScrollUiComponent) {
      // setter la page courante
      this.GridTabInfinityScrollUiComponent.currentSelectPageIndex =
        this.GridTabInfinityScrollUiComponent.agGrid.api.paginationGetCurrentPage();
      if (this.editViewParent) {
        this.editViewParent.updateAll(false);
      } else {
        this.GridTabInfinityScrollUiComponent.refresh(true);
      }
    }
  }

  onRemoveComponent(event: IupicsEvent) {}

  updateRow(recordId: string) {
    this.GridTabInfinityScrollUiComponent.agGrid.api.forEachNode((node) => {
      if (node.data && node.data['Data_UUID'] == recordId) {
        const request: DataStoreRequest = {
          windowId: this.data.AD_window_ID,
          record_id: recordId,
          parent_constraint: this.editViewParent
            ? this.editViewParent.currentDataStoreKey
              ? this.editViewParent.currentDataStoreKey.recordId
              : ''
            : '',
          compiereRequest: {
            windowType: CompiereDataGridType.WINDOW,
            entityId: this.tabId,
            startRow: 0,
            endRow: 1
          }
        };
        this.dataStoreService.getWindowSingleData(request).subscribe((dataStore) => {
          if (dataStore) {
            this.GridTabInfinityScrollUiComponent.agGrid.api.getRowNode(node.id).setData({ ...dataStore.data });

            for (let i = 1; i < this.container.DOMChildrenComponent.length; i++) {
              if (
                this.container.DOMChildrenComponent[i] instanceof EditViewUiComponent &&
                this.container.DOMChildrenComponent[i].tabId === this.tabId &&
                this.container.DOMChildrenComponent[i].currentDataStoreKey &&
                this.container.DOMChildrenComponent[i].currentDataStoreKey.parentId
              ) {
                const parentColumnName = this.container.DOMChildrenComponent[i].currentDataStoreKey.parentId.split(',')[0];
                const parentId = this.container.DOMChildrenComponent[i].currentDataStoreKey.parentId.split(',')[1];

                if (dataStore.data[parentColumnName] && dataStore.data[parentColumnName].id !== parseInt(parentId, 10)) {
                  this.GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();
                  this.refreshGridAndParent();
                }
              }
            }
          } else {
            console.error('No data found!');
          }
        });
        return;
      }
    });
  }
  displayHideFilters(isFilter) {
    if (this.GridTabInfinityScrollUiComponent) {
      this.GridTabInfinityScrollUiComponent.displayHideFilters(isFilter);
    }
  }

  displayHideMultiButton(event) {
    if (this.menuBarUiComponent) {
      this.menuBarUiComponent.setVisibleButtonMultiSelection(event.selected);
    }
    this.multiSelectEmitter.emit(event);
  }

  refreshGrid(query?: SpecificGridQuery) {
    if (this.kanbanUiComponent) {
      this.kanbanUiComponent.getData();
    } else if (this.treeUiComponent) {
      this.treeUiComponent.getData();
    } else if (this.calendarViewUiComponent) {
      this.calendarViewUiComponent.initData();
    } else if (this.chartUiComponent) {
      this.chartUiComponent.getData();
    } else if (this.GridTabInfinityScrollUiComponent) {
      this.GridTabInfinityScrollUiComponent.refresh(true, query);
    }
  }

  startAnimation(state) {
    if (Global.isMobile() && state !== '' && this.DOMParentComponent === this.container) {
      const breadCrumb = (<BladeUiComponent>this.DOMParentComponent).breadcrumbComponent;
      const model = breadCrumb.model;
      if (state === 'slideOutLeft') {
        let timeout = 0;
        if (Global.getDeviceWidth() > 640) {
          timeout = 100;
        }
        setTimeout(() => {
          breadCrumb.itemClick(null, model[1]);
        }, timeout);
      }
    }
  }

  openExportDataModal(event: Event) {
    event.stopPropagation();
    // Récupération des records sélectionnés
    if (!this.checkAndExecuteOverride('export')) {
      this.exportIds = [];
      if (this.editViewParent && this.editViewParent.currentDataStoreKey) {
        const splittedRecord = this.editViewParent.currentDataStoreKey.recordId.split(',');
        if (splittedRecord.length > 1) {
          const tableName = this.data.TableName;
          this.whereClause = '';
          if (tableName) {
            this.whereClause += tableName;
          }
          this.whereClause += '.' + splittedRecord[0] + '=' + splittedRecord[1];
        }
      }
      this.GridTabInfinityScrollUiComponent.agGrid.api.getSelectedNodes().forEach((node) => {
        this.exportIds.push(node.data.Data_UUID);
      });
      this.displayExportDataModal = true;
    }
  }
  /**
   * Find an edit which has the current tabId in the container
   * @param {any}container
   * @param {number}tabId
   * @returns {number} index
   */
  public isEditExist(): boolean {
    let find = false;
    let j = 1;
    while (!find && this.container.DOMChildrenComponent.length > j) {
      if (
        this.container.DOMChildrenComponent[j].DOMComponent.instance instanceof EditViewUiComponent &&
        this.container.DOMChildrenComponent[j].tabId === this.tabId
      ) {
        find = true;
      }
      j++;
    }
    return find;
  }
  openOtherEditViews() {
    if (this.container.activeTab.othersRecordId[0].tabId == this.tabId) {
      if (this.container.activeTab.othersRecordId[0].recordId === 'newRecord') {
        this.openNew();
      } else {
        this.forceOpenEditView(this.container.activeTab.othersRecordId[0].recordId);
        this.selectedRecordId = this.container.activeTab.othersRecordId[0].recordId;
      }
      this.container.activeTab.othersRecordId.splice(0, 1);
      if (this.container.activeTab.othersRecordId.length === 0) {
        this.container.activeTab.othersRecordId = undefined;
      }
    }
  }
  zoomAcrossPropagation(zoomInfo) {
    const nbChildren = zoomInfo.children.length;
    if (nbChildren > 0 && this.tabId == zoomInfo.children[nbChildren - 1].Tab_ID) {
      const concernedChild = zoomInfo.children[nbChildren - 1];
      zoomInfo.children.splice(-1, 1);
      const zoomInfoParam = {
        parentId: zoomInfo.dataUUID,
        windowId: concernedChild.Window_ID,
        dataUUID: concernedChild.Record_ID,
        record_id: concernedChild.Record_ID.split(',')[1],
        children: zoomInfo.children
      };
      this.forceOpenEditView(concernedChild.Record_ID, zoomInfoParam);
    }
  }

  applyFilter(filter: CompiereDataGridRequestJSON) {
    if (filter) {
      this.filter = filter;
      switch (this.viewType) {
        case ViewType.GRID:
          this.GridTabInfinityScrollUiComponent.applyFilter(
            this.filter.sortModel,
            this.filter.filterModel,
            this.filter.rowGroupCols,
            this.filter.valueCols,
            this.filter.pivotCols,
            this.filter.pivotMode
          );
          break;
        case ViewType.KANBAN:
          if (this.kanbanUiComponent) {
            this.kanbanUiComponent.applyFilter(this.filter);
          }
          break;
        case ViewType.CHART:
          if (this.chartUiComponent) {
            this.chartUiComponent.applyFilter(this.filter);
          }
          break;
        case ViewType.CALENDAR:
          if (this.calendarViewUiComponent) {
            this.calendarViewUiComponent.applyFilter(this.filter);
          }
          break;
        default:
          break;
      }
    }
  }

  setFilterFromView(filter: CompiereDataGridRequestJSON) {
    this.updateFilterEmitter.emit(filter);
  }

  notifyGridTabAfterViewInitEmitter(gridTab: GridTabInfinityScrollUiComponent) {
    this.gridTabAfterViewInitEmitter.emit(gridTab);
  }

  switchViewType(viewType: ViewType) {
    this.initRequest = { ...this.filter };
    this.viewType = viewType;
    this.initFromSwitch = true;
    if (viewType === ViewType.GRID) {
      this.GridTabInfinityScrollUiComponent.createServerDataSource();
    }
  }
  gridCellClicked(event) {
    this.gridViewCellClicked.emit(event);
  }
  setPosition(top, left, width?) {
    this.topPosition = top + 'px';
    this.leftPosition = left + 'px';
    if (width) {
      this.widthPosition = width + 26 + 'px';
    }
  }
  //#endregion

  /**
   * mise à jour du display
   * @param dataStore noveau datastore à prendre en compte
   */
  updateDisplayLogic(dataStore?: DataStore) {
    if (this.data && this.data.DisplayLogic) {
      const currentContext = this.getCurrentContext(dataStore);
      this.isDisplayed = LogicEvaluator.evaluateLogic(currentContext ? currentContext : null, this.data.DisplayLogic);
    } else {
      this.isDisplayed = true;
    }
    return this.isDisplayed;
  }
  getParentStore() {
    if (this.editViewParent && this.editViewParent.editTabs[0] && this.editViewParent.editTabs[0]) {
      return this.editViewParent.editTabs[0].dataStored;
    } else if (this.container) {
      /* dans le cas d'une grid dans une form */
      return this.container.dataStore;
    }
  }
  /**
   * récupération du contexte complet du composant
   * @param dataStore nouveau datastore à prendre en compte
   */
  getCurrentContext(datastore?: DataStore) {
    let currentParent;
    let dataStored;
    /* dans le cas d'une grid dans une editview */
    if (this.editViewParent && this.editViewParent.editTabs[0] && this.editViewParent.editTabs[0]) {
      dataStored = this.editViewParent.editTabs[0].dataStored;
      if (this.editViewParent) {
        currentParent = this.editViewParent;
      }
    } else if (this.container) {
      /* dans le cas d'une grid dans une form */
      dataStored = this.container.dataStore;
      if (
        this.container.parentComponent &&
        this.container.parentComponent.linkedComponents &&
        this.container.parentComponent.linkedComponents[0]
      ) {
        currentParent = this.container.parentComponent;
      }
      /*cas d'une form dans une form */
      if (this.container.parentComponent && this.container.parentComponent.dataStore) {
        let tmpData = dataStored.data;
        /*cas d'une form dans une form provenant d'un autocomplete */
        if (this.container.sourceComponent) {
          tmpData = EditViewUtils.mergeCurrentDataDeepCopy(tmpData, this.container.sourceComponent.getCurrentContext(), false);
        } else {
          tmpData = EditViewUtils.mergeCurrentDataDeepCopy(tmpData, this.container.parentComponent.getCurrentContext(), false);
        }
        return tmpData;
      }
    }
    /*ajout du tabId */
    dataStored = datastore ? datastore : dataStored;
    if (dataStored && dataStored.data) {
      dataStored = {
        ...dataStored,
        data: { ...dataStored.data, Parent_Tab_ID: this.tabId ? this.tabId : this.parentTab ? this.parentTab.tabId : null }
      };
    }
    return EditViewUtils.getCurrentContext(currentParent, dataStored, this.connectorService.getIupicsUserContext());
  }

  // * search panel pour universal filter
  showSearchPanel(linkedComponent: any) {
    let url = null;
    if (linkedComponent.data.urlSearch) {
      url = linkedComponent.data.urlSearch;
    } else if (linkedComponent.data && linkedComponent.data.details && linkedComponent.data.details.entityID) {
      url = '/Field/Search?id=' + linkedComponent.data.details.entityID + '&type=0';
    }
    if (url) {
      this.subscriptions.push(
        this.uiCreatorService.getFieldsData(url).subscribe((data) => {
          this.searchPanelValidation = linkedComponent.data.details.tableName + ".IsActive='Y'";
          linkedComponent.data.searchColumns = data;
          this.displaySearch = true;
          this.searchLinkedComponent = linkedComponent;
        })
      );
    }
  }
  // *
  /**
   * permet d'overrider l'action si il existe un process ou une form. La méthode retourne true si c'est le cas.
   * @param source indique quelle action est à overrider
   */
  checkAndExecuteOverride(source: string) {
    let formIdToOpen;
    let processIdToOpen;
    const ad_window_id = this.data ? '' + this.data.AD_window_ID : '';
    switch (source) {
      case 'new':
        formIdToOpen = this.config.getConstant(GridViewGetFormIDByTabID.OPEN_NEW + this.tabId);
        processIdToOpen = this.config.getConstant(GridViewGetProcessIDByTabID.OPEN_NEW + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(GridViewGetFormIDByWindowID.OPEN_NEW + ad_window_id);
          processIdToOpen = this.config.getConstant(GridViewGetProcessIDByWindowID.OPEN_NEW + ad_window_id);
        }
        break;
      case 'copy':
        formIdToOpen = this.config.getConstant(GridViewGetFormIDByTabID.COPY_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(GridViewGetProcessIDByTabID.COPY_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(GridViewGetFormIDByWindowID.COPY_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(GridViewGetProcessIDByWindowID.COPY_DATA + ad_window_id);
        }
        break;
      case 'export':
        formIdToOpen = this.config.getConstant(GridViewGetFormIDByTabID.EXPORT_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(GridViewGetProcessIDByTabID.EXPORT_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(GridViewGetFormIDByWindowID.EXPORT_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(GridViewGetProcessIDByWindowID.EXPORT_DATA + ad_window_id);
        }
        break;
      case 'refresh':
        formIdToOpen = this.config.getConstant(GridViewGetFormIDByTabID.REFRESH_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(GridViewGetProcessIDByTabID.REFRESH_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(GridViewGetFormIDByWindowID.REFRESH_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(GridViewGetProcessIDByWindowID.REFRESH_DATA + ad_window_id);
        }
        break;
      case 'delete':
        formIdToOpen = this.config.getConstant(GridViewGetFormIDByTabID.DELETE_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(GridViewGetProcessIDByTabID.DELETE_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(GridViewGetFormIDByWindowID.DELETE_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(GridViewGetProcessIDByWindowID.DELETE_DATA + ad_window_id);
        }
        break;
      default:
        break;
    }
    if (formIdToOpen !== undefined) {
      if (this.editViewParent && this.editViewParent.updateModalDisplay) {
        this.editViewParent.updateModalDisplay(
          { key: 'displayFormUI', value: true, sourceComponent: { columnName: source } },
          { key: 'formId', value: formIdToOpen }
        );
      } else {
        this.updateModalDisplay({ key: 'displayFormUI', value: true }, { key: 'formId', value: formIdToOpen });
      }
    } else if (processIdToOpen !== undefined) {
      if (this.editViewParent && this.editViewParent.updateModalDisplay) {
        this.editViewParent.updateModalDisplay(
          { key: 'displayProcessUI', value: true, sourceComponent: source },
          { key: 'processId', value: processIdToOpen }
        );
      } else {
        this.updateModalDisplay({ key: 'displayProcessUI', value: true }, { key: 'processId', value: processIdToOpen });
      }
    }
    return formIdToOpen !== undefined || processIdToOpen !== undefined;
  }
  /**
   * mise à jour du readOnly
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateReadOnlyLogic(dataStore?: DataStore) {
    let isReadOnly=false ; /*Anomalie 153445*
    //let isReadOnly = this.data.IsReadOnly ?? false; /*Anomalie 153445*/
    const readOnlyLogic = this.data.ReadOnlyLogic;
    if (readOnlyLogic) {
      if (EditViewUtils.checkLogic(readOnlyLogic, this.getCurrentContext(dataStore))) {
        isReadOnly = true;
      } else {
        isReadOnly = false;
      }
    }
    this.IsReadOnly = isReadOnly;
    if (this.parentTab && this.parentTab.isReadOnly === true) {
      this.IsReadOnly = true;
    }
  }
  /**
   * mise à jour du IsDeletable
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateIsDeleteableOnlyLogic(dataStore?: DataStore) {
    let isDeleteable = true;
    const isDeletableLogic = this.data.IsDeletableLogic;
    if (isDeletableLogic) {
      if (EditViewUtils.checkLogic(isDeletableLogic, this.getCurrentContext(dataStore))) {
        isDeleteable = true;
      } else {
        isDeleteable = false;
      }
    }
    this.IsDeleteable = isDeleteable;

    if (this.parentTab && this.parentTab.IsDeleteable === false) {
      this.IsDeleteable = false;
    }
  }
  updateAllLogic(datastore?: DataStore) {
    this.updateDisplayLogic(datastore);
    this.updateReadOnlyLogic(datastore);
    this.updateIsDeleteableOnlyLogic(datastore);
  }
}

export type RowSelectionType = 'single' | 'multiple';
