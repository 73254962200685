import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  CompiereNotification,
  CompiereNotificationFileInfo,
  CompiereNotificationPriority,
  CompiereNotificationType
} from '@compiere-ws/models/compiere-notification-json';
import { PushNotificationsService } from '@compiere-ws/services/push-notifications/push-notifications.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { AppConfig } from '@iupics-config/app.config';
import { NotificationManagerService } from '@iupics-manager/managers/notification-manager/notification-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import * as moment from 'moment';
import { Observable, Subject, zip } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'wd-notification-center-ui',
  templateUrl: './notification-center-ui.component.html',
  styleUrls: ['./notification-center-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationCenterUiComponent implements OnInit, OnDestroy {
  private readonly exportDataGridProcessId: number;

  private _notifications: CompiereNotification[] = [];
  private _alerts: CompiereNotification[] = [];
  private datas: CompiereNotification[] = [];
  private _datas$: Subject<CompiereNotification[]>;
  datas$: Observable<CompiereNotification[]>;
  areButtonsDisabled = false;
  messageInfoDialog: string;
  savedDate: number;
  subscriptions: any[] = [];
  viewAllToolTip: string;
  deleteAllToolTip: string;
  isInfoDialogShow = false;
  @Output()
  updateNbNotification: EventEmitter<any> = new EventEmitter();
  @Output()
  toggleNotifCenter: EventEmitter<any> = new EventEmitter();
  timerList: Map<number, any> = new Map<number, any>();

  notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF;
  nbNotification = 0;
  nbAlerts = 0;

  constructor(
    private socketService: SocketService,
    private notificationManager: NotificationManagerService,
    private pushNotificationService: PushNotificationsService,
    private config: AppConfig
  ) {
    this.pushNotificationService.requestPermission();
    this.exportDataGridProcessId = this.config.getConstant('exportDataGridProcessId', -1);
  }

  public ngOnInit() {
    this.socketService.initSocket();
    this.subscription();
    Global.notificationCenter = this;
    this._datas$ = new Subject();
    this.datas$ = this._datas$.asObservable();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });

    this.socketService.disableBroadcastNotifications();
    this.socketService.disablePersonalNotifications();
    this.socketService.disableRoleNotifications();
  }

  /**
   * Initialise les subscriptions
   */
  private subscription() {
    (async () => {
      for await (const notification of this.socketService.enableBroadcastNotifications()) {
        this.notificationSocketHandler(notification);
      }
    })();

    (async () => {
      for await (const notification of this.socketService.enablePersonalNotifications()) {
        this.notificationSocketHandler(notification);
      }
    })();

    (async () => {
      for await (const notification of this.socketService.enableRoleNotifications()) {
        if (
          notification.sourceType === 'Process' &&
          notification.sourceTypeId === this.exportDataGridProcessId &&
          !notification.isError
        ) {
          notification.fileLinks.forEach((fileLink) => this.notificationManager.downloadReport(fileLink.path));
        }
        this.notificationSocketHandler(notification);
      }
    })();

    this.load();
    this.subscriptions.push(
      this.notificationManager.onChangeRoleChannel.subscribe(({ _, nextRole }) => {
        this.socketService.disableRoleNotifications();
        (async () => {
          for await (const notification of this.socketService.enableRoleNotifications(nextRole)) {
            this.notificationSocketHandler(notification);
          }
        })();
      }),
      this.notificationManager.onRoleChanged.subscribe(() => {
        this.load();
      })
    );
  }

  private load() {
    const sub = zip(
      this.notificationManager.getNotifications(CompiereNotificationType.NOTIF),
      this.notificationManager.getNotifications(CompiereNotificationType.ALERT)
    )
      .pipe(
        map(([notifResponse, alertResponse]) => {
          return [this.notificationResponseHandler(notifResponse), this.notificationResponseHandler(alertResponse)];
        })
      )
      .subscribe(([notifications, alerts]) => {
        this._notifications = notifications;
        this._alerts = alerts;

        this.updateNbNotif();
        this.sortNotif();

        if (
          new Set(this.datas.map((n) => n.closed === false && n.priority === CompiereNotificationPriority.IMPORTANT)).has(true)
        ) {
          this.toggleNotifCenter.emit(null);
        }

        sub.unsubscribe();
      });
  }

  /**
   * Ajoute une notification
   * @param {CompiereNotification}notif
   */
  private newNotif(notif?: CompiereNotification) {
    if (notif !== undefined && notif.notificationType === this.notificationType) {
      if (notif.closed === false && notif.priority === CompiereNotificationPriority.IMPORTANT) {
        this.startTimer(notif);
      }
      (notif.notificationType === CompiereNotificationType.ALERT ? this._alerts : this._notifications).unshift(notif);
    }
  }
  /**
   * trie les notifs sur la date de création, de lecture et la priorité
   */
  private sortNotif() {
    this.datas = this.notificationType === CompiereNotificationType.NOTIF ? this._notifications : this._alerts;
    this.datas
      .sort((a, b) => {
        return moment(a.created).isSame(moment(b.created)) ? 0 : moment(a.created).isBefore(moment(b.created)) ? 1 : -1;
      })
      .sort((a, b) => {
        const weightA = this.getWeightPriority(a.priority);
        const weightB = this.getWeightPriority(b.priority);
        return weightB - weightA;
      })
      .sort((a, b) => {
        const weightA = a.closed ? 0 : 1;
        const weightB = b.closed ? 0 : 1;
        return weightB - weightA;
      });
    if (this._datas$) {
      this._datas$.next(this.datas);
    }
  }
  /**
   * Donne le poid d'une priorité
   * @param {CompiereNotificationPriority}priority
   */
  private getWeightPriority(priority: CompiereNotificationPriority) {
    switch (priority) {
      case CompiereNotificationPriority.IMPORTANT:
        return 3;
      case CompiereNotificationPriority.HIGH:
        return 2;
      case CompiereNotificationPriority.MEDIUM:
        return 1;
      case CompiereNotificationPriority.LOW:
        return 0;
      default:
        return 0;
    }
  }
  /**
   * Supprime une notification
   * @param {CompiereNotification}item
   */
  public deleteNotification(item: CompiereNotification): void {
    this.areButtonsDisabled = true;
    const index = this.datas.findIndex((data) => data.request_id === item.request_id);
    this.datas.splice(index, 1);
    item.notificationType === CompiereNotificationType.ALERT
      ? (this.nbAlerts = this.datas.filter((n) => !n.closed).length)
      : (this.nbNotification = this.datas.filter((n) => !n.closed).length);
    this.updateNbNotif();
    this.clearTimer(item.request_id);
    this.areButtonsDisabled = false;
    this.subscriptions.push(this.notificationManager.closeNotification(item, this.notificationType).subscribe());
  }

  /**
   * Emet un event pour mettre à jour le nombre de notification dans le menu-top et dans le title
   */
  public updateNbNotif(notif: CompiereNotification = null) {
    if (notif) {
      this.readNotif(notif.request_id);
    }
    this.updateNbNotification.emit(this.nbNotification + this.nbAlerts);
  }

  /**
   * permet d'enclencher un timer pour faire un rappel
   * @param {CompiereNotification}notif
   */
  private startTimer(notif: CompiereNotification) {
    const defaultTimeOut = this.config.getConstant('notificationReminderTimeout');
    const timeout = defaultTimeOut ? defaultTimeOut : 2;
    const intervalID = setInterval(() => {
      this.popNotifications(notif);
    }, timeout * 60000);
    this.timerList.set(notif.request_id, intervalID);
  }
  /**
   * permet d'annuler le timer
   * @param {Number}request_id
   */
  private clearTimer(request_id: number) {
    if (this.timerList.get(request_id)) {
      clearInterval(this.timerList.get(request_id));
      this.timerList.delete(request_id);
    }
  }
  /**
   * permet d'annuler tous les timers existants
   * @param {Number}request_id
   */
  private clearAllTimer() {
    this.timerList.forEach((id, requestId) => {
      this.clearTimer(requestId);
    });
  }
  /**
   * passe le statut de la notif en lue
   * @param {Number}request_id
   */
  private readNotif(request_id: number) {
    const index = this.datas.findIndex((data) => data.request_id === request_id);
    if (index >= 0) {
      this.datas[index].closed = true;
    }
    this.clearTimer(request_id);
  }
  /**
   * Change le status d'une notification
   * @param {MouseEvent}event
   * @param {CompiereNotification}item
   */
  public handleNotification(event: MouseEvent, item: CompiereNotification): void {
    event.stopPropagation();
    if (item?.closed === false) {
      this.notificationManager
        .handleNotification(item, item.notificationType)
        .pipe(
          map((notifResponse) => {
            return this.notificationResponseHandler(notifResponse);
          })
        )
        .subscribe(() => {
          this.readNotif(item.request_id);
          this.updateNbNotif();
        });
    }
  }

  /**
   * Fait apparaitre les notifications dans une pop-up
   * @param {CompiereNotification[]}notifications
   */
  private popNotifications(...notifications: CompiereNotification[]): any {
    if (this.pushNotificationService.permission === 'granted') {
      this.pushNotificationService.generateNotification(notifications);
    }
    this.notificationManager.popNotification(...notifications);
  }

  /**
   * Télécharge les fichiers passés en paramètre
   * @param {CompiereNotification}item
   * @param { string | Array }fileLinks
   */
  public handleDlFile(fileLinks: string | CompiereNotificationFileInfo[], item: CompiereNotification) {
    if (typeof fileLinks === 'string') {
      fileLinks.split(',').forEach((fileLink) => {
        this.notificationManager.downloadReport(fileLink);
      });
    } else {
      fileLinks.forEach((fileLink) => {
        this.notificationManager.downloadReport(fileLink.path);
      });
    }
    this.handleNotification(document.createEvent('MouseEvent'), item);
  }

  public deleteAllNotifications(event: MouseEvent) {
    event.stopPropagation();
    this.subscriptions.push(
      this.notificationManager.closeAllNotification(this.notificationType).subscribe(() => {
        if (this.notificationType === CompiereNotificationType.ALERT) {
          this.nbAlerts = 0;
          this._alerts = [];
        } else {
          this.nbNotification = 0;
          this._notifications = [];
        }
        this.clearAllTimer();
        this.updateNbNotif();
        this.sortNotif();
        this.messageInfoDialog = '';
        this.isInfoDialogShow = false;
      })
    );
  }

  public showDeleteDialog(event: MouseEvent) {
    event.stopPropagation();
    this.messageInfoDialog = 'Voulez-vous vider la liste?';
    this.isInfoDialogShow = true;
  }

  public viewAllNotifications(event: MouseEvent) {
    this.notificationManager
      .handleAllNotification(this.notificationType)
      .pipe(
        map((notifResponse) => {
          return this.notificationResponseHandler(notifResponse);
        })
      )
      .subscribe(() => {
        this.datas.map((data) => (data.closed = true));
        this.clearAllTimer();
        this.updateNbNotif();
        this.sortNotif();
      });
  }

  public cancelInfoDialog(event: MouseEvent) {
    event.stopPropagation();
    this.messageInfoDialog = '';
    this.isInfoDialogShow = false;
  }

  public refresh(event: MouseEvent) {
    event.stopPropagation();
    this.clearAllTimer();
    this.load();
  }

  public setActiveTab(event: MouseEvent, activeTab: string): void {
    event.stopPropagation();
    if (this.notificationType !== CompiereNotificationType[activeTab]) {
      this.notificationType = activeTab as CompiereNotificationType;
      this.refresh(event);
    }
  }

  private notificationResponseHandler<T>(response: HttpResponse<T>): T {
    try {
      this.nbNotification = parseInt(response.headers.get('x-data-nb-notif'), 10) || 0;
    } catch {
      this.nbNotification = 0;
    }
    try {
      this.nbAlerts = parseInt(response.headers.get('x-data-nb-alert'), 10) || 0;
    } catch {
      this.nbAlerts = 0;
    }
    return response.body;
  }

  private notificationSocketHandler(notification: CompiereNotification): void {
    if (notification.notificationType === CompiereNotificationType.ALERT) {
      this._alerts = this._alerts.filter((alert) => parseInt(alert.alertRuleId, 10) !== parseInt(notification.alertRuleId, 10));
      this.nbAlerts = this._alerts.length + 1;
    } else {
      this.nbNotification++;
    }

    this.newNotif(notification);
    this.updateNbNotif();
    this.sortNotif();
    this.popNotifications(notification);
  }
}
