import { AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { DomHandler } from 'primeng/dom';

@Directive({
  selector: '[iuPrimeButton]'
})
export class PrimeButtonDirective extends ButtonDirective implements AfterViewInit {
  private _defaultClass = 'p-button p-component';

  @Input() className = '';

  @HostBinding('class') get class() {
    return `${this._defaultClass} ${this.className}`;
  }

  @Input() get icon(): string {
    return this._icon;
  }

  set icon(val: string) {
    this._icon = val;
    this.updateDisplayIcon();

    if (this.initialized) {
      if (this.iconPos) {
        DomHandler.findSingle(this.el.nativeElement, '.p-button-icon').className =
          'p-button-icon p-button-icon-' + this.iconPos + ' ' + this._icon;
      } else {
        DomHandler.findSingle(this.el.nativeElement, '.p-button-icon').className = 'p-button-icon ' + this._icon;
      }
    }
  }

  private iconElement: HTMLSpanElement;

  constructor(public el: ElementRef) {
    super(el);
  }

  ngAfterViewInit() {
    // gestion de l'icone
    this.iconElement = document.createElement('span');
    this.iconElement.className = 'p-button-icon';
    this.iconElement.setAttribute('aria-hidden', 'true');
    const iconPosClass = this.label ? 'p-button-icon-' + this.iconPos : null;
    if (iconPosClass) {
      DomHandler.addClass(this.iconElement, iconPosClass);
    }

    if (this.icon) {
      DomHandler.addMultipleClasses(this.iconElement, this.icon);
    }
    this.el.nativeElement.appendChild(this.iconElement);

    // gestion du label
    const labelElement = document.createElement('span');
    if (this.icon && !this.label) {
      labelElement.setAttribute('aria-hidden', 'true');
    }
    this.updateDisplayIcon();
    labelElement.className = 'p-button-label';
    if (this.label) {
      labelElement.appendChild(document.createTextNode(this.label));
    } else {
      labelElement.innerHTML = '&nbsp;';
    }
    // labelElement.appendChild(document.createTextNode(this.label || '&nbsp;'));
    this.el.nativeElement.appendChild(labelElement);
    this.initialized = true;
  }

  private updateDisplayIcon() {
    if (this.iconElement) {
      this.iconElement.style.display = this.icon ? '' : 'none';
    }
  }

  getStyleClass(): string {
    return '';
  }

  setStyleClass() {}
}
