import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcess } from '@compiere-ws/models/compiere-process-json';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import { UploadedFile } from '@iupics-components/models/uploaded-file';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { MongoSearchQueryCombination } from '@iupics-manager/models/mongo-search';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, debounce, has, isNil } from 'lodash';
import { Subscription, zip } from 'rxjs';
import {
  SpecificWindowUiComponent,
  TableForProcess,
  TableForProcessRow
} from '../specific-window-ui/specific-window-ui.component';
import {
  formatAttachments,
  getComponentRequest,
  getProductRequest,
  OperationComponent,
  OperationProduct
} from './operation-management-utils';

@Component({
  selector: 'iu-operation-management-ui',
  templateUrl: './operation-management-ui.component.html',
  styleUrls: ['./operation-management-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OperationManagementUIComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly FULLSCREEN_CLASS = 'specific-window-fullscreen';
  readonly OPERATION_COLUMN_NAME = 'Operation Management Table Operation';
  readonly PRODUCT_COLUMN_NAME = 'Operation Management Table Product';
  readonly COMPONENT_COLUMN_NAME = 'Operation Management Table Component';
  readonly PRODUCT_TABLE_NAME = 'operation_management_product_v';
  readonly COMPONENT_TABLE_NAME = 'operation_management_component_v';

  private productTableFormId: number;
  private componentTableFormId: number;
  private operationTableFormId: number;
  @ViewChild('top', { read: ViewContainerRef, static: true }) vcrTop: ViewContainerRef;
  @ViewChild('grid', { read: ViewContainerRef, static: true }) vcrGrid: ViewContainerRef;
  @ViewChild('bottomLeft', { read: ViewContainerRef, static: true }) vcrBottomLeft: ViewContainerRef;

  uploadedFiles: UploadedFile[] = [];

  customDesignArray: CustomDesignItem[] = [
    {
      vcr: 'vcrGrid',
      type: CustomDesignItemType.GRID,
      tableName: this.OPERATION_COLUMN_NAME,
      isLabelDisplay: false,
      cssClass: 'grid-full-height',
      forcePaginationAutoPageSize: true,
      suppressRowClickSelection: false,
      tableHeight: '100%'
    },
    {
      vcr: 'vcrTop',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_ProductionRessource_ID',
      cssClass: 'p-col-3'
    },
    {
      vcr: 'vcrTop',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_User_ID',
      label: this.translateService.instant('operation-management.user'),
      cssClass: 'p-col-3'
    },
    {
      vcr: 'vcrTop',
      type: CustomDesignItemType.FIELD,
      columnName: 'StartDate',
      cssClass: 'p-col-3'
    },
    {
      vcr: 'vcrTop',
      type: CustomDesignItemType.FIELD,
      columnName: 'EndDate',
      cssClass: 'p-col-3'
    },
    {
      vcr: 'vcrTop',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_ProductionGroupe_ID',
      cssClass: 'p-col-3'
    },
    {
      vcr: 'vcrTop',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_Production_ID',
      cssClass: 'p-col-3'
    },
    {
      vcr: 'vcrTop',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_Product_ID',
      cssClass: 'p-col-3'
    },
    {
      vcr: 'vcrBottomLeft',
      type: CustomDesignItemType.FIELD,
      columnName: 'ZLancer',
      cssClass: 'p-col-3'
    },
    {
      vcr: 'vcrBottomLeft',
      type: CustomDesignItemType.FIELD,
      columnName: 'ZStop',
      cssClass: 'p-col-3'
    }
  ];

  fakeComponents: OperationComponent[] = [
    {
      M_Product_ID: { id: -1, displayValue: 'COMPONENT' },
      QtyConsumed: 0,
      QtyToConsume: 0,
      C_UOM_ID: { id: -1, displayValue: this.translateService.instant('operation-management.unit') }
    }
  ];

  components: OperationComponent[];

  fakeProducts: OperationProduct[] = [
    {
      Data_UUID: '',
      M_Product_ID: { id: -1, displayValue: 'PRODUCT' },
      M_AttributeSetInstance_ID: { id: -1, displayValue: 'ATTRIBUTE' },
      QtyProduced: 0,
      QtyToProduce: 0,
      QtyEntered: 0,
      C_UOM_ID: this.translateService.instant('operation-management.unit')
    }
  ];

  products: OperationProduct[];

  private _currentState: OperationManagementState = OperationManagementState.START;
  get currentState(): OperationManagementState {
    return this._currentState;
  }

  set currentState(val: OperationManagementState) {
    this._currentState = val;
    const gridView = this.gridViews.find((gv) => gv.data['AD_FormDetail_ID'] === this._tableFormDetailId);
    if (gridView) {
      gridView.GridTabInfinityScrollUiComponent.IsReadOnly = this._currentState != OperationManagementState.START;
    }
  }

  get productsTableHeight() {
    return this.getTableHeight('products');
  }

  get componentsTableHeight() {
    return this.getTableHeight('components');
  }

  private productAndCompoSubscription: Subscription;
  private window: Window;
  private _tableFormDetailId: number;
  private dataContainerZLancer: AbstractDataContainer;
  private dataContainerZStop: AbstractDataContainer;
  private _ofGridView: GridViewUiComponent;

  private get processIdZStop() {
    return this.dataContainerZStop.data['processId'];
  }
  private get processIdZLancer() {
    return this.dataContainerZLancer.data['processId'];
  }

  public ofGridTitle = '';
  private _productGridTitle: string;
  public get productGridTitle(): string {
    if (isNil(this._productGridTitle)) {
      const table = this.specificData.items.find((_item) => _item.data.columnName === this.PRODUCT_COLUMN_NAME);
      this._productGridTitle = table?.data.title;
    }
    return this._productGridTitle;
  }

  private _componentGridTitle: string;
  public get componentGridTitle(): string {
    if (isNil(this._componentGridTitle)) {
      const table = this.specificData.items.find((_item) => _item.data.columnName === this.COMPONENT_COLUMN_NAME);
      this._componentGridTitle = table?.data.title;
    }
    return this._componentGridTitle;
  }

  isExpended = true;

  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService,
    private docServerService: DocServerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.window = this.document.defaultView;
    this.notifySelect = debounce(this.notifySelect, 500);
  }

  ngOnInit() {
    super.ngOnInit();
    this.showSpecificWindow();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected onAfterBuildWindow() {
    this.productTableFormId = this.specificData.items.find(
      (item) => item.data.columnName === this.PRODUCT_COLUMN_NAME
    ).formDetailId;
    this.componentTableFormId = this.specificData.items.find(
      (item) => item.data.columnName === this.COMPONENT_COLUMN_NAME
    ).formDetailId;
    this.operationTableFormId = this.specificData.items.find(
      (item) => item.data.columnName === this.OPERATION_COLUMN_NAME
    ).formDetailId;
  }

  notifyFromDatacontainerInit(dataContainer: AbstractDataContainer) {
    switch (dataContainer.data.columnName) {
      case 'ZLancer':
        this.dataContainerZLancer = dataContainer;
        this.updateButton();
        break;
      case 'ZStop':
        this.dataContainerZStop = dataContainer;
        this.updateButton();
        break;
      default:
        break;
    }
  }

  notifyFromGridAfterViewInit(gridView: GridViewUiComponent) {
    super.notifyFromGridAfterViewInit(gridView);
    if (gridView.data.columnName === this.OPERATION_COLUMN_NAME) {
      this._ofGridView = gridView;
      this._tableFormDetailId = this._ofGridView.data['AD_FormDetail_ID'];
      this.ofGridTitle = this._ofGridView.gridTitle;
      this._ofGridView.cssClass = 'operation-management-grid-full-height';
    }
  }

  notifySelect(gridView: GridViewUiComponent, rowSelected?: any) {
    if (gridView.data.columnName === this.OPERATION_COLUMN_NAME && rowSelected?.data?.length > 0) {
      this.updateButtonReadOnly(this.processIdZLancer, true);
      this.updateButtonReadOnly(this.processIdZStop, true);
      this.productAndCompoSubscription?.unsubscribe();
      const productRequest: DataStoreRequest = getProductRequest(rowSelected, this.PRODUCT_TABLE_NAME);
      const componentRequest: DataStoreRequest = getComponentRequest(rowSelected, this.COMPONENT_TABLE_NAME);

      this.productAndCompoSubscription = zip(
        this.store.getDataGrid(productRequest),
        this.store.getDataGrid(componentRequest)
      ).subscribe(
        ([productResponse, componentResponse]) => {
          this.products = productResponse.data as OperationProduct[];
          this.components = componentResponse.data as OperationComponent[];

          this.updateButtonReadOnly(this.processIdZLancer, rowSelected?.data?.length > 1);
          this.updateButtonReadOnly(this.processIdZStop, false);
        },
        () => {
          this.products = undefined;
          this.components = undefined;
          this.updateButtonReadOnly(this.processIdZLancer, rowSelected?.data?.length > 1);
          this.updateButtonReadOnly(this.processIdZStop, false);
        }
      );

      const query = this.docServerService.createQuery(
        rowSelected.data.map((row) => ({ 'META|M_BOM_ID': row['M_BOM_ID'] })),
        MongoSearchQueryCombination.AND,
        true,
        true
      );
      this.docServerService
        .advancedSearchDocuments(query, { start: 0, limit: -1, attachmentInteraction: false })
        .subscribe((response) => (this.uploadedFiles = formatAttachments(response)));
    } else {
      this.products = undefined;
      this.components = undefined;
      this.uploadedFiles = [];
    }
  }

  notifyFromDataChange(item?: any) {
    if (item.data.isLaunchSearchGrid) {
      if (this.getMissingMantoryField().length == 0) {
        this.resetState();
        this.refreshGrids(this.dataStore, false, item.data);
      }
    }
  }

  getMissingMantoryField(): string[] {
    const mandatoryFields = [];
    const mandatoryDataContainers = this.dataContainers.filter((dc) => dc.data.isMandatory);
    if (mandatoryDataContainers.length > 0) {
      for (let i = 0; i < mandatoryDataContainers.length; i++) {
        const dataContainer = mandatoryDataContainers[i];
        const column = dataContainer.data.columnName;
        if (dataContainer && dataContainer.data && dataContainer.data.isMandatory) {
          if (
            this.dataStore &&
            (this.dataStore.data[column] === null || this.dataStore.data[column] === undefined) &&
            (dataContainer.fieldValue === null || dataContainer.fieldValue === undefined)
          ) {
            mandatoryFields.push(dataContainer.label);
          }
        }
      }
    }
    return mandatoryFields;
  }

  protected executeProcess(processId: number, additionalParams?: any) {
    if (this.processIdZLancer === processId) {
      additionalParams = {
        State: this.currentState
      };
      this.updateButtonReadOnly(this.processIdZStop);
    } else if (this.processIdZStop === processId) {
      additionalParams = {
        State: OperationManagementState.endState(this.currentState)
      };
      this.updateButtonReadOnly(this.processIdZLancer);
    }
    super.executeProcess(processId, additionalParams);
  }

  private updateButton(processId: number = 0) {
    if (!this.dataContainerZLancer || !this.dataContainerZStop) {
      return;
    }

    if (processId > 0) {
      if (this.processIdZLancer === processId) {
        this.currentState = OperationManagementState.nextState(this.currentState);
        this.updateButtonReadOnly(this.processIdZStop, false);
      } else if (this.processIdZStop === processId) {
        this.currentState = OperationManagementState.START;
        this.updateButtonReadOnly(this.processIdZLancer, false);
      }
    }

    this.dataContainerZLancer['type'] = 'big-btn p-button-success';
    this.dataContainerZStop['type'] = 'big-btn p-button-error';
    this.dataContainerZStop['icon'] = 'icon-stop';
    switch (this.currentState) {
      case OperationManagementState.START:
        this.dataContainerZLancer['icon'] = 'icon-play';
        this.dataContainerZLancer.label = "Lancer l'opération";
        break;
      case OperationManagementState.PAUSE:
        this.dataContainerZLancer['icon'] = 'icon-pause';
        this.dataContainerZLancer['type'] = 'big-btn p-button-warning';
        this.dataContainerZLancer.label = 'Pause';
        break;
      case OperationManagementState.RESUME:
        this.dataContainerZLancer['icon'] = 'icon-play';
        this.dataContainerZLancer.label = 'Reprise';
        break;
    }
  }

  onEndProcess(ping: ProcessPingInfo = null) {
    if (ping && ping.AD_Process_ID && ping.AD_Process_ID.id) {
      setTimeout(() => {
        this.updateButtonReadOnly(ping.AD_Process_ID.id, false);
        this.updateButton(ping.AD_Process_ID.id);
        if (ping.AD_Process_ID.id === this.processIdZStop) {
          this.resetState(true);
        }
      }, 1500);
    }
  }

  private resetState(isFullReset = false) {
    this._ofGridView.GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();

    if (isFullReset) {
      this.dataStore.data['Z_ProductionGroupe_ID'] = null;
      this.dataStore.data['Z_Production_ID'] = null;
      this.dataStore.data['M_Product_ID'] = null;
      this.setDataContainersValueWithChangedStore(this.dataStore);
      this.reInitGridData(this.OPERATION_COLUMN_NAME);
    }
  }

  /** Update the QtyConsumed with the newValue */
  public updateComponentsQty(newValue: number, index: number) {
    this.components[index].QtyConsumed = Math.max(0, newValue);
  }

  /** Update the QtyProduced with the newValue and then update the components links to it */
  public updateProductQty(newValue: number, index: number) {
    const oldValue = this.products[index].QtyProduced;
    this.products[index].QtyProduced = Math.max(0, newValue);
    this.updateComponentsOfProduct(this.products[index], oldValue, newValue);
  }

  private updateComponentsOfProduct(product: OperationProduct, oldValue: number, newValue: number) {
    const oldFactor = oldValue / product.QtyEntered;
    const newFactor = newValue / product.QtyEntered;
    const filteredComponents = this.components.filter((component) => component.Z_Production_ID.id === product.Z_Production_ID);

    for (let i = 0; i < filteredComponents.length; i++) {
      const component = filteredComponents[i];
      component.QtyConsumed = Math.max(
        0,
        component.QtyConsumed - component.QtyToConsume * oldFactor + component.QtyToConsume * newFactor
      );
    }
  }

  protected getTablesForProcess(processEntity: CompiereProcess): TableForProcess[] {
    const tables = super.getTablesForProcess(processEntity);

    for (let i = 0; i < tables.length; i++) {
      const table = tables[i];
      switch (table.ad_FormDetail_ID) {
        case this.productTableFormId:
          table.rows = this.createTableForProcessRow(cloneDeep(this.products));
          break;
        case this.componentTableFormId:
          table.rows = this.createTableForProcessRow(cloneDeep(this.components));
          break;
        case this.operationTableFormId:
          table.rows = table.rows.map((value) => {
            value.columns['QtyToLaunch'] =
              this.products.find((p) => p.Z_Production_ID === value.columns.Z_Production_ID)?.QtyProduced ?? 0;
            return value;
          });
      }
    }

    return tables;
  }

  private createTableForProcessRow(datas: any[]) {
    const rows: TableForProcessRow[] = [];
    for (let i = 0; i < datas.length; i++) {
      const data = datas[i];
      const splittedDataUUID = data.Data_UUID.split(',');
      const keys = Object.keys(data);
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j];
        data[key] = has(data[key], 'id') ? data[key].id : data[key];
      }
      rows.push({
        record_ID: splittedDataUUID.length > 1 ? splittedDataUUID[1] : splittedDataUUID[0],
        columns: data
      });
    }

    return rows;
  }

  protected checkBeforeProcessing(dataContainer: any) {
    const missingFieldLabels = this.getMissingMantoryField();
    if (missingFieldLabels.length > 0) {
      this.checkAndShowMandatory();
      return false;
    } else {
      const dataSelection = this.dataStore.data.selections.find(
        (_selection) => _selection.AD_FormDetail_ID === this._tableFormDetailId
      );
      if (!Boolean(dataSelection?.selection?.length > 0)) {
        MessageManagerService.newMessageStatic(
          new IupicsMessage(this.translateService.instant('generic.error'), 'Aucune opération sélectionnée', 'warning')
        );
      } else {
        return true;
      }
    }
  }

  private getActualHeight(el: HTMLElement) {
    const computedStyle = this.window.getComputedStyle(el);
    const height = el.offsetHeight;
    const marginTop = parseInt(computedStyle.getPropertyValue('margin-top'), 10);
    const marginBottom = parseInt(computedStyle.getPropertyValue('margin-bottom'), 10);
    return height + marginTop + marginBottom;
  }

  private getInnerHeight(el: HTMLElement) {
    const computedStyle = this.window.getComputedStyle(el);
    const height = el.offsetHeight;
    const paddingTop = parseInt(computedStyle.getPropertyValue('padding-top'), 10);
    const paddingBottom = parseInt(computedStyle.getPropertyValue('padding-bottom'), 10);
    const borderTop = parseInt(computedStyle.getPropertyValue('border-top-width'), 10);
    const borderBottom = parseInt(computedStyle.getPropertyValue('border-bottom-width'), 10);
    return Math.max(0, height - paddingTop - paddingBottom - borderTop - borderBottom);
  }

  /**
   * $1: specificContainer\
   * $2: rightPanel\
   * $3: fieldPanel\
   * $4: hr\
   * $5: tablePanel\
   * $6: tableH2\
   * $1.innerHeight - $2.paddingTop - $2.paddingBottom - $3.actualHeight - $4.actualheight - $5.paddingTop - $5.paddingBottom - $6.actualHeight
   */
  private getTableHeight(id: string) {
    const specificContainer = this.specificContainer.nativeElement;
    const rightPanel = this.document.getElementById('right-panel');
    const rightPanel_computedStyle = this.window.getComputedStyle(rightPanel);
    const rightPanel_paddingTop = parseInt(rightPanel_computedStyle.getPropertyValue('padding-top'), 10);
    const rightPanel_paddingBottom = parseInt(rightPanel_computedStyle.getPropertyValue('padding-bottom'), 10);
    const fieldPanel = this.document.getElementById('field-panel');
    const hr = this.document.getElementById('hr');
    const tablePanel = this.document.getElementById(`${id}-table-panel`);
    const tablePanel_computedStyle = this.window.getComputedStyle(tablePanel);
    const tablePanel_paddingTop = parseInt(tablePanel_computedStyle.getPropertyValue('padding-top'), 10);
    const tablePanel_paddingBottom = parseInt(tablePanel_computedStyle.getPropertyValue('padding-bottom'), 10);
    const tableH2 = tablePanel.getElementsByTagName('h2').item(0);

    return (
      this.getInnerHeight(specificContainer) -
      rightPanel_paddingTop -
      rightPanel_paddingBottom -
      this.getActualHeight(fieldPanel) -
      this.getActualHeight(hr) -
      tablePanel_paddingTop -
      tablePanel_paddingBottom -
      this.getActualHeight(tableH2)
    );
  }
}

enum OperationManagementState {
  START = 'START',
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  STOP = 'STOP',
  END = 'END'
}

namespace OperationManagementState {
  export function nextState(currentState: OperationManagementState): OperationManagementState {
    switch (currentState) {
      case OperationManagementState.START:
        return OperationManagementState.PAUSE;
      case OperationManagementState.PAUSE:
        return OperationManagementState.RESUME;
      case OperationManagementState.RESUME:
        return OperationManagementState.PAUSE;
      case OperationManagementState.STOP:
        return OperationManagementState.START;
      case OperationManagementState.END:
        return OperationManagementState.START;
    }
  }
  export function endState(currentState: OperationManagementState): OperationManagementState {
    return currentState === OperationManagementState.START ? OperationManagementState.END : OperationManagementState.STOP;
  }
}
