import { HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CompiereNotification, CompiereNotificationType } from '@compiere-ws/models/compiere-notification-json';
import { CompiereNotificationService } from '@compiere-ws/services/compiere-notification/compiere-notification.service';
import { PrintReportService } from '@compiere-ws/services/compiere-print-report/print-report.service';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { MessageManagerService } from '../message/message-manager.service';

@Injectable()
export class NotificationManagerService {
  onChangeRoleChannel = new EventEmitter<any>();
  onRoleChanged = new EventEmitter<any>();
  private syncWaitingNotifications: {
    [key: string]: Subject<CompiereNotification>;
  } = {};

  constructor(
    private printReportService: PrintReportService,
    private messageService: MessageService,
    private compiereNotificationService: CompiereNotificationService,
    private socketService: SocketService,
    private docServerService: DocServerService
  ) {}

  getNotifications(
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<HttpResponse<CompiereNotification[]>> {
    return this.compiereNotificationService.getNotifications(notificationType);
  }

  handleNotification(
    notif: CompiereNotification,
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<HttpResponse<CompiereNotification>> {
    return this.compiereNotificationService.handleNotification(notif, notificationType);
  }

  handleAllNotification(
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<HttpResponse<CompiereNotification>> {
    return this.compiereNotificationService.handleAllNotification(notificationType);
  }

  closeNotification(
    notif: CompiereNotification,
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<CompiereNotification> {
    return this.compiereNotificationService.closeNotification(notif, notificationType);
  }

  closeAllNotification(
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<CompiereNotification> {
    return this.compiereNotificationService.closeAllNotification(notificationType);
  }

  changeRoleChannel(previous: number, next: number): void {
    this.onChangeRoleChannel.emit({ previousRole: previous, nextRole: next });
  }

  roleChanged(): void {
    this.onRoleChanged.emit();
  }

  popNotification(...notifications: CompiereNotification[]) {
    notifications.forEach((notification) => {
      this.messageService.add({
        summary: notification.title,
        detail: notification.summary,
        data: { notification: notification, type: 'Notification' }
      });
    });
  }

  syncWithNotification(channelId: string): Subject<CompiereNotification> {
    this.syncWaitingNotifications[channelId] = new Subject();
    (async () => {
      for await (const notification of this.socketService.enableRoleNotifications()) {
        const foundChannelId = (<CompiereNotification>notification).processChannelId
          ? (<CompiereNotification>notification).processChannelId
          : (<CompiereNotification>notification).processParams?.channel_id;
        if (foundChannelId === channelId) {
          this.syncWaitingNotifications[channelId].next(notification);
          delete this.syncWaitingNotifications[channelId];
        }
      }
    })();
    return this.syncWaitingNotifications[channelId];
  }

  downloadReport(url: string): void {
    if (url.startsWith('/download?url=/download/')) {
      this.docServerService.downloadDocument(url.replace(/^\/download\?url=/, '')).subscribe((response) => {
        setTimeout(async () => {
          const { body } = response;
          if (body instanceof Blob && body.size < 512) {
            const text = await body.text();
            if (text.startsWith('Could not find document')) {
              MessageManagerService.newMessageStatic(new IupicsMessage('Error', text, 'error'));
              return;
            }
          }
          Global.downloadFile(response);
        }, 100);
      });
    } else {
      const unSubObj = this.printReportService.downloadReport(url).subscribe((response) =>
        setTimeout(() => {
          Global.downloadFile(response);
          unSubObj.unsubscribe();
        }, 100)
      );
    }
  }
}
