import { EventEmitter, Injectable } from '@angular/core';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DataConflict } from '@iupics-manager/models/data-conflict';

export interface MessageDelete {
  type: string;
  message: string;
}

export interface CompiereDeleteDataJson {
  messages: MessageDelete[];
  passThrough: boolean;
  commands?: any;
  targetWinCmd: number;
  newConfirmedFieldValue?: any;
  changedFields?: any;
  changedDropDowns?: any;
  changedContext?: any;
  changedComponents?: any;
  rowData?: any;
  queryComponents?: any;
  updateCurrentRow: boolean;
  updateWindowVO: boolean;
  newWindowVO?: any;
  trxInfo?: any;
  links: any[];
  processID: number;
  formId: number;
  refreshAll: boolean;
  refreshChildRows: boolean;
}

export interface CompiereDataJSON {
  data: any[][];
  fields: string[];
  tabId: number;
}

export interface DataStoreRequest {
  windowId: number;
  record_id?: string;
  parent_constraint?: string;
  compiereRequest: CompiereDataGridRequestJSON;
  isUserFilter?: boolean;
}

export interface CompiereDataGridRequestJSON {
  ad_language?: string;
  windowType?: CompiereDataGridType;
  entityId?: number;
  startRow?: number;
  endRow?: number;
  rowGroupCols?: CompiereDataGridGroupModel[];
  valueCols?: CompiereDataGridGroupModel[];
  headerCols?: CompiereDataGridGroupModel[];
  pivotCols?: CompiereDataGridGroupModel[];
  pivotMode?: boolean;
  groupKeys?: any[];
  filterModel?: {
    [columnName: string]: CompiereDataGridFilterModel;
  };
  sortModel?: CompiereDataGridSortModel[];
  validation?: string;
  query?: string;
  parent_formDetail_id?: number;
  label?: string;
  isDefault?: boolean;
  searchEntityType?: any;
  searchKeyColumn?: string;
  tableName?: string;
  windowCtx?: any;
}

export interface CompiereDataGridResponseJSON {
  data: {}[];
  data_UUID: string[];
  displayData: {};
  lastRow: number;
  secondaryColumnFields: any[];
  dataStoreKeys?: {};
  compiereRequest?: CompiereDataGridRequestJSON;
}
export interface CompiereDataJSON2 {
  data: {}[];
  data_UUID: string[];
  displayData: {};
  lastRow: number;
  secondaryColumnFields: any[];
  tab_id?: number;
}

export interface CompiereDataGridFilterModel {
  operators: OperatorFilterType[];
  values: any[];
  filterType: CompiereDataGridFilterType;
}

export enum CompiereDataGridFilterType {
  NUMBER = 'number',
  TEXT = 'text',
  DATE = 'date',
  NONE = 'none',
  SET = 'set'
}

export enum CompiereDataGridSortModelType {
  ASC = 'asc',
  DESC = 'desc'
}

export interface CompiereDataGridSortModel {
  colId: string;
  sort: CompiereDataGridSortModelType;
}

export interface CompiereDataGridGroupModel {
  aggFunc?: string;
  displayName: string;
  field: string;
  id: string;
}

export enum CompiereDataGridType {
  WINDOW = 'W',
  FORM = 'F',
  SEARCH_WINDOW = 'S',
  TABLE = 'T'
}
export enum CompiereDataFieldType {
  FIELD = 'F',
  FORM_ITEM = 'I',
  PROCESS_PARA = 'P',
  COLUMN_INFO = 'C'
}

export enum DataStoreState {
  VISIBLE = 'visible',
  HIDDEN = 'hidden'
}

export enum DataStoreStatus {
  SYNC = 'sync',
  NOTSYNC = 'not sync',
  NEWRECORD = 'new record'
}

export interface DataStoreKey {
  parentId: string;
  windowId: number;
  tabId: number;
  recordId: string;
}

export class DataStoreSet {
  data: { [key: string]: DataStore };
  nextSeqNo: number;
  orderKeys: string[];
  lastRowRemote: number;

  constructor() {
    this.nextSeqNo = 0;
    this.data = {};
    this.orderKeys = [];
    this.lastRowRemote = -1;
  }
}
export enum DataStoreName {
  CURRENT = 'current',
  OLD = 'old',
  REMOTE = 'remote'
}
export enum CalloutStatus {
  WAITING = 'waiting',
  LOADING = 'loading'
}
@Injectable()
export class DataStore {
  key: DataStoreKey;
  state: DataStoreState;
  status: DataStoreStatus;
  calloutStackEmptied: EventEmitter<boolean> = new EventEmitter();
  dataChange: EventEmitter<any>;
  dataConflict: EventEmitter<DataConflict>;
  initContextField: EventEmitter<any>;
  data: any;
  seqNo: number;
  isCopied = false;
  calloutStates: Map<string, CalloutStatus> = new Map<string, CalloutStatus>();
  subscribers: AbstractDataContainer[] = [];
  currentContext: any;
  constructor() {
    this.state = DataStoreState.VISIBLE;
    this.status = DataStoreStatus.SYNC;
    this.dataChange = new EventEmitter();
    this.dataConflict = new EventEmitter();
    this.initContextField = new EventEmitter();
    this.data = {};
  }
  addSubscriber(subscriber: AbstractDataContainer) {
    if (!this.subscribers.includes(subscriber)) {
      this.subscribers.push(subscriber);
    }
  }
  queueCallout(calloutName: string) {
    if (!['apiz_dataResult', 'apiz_ctxChanged'].includes(calloutName)) {
      this.calloutStates.set(calloutName, CalloutStatus.WAITING);
    }
  }
  startCallout(calloutName: string) {
    if (!['apiz_dataResult', 'apiz_ctxChanged'].includes(calloutName)) {
      this.calloutStates.set(calloutName, CalloutStatus.LOADING);
    }
  }
  endCallout(calloutName: string) {
    if (!['apiz_dataResult', 'apiz_ctxChanged'].includes(calloutName)) {
      this.calloutStates.delete(calloutName);
      if (this.calloutStates.size === 0) {
        this.calloutStackEmptied.emit(true);
      }
    }
  }

  addContextData(data: {}, ctxPrefix = '') {
    // console.log(
    //   cloneDeep(data),
    //   '\n',
    //   cloneDeep(ctxPrefix),
    //   '\n',
    //   cloneDeep(this.currentContext),
    //   '\n',
    //   cloneDeep(this.data),
    //   '\n'
    // );
    if (ctxPrefix) {
      Object.keys(this.data).forEach((key) => {
        if (key.startsWith(ctxPrefix)) {
          delete this.data[key];
          if (this.currentContext) {
            delete this.currentContext[key];
          }
        }
      });
    }
    // console.log(
    //   cloneDeep(data),
    //   '\n',
    //   cloneDeep(ctxPrefix),
    //   '\n',
    //   cloneDeep(this.currentContext),
    //   '\n',
    //   cloneDeep(this.data),
    //   '\n'
    // );
    Object.keys(data).forEach((key) => {
      this.data[ctxPrefix + key] = data[key];
      if (this.currentContext) {
        this.currentContext[ctxPrefix + key] = data[key];
      }
    });
  }
}
