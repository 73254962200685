import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { DocserverWsResponse } from '@compiere-ws/models/docserverWsResponse';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { UploadedFile } from '@iupics-components/models/uploaded-file';

export function formatAttachments(response: DocserverWsResponse): UploadedFile[] {
  return response.entries.map(
    (hit) =>
      ({
        src: hit.url,
        name: hit.fileName,
        id: hit.id,
        docId: hit.docId,
        attachment_ID: hit.attachment_ID,
        extension: hit.fileName
          ? hit.fileName.split('.').length > 1
            ? hit.fileName.split('.')[hit.fileName.split('.').length - 1]
            : undefined
          : undefined,
        isDeletable: false
      } as UploadedFile)
  );
}

export function getComponentRequest(rowSelected: any, tableName: string): DataStoreRequest {
  return {
    windowId: undefined,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName,
      headerCols: [
        {
          displayName: 'M_Product_ID',
          field: 'M_Product_ID',
          id: 'M_Product_ID'
        },
        {
          displayName: 'QtyConsumed',
          field: 'QtyConsumed',
          id: 'QtyConsumed'
        },
        {
          displayName: 'QtyToConsume',
          field: 'QtyToConsume',
          id: 'QtyToConsume'
        },
        {
          displayName: 'C_UOM_ID',
          field: 'C_UOM_ID',
          id: 'C_UOM_ID'
        },
        {
          displayName: 'M_AttributeSetInstance_ID',
          field: 'M_AttributeSetInstance_ID',
          id: 'M_AttributeSetInstance_ID'
        },
        {
          displayName: 'M_Locator_ID',
          field: 'M_Locator_ID',
          id: 'M_Locator_ID'
        },
        {
          displayName: 'Z_Production_Operation_ID',
          field: 'Z_Production_Operation_ID',
          id: 'Z_Production_Operation_ID'
        },
        {
          displayName: 'Z_Production_ID',
          field: 'Z_Production_ID',
          id: 'Z_Production_ID'
        }
      ],
      filterModel: {
        Z_Production_Operation_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [(rowSelected.data || []).map((item: any) => item.Z_Production_Operation_ID)]
        }
      }
    }
  };
}

export function getProductRequest(rowSelected: any, tableName: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName,
      headerCols: [
        {
          displayName: 'M_Product_ID',
          field: 'M_Product_ID',
          id: 'M_Product_ID'
        },
        {
          displayName: 'M_AttributeSetInstance_ID',
          field: 'M_AttributeSetInstance_ID',
          id: 'M_AttributeSetInstance_ID'
        },
        {
          displayName: 'QtyProduced',
          field: 'QtyProduced',
          id: 'QtyProduced'
        },
        {
          displayName: 'QtyToProduce',
          field: 'QtyToProduce',
          id: 'QtyToProduce'
        },
        {
          displayName: 'QtyEntered',
          field: 'QtyEntered',
          id: 'QtyEntered'
        },
        {
          displayName: 'C_UOM_ID',
          field: 'C_UOM_ID',
          id: 'C_UOM_ID'
        },
        {
          displayName: 'Z_Production_ID',
          field: 'Z_Production_ID',
          id: 'Z_Production_ID'
        }
      ],
      filterModel: {
        Z_Production_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [(rowSelected.data || []).map((item: any) => item.Z_Production_ID)]
        }
      }
    }
  };
}

export interface Operation {
  components: OperationComponent[];
  ressources: OperationRessource[];
  attachedFile: UploadedFile[];
  description: string;
  deltaTime: string;
  note: string;
  status: IAutocomplete;
}

export interface OperationComponent {
  M_Product_ID: IAutocomplete;
  QtyConsumed: number;
  QtyToConsume: number;
  C_UOM_ID: IAutocomplete;
  Z_Production_ID?: IAutocomplete;
  [key: string]: any;
}

export interface OperationRessource {
  name: string;
  baseTime: number;
  usedTime: number;
}

export interface OperationProduct {
  Data_UUID: string;
  M_Product_ID: IAutocomplete;
  M_AttributeSetInstance_ID: IAutocomplete;
  QtyProduced: number;
  QtyToProduce: number;
  QtyEntered: number;
  C_UOM_ID: IAutocomplete;
  Z_Production_ID?: number;
}
