import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IupicsComponentsModule } from '@iupics-components/iupics-components.module';
import { PrimengModule } from '@iupics-components/primeng.module';
import { ChartWidgetComponent } from './components/chart-widget/chart-widget.component';
import { GridWidgetComponent } from './components/grid-widget/grid-widget.component';
import { ListWidgetComponent } from './components/list-widget/list-widget.component';
import { MyAlertsWidgetUiComponent } from './components/my-alerts-widget-ui/my-alerts-widget-ui.component';
import { RecentItemWidgetUiComponent } from './components/recent-item-widget-ui/recent-item-widget-ui.component';
@NgModule({
  declarations: [
    ChartWidgetComponent,
    ListWidgetComponent,
    GridWidgetComponent,
    RecentItemWidgetUiComponent,
    MyAlertsWidgetUiComponent
  ],
  imports: [CommonModule, PrimengModule, IupicsComponentsModule],
  exports: [
    ChartWidgetComponent,
    ListWidgetComponent,
    GridWidgetComponent,
    RecentItemWidgetUiComponent,
    MyAlertsWidgetUiComponent
  ]
})
export class IupicsWidgetsModule {}
