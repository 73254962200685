import {
  CompiereDataGridFilterType,
  CompiereDataGridSortModelType,
  CompiereDataGridType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { GanttData, Link, LinkType, Task } from '@iupics-components/models/gantt.model';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import * as moment from 'moment';

export const CALENDAR_ID = 'planning-window-calendar-01';

export function getGroupRequest(
  entityId: number,
  parent_formDetail_id: number,
  pageNumber = 1,
  paginationStep = 30
): DataStoreRequest {
  return {
    windowId: undefined,
    parent_constraint: undefined,
    compiereRequest: {
      entityId,
      parent_formDetail_id,
      windowType: CompiereDataGridType.FORM,
      startRow: (pageNumber - 1) * paginationStep,
      endRow: pageNumber * paginationStep,
      filterModel: {
        DocStatus: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [['DR', 'IP']]
        }
      },
      sortModel: [
        { colId: 'StartDate', sort: CompiereDataGridSortModelType.ASC },
        { colId: 'DocumentNo', sort: CompiereDataGridSortModelType.ASC }
      ]
    }
  };
}

export function getOFRequest(formDetailId: number, parentFormDetailId: number, ids: number[]): DataStoreRequest {
  return {
    windowId: undefined,
    parent_constraint: undefined,
    compiereRequest: {
      entityId: formDetailId,
      parent_formDetail_id: parentFormDetailId,
      windowType: CompiereDataGridType.FORM,
      startRow: 0,
      endRow: -1,
      filterModel: {
        Z_ProductionGroupe_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [ids]
        }
      },
      sortModel: [{ colId: 'DocumentNo', sort: CompiereDataGridSortModelType.ASC }]
    }
  };
}

export function getOperationRequest(formDetailId: number, parentFormDetailId: number, ids: number[]): DataStoreRequest {
  return {
    windowId: undefined,
    parent_constraint: undefined,
    compiereRequest: {
      entityId: formDetailId,
      parent_formDetail_id: parentFormDetailId,
      windowType: CompiereDataGridType.FORM,
      startRow: 0,
      endRow: -1,
      filterModel: {
        Z_Production_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [ids]
        }
      },
      sortModel: [{ colId: 'SeqNo', sort: CompiereDataGridSortModelType.ASC }]
    }
  };
}

export function convertToGanttData(groups: any[], productions: any[], operations: any[]): GanttData {
  const data: GanttTask[] = [];
  const links: Link[] = [];
  for (let i = 0; i < groups.length; i++) {
    const g = groups[i];

    if (g.production_startdate === null || (g.production_enddate === null && g.EndDate === null)) {
      continue;
    }
    const task_g = groupToGanttTask(g);
    data.push(task_g);

    const _productions = productions.filter((prod) => prod.Z_ProductionGroupe_ID.id === g.Z_ProductionGroupe_ID);
    for (let j = 0; j < _productions.length; j++) {
      const p = _productions[j];
      if (p.StartDate === null || p.EndDate === null) {
        continue;
      }
      const task_p = productionToGanttTask(task_g, p);
      data.push(task_p);
      if (j > 0) {
        links.push({
          id: data[data.length - 1].id,
          source: data[data.length - 2].id + '',
          target: data[data.length - 1].id + '',
          type: LinkType.END_TO_START
        });
      }

      const _operations = operations.filter((op) => op.Z_Production_ID.id === p.Z_Production_ID);
      for (let k = 0; k < _operations.length; k++) {
        const o = _operations[k];
        data.push(operationToGanttTask(task_p, o));
        if (k > 0) {
          links.push({
            id: data[data.length - 1].id,
            source: data[data.length - 2].id + '',
            target: data[data.length - 1].id + '',
            type: LinkType.END_TO_START
          });
        }
      }
    }
  }
  return { data, links };
}

export function groupToGanttTask(g: any): GanttTask {
  const g_startdate = formatDate(g.StartDate);
  const duration = g.ProductionTime;
  // const g_enddate = formatDate(g.EndDate);
  return {
    id: g.Z_ProductionGroupe_ID,
    po_id: g.Z_ProductionGroupe_ID,
    reference: g.DocumentNo,
    description: g.Description,
    start_date: g_startdate,
    duration: duration * 60,
    static_duration: duration,
    po_type: 'G',
    $has_child: true,
    $open: false,
    po_docStatus: g.DocStatus,
    po_data: g
  };
}

export function productionToGanttTask(g: GanttTask, p: any): ProductionGanttTask {
  const p_startdate = formatDate(p.StartDate);
  // const p_enddate = formatDate(p.EndDate);
  // task.autocomplete_template = `<span>${task['M_Product_ID'].id}: ${task['M_Product_ID'].displayValue}</span>`;
  const task: ProductionGanttTask = {
    id: g.id + '.' + p.Z_Production_ID,
    po_id: p.Z_Production_ID,
    reference: p.DocumentNo,
    description: p.Description,
    start_date: p_startdate,
    duration: p.ProductionTime * 60,
    static_duration: p.ProductionTime,
    parent: g.id,
    po_type: 'OF',
    $has_child: true,
    $open: false,
    M_Product_ID: p.M_Product_ID,
    QtyEntered: p.QtyEntered,
    DocStatus: p.DocStatus,
    C_Order_ID: p.C_Order_ID,
    C_BPartner_ID: p.C_BPartner_ID,
    ZDueDate: p.ZDueDate,
    po_docStatus: p.DocStatus,
    po_data: p
  };

  ['M_Product_ID', 'C_Order_ID', 'C_BPartner_ID'].forEach((key) => {
    task[`template_${key}`] = `<span>${task[key].id}: ${task[key].displayValue}</span>`;
  });

  return task;
}
export function operationToGanttTask(p: GanttTask, o: any): GanttTask {
  const o_startdate = o.StartDate ? formatDate(o.StartDate) : formatDate(p.start_date as string);
  // const o_enddate = o.EndDate ? formatDate(o.EndDate) : formatDate(p.end_date as string);
  const owner_id = (new Date().getTime() % 5) + 5;

  return {
    id: p.id + '.' + o.Z_Production_Operation_ID,
    po_id: o.Z_Production_Operation_ID,
    reference: o.Value,
    description: o.Name || o.M_ProductOperation_ID?.displayValue,
    start_date: o_startdate,
    duration: o.ProductionTime * 60,
    static_duration: o.ProductionTime,
    po_type: 'OP',
    parent: p.id,
    owner_id,
    $open: false,
    po_data: o
  };
}

export const compiereMomentFormat = 'YYYY-MM-DD HH:mm:ss';
export const ganttMomentFormat = 'YYYY-MM-DD HH:mm:ss';
// export const ganttDateFormat = '%Y-%m-%d %H:%i:%s';

export function formatDate(date: string, fromFormat = compiereMomentFormat): Date {
  return moment(date, fromFormat).toDate();
}

export class GanttTask extends Task {
  reference: string; // DocumentNo(OF)/Value(OP)
  // product?: IAutocomplete; // M_Product_ID(OF)/''(OP)
  // qty_to_produce?: number; // QtyToProduce(OF/OP)
  start_date: string | Date; // StartDate (OF/OP)
  end_date?: string | Date; // EndDate (OF/OP)
  duration: number; // ProductionTime (OF/OP)
  // is_sub_contracting?: boolean; // ZIsSubContracting(OF)/IsSubContracting(OP)
  po_type: 'G' | 'OF' | 'OP';
  po_id: number;
  po_data: any;
  po_docStatus?: any;
}

export interface ProductionGanttTask extends GanttTask {
  M_Product_ID: IAutocomplete;
  QtyEntered: number;
  DocStatus: string;
  C_Order_ID: IAutocomplete;
  C_BPartner_ID: IAutocomplete;
  ZDueDate: string | Date;
  templates?: any;
}
