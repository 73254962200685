<span [pTooltip]="tooltip" life="3" tooltipZIndex="2100" showDelay="500" tooltipPosition="top">
  <label *ngIf="this.isLabelDisplay" for="float-input">{{
    label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
  }}</label>
  <textarea
    #input
    (change)="dataChange(input.value)"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($event)"
    [(ngModel)]="fieldValue"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    [attr.value]="fieldValue"
    [ngClass]="[
      hasConflict ? 'iu-input-text-conflict' : '',
      isReadOnly ? 'p-disabled' : '',
      cssClassMandatory || '',
      'ui-textarea',
      mandatoryCss || ''
    ]"
    [readonly]="isReadOnly ? 'disabled' : null"
    [rows]="shouldSyncHeightWithLine ? rows : 5"
    [style.font-size]="overridedCSS?.inputFontSize"
    [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
    [style.resize]="resize"
    [tabindex]="isReadOnly ? '-1' : null"
    autocomplete="no"
    autoResize="autoResize"
    data-cy="input-text"
    pInputTextarea
  ></textarea>

  <span
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="ui-inputgroup-addon p-autocomplete-conflict-star"
    (mouseenter)="opConflict.show($event)"
  >
    /!\
  </span>
  <iu-value-preference-panel
    *ngIf="showValuePrefPanel"
    [fieldValue]="fieldValue"
    [dataStored]="this.dataStored"
    [sourceComponent]="this"
    [data]="this.data"
    (closeEmitter)="showValuePrefPanel = !showValuePrefPanel"
  ></iu-value-preference-panel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  | <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{ conflictedData }}</a>
</p-overlayPanel>
