import {
  CompiereDataGridFilterType,
  CompiereDataGridGroupModel,
  CompiereDataGridSortModelType,
  CompiereDataGridType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api/treenode';

/**
 * Retourne la request pour obtenir le AD_Table_ID de l'arbre donné
 * @param AD_Tree_ID
 * @param ad_language
 */
export function getTableIDRequest(AD_Tree_ID: number, ad_language: string, translator: TranslateService): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_Tree',
      startRow: 0,
      endRow: 1,
      ad_language,
      filterModel: {
        AD_Tree_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [AD_Tree_ID],
          operators: [OperatorFilterType.EQUALS]
        }
      },
      headerCols: [
        { field: 'AD_Table_ID', id: 'AD_Table_ID', displayName: translator.instant('tree-maintenance.columns.AD_Table_ID') },
        { field: 'TreeType', id: 'TreeType', displayName: translator.instant('tree-maintenance.columns.TreeType') }
      ]
    }
  };
}

/**
 * Retourne la request pour la récupération du tableName de l'AD_Table_ID donné
 * @param AD_Table_ID
 * @param ad_language
 */
export function getTableNameRequest(AD_Table_ID: number, ad_language: string, translator: TranslateService): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_Table',
      startRow: 0,
      ad_language,
      filterModel: {
        AD_Table_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [AD_Table_ID],
          operators: [OperatorFilterType.EQUALS]
        }
      },
      headerCols: [{ field: 'TableName', id: 'TableName', displayName: translator.instant('tree-maintenance.columns.TableName') }]
    }
  };
}

/**
 * Retourne la request pour obtenir la définition des colonnes de l'AD_Table_ID donné
 * @param AD_Table_ID
 * @param ad_language
 */
export function getColumnsRequest(AD_Table_ID: number, columns: string[], ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_Column',
      startRow: 0,
      ad_language,
      headerCols: [
        { displayName: 'Name', field: 'Name', id: 'Name' },
        { displayName: 'IsKey', field: 'IsKey', id: 'IsKey' },
        { displayName: 'ColumnName', field: 'ColumnName', id: 'ColumnName' }
      ],
      sortModel: [{ colId: 'IsKey', sort: CompiereDataGridSortModelType.DESC }],
      validation: buildValidationColumn(columns),
      filterModel: {
        AD_Table_ID: {
          filterType: CompiereDataGridFilterType.SET,
          values: [AD_Table_ID],
          operators: [OperatorFilterType.EQUALS]
        }
      }
    }
  };
}

function buildValidationColumn(columns: string[]): string {
  return `(AD_Column.IsKey = 'Y' OR AD_Column.ColumnName IN (${columns
    .filter((c) => c !== '$COL_ID')
    .map((c) => "'" + c + "'")
    .join(',')}))`;
}

/**
 * Retourne la request pour la récupération des données dans la table donnée avec les colonnes souhaitées
 * @param tableName
 * @param headerCols
 * @param ad_language
 */
export function getDataFromTable(
  tableName: string,
  headerCols: CompiereDataGridGroupModel[],
  validation: string,
  ad_language: string
): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName,
      ad_language,
      headerCols,
      validation
    }
  };
}

export function buildValidationTreeNode(
  tableID: IAutocomplete,
  treeType: IAutocomplete,
  treeID: IAutocomplete,
  removed_ids: number[] = [],
  added_ids: number[] = []
): string {
  if (tableID?.displayValue && treeType?.id && treeID?.id) {
    let validation = `(${tableID.displayValue}.${tableID.displayValue}_ID not in (SELECT x.node_id FROM ${getNodeTableName(
      treeType
    )} x WHERE x.isActive = 'Y' AND x.AD_Tree_ID = ${treeID.id})`;
    if (removed_ids?.length > 0) {
      validation = validation.concat(
        ' OR ',
        `${tableID.displayValue}.${tableID.displayValue}_ID`,
        ' IN (',
        removed_ids.join(','),
        ')'
      );
    }
    if (added_ids?.length > 0) {
      validation = validation.concat(
        ' AND ',
        `${tableID.displayValue}.${tableID.displayValue}_ID`,
        ' NOT IN (',
        added_ids.join(','),
        ')'
      );
    }
    return validation + ')';
  } else {
    return '';
  }
}

function getNodeTableName(treeType: IAutocomplete): string {
  switch (treeType.id.toUpperCase()) {
    case 'BP':
    case 'PR':
    case 'CC':
    case 'CS':
    case 'CM':
    case 'CT':
    case 'WG':
    case 'MM':
      return `AD_TreeNode${treeType.id.toUpperCase()}`;
    default:
      return 'AD_TreeNode';
  }
}

export function formatTree(tree: TreeNode): TreeNode {
  for (let i = 0; i < tree.children.length; i++) {
    let node = tree.children[i];
    node.parent = tree;
    if (node.children?.length) {
      node = formatTree(node);
    }
  }
  return tree;
}

/**
 * Creation de la stack pour la fonction getInTree à partir d'un simple node
 * @param treenode
 * @param _stack
 */
export function pushInStack(treenode: TreeNode, _stack: any[] = []): any[] {
  _stack = [treenode, ..._stack];
  if (treenode.parent) {
    _stack = pushInStack(treenode.parent, _stack);
  }
  return _stack;
}

/**
 * Retourne le node se trouvant au bout de la call stack
 * @param tree
 * @param stack
 */
export function getInTree(tree: TreeNode[], stack: TreeNode[]): TreeNode {
  let node: TreeNode = tree.find((n) => n.data.id === stack[0].data.id);
  for (let i = 1; i < stack.length; i++) {
    const _node = stack[i];
    node = node.children.find((n) => n.data.id === _node.data.id);
  }
  return node;
}

/**
 * Vérifie si l'info est dans le tableau de TreeNode passé
 * @param tree
 * @param data
 */
export function isInTree(tree: TreeNode[], data: any): boolean {
  let found = false;
  for (let i = 0; i < tree.length && !found; i++) {
    const n = tree[i];
    if (n.data.id === data.id) {
      found = true;
    }
    if (!found && n.children?.length > 0) {
      found = isInTree(n.children, data);
    }
  }

  return found;
}

export function findInTree(tree: TreeNode[], node: TreeNode): TreeNode {
  let _node;
  for (let i = 0; i < tree.length && !_node; i++) {
    const n = tree[i];
    if (n.data.id === node.data.id) {
      _node = n;
    } else if (n.children?.length) {
      _node = findInTree(n.children, node);
    }
  }
  return _node;
}

/**
 * Retourne l'icône correspondante si necessaire
 * @param data
 */
export function getIconForItem(data: any): string {
  if (data.imageIndicator != null) {
    switch (data.imageIndicator) {
      case 'W':
        return 'fa fa-windows';
      case 'P':
        return 'fa fa-cogs';
      case 'R':
        return 'fa fa-line-chart';
      case 'X':
        return 'fa fa-windows';
      case 'F':
        return 'fa fa-code-fork';
      default:
        return 'fa fa-windows';
    }
  }
  return '';
}

/**
 * Uniformise les données reçues du backend
 * @param data
 */
export function uniformData(data: any): UniformTreeData {
  const [keyColumn, _] = (data.Data_UUID as string).split(',');
  return {
    color: null,
    description: data.Description,
    id: data[keyColumn],
    imageIndicator: data.Action || data.AD_PrintColor_ID || '',
    isSummary: data.IsSummary === 'Y',
    name: data.Name
  };
}

export function moveToNewIndex(arr: any[], old_index: number, new_index: number): any[] {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function getNbLevel(node: TreeNode): number {
  let lvl = 0;
  while (node.parent.data.id > -1) {
    node = node.parent;
    lvl++;
  }
  return lvl;
}

export interface UniformTreeData {
  color: string;
  description: string;
  id: number;
  imageIndicator: string;
  isSummary: boolean;
  name: string;
}
