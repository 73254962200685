import { Injectable } from '@angular/core';
import { CompiereNotification } from '@compiere-ws/models/compiere-notification-json';
import { AppConfig } from '@iupics-config/app.config';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import * as AGChannel from 'ag-channel';
import { environment } from 'environments/environment';
import { AGClientSocket, create } from 'socketcluster-client';

@Injectable()
export class SocketService {
  public static BROADCAST_NOTIFICATION_CHANNEL = 'notifications';
  public static PERSONAL_NOTIFICATION_CHANNEL = '';
  public static ROLE_NOTIFICATION_CHANNEL = '';
  public static PROCESS_IN_PROGRESS_CHANNEL = '';
  public static RELOAD_CONFIG_CHANNEL = 'reload_config';

  private socket: AGClientSocket;

  private broadcastNotificationsChannel: AGChannel<CompiereNotification>;
  private personalNotificationsChannel: AGChannel<CompiereNotification>;
  private roleNotificationsChannel: AGChannel<CompiereNotification>;
  private processInProgressChannel: AGChannel<any>;

  constructor(private config: AppConfig, private smService: SecurityManagerService) {}

  //#region init

  /**
   * Initialise le socket
   */
  initSocket(): void {
    const hostname = environment.config.backend.socketcluster.hostname.replace(/http:\/\/|https:\/\//g, '');
    const port = environment.config.backend.socketcluster.port;
    const secure = environment.config.backend.socketcluster.secure;

    this.socket = this.socket === undefined ? create({ hostname, port, secure }) : this.socket;
    this.putSocketConnectionToWindow();
    this.handleError();
  }

  //#endregion

  //#region broadcast

  /**
   * Active les notifications propagées
   */
  enableBroadcastNotifications(): AGChannel<CompiereNotification> {
    if (this.broadcastNotificationsChannel === undefined) {
      this.broadcastNotificationsChannel = this.socket.subscribe(SocketService.BROADCAST_NOTIFICATION_CHANNEL);
      this.putSocketConnectionToWindow();
    }
    return this.broadcastNotificationsChannel;
  }

  /**
   * Désactive les notifications propagées
   */
  disableBroadcastNotifications(): void {
    if (this.broadcastNotificationsChannel) {
      this.broadcastNotificationsChannel.kill();
      this.broadcastNotificationsChannel.unsubscribe();
      this.broadcastNotificationsChannel = undefined;
    }
  }

  //#endregion

  //#region personal

  /**
   * Active les notifications personnelles
   */
  enablePersonalNotifications(): AGChannel<CompiereNotification> {
    if (this.personalNotificationsChannel === undefined) {
      SocketService.PERSONAL_NOTIFICATION_CHANNEL = String(this.smService.getIupicsUserAccount().id);
      this.personalNotificationsChannel = this.socket.subscribe(SocketService.PERSONAL_NOTIFICATION_CHANNEL);
      this.putSocketConnectionToWindow();
    }
    return this.personalNotificationsChannel;
  }

  /**
   * Désactive les notifications personnelles
   */
  disablePersonalNotifications(): void {
    if (this.personalNotificationsChannel) {
      this.personalNotificationsChannel.kill();
      this.personalNotificationsChannel.unsubscribe();
      this.personalNotificationsChannel = undefined;
    }
  }

  //#endregion

  //#region role

  /**
   * Active les notifications pour le rôle courant
   */
  enableRoleNotifications(role_id?: number): AGChannel<CompiereNotification> {
    if (this.roleNotificationsChannel === undefined) {
      role_id =
        role_id === undefined ? this.smService.getIupicsUserAccount().roles.find((role) => role.isSelected).role_id : role_id;
      SocketService.ROLE_NOTIFICATION_CHANNEL = `${this.smService.getIupicsUserAccount().id}_${role_id}`;
      this.roleNotificationsChannel = this.socket.subscribe(SocketService.ROLE_NOTIFICATION_CHANNEL);
      this.putSocketConnectionToWindow();
    }
    return this.roleNotificationsChannel;
  }

  /**
   * Désactive les notifications pour le rôle courant
   */
  disableRoleNotifications(): void {
    if (this.roleNotificationsChannel) {
      this.roleNotificationsChannel.kill();
      this.roleNotificationsChannel.unsubscribe();
      this.roleNotificationsChannel = undefined;
    }
  }
  //#endregion

  //#region processInProgressChannel

  enableProcessInProgressChannel(): AGChannel<string> {
    if (this.processInProgressChannel === undefined) {
      const me = this.smService.getIupicsUserAccount();

      if (me.current_role.role_id === 0) {
        // * systemAdmin: processRunning
        SocketService.PROCESS_IN_PROGRESS_CHANNEL = `processRunning`;
      } else if (me.current_role.isAdministrator) {
        // * administrator: processRunning_clientID
        const clientID = this.smService.getIupicsUserContext()['#AD_Client_ID'];
        SocketService.PROCESS_IN_PROGRESS_CHANNEL = `processRunning_${clientID}`;
      } else {
        // * other: processRunning_userID_roleID
        SocketService.PROCESS_IN_PROGRESS_CHANNEL = `processRunning_${me.id}_${me.current_role.role_id}`;
      }
      this.processInProgressChannel = this.socket.subscribe(SocketService.PROCESS_IN_PROGRESS_CHANNEL);
    }

    return this.processInProgressChannel;
  }

  /**
   * Désactive les notifications pour le rôle courant
   */
  disableProcessInProgressChannel(): void {
    if (this.processInProgressChannel) {
      this.processInProgressChannel.kill();
      this.processInProgressChannel.unsubscribe();
      this.processInProgressChannel = undefined;
    }
  }
  //#endregion

  //#region data

  /**
   * Ouvre un channel de données
   * @param {string} channel name of the channel
   * @returns {any} channel data
   */
  openDataChannel<T>(channel: string): AGChannel<T> {
    return this.socket.subscribe(channel);
  }

  /**
   * Ferme un canal de reception de données
   * @param {string} channel
   */
  closeDataChannel(channel: string) {
    this.socket.killChannel(channel);
    this.socket.unsubscribe(channel);
  }

  //#endregion

  //#region reloadConfig

  /**
   * @returns {any} channel reload_config
   */
  enableReloadConfigChannel(): AGChannel<any> {
    return this.socket.subscribe(SocketService.RELOAD_CONFIG_CHANNEL);
  }

  /**
   * Ferme le channel de rechargement de config
   */
  disableReloadConfigChannel() {
    this.socket.killChannel(SocketService.RELOAD_CONFIG_CHANNEL);
    this.socket.unsubscribe(SocketService.RELOAD_CONFIG_CHANNEL);
  }

  //#endregion

  //#region misc

  /**
   * Broadcast des datas sur un channel spécifique
   * @param {string} channel
   * @param {?any} data
   */
  broadcast(channel: string, data?: any) {
    this.socket.transmitPublish(channel, data);
  }

  /**
   * Retourne l'id du socket ouvert
   * @returns {string} Socket Id
   */
  getSocketId(): string {
    return this.socket.id;
  }

  /**
   * Gère les erreurs
   */
  handleError() {
    (async () => {
      for await (const { error } of this.socket.listener('error')) {
        console.error(error);
      }
    })();
  }

  //#endregion

  putSocketConnectionToWindow() {
    window['apiz_socket'] = {
      broadcastNotificationsChannel: this.broadcastNotificationsChannel,
      personalNotificationsChannel: this.personalNotificationsChannel,
      roleNotificationsChannel: this.roleNotificationsChannel,
      socket: this.socket
    };
  }
}
