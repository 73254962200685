import { Component, ComponentFactoryResolver, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'iu-create-from-open-invoicest-window-ui',
  templateUrl: './create-from-open-invoicest-window-ui.component.html',
  styleUrls: ['./create-from-open-invoicest-window-ui.component.scss']
})
export class CreateFromOpenInvoicestWindowUiComponent extends SpecificWindowUiComponent implements OnInit {

  @ViewChild('left', { read: ViewContainerRef, static: true })
  vcrLeft: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true })
  vcrRight: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  @Output()
  vcrBot: ViewContainerRef;
  SumSelectedRows = 0;
  countOfRows = 0;
  
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push(
	  {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankStatement_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankStatementLine_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_DocType_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'Create From Open InvoiceST Table',
        cssClass: 'p-col-12',
        shouldSelectFirst: false,
        isFitResize: true
      },
      {
       vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Total',
        cssClass:"p-col-2" , 
        isLabelDisplay:false
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-12 p-md-3 p-lg-2'
      }
    );
  }

  ngOnInit() {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    this.showSpecificWindow();
  }

  notifyFromRowSelected(rowSelected: any) {
    this.countOfRows = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getModel().getRowCount();

    if (rowSelected.length === this.countOfRows) {
      this.dataStore.data.Select_All = 'Y';
    } else {
      this.dataStore.data.Select_All = 'N';
    }
    if (rowSelected.length === 0) {
      this.dataStore.data.Total = this.getField('Total').data.defaultValue;
      this.dataStore.data.Select_All = 'N';
    }
    this.dataContainers.forEach((datacontainer) => {
      if (this.dataStore.data && datacontainer.data && datacontainer.data.columnName) {
        datacontainer.updateStore(this.dataStore.data[datacontainer.data.columnName]);
      }
    });
    this.totalOfAmount();
  }


  totalOfAmount() {

    this.SumSelectedRows = 0;
      if (this.dataStore.data.selections[0].selection.length > 0) {
        for (let i = 0; i < this.dataStore.data.selections[0].selection.length; i++) {
          this.SumSelectedRows += parseFloat(this.dataStore.data.selections[0].selection[i].Open);
        }
        this.dataStore.data.Total = this.SumSelectedRows;
      }
      this.setDataContainersValueWithChangedStore();
      
  }

}