import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  private urlAppVersions: string;
  constructor(private http: ApiService, private config: AppConfig) {}

  public getAppVersions(): Observable<WsVersion[]> {
    this.urlAppVersions = environment.config.backend.version.url;
    return this.http.get<WsVersion[]>(`${this.urlAppVersions}`);
  }
}
export interface WsVersion {
  name: string;
  image: string;
  version: string;
  runningTasks: number;
  replicas: number;
}
