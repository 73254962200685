import { Component, ComponentFactoryResolver, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iu-create-from-statement-window-ui',
  templateUrl: './create-from-statement-window-ui.component.html',
  styleUrls: ['./create-from-statement-window-ui.component.scss']
})
export class CreateFromStatementWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('left', { read: ViewContainerRef, static: true })
  vcrLeft: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true })
  vcrRight: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  @Output()
  vcrBot: ViewContainerRef;
  SumSelectedRows = 0;
  countOfRows = 0;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'ZSubPaymentRule_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'DateTrx',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'Amount',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'EcartAmt',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankAccount_ID',
        cssClass: 'p-col-12'
      },

      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'Create From Statement Table',
        cssClass: 'p-col-12',
        shouldSelectFirst: false
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'Select_All',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'Total',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-12 p-md-3 p-lg-2'
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankStatement_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_Currency_ID',
        cssClass: 'p-col-12'
      }
    );
  }
  ngOnInit() {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    if (this.parentComponent instanceof EditViewUiComponent) {
      this.setFormStoreData('Date', (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data['StatementDate']);
      this.setFormStoreData(
        'C_BankStatement_ID',
        (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data['C_BankStatement_ID']
      );

      this.showSpecificWindow();
    }
  }
  notifyFromDataChange(item: any) {
    if (this.dataStore.data.ZSubPaymentRule_ID === null && item.data.isLaunchSearchGrid) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('specificWindow.fillMandatory') + this.getField('ZSubPaymentRule_ID').data.label,
          'error'
        )
      );
      return;
    }
    if (this.gridViews[0].GridTabInfinityScrollUiComponent !== undefined) {
      if (this.dataStore.data.Select_All === 'Y') {
        this.selectAllGridLines();
      } else if (
        this.dataStore.data.Select_All === 'N' &&
        this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length === 1
      ) {
        this.clearAllGridsSelection();
      } else {
        this.dataStore.data.Select_All = 'N';
      }
      this.setDataContainersValueWithChangedStore();
    }

    if (this.SumSelectedRows !== 0) {
      this.dataStore.data.EcartAmt = this.SumSelectedRows - this.dataStore.data.Amount;
      this.dataStore.data.Total = this.dataStore.data.Amount + this.dataStore.data.EcartAmt;
    }
    if (item.data.isLaunchSearchGrid) {
      this.refreshGrids(this.dataStore, false);
    }
    if (item.data.columnName === 'Select_All') {
      this.totalOfAmount();
      if (this.dataStore.data.Select_All == 'N') {
        this.clearAllGridsSelection();
      }
    }
  }
  totalOfAmount() {
    if (this.dataStore.data.ZSubPaymentRule_ID === null) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('specificWindow.fillMandatory') + this.getField('ZSubPaymentRule_ID').data.label,
          'error'
        )
      );
    } else {
      this.SumSelectedRows = 0;
      this.dataStore.data.EcartAmt = this.getField('EcartAmt').data.defaultValue;
      if (this.dataStore.data.selections[0].selection.length > 0) {
        this.getField('Amount').data.ReadOnlyLogic = null; // je ne sais pas pq on fait ça car on ne le reset plus par après
        for (let i = 0; i < this.dataStore.data.selections[0].selection.length; i++) {
          this.SumSelectedRows += parseFloat(this.dataStore.data.selections[0].selection[i].Amount);
        }
        this.dataStore.data.Amount = this.SumSelectedRows;
        this.dataStore.data.Total = this.SumSelectedRows;
      }
      if (this.dataStore.data.Select_All === 'N' && this.dataStore.data.selections[0].selection.length === 0) {
        this.dataStore.data.Amount = this.getField('Amount').data.defaultValue;
        this.dataStore.data.Total = this.getField('Total').data.defaultValue;
        this.dataStore.data.EcartAmt = this.getField('EcartAmt').data.defaultValue;
      }
      this.setDataContainersValueWithChangedStore();
    }
  }
  notifyFromRowSelected(rowSelected: any) {
    this.countOfRows = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getModel().getRowCount();

    if (rowSelected.length === this.countOfRows) {
      this.dataStore.data.Select_All = 'Y';
    } else {
      this.dataStore.data.Select_All = 'N';
    }
    if (rowSelected.length === 0) {
      this.dataStore.data.Amount = this.getField('Amount').data.defaultValue;
      this.dataStore.data.Total = this.getField('Total').data.defaultValue;
      this.dataStore.data.EcartAmt = this.getField('EcartAmt').data.defaultValue;
      this.dataStore.data.Select_All = 'N';
    }
    this.dataContainers.forEach((datacontainer) => {
      if (this.dataStore.data && datacontainer.data && datacontainer.data.columnName) {
        datacontainer.updateStore(this.dataStore.data[datacontainer.data.columnName]);
      }
    });
    this.totalOfAmount();
  }

  checkBeforeProcessing() {
    const totalAmount = parseFloat(this.dataStore.data.EcartAmt) + parseFloat(this.dataStore.data.Amount);
    if (this.dataStore.data.ZSubPaymentRule_ID === null) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('specificWindow.fillMandatory') + this.getField('ZSubPaymentRule_ID').data.label,
          'error'
        )
      );
      // } else if (parseFloat(this.dataStore.data.Amount) === 0) {
      //   this.messageManager.newMessage(
      //     new IupicsMessage(this.translateService.instant('specificWindow.createFromStatement.sortieCaisseAmtZeroERR'), 'error')
      //   );
    } else if (totalAmount !== this.dataStore.data.Total) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('specificWindow.createFromStatement.sortieCaisseEcartAmtZeroERR'),
          'error'
        )
      );
    } else {
      return true;
    }
  }
}
