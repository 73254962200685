import { HttpClient } from '@angular/common/http';
import { Component, ComponentFactoryResolver, EventEmitter, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  DataStore,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import {
  GanttData,
  GanttDragConfig,
  GanttDragMode,
  GanttGridColumn,
  GanttLightboxSection,
  GanttTaskOpenedEvent,
  GanttZoomLevel,
  LinkType
} from '@iupics-components/models/gantt.model';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { GanttComponent } from '@iupics-components/standard/grid/gantt/gantt.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { GanttStatic } from 'dhtmlx-gantt';
import { environment } from 'environments/environment';
import { cloneDeep, isNil, merge } from 'lodash';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import {
  convertToGanttData,
  ganttMomentFormat,
  GanttTask,
  getGroupRequest,
  getOFRequest,
  getOperationRequest,
  operationToGanttTask,
  productionToGanttTask
} from './planning-window-utils';

@Component({
  selector: 'iu-planning-window-ui',
  templateUrl: './planning-window-ui.component.html',
  styleUrls: ['./planning-window-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlanningWindowUiComponent extends SpecificWindowUiComponent implements OnInit, OnDestroy {
  // readonly momentDateFormat: string = 'YYYY-MM-DD HH:mm:ss';
  readonly momentDateFormat: string = 'DD/MM/YYYY';

  private groupFormDetailId: number;
  private ofFormDetailId: number;
  private operationFormDetailId: number;

  ganttData$: Observable<GanttData>;
  gridWidth = 750;
  dragConfiguration: GanttDragConfig = {
    drag_progress: false
  };

  private loadedTasks: GanttTask[] = [];
  private datacontainersToWait = ['IsGOF', 'M_Warehouse_ID', 'StartDate', 'Z_ProductionRessource_ID', 'NbDays'];

  private groups: any[] = [];
  private productions: any[] = [];
  private operations: any[] = [];
  lastRow: boolean;
  maximumPage = 1;
  actualPage = 1;
  paginationStep = 30;
  paginationEnable = true;

  public onBeforeTaskDrag = this._onBeforeTaskDrag.bind(this);
  public onAfterTaskDrag = this._onAfterTaskDrag.bind(this);
  public worktimes = { hours: ['7:00-19:00'] };

  protected customDesignArray: CustomDesignItem[] = [
    {
      type: CustomDesignItemType.FIELD,
      vcr: 'vcr',
      columnName: 'IsGOF',
      cssClass: 'p-col-2'
    },
    {
      type: CustomDesignItemType.FIELD,
      vcr: 'vcr',
      columnName: 'M_Warehouse_ID',
      cssClass: 'p-col-2'
    },
    {
      type: CustomDesignItemType.FIELD,
      vcr: 'vcr',
      columnName: 'StartDate',
      cssClass: 'p-col-2'
    },
    {
      type: CustomDesignItemType.FIELD,
      vcr: 'vcr',
      columnName: 'Z_ProductionRessource_ID',
      cssClass: 'p-col-2'
    },
    {
      type: CustomDesignItemType.FIELD,
      vcr: 'vcr',
      columnName: 'NbDays',
      cssClass: 'p-col-2'
    },
    {
      type: CustomDesignItemType.GRID,
      vcr: '',
      tableName: 'planning-window-table-groupe'
    },
    {
      type: CustomDesignItemType.GRID,
      vcr: '',
      tableName: 'planning-window-table-of'
    },
    {
      type: CustomDesignItemType.GRID,
      vcr: '',
      tableName: 'planning-window-table-operation'
    }
  ];

  _columns: GanttGridColumn[] = [
    {
      name: '_empty_',
      label: '',
      width: 40,
      tree: true,
      show: true
    },
    {
      name: 'reference',
      label: this.translateService.instant('planning-window.columns.reference'),
      template: (task: GanttTask) => `${task.reference}${task.po_type === 'G' ? ' (' + task.po_data.Z_NbOF + ')' : ''}`,
      width: 90,
      show: true
    },
    { name: 'description', label: this.translateService.instant('planning-window.columns.description'), width: 150, show: false },
    {
      name: 'product',
      label: this.translateService.instant('planning-window.columns.product'),
      template: (task: GanttTask) => `${task['M_Product_ID']?.displayValue || ''}`,
      width: 140,
      show: false
    },
    {
      name: 'start_date',
      label: this.translateService.instant('planning-window.columns.start_date'),
      align: 'center',
      template: (task: GanttTask) => `${moment(task.start_date).format(this.momentDateFormat)}`,
      width: 110,
      show: true
    },
    {
      name: 'end_date',
      label: this.translateService.instant('planning-window.columns.end_date'),
      align: 'center',
      template: (task: GanttTask) => `${moment(task.end_date).format(this.momentDateFormat)}`,
      width: 110,
      show: true
    },
    {
      name: 'po_docStatus',
      label: 'Status',
      align: 'center',
      template: (task: GanttTask) =>
        task?.po_docStatus
          ? `<div data-doc-status="${task.po_docStatus.id ?? '??'}" class="gantt-doc-status-badge">${
              task.po_docStatus.displayValue ?? ''
            }</div>`
          : '',
      width: 110,
      show: true
    },
    {
      name: 'static_duration',
      label: 'SD(h)',
      align: 'center',
      template: (task: GanttTask) => `${task.static_duration}`,
      width: 60,
      show: true
    }
  ];

  columns: GanttGridColumn[] = cloneDeep(this._columns.filter((c) => c.show));

  sections: GanttLightboxSection[] = [
    { name: 'M_Product_ID', type: 'template', map_to: 'template_M_Product_ID' },
    { name: 'C_BPartner_ID', type: 'template', map_to: 'template_C_BPartner_ID' },
    { name: 'C_Order_ID', type: 'template', map_to: 'template_C_Order_ID' }
  ];

  lightboxLabels: Map<string, string> = new Map(
    Object.entries({
      section_M_Product_ID: 'Articles',
      section_C_BPartner_ID: 'Tiers',
      section_C_Order_ID: 'Ordre de vente'
    })
  );

  //#region universal-filter-related
  uf_columns: any[] = [];
  setFilterEmitter: EventEmitter<CompiereDataGridRequestJSON> = new EventEmitter();
  uf_formId = -1;
  //#endregion

  //#region
  GanttZoomLevel = GanttZoomLevel;
  //#endregion
  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService,
    protected http: HttpClient
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.showSpecificWindow();
    this.http
      .get<any>(environment.config.backend.ws.url + '/DataGantt', { responseType: 'text' as 'json' })
      .subscribe({ next: console.log, error: null });
  }

  protected onAfterBuildWindow() {
    this.groupFormDetailId = this.fields.find(
      (f) => f.component === 'GridViewUiComponent' && f.name === 'planning-window-table-groupe'
    ).formDetailId;
    this.ofFormDetailId = this.fields.find(
      (f) => f.component === 'GridViewUiComponent' && f.name === 'planning-window-table-of'
    ).formDetailId;
    this.operationFormDetailId = this.fields.find(
      (f) => f.component === 'GridViewUiComponent' && f.name === 'planning-window-table-operation'
    ).formDetailId;
    this.initGanttData$(this.actualPage, true);
  }

  notifyFromDatacontainerInit(datacontainer: AbstractDataContainer) {
    const index = this.datacontainersToWait.indexOf(datacontainer.data.columnName);
    if (index >= 0) {
      this.datacontainersToWait.splice(index, 1);
    }
    console.log(this.datacontainersToWait);
    datacontainer.fieldValueModified.subscribe((data: DataStore) => {
      switch (datacontainer.data.columnName) {
        case 'IsGOF':
          // restart with proper table
          break;
        case 'NbDays':
        // update nbDays to display in Gantt
        default:
          // apply new filter
          this.actualPage = 1;
          this.initGanttData$(this.actualPage, true);
          break;
      }

      console.log(this.dataStore);
    });
  }

  private initGanttData$(pageNumber = 0, reset = false, filterToApply?: CompiereDataGridRequestJSON): void {
    // groupe d'OF
    this.uf_formId = this.groupFormDetailId;

    // Ordre de fabrication
    // this.fields.find((f) => f.component === 'GridViewUiComponent' && f.name === 'planning-window-table-of').formDetailId

    // opération
    // this.fields.find((f) => f.component === 'GridViewUiComponent' && f.name === 'planning-window-table-operation').formDetailId

    if (reset) {
      this.groups = [];
      this.productions = [];
      this.operations = [];
    }
    const request = getGroupRequest(this.groupFormDetailId, this.parentFormID, pageNumber, this.paginationStep);

    const filter = this.getFilterFromDatastore();
    if (filter) {
      request.compiereRequest.filterModel = merge(request.compiereRequest.filterModel, filter.filterModel);
    }

    if (filterToApply) {
      request.compiereRequest.filterModel = merge(request.compiereRequest.filterModel, filterToApply.filterModel);
      request.compiereRequest.sortModel.push(
        ...filterToApply.sortModel.filter(
          (sm) => request.compiereRequest.sortModel.find((_sm) => _sm.colId === sm.colId) === undefined
        )
      );
    }
    this.ganttData$ = this.store.getDataGrid(request).pipe(
      map((groups) => {
        console.log(groups);
        this.setFilterEmitter.emit(request.compiereRequest);
        this.groups.push(...groups.data);
        this.lastRow = groups.lastRow !== -1;
        const ganttData: GanttData = convertToGanttData(
          this.groups.slice((this.actualPage - 1) * this.paginationStep, this.actualPage * this.paginationStep),
          this.productions,
          this.operations
        );
        return ganttData;
      })
    );
  }

  updateDisplayColumns(columnName: string, value: string) {
    this._columns[this._columns.findIndex((c) => c.name === columnName)].show = value === 'Y';
    this.columns = cloneDeep(this._columns.filter((c) => c.show));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private getFilterFromDatastore(): CompiereDataGridRequestJSON {
    const isGOF = this.dataStore.data.IsGOF === 'Y';
    const M_Warehouse_ID: IAutocomplete = this.dataStore.data.M_Warehouse_ID;
    const StartDate: string = this.dataStore.data.StartDate;
    const NbDays: number = this.dataStore.data.NbDays;
    const EndDate: string = moment(this.dataStore.data.StartDate).add(NbDays, 'day').format(Global.dateFormat);
    let Z_ProductionRessource_ID: IAutocomplete;
    if (!isGOF) {
      Z_ProductionRessource_ID = this.dataStore.data.Z_ProductionRessource_ID;
    }
    const filter: CompiereDataGridRequestJSON = { filterModel: {} };
    if (!isNil(M_Warehouse_ID) && M_Warehouse_ID.id) {
      filter.filterModel['M_Warehouse_ID'] = {
        filterType: CompiereDataGridFilterType.SET,
        operators: [OperatorFilterType.EQUALS],
        values: [[M_Warehouse_ID.id]]
      };
    }
    if (!isNil(StartDate) && !isNil(EndDate) && NbDays) {
      filter.filterModel['StartDate'] = {
        filterType: CompiereDataGridFilterType.DATE,
        operators: [OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN],
        values: [StartDate, EndDate]
      };
    }
    if (!isNil(Z_ProductionRessource_ID) && Z_ProductionRessource_ID.id) {
      filter.filterModel['Z_ProductionRessource_ID'] = {
        filterType: CompiereDataGridFilterType.SET,
        operators: [OperatorFilterType.EQUALS],
        values: [[Z_ProductionRessource_ID.id]]
      };
    }

    return filter;
  }

  /**
   * If the task is of type "OF", then don't allow the task to be dragged
   * @param {GanttTask} task - The task that is being dragged.
   * @param {GanttStatic} _gantt - GanttStatic
   * @param {GanttDragMode} mode - The mode of the drag operation.
   * @param {Event} e - The event object.
   * @returns A boolean value.
   */
  private _onBeforeTaskDrag(task: GanttTask, _gantt: GanttStatic, mode: GanttDragMode, e: Event): boolean {
    switch (mode) {
      case GanttDragMode.RESIZE:
        return task.po_type !== 'OF';
      default:
        return true;
    }
  }

  private _onAfterTaskDrag(task: GanttTask, _gantt: GanttStatic, mode: GanttDragMode, e: Event): GanttTask[] {
    switch (mode) {
      case GanttDragMode.RESIZE:
        // update de la tâche
        if (task.duration < task.static_duration) {
          if ((e.target as HTMLElement).classList.contains('task_start_date')) {
            // end_date ne bouge pas
            const date = moment(task.end_date, ganttMomentFormat).subtract(task.static_duration, 'day');
            task.start_date = date.toDate();
          } else if ((e.target as HTMLElement).classList.contains('task_end_date')) {
            // start_date ne bouge pas
            const date = moment(task.start_date, ganttMomentFormat).add(task.static_duration, 'day');
            task.end_date = date.toDate();
          }
          task.duration = task.static_duration;
        }
        break;
      case GanttDragMode.MOVE:
        if (task.start_date.valueOf() === task.end_date.valueOf()) {
          if (task.static_duration > 1) {
            const date = moment(task.start_date, ganttMomentFormat).add(task.static_duration, 'day');
            task.end_date = date.toDate();
          } else if (task.static_duration > 0) {
            const date = moment(task.start_date, ganttMomentFormat).add(1, 'day');
            task.end_date = date.toDate();
          }
        }
        break;
    }

    if (!task.parent) {
      return [task];
    }

    // Préparation des infos pour l'update du parent
    const parent: GanttTask = _gantt.getTask(task.parent);
    const children: GanttTask[] = _gantt.getChildren(task.parent).map((_id) => _gantt.getTask(_id));
    const edited_task_i = children.findIndex((c) => c.id === task.id);
    children[edited_task_i].start_date = task.start_date;
    children[edited_task_i].end_date = task.end_date;
    children[edited_task_i].duration = task.duration;

    // update du parent de la tâche
    const start_date = new Date(Math.min(...children.map((t) => (t.start_date as Date)?.valueOf()).filter((i) => i)));
    const end_date = new Date(Math.max(...children.map((t) => (t.end_date as Date)?.valueOf()).filter((i) => i)));
    const duration = moment(end_date).diff(moment(start_date), 'days');
    if (duration < parent.static_duration) {
      if ((e.target as HTMLElement).classList.contains('task_start_date')) {
        // end_date ne bouge pas
        const date = moment(parent.end_date, ganttMomentFormat).subtract(parent.static_duration, 'day');
        parent.start_date = date.toDate();
        parent.end_date = end_date;
      } else if ((e.target as HTMLElement).classList.contains('task_end_date')) {
        // start_date ne bouge pas
        const date = moment(parent.start_date, ganttMomentFormat).add(parent.static_duration, 'day');
        parent.end_date = date.toDate();
        parent.start_date = start_date;
      }
      parent.duration = parent.static_duration;
    } else {
      parent.start_date = start_date;
      parent.end_date = end_date;
      parent.duration = duration;
    }
    return [task, parent];
  }

  public onTaskOpened({ task, gantt }: GanttTaskOpenedEvent<GanttTask>) {
    let request: DataStoreRequest;
    let subHandler: (data: any) => void;
    if (task.po_type === 'G') {
      request = getOFRequest(this.ofFormDetailId, this.parentFormID, [task.po_id as number]);
      subHandler = (productions) => {
        this.productions.push(...productions.data);
        this.loadedTasks.push(task);
        const data = [];
        for (let i = 0; i < productions.data.length; i++) {
          const p: any = productions.data[i];
          if (p.StartDate === null || p.EndDate === null) {
            continue;
          }
          const task_p = productionToGanttTask(task, p);
          gantt.addTask(task_p);
          data.push(task_p);
          if (i > 0) {
            gantt.addLink({
              id: data[data.length - 1].id,
              source: data[data.length - 2].id + '',
              target: data[data.length - 1].id + '',
              type: LinkType.END_TO_START
            });
          }
        }
      };
    } else if (task.po_type === 'OF') {
      request = getOperationRequest(this.operationFormDetailId, this.parentFormID, [task.po_id as number]);

      subHandler = (operations) => {
        this.operations.push(...operations.data);
        this.loadedTasks.push(task);
        const data = [];
        for (let i = 0; i < operations.data.length; i++) {
          const o: any = operations.data[i];
          const task_o = operationToGanttTask(task, o);
          gantt.addTask(task_o);
          data.push(task_o);
          if (i > 0) {
            gantt.addLink({
              id: data[data.length - 1].id,
              source: data[data.length - 2].id + '',
              target: data[data.length - 1].id + '',
              type: LinkType.END_TO_START
            });
          }
        }
      };
    }

    if (!this.loadedTasks.find((t) => t.id === task.id && t.po_type === task.po_type)) {
      this.store.getDataGrid(request).subscribe(subHandler);
    }
  }

  onPageChange(e: { gantt: GanttComponent; type: 'next' | 'prev' }) {
    if (e.type === 'next') {
      this.actualPage++;
      if (this.actualPage > this.maximumPage) {
        if (this.lastRow) {
          this.actualPage = this.maximumPage;
          e.gantt.onPageChange();
        } else {
          this.initGanttData$(this.actualPage);
          this.maximumPage = this.actualPage;
          e.gantt.onPageChange(this.ganttData$);
        }
      } else {
        this.ganttData$ = of(
          convertToGanttData(
            this.groups.slice((this.actualPage - 1) * this.paginationStep, this.actualPage * this.paginationStep),
            this.productions,
            this.operations
          )
        );
        e.gantt.onPageChange(this.ganttData$);
      }
    } else if (e.type === 'prev') {
      this.actualPage = --this.actualPage < 1 ? 1 : this.actualPage;
      this.ganttData$ = of(
        convertToGanttData(
          this.groups.slice((this.actualPage - 1) * this.paginationStep, this.actualPage * this.paginationStep),
          this.productions,
          this.operations
        )
      );
      e.gantt.onPageChange(this.ganttData$);
    }
  }

  /**
   * This function is called when the user changes the filter
   * @param e - { filterToApply: CompiereDataGridRequestJSON; isNotFromUF: boolean; gantt:
   * GanttComponent }
   */
  onFilterChange(e: { filterToApply: CompiereDataGridRequestJSON; isNotFromUF: boolean; gantt: GanttComponent }) {
    if (!e.isNotFromUF) {
      this.actualPage = 1;
      this.initGanttData$(this.actualPage, true, e.filterToApply);
      this.maximumPage = this.actualPage;
      e.gantt.onPageChange(this.ganttData$);
    }
  }

  //#region gantt functions
  public taskText(start: Date, end: Date, task: GanttTask) {
    return task.description;
  }

  /**
   * It returns a CSS class name based on the task type.
   * @param {Date} start - The start date of the task.
   * @param {Date} end - The end date of the task.
   * @param {GanttTask} task - The task object.
   * @returns The class name for the task.
   */
  public taskClass(start: Date, end: Date, task: GanttTask) {
    switch (task.po_type) {
      case 'G':
        return 'gantt-task-G';
      case 'OF':
        return 'gantt-task-OF';
      case 'OP':
        return 'gantt-task-OP';
      default:
        return '';
    }
  }

  /**
   * It returns a string that will be used as a CSS class for the row.
   * @param {Date} start - The start date of the task.
   * @param {Date} end - The end date of the task.
   * @param {GanttTask} task - GanttTask
   * @returns The return value is a string.
   */
  public gridRowClass(start: Date, end: Date, task: GanttTask) {
    return task.po_type === 'OF' ? 'gantt-row-OF' : task.po_type === 'OP' ? 'gantt-row-OP' : '';
  }

  /**
   * It returns true if the task is of type "OF" (Offer).
   * @param {GanttTask} task - GanttTask
   * @returns A boolean value.
   */
  public lightboxEnable(task: GanttTask) {
    return task.po_type === 'OF';
  }
  //#endregion
}
