<div class="alerts">
  <ng-container *ngIf="alerts$ | async as alerts; else alertLoading">
    <div *ngFor="let alert of alerts" class="alert" (click)="openTab($event, alert)">
      {{ alert.Reference }}
    </div>
  </ng-container>
</div>

<ng-template #alertLoading>
  <div class="alert">Loading...</div>
</ng-template>
