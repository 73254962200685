import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Component } from '@angular/core';

@Component({
  selector: 'app-button-renderer',
  template: ` <button type="button" (click)="onClick($event)" class="p-button p-button-secondary">{{ label }}</button> `
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: ICellRendererParams): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onSelect instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field
        // ...something
      };
      this.params.onSelect({ ...this.params, ...params });
    }
  }
}
