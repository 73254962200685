<div class="close">
  <button
    (click)="closeModalEmitter.emit()"
    icon="icon-ico-close"
    class="btn btn-default ui-corner-all p-button-icon-only p-button"
  >
    <span class="p-button-icon-left ui-clickable icon-ico-close" aria-hidden="true"></span>
  </button>
</div>
<div class="title">{{ 'editView.registeredInformation' | translate }}</div>
<div class="infos">
  <div
    class="info"
    *ngIf="
      parentComponent.editTabs &&
      parentComponent.editTabs[0] &&
      parentComponent.editTabs[0].data &&
      !parentComponent.editTabs[0].data.isView
    "
  >
    <label>{{ 'editView.createdBy' | translate }}</label> {{ auditsInfo.created_by }}
  </div>
  <div
    class="info"
    *ngIf="
      parentComponent.editTabs &&
      parentComponent.editTabs[0] &&
      parentComponent.editTabs[0].data &&
      !parentComponent.editTabs[0].data.isView
    "
  >
    <label>{{ 'editView.updatedBy' | translate }}</label> {{ auditsInfo.updated_by }}
  </div>
  <div class="info">
    <button *ngIf="auditsInfo.id_txt !== ''" class="btn btn-default" (click)="copyToClipBoard(auditsInfo, $event)">
      {{ 'editView.copyToClipBoard' | translate }}
    </button>
    <div>
      <label>ID ({{ auditsInfo.tableName }})</label>
      <p style="white-space: initial">{{ auditsInfo.id_txt }}</p>
    </div>
  </div>
</div>

<div class="copied-to-clipboard" *ngIf="isCopiedToClipBoard">{{ 'editView.copyToClipBoardMsg' | translate }}</div>

<div class="table">
  <ng-template #vcrGrid></ng-template>
</div>
