import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridResponseJSON,
  CompiereDataGridType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'iu-my-alerts-widget-ui',
  templateUrl: './my-alerts-widget-ui.component.html',
  styleUrls: ['./my-alerts-widget-ui.component.scss']
})
export class MyAlertsWidgetUiComponent implements OnInit, AfterViewInit, OnDestroy {
  private static readonly windowValue = 'Alert Maintenance';
  private static windowId: number;
  private static windowName: string;
  private static adLanguage: string;
  private static adUserId: number;
  private static alertIdToAvoid: number;

  refresherSub: Subscription;
  alerts$: Observable<Alert[]>;

  @Input() widget: IupicsWidget;

  @Output() openTabEmitter = new EventEmitter<any>();

  constructor(private datastore: DataStoreService, private securityManager: SecurityManagerService) {
    MyAlertsWidgetUiComponent.adLanguage = this.securityManager.getIupicsDefaultLanguage().iso_code;
    MyAlertsWidgetUiComponent.adUserId = this.securityManager.getIupicsUserAccount().id;
  }

  ngOnInit(): void {
    this.alerts$ = of([]);
    if (!MyAlertsWidgetUiComponent.windowId || !MyAlertsWidgetUiComponent.windowName) {
      this.alerts$ = this.alerts$.pipe(
        switchMap(() => this.datastore.getDataGrid(this.getWindow(), true)),
        tap((response: CompiereDataGridResponseJSON) => {
          MyAlertsWidgetUiComponent.windowId = response.data[0]['AD_Window_ID'];
          MyAlertsWidgetUiComponent.windowName = response.data[0]['Name'];
        }),
        switchMap(() => of([]))
      );
    }

    if (!MyAlertsWidgetUiComponent.alertIdToAvoid) {
      this.alerts$ = this.alerts$.pipe(
        switchMap(() => this.datastore.getDataGrid(this.getAlertToAvoid(), true)),
        tap((response: CompiereDataGridResponseJSON) => {
          MyAlertsWidgetUiComponent.alertIdToAvoid = response.data[0]['AD_Alert_ID'];
        }),
        switchMap(() => of([]))
      );
    }

    this.alerts$ = this.alerts$.pipe(
      switchMap(() => this.datastore.getDataGrid(this.getNotes())),
      map((response) => response.data as Alert[])
    );
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  openTab(event: Event, item: Alert) {
    event.stopPropagation();
    this.openTabEmitter.emit({
      action_id: MyAlertsWidgetUiComponent.windowId, // windowId
      menu_type: 'Window',
      name: MyAlertsWidgetUiComponent.windowName, // windowName
      iconClass: 'fa fa-windows',
      openedRecordId: item.Data_UUID // recordId: columnName,recordId
    });
  }

  private getWindow(): DataStoreRequest {
    return {
      windowId: -1,
      parent_constraint: undefined,
      compiereRequest: {
        startRow: 0,
        endRow: 1,
        windowType: CompiereDataGridType.TABLE,
        tableName: 'AD_Window',
        ad_language: MyAlertsWidgetUiComponent.adLanguage,
        filterModel: {
          Value: {
            filterType: CompiereDataGridFilterType.TEXT,
            operators: [OperatorFilterType.EQUALS],
            values: [MyAlertsWidgetUiComponent.windowValue]
          }
        },
        headerCols: [
          {
            displayName: 'ID',
            field: 'AD_Window_ID',
            id: 'AD_Window_ID'
          },
          {
            displayName: 'Nom',
            field: 'Name',
            id: 'Name'
          }
        ]
      }
    };
  }

  private getAlertToAvoid(): DataStoreRequest {
    return {
      windowId: -1,
      parent_constraint: undefined,
      compiereRequest: {
        startRow: 0,
        endRow: 1,
        windowType: CompiereDataGridType.TABLE,
        tableName: 'AD_Alert',
        ad_language: MyAlertsWidgetUiComponent.adLanguage,
        filterModel: {
          Name: {
            filterType: CompiereDataGridFilterType.TEXT,
            operators: [OperatorFilterType.EQUALS],
            values: ['Alerte générique']
          }
        },
        headerCols: [
          {
            displayName: 'AD_Alert_ID',
            field: 'AD_Alert_ID',
            id: 'AD_Alert_ID'
          }
        ]
      }
    };
  }

  private getNotes(): DataStoreRequest {
    return {
      windowId: -1,
      parent_constraint: undefined,
      compiereRequest: {
        startRow: 0,
        endRow: 300,
        windowType: CompiereDataGridType.TABLE,
        tableName: 'AD_Note',
        ad_language: MyAlertsWidgetUiComponent.adLanguage,
        filterModel: {
          AD_Alert_ID: {
            filterType: CompiereDataGridFilterType.NUMBER,
            operators: [OperatorFilterType.BIGGER, OperatorFilterType.NOT_EQUALS],
            values: [0, MyAlertsWidgetUiComponent.alertIdToAvoid]
          },
          AD_User_ID: {
            filterType: CompiereDataGridFilterType.NUMBER,
            operators: [OperatorFilterType.EQUALS],
            values: [MyAlertsWidgetUiComponent.adUserId]
          }
        },
        headerCols: [
          {
            displayName: 'TextMsg',
            field: 'TextMsg',
            id: 'TextMsg'
          },
          {
            displayName: 'Reference',
            field: 'Reference',
            id: 'Reference'
          },
          {
            displayName: 'AD_Alert_ID',
            field: 'AD_Alert_ID',
            id: 'AD_Alert_ID'
          }
        ]
      }
    };
  }
}

export interface Alert {
  AD_Note_ID: number;
  Data_UUID: string;
  Reference: string;
  TextMsg: string;
}
