import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Component } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';

@Component({
  selector: 'app-autocomplete-renderer',
  template: `
    <iu-autocomplete-ui
      label="{{ label }}"
      [cssClass]="'p-col-12 autocompleteRenderer'"
      [isLabelDisplay]="false"
      limitSuggestions="25"
      (autoCompleteEmitter)="onSelect($event)"
      (checkGridRendererEmitter)="onSearch($event)"
      (blurEmitter)="closePanel($event)"
      [data]="templates"
      [fieldValue]="this.fieldValue"
      [isRendererView]="true"
      [dataStoredRenderer]="dataStored"
    ></iu-autocomplete-ui>
  `
})
export class AutocompleteRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;
  templates: any;
  fieldValue = null;
  dataStored: DataStore;
  agInit(params: any): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    this.dataStored = new DataStore();
    this.dataStored.data = this.params.data;
    if (params.value) {
      if (params.value.id !== null && params.value.id !== undefined) {
        this.fieldValue = {
          id: params.value.id,
          displayValue: params.value.displayValue
        };
      } else {
        this.fieldValue = {
          id: params.value,
          displayValue: params.valueFormatted
        };
      }
    }
  }

  refresh(params?: ICellRendererParams): boolean {
    return true;
  }

  onSelect($event) {
    this.params.node.data[this.templates.columnName] = $event ? $event.id : $event;
    if (this.params.onSelect instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field
        // ...something
      };
      this.params.onSelect({ ...this.params, ...params });
    }
  }

  onSearch(event) {
    // console.log(event);
    this.params.onSearch(event);
  }

  closePanel(event) {
    // console.log(event);
    this.params.closePanel();
  }
}
