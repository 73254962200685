import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import {
  CompiereLoginJSON,
  CompiereRoleUI,
  CompiereValidSession,
  OAuth2TokenJSON
} from '@compiere-ws/models/compiere-login-json';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { AppConfig } from '@iupics-config/app.config';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { Global } from '@iupics-manager/models/global-var';
import { UserPreference } from '@web-desktop/models/user-preference';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable()
export class CompiereLoginService {
  private loginInfoUrl: string;
  private usersRoleUrl: string;
  private languageUrl: string;
  private ctxUrl: string;
  private prefUrl: string;
  private resetUrl: string;
  private sessionUrl: string;
  private connectAsUrl: string;
  private organisationsUrl: string;
  private warehousesUrl: string;

  constructor(private http: ApiService, private config: AppConfig, private cookieService: IupicsCookieService) {}

  login(login: string, password: string): Observable<OAuth2TokenJSON> {
    const formData = new FormData();
    formData.append('username', login);
    formData.append('password', password);
    formData.append('grant_type', 'api');
    formData.append('client_id', environment.config.backend.auth.clientId);

    return this.http.post<OAuth2TokenJSON>(this.config.getAuthorizationResource('login'), formData, {
      headers: new HttpHeaders({
        Authorization:
          'Basic ' +
          Buffer.from(
            environment.config.backend.auth.clientId + ':' + environment.config.backend.auth.clientSecret,
            'utf-8'
          ).toString('base64'),
        Device: this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.device_id),
        'X-Lang': (<CompiereLanguage>JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language)))
          .iso_code
      })
    });
  }

  loadUrl() {
    this.sessionUrl = this.config.getAuthorizationResource('session');
    this.connectAsUrl = this.config.getAuthorizationResource('connectas');
    this.loginInfoUrl = this.config.getBackendResource('sessionInfo');
    this.usersRoleUrl = this.config.getBackendResource('getUserRole');
    this.languageUrl = this.config.getBackendResource('changeLanguage');
    this.ctxUrl = this.config.getBackendResource('ctx');
    this.prefUrl = this.config.getBackendResource('preference');
    this.resetUrl = this.config.getBackendResource('cachereset');
    this.organisationsUrl = this.config.getBackendResource('organisationsCTX');
    this.warehousesUrl = this.config.getBackendResource('warehousesCTX');
    Global.mergeLevel = this.config.getConstant('mergeLevel');
  }

  getConfig(): Observable<any> {
    return this.http.get<any>(environment.configUrl);
  }

  refreshLogin(refresh_token: string): Observable<OAuth2TokenJSON> {
    const formData = new FormData();
    formData.append('refresh_token', refresh_token);
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', environment.config.backend.auth.clientId);

    return this.http.post<OAuth2TokenJSON>(this.config.getAuthorizationResource('login'), formData, {
      headers: new HttpHeaders({
        Authorization:
          'Basic ' +
          Buffer.from(
            environment.config.backend.auth.clientId + ':' + environment.config.backend.auth.clientSecret,
            'utf-8'
          ).toString('base64')
      })
    });
  }

  getLoginInfo(access_token: string): Observable<CompiereLoginJSON> {
    return this.http.get<any>(this.loginInfoUrl, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + access_token
      })
    });
  }

  logout() {
    return this.http.delete<CompiereLoginJSON>(this.sessionUrl);
  }

  getCtx(): Observable<any> {
    return this.http.get<any>(this.ctxUrl).pipe(
      map((ctx) => {
        try {
          if (ctx) {
            Object.keys(ctx).forEach((key) => {
              ctx[key] = isNaN(parseInt(ctx[key], 10)) ? ctx[key] : parseInt(ctx[key], 10);
            });
          }
        } catch (e) {
          return ctx;
        }
        return ctx;
      })
    );
  }
  updateCtx(ctx: any): Observable<any> {
    return this.http.put<any>(this.ctxUrl, ctx).pipe(
      map((_ctx) => {
        try {
          if (_ctx) {
            Object.keys(_ctx).forEach((key) => {
              _ctx[key] = isNaN(parseInt(_ctx[key], 10)) ? _ctx[key] : parseInt(_ctx[key], 10);
            });
          }
        } catch (e) {
          return _ctx;
        }
        return _ctx;
      })
    );
  }

  changeRole(role: CompiereRoleUI): Observable<OAuth2TokenJSON> {
    const formData = new FormData();
    formData.append('role', String(role.role_id));
    return this.http.put<OAuth2TokenJSON>(`${this.sessionUrl}`, formData);
  }

  changeLanguage(language: CompiereLanguage): Observable<any> {
    return this.http.post<CompiereLoginJSON>(this.languageUrl + language.iso_code, {});
  }

  sessionValid(): Observable<CompiereValidSession> {
    if (this.sessionUrl === undefined) {
      this.sessionUrl = this.config.getAuthorizationResource('session');
    }
    return this.http.get<any>(this.sessionUrl);
  }

  getAllUsers(): Observable<any> {
    return this.http.get<any>(this.usersRoleUrl);
  }

  loginAs(userName: string): Observable<OAuth2TokenJSON> {
    const formData = new FormData();
    formData.append('user', userName);
    return this.http.post<OAuth2TokenJSON>(this.connectAsUrl, formData);
  }

  getPref(): Observable<any> {
    return this.http.get<any>(this.prefUrl);
  }

  savePref(userPref: UserPreference): Observable<any> {
    return this.http.post<CompiereLoginJSON>(this.prefUrl, userPref);
  }

  resetCache(): Observable<any> {
    return this.http.get<any>(this.resetUrl);
  }

  getOrganisations(): Observable<IAutocomplete[]> {
    return this.http.get<IAutocomplete[]>(this.organisationsUrl);
  }

  getWarehouses(): Observable<IAutocomplete[]> {
    return this.http.get<IAutocomplete[]>(this.warehousesUrl);
  }
}
