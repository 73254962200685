import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  IterableDiffers,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { AutoComplete } from 'primeng/autocomplete';
import { ConnectedOverlayScrollHandler, DomHandler } from 'primeng/dom';
import { ObjectUtils } from 'primeng/utils';

@Component({
  selector: 'iu-prime-autocomplete',
  templateUrl: './prime-autocomplete.component.html',
  styleUrls: ['./prime-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: Global.overlayAnimationAutocomplete
})
export class PrimeAutocompleteComponent extends AutoComplete implements OnInit {
  public autocompleteOverlayCSS = {
    width: '200px',
    top: '0px'
  };

  constructor(public el: ElementRef, public renderer: Renderer2, public cd: ChangeDetectorRef, public differs: IterableDiffers) {
    super(el, renderer, cd, differs);
  }
  @Input()
  columnName: string;
  @Input()
  container: any;
  @Input()
  label: string;
  @Input()
  data: IupicsDataField;
  @Input()
  description: string;
  @Input()
  tabID: number;
  @Input()
  scrollHeight: string;
  @Input()
  iconClass: string;
  @Input()
  readonly: boolean;
  @Input()
  suggestionsFilter: any;
  @Input()
  isLabelDisplay: boolean;
  @Input()
  isRendererView = false;
  mandatoryCss: string;
  formId;
  overlayBis: any;
  valueHasChanged = false;
  @Output() selectItemEmitter: EventEmitter<any> = new EventEmitter();
  @Output() searchSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() showSearchPanelEmitter: EventEmitter<any> = new EventEmitter();
  @Output() showFormPanelEmitter: EventEmitter<any> = new EventEmitter();
  @Input()
  dataStored: DataStore;
  iconeMandatoryCss: string;
  ObjectUtils = ObjectUtils;
  @ViewChild('wrapper', { read: ElementRef })
  wrapper: ElementRef;
  @Input() overridedCSS: any;
  // panel style
  @Input()
  isInsideOverflow = false;
  panelTop = '0px';
  panelLeft = '';

  ngOnInit() {
    this.appendTo = 'body';
    if (!this.scrollHeight) {
      this.scrollHeight = '240px';
    }
    if (!this.iconClass) {
      this.iconClass = 'fa fa-search';
    }
  }

  onInputBlur(event) {
    if (this.overlayVisible) {
      if (this.highlightOption) {
        this.selectItem(this.highlightOption);
      } else {
        this.hide();
      }
      this.focus = false;
    } else {
      this.focus = false;
      this.onModelTouched();
      this.onBlur.emit();
    }
  }

  hide() {
    this.overlayVisible = false;
    this.onBlur.emit();
    this.cd.markForCheck();
  }

  onInputChange(event) {
    if (!this.valueHasChanged) {
      if (this.suggestions && this.suggestions.length === 1) {
        if (this.multiple) {
          this.multiInputEL.nativeElement.value = '';
          this.value = this.value || [];
          if (!this.isSelected(this.suggestions[0])) {
            this.value = [...this.value, this.suggestions[0]];
            this.onModelChange(this.value);
          }
        } else {
          this.inputEL.nativeElement.value = this.field
            ? ObjectUtils.resolveFieldData(this.suggestions[0], this.field) || ''
            : this.suggestions[0].displayValue;
          this.value = this.suggestions[0];
          this.onModelChange(this.value);
          this.hide();
        }
        this.onSelect.emit(this.suggestions[0]);
        this.updateFilledState();
        this._suggestions = null;
        this.updateInputField();
      } else if (this.highlightOption) {
        this.selectItem(this.highlightOption);
        this.hide();
      } else {
        if (this.multiple) {
          this.multiInputEL.nativeElement.value = '';
          this.value = [];
        } else {
          event.target.value = '';
          this.value = null;
          this.onSelect.emit(null);
        }
      }
    }
    this.valueHasChanged = false;
  }

  handleSuggestionsChange() {
    if (this._suggestions != null) {
      this.highlightOption = null;
      if (this._suggestions.length) {
        this.noResults = false;
        this.show();
        this.suggestionsUpdated = true;

        if (this.autoHighlight) {
          this.highlightOption = this._suggestions[0];
        }
      } else {
        this.noResults = true;

        if (this.emptyMessage) {
          this.show();
          this.suggestionsUpdated = true;
        } else {
          this.hide();
        }
      }
      this.loading = false;
      // filtre la liste de l'autoComplete si suggestionFilter est setté
      if (this.suggestionsFilter) {
        this._suggestions = this._suggestions.filter((suggestion) => this.suggestionsFilter(suggestion, this.data.columnName));
      }
    }
  }

  selectItem(option: any, focus: boolean = true) {
    if (this.forceSelectionUpdateModelTimeout) {
      clearTimeout(this.forceSelectionUpdateModelTimeout);
      this.forceSelectionUpdateModelTimeout = null;
    }
    if (option.id !== -1 && option.id !== -2) {
      let sameValue = false;
      if (this.value && this.value.id === option.id) {
        sameValue = true;
      }
      if (!sameValue) {
        if (this.multiple) {
          this.multiInputEL.nativeElement.value = '';
          this.value = this.value || [];
          if (!this.isSelected(option)) {
            this.value = [...this.value, option];
            this.onModelChange(this.value);
          }
        } else {
          this.inputEL.nativeElement.value = this.field
            ? ObjectUtils.resolveFieldData(option, this.field) || ''
            : option.displayValue;
          this.value = option;
          this.onModelChange(this.value);
          this.selectItemEmitter.emit();
        }
        this.onSelect.emit(option);
        this.updateFilledState();
        this._suggestions = null;
        this.updateInputField();
      }
      this.hide();
    } else if (option.id === -2) {
      if (this.value !== null && this.value !== undefined) {
        this.inputEL.nativeElement.value = null;
        this.value = null;
        this.onModelChange(null);
        this.selectItemEmitter.emit();
        this.hide();
        this.onSelect.emit(null);
        this.updateFilledState();
        this._suggestions = null;
        this.updateInputField();
      }
    } else if (this.checkSpecificView()) {
      this.showFormPanel();
      this.hide();
    } else {
      this.openSearchPanel();
      this.hide();
    }
    this.onBlur.emit();
  }

  openSearchPanel() {
    this.showSearchPanelEmitter.emit();
  }

  checkSpecificView() {
    if (this.data && this.data.formId) {
      this.formId = this.data.formId;
      return true;
    } else {
      return false;
    }
  }
  showFormPanel() {
    this.showFormPanelEmitter.emit(this.formId);
  }

  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        document.body.appendChild(this.overlay);
      } else {
        DomHandler.appendChild(this.overlay, this.appendTo);
      }

      if (!this.overlay.style.width) {
        this.overlay.style.width = DomHandler.getWidth(this.el.nativeElement.children[0]) + 'px';
      }
    }
  }

  updateInputField() {
    const formattedValue = this.value
      ? this.field
        ? ObjectUtils.resolveFieldData(this.value, this.field) || ''
        : this.value.displayValue
      : '';
    this.inputFieldValue = formattedValue;
    if (this.inputEL && this.inputEL.nativeElement) {
      // Si probleme autocomplete décommenter
      this.valueHasChanged = true;
      this.inputEL.nativeElement.value = formattedValue || '';
    }

    this.updateFilledState();
  }

  setMandatoryCss(css: string) {
    this.mandatoryCss = css;
  }

  handleWheel(event: WheelEvent) {
    event.stopPropagation();
    if (event.deltaY > 0) {
      if (
        this.wrapper.nativeElement.scrollWidth - this.wrapper.nativeElement.scrollLeft - this.wrapper.nativeElement.clientWidth >
        0
      ) {
        this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollLeft + 25 });
      }
    } else {
      if (this.wrapper.nativeElement.scrollLeft !== 0) {
        this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollLeft - 25 });
      }
    }
  }

  onKeyup(event: any) {
    // event.which === 8
    if (event.key === 'Backspace') {
      this.search(event, event.target.value);
    }
    super.onKeyup(event);
  }

  onKeydown(event) {
    if (this.overlayVisible) {
      const highlightItemIndex = this.findOptionIndex(this.highlightOption);
      switch (event.which) {
        // down
        case 40:
          if (highlightItemIndex != -1) {
            const nextItemIndex = highlightItemIndex + 1;
            if (nextItemIndex != this.suggestions.length) {
              this.highlightOption = this.suggestions[nextItemIndex];
              this.highlightOptionChanged = true;
            }
          } else {
            this.highlightOption = this.suggestions[0];
          }
          event.preventDefault();
          break;
        // up
        case 38:
          if (highlightItemIndex > 0) {
            const prevItemIndex = highlightItemIndex - 1;
            this.highlightOption = this.suggestions[prevItemIndex];
            this.highlightOptionChanged = true;
          }
          event.preventDefault();
          break;
        // enter
        case 13:
          if (this.highlightOption) {
            this.selectItem(this.highlightOption);
            this.hide();
          }
          event.preventDefault();
          break;
        // escape
        case 27:
          this.hide();
          event.preventDefault();
          break;
        // tab
        case 9:
          if (this.highlightOption) {
            this.selectItem(this.highlightOption);
          }
          this.hide();
          break;
      }
    } else {
      if (event.which === 40 && this.suggestions) {
        this.search(event, event.target.value);
      }
    }
    if (this.multiple) {
      switch (event.which) {
        // backspace
        case 8:
          if (this.value && this.value.length && !this.multiInputEL.nativeElement.value) {
            this.value = [...this.value];
            const removedValue = this.value.pop();
            this.onModelChange(this.value);
            this.updateFilledState();
            this.onUnselect.emit(removedValue);
          }
          break;
      }
    }
    this.inputKeyDown = true;
  }
  onInput(event: Event) {
    this.valueHasChanged = false;
    // When an input element with a placeholder is clicked, the onInput event is invoked in IE.
    if (!this.inputKeyDown && DomHandler.isIE()) {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const value = (<HTMLInputElement>event.target).value;
    if (!this.multiple && !this.forceSelection) {
      this.onModelChange(value);
    }
    if (value.length === 0 && !this.multiple) {
      this.hide();
      this.onClear.emit(event);
      this.onModelChange(value);
    }
    if (value.length >= this.minLength) {
      this.timeout = setTimeout(() => {
        this.search(event, value);
      }, this.delay);
    } else {
      this.suggestions = null;
      this.hide();
    }
    this.updateFilledState();
    this.inputKeyDown = false;
  }

  bindScrollListener() {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.containerEL.nativeElement, () => {
        if (this.overlayVisible) {
          this.hide();
          if (this.multiInputEL) {
            this.multiInputEL.nativeElement.blur();
          }
          if (this.inputEL) {
            this.inputEL.nativeElement.blur();
          }
        }
      });
    }

    this.scrollHandler.bindScrollListener();
  }

  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
}
