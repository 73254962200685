import { GanttStatic } from 'dhtmlx-gantt';

export class Task {
  id: number | string;
  start_date?: string | Date;
  end_date?: string | Date;
  duration?: number;
  progress?: number;
  parent?: number | string;
  $open?: boolean;
  type?: 'task' | 'project' | 'milestone';
  calendar_id?: string;
  static_duration?: number; // only for programmation purpose
  $has_child?: boolean;
  text?: string;
  reference?: string;
  owner_id?: string | number;
  description?: string;
  autocomplete_template?: string;
}
export class GanttData {
  data?: Task[];
  links?: Link[];
}

export interface ApizGanttStatic extends GanttStatic {
  ignore_time(date: Date): boolean;
}

export class Link {
  id: number | string;
  source: string;
  target: string;
  type: LinkType;
}

export class Resource {
  id: number | string;
  text: string;
  parent?: number | string;
  open?: boolean;
}

export enum LinkType {
  END_TO_START = '0',
  START_TO_START = '1',
  END_TO_END = '2',
  START_TO_END = '3'
}

export enum GanttZoomLevel {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}

export class GanttGridColumn {
  label?: string;
  name: string;
  width?: number | '*';
  template?: (task: Task) => string;
  align?: 'left' | 'center' | 'right';
  show?: boolean;
  // hide?: boolean; // PRO Only
  max_width?: number;
  min_width?: number;
  // resize?: boolean; // PRO Only
  tree?: boolean; // show the plus button to expand and collapse
}

export interface GanttDragConfig {
  drag_lightbox?: boolean;
  drag_links?: boolean;
  drag_move?: boolean;
  drag_multiple?: boolean;
  drag_progress?: boolean;
  drag_resize?: boolean;
  drag_timeline?: boolean;
}

export interface GanttZoomConfig {
  levels: GanttZoomConfigLevel[];
  handler?: Function;
  startDate?: Date;
  endDate?: Date;
  activeLevelIndex?: number;
  widthStep?: number;
  minColumnWidth?: number;
  maxColumnWidth?: number;
  useKey?: string;
  trigger?: 'wheel' | null | undefined;
  element?: HTMLElement | Function;
}

export interface GanttZoomConfigLevel {
  name: string;
  scale_height: number;
  min_column_width: number;
  scales: any[];
}

export enum GanttDragMode {
  RESIZE = 'resize',
  PROGRESS = 'progress',
  MOVE = 'move',
  IGNORE = 'ignore'
}

export interface GanttCalendar {
  id: string;
  worktime: {
    hours: any[];
    days: any[];
  };
}

export interface GanttWorktimeConfig {
  day?: number;
  date?: Date;
  hours: boolean | string[] | number[];
}

export interface GanttTaskOpenedEvent<T> {
  task: T;
  gantt: GanttStatic;
}

export interface GanttPageChangedEvent<T> {
  type: 'next' | 'prev';
  gantt: GanttStatic;
}

export interface GanttDurationFormatter {
  canParse(value: string): boolean;
  format(value: number): string;
  parse(value: string): number;
}

export type GanttTime = 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
export interface GanttDurationFormatterConfig {
  /**
   * specifies the default format for the parse method, which is used when an input value is entering without units
   * @default 'day'
   */
  enter?: GanttTime;
  /**
   * specifies the format for the duration values storage in the gantt. This property affects the output value of the parse method
   * @default 'hour'
   */
  store?: GanttTime;
  /**
   * specifies the format for the output value
   */
  format?: (GanttTime | 'auto') | (GanttTime | 'auto')[];
  /**
   * sets short labels (abbreviations) for time units
   * @default false
   */
  short?: boolean;
  /**
   * defines how duration values will be converted from minutes to hours and vice-versa
   * @default 60
   */
  minutesPerHour?: number;
  /**
   * defines how duration values will be converted from hours to days and vice-versa
   * @default 8
   */
  hoursPerDay?: number;
  /**
   * defines how duration values will be converted from hours to weeks and vice-versa
   * @default 40
   */
  hoursPerWeek?: number;
  /**
   * defines how duration values will be converted from days to months and vice-versa
   * @default 30
   */
  daysPerMonth?: number;
  /**
   * defines how duration values will be converted from days to years and vice-versa
   * @default 365
   */
  daysPerYear?: number;
  /**
   * defines text labels for different time units. These labels are used both for parsed and formatted values
   */
  label?: any;
}

export interface GanttLightboxSection {
  /**
   * the section's name (according to this name, dhtmlxGantt will take the section's label from the locale.labels collection).
   * For example, for the time section, dhtmlxGantt will take the label stored as gantt.locale.labels.section_time.
   */
  name: string;
  /**
   * the name of a data property that will be mapped to the section.
   */
  map_to?: string;
  /**
   * the type of the section control (editor).
   * Can be 'textarea' or 'duration' or 'time' or 'select' or 'parent' or 'checkbox' or 'radio' or 'resources' or 'constraint' or 'template'
   * or something custom
   */
  type: string;
  /**
   * the section's height. Not used with the checkbox and radio sections.
   */
  height?: number;
  /**
   * if set to true, the section will take focus on opening the lightbox.
   */
  focus?: boolean;
}
