import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-order-planning',
  templateUrl: './order-planning.component.html',
  styleUrls: ['./order-planning.component.scss']
})
export class OrderPlanningComponent extends SpecificWindowUiComponent implements OnInit {
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }
  gridId = 0;
  workingTime = 0;
  percentUtilization = 0;

  ngOnInit() {
    super.ngOnInit();
  }

  initialize() {
    this.workingTime = 0;
    this.percentUtilization = 0;
    this.dataStore.data.WorkingTime = 0;
    this.dataStore.data.PercentUtilization = 0;
  }

  notifyFromDataChange(item: any) {
    if (item.data['isLaunchSearchGrid']) {
      this.initialize();
    }

    if (item.data.columnName === 'Z_ProductionRessource_ID' && this.dataStore.data['Z_ProductionRessource_ID']) {
      const request: DataStoreRequest = {
        windowId: this.dataStore.key.windowId,
        record_id: this.dataStore.key.recordId.toString(),
        parent_constraint: undefined,
        compiereRequest: {
          tableName: 'Z_ProductionRessource',
          startRow: 0,
          endRow: 1,
          filterModel: {
            Z_ProductionRessource_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data['Z_ProductionRessource_ID'].id],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };

      this.subscriptions.push(
        this.store.getDataGrid(request).subscribe((response) => {
          const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'DailyCapacity');
          this.dataStore.data['DailyCapacity'] = response.data[0]['Z_HR_HEBDO'] ? response.data[0]['Z_HR_HEBDO'] / 5 : 8;
          datacontainer.updateStore(this.dataStore.data['DailyCapacity']);
        })
      );
    }
    super.notifyFromDataChange(item);
  }

  notifySelect(rowSelected: any) {
    const WorkingTimeDataContainer = this.dataContainers.find((elem) => elem.data.columnName === 'workingtimemin');
    const percentDataContainer = this.dataContainers.find((elem) => elem.data.columnName === 'percentutilization');

    this.gridId = this.fields.find(
      (el) => el.component === 'GridViewUiComponent' && el.name === 'Approbation des ordres planifiés (Table)'
    ).formDetailId;

    const workingTimeReducer = (accumulator: number, current: any) => accumulator + current.workingtimemin;
    const percentUtilizationReducer = (accumulator: number, current: any) => accumulator + current.percentutilization;

    const selectedRow = this.dataStore.data.selections.find((gridSel) => gridSel.AD_FormDetail_ID === this.gridId)
      .selection as any[];

    this.workingTime = selectedRow.length > 0 ? selectedRow.reduce(workingTimeReducer, 0) : 0;
    this.percentUtilization = selectedRow.length > 0 ? selectedRow.reduce(percentUtilizationReducer, 0) : 0;

    if (WorkingTimeDataContainer !== undefined) {
      this.dataStore.data['workingtimemin'] = this.workingTime;
      WorkingTimeDataContainer.updateStore(this.dataStore.data['workingtimemin']);
    }
    if (percentDataContainer !== undefined) {
      this.dataStore.data['percentutilization'] = this.percentUtilization;
      percentDataContainer.updateStore(this.dataStore.data['percentutilization']);
    }
  }

  onEndProcess(ping: ProcessPingInfo) {
    super.onEndProcess(ping);
    this.initialize();
  }
}
