import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocserverEntry } from '@compiere-ws/models/docserver-entry-json';
import { DocserverDisplayedTag } from '@compiere-ws/models/docserverDisplayedTag';
import { DocserverWsResponse } from '@compiere-ws/models/docserverWsResponse';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { UploadedFile } from '@iupics-components/models/uploaded-file';
import { PrimeFileuploadComponent } from '@iupics-components/overrided/prime-fileupload/prime-fileupload.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { Global } from '@iupics-manager/models/global-var';
import { MongoSearchQueryCombination } from '@iupics-manager/models/mongo-search';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'iu-input-file-ui',
  templateUrl: './input-file-ui.component.html',
  styleUrls: ['./input-file-ui.component.scss']
})
export class InputFileUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  dataContainers: AbstractDataContainerCallout;
  uploadingFiles: File[] = [];
  @Output()
  nbUploadedFilesChangeEmitter: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  @ViewChild(PrimeFileuploadComponent, { static: true })
  primeFileuploadComponent: PrimeFileuploadComponent;

  @Output()
  fileAttachedEmitter = new EventEmitter<any>();

  @Output() uploadEnd = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  @Input()
  isAttachment = false;
  @Input()
  displayedTags: DocserverDisplayedTag[] = [];
  @Input()
  uploadedFiles: UploadedFile[] = [];
  @Input()
  linkedFiles: UploadedFile[] = [];
  @Input()
  multiple = false;
  @Input()
  displayFileUploaded = true;
  @Input()
  displayButtons = true;
  @Input()
  auto = false;
  @Input()
  type = 'ATTACHEMENT';
  @Input()
  styleClass: string;
  @Input()
  mustGet = true;
  @Input()
  isLoaderActive = false;
  @Input()
  searchInformations = [{ 'META|TYPE': this.type }];
  @Input()
  additionalUploadInformations: any;
  @Input()
  canUpload = true;
  @Input()
  isLocal = false;
  @Input()
  canDelete = true;
  @Input()
  canDownload = true;
  @Input()
  canPreview = true;
  @Input()
  columnName: string;
  @Input()
  fromDocServer = true;
  @Input()
  adTable_ID: number;
  @Input()
  adTab_ID: number;
  @Input()
  record_id: number;

  @Input()
  uploadedFileList_title = 'fileupload.files_list';
  @Input()
  uploadedFileList_noFiles = 'fileupload.no_files';
  @Input()
  uploadingFileList_title = 'fileupload.add_files';
  @Input()
  uploadingFileList_drag = 'fileupload.label';
  @Input()
  uploadBtn_confirm = 'fileupload.upload_files';
  @Input()
  uploadBtn_cancel = 'fileupload.clear';

  localFiles: File[];

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    private docServerService: DocServerService,
    renderer: Renderer2,
    protected po: PoService,
    private config: AppConfig,
    private sanitizer: DomSanitizer
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }

  ngOnInit() {
    super.ngOnInit();
    if ((!this.isStandalone && this.data.IsParam) || this.container instanceof SpecificWindowUiComponent) {
      this.mustGet = false;
    }
    // récupère les fichier depuis le serveur
    if (this.mustGet && !this.isLocal) {
      this.getFiles();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        this.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService);
        });
      });
    }
  }

  getFiles() {
    const searchQuery = this.docServerService.createQuery(this.searchInformations, MongoSearchQueryCombination.OR, false, true);
    const sub = this.docServerService
      .advancedSearchDocuments(
        searchQuery,
        { limit: -1, start: 0, attachmentInteraction: true },
        this.adTab_ID,
        this.adTable_ID,
        this.record_id
      )
      .subscribe((response: DocserverWsResponse) => {
        if (response) {
          this.displayedTags = response.displayedTags;
          response.entries.forEach((hit: DocserverEntry) => {
            if (hit.type === 'ATTACHEMENT' && parseInt(hit.table_id, 10) === this.adTable_ID) {
              this.uploadedFiles.push({
                createdDate: hit.ufa_created_date ? this.formatDate(hit.ufa_created_date) : null,
                src: hit.url,
                name: hit.fileName,
                id: hit.id,
                docId: hit.docId,
                attachment_ID: hit.attachment_ID,
                extension: hit.fileName
                  ? hit.fileName.split('.').length > 1
                    ? hit.fileName.split('.')[hit.fileName.split('.').length - 1]
                    : undefined
                  : undefined,
                isDeletable: true,
                tags: hit.tags
              });
            } else {
              this.linkedFiles.push({
                createdDate: hit.ufa_created_date ? this.formatDate(hit.ufa_created_date) : null,
                src: hit.url,
                name: hit.fileName,
                id: hit.id,
                docId: hit.docId,
                attachment_ID: hit.attachment_ID,
                extension: hit.fileName
                  ? hit.fileName.split('.').length > 1
                    ? hit.fileName.split('.')[hit.fileName.split('.')[hit.fileName.split('.').length - 1]]
                    : undefined
                  : undefined,
                isDeletable: hit.table_id && parseInt(hit.table_id, 10) === this.adTable_ID,
                tags: hit.tags
              });
            }
          });
        }

        this.nbUploadedFilesChangeEmitter.emit(this.uploadedFiles.length + this.linkedFiles.length);
        sub.unsubscribe();
      });
  }
  formatDate(value: string) {
    let dateFormatted;
    try {
      dateFormatted = new Date(value).toLocaleDateString();
    } catch (error) {
      dateFormatted = value;
    }
    return dateFormatted;
  }
  myUploader(event: any) {
    // upload des fichiers
    this.uploadFiles(event.files);
  }

  /**
   * Upload les fichiers vers le document server
   * OK
   * @param {File[]}files
   */
  uploadFiles(files: File[] = []) {
    this.localFiles = [...files];
    for (const file of files) {
      // le fichier n'est pas déjà uploadé..
      if (this.uploadingFiles.indexOf(file) === -1) {
        this.uploadingFiles.push(file);
        const id = file.name.replace(/./g, '_') + new Date().getTime();
        const attachmentInteraction = this.config.getConstant('AttachmentInteraction');
        this.subscriptions.push(
          this.docServerService
            .uploadDocument(file, id, this.type, this.additionalUploadInformations, this.isAttachment)
            .subscribe((docServerData) => {
              if (attachmentInteraction && this.isAttachment) {
                const s = this.po
                  .save('AD_Attachment', {
                    AD_Table_ID: this.additionalUploadInformations['META|TABLE_ID'],
                    Record_ID: this.additionalUploadInformations['META|RECORD_ID'],
                    Title: file.name ? file.name : docServerData.match(/^(?:\/download\/)(.*)(?:\/.*$)/)[1],
                    DocServerID: docServerData.match(/^(?:\/download\/)(.*)(?:\/.*$)/)[1],
                    TextMsg: 'http://' + environment.config.backend.docserver.servicename + ':9000' + docServerData // 9000 is internal port of doc-server Docker , never change
                  })
                  .subscribe((res) => {
                    /* res = urlDownload du fichier uploadé */
                    const uploadedFile = {
                      createdDate: new Date().toLocaleDateString(),
                      src: docServerData,
                      name: file.name,
                      id: id,
                      docId: docServerData.match(/^(?:\/download\/)(.*)(?:\/.*$)/)[1],
                      attachment_ID: res['AD_Attachment_ID'],
                      extension: file.name
                        ? file.name.split('.').length > 1
                          ? file.name.split('.')[file.name.split('.').length - 1]
                          : undefined
                        : undefined,
                      isDeletable: true,
                      tags: this.additionalUploadInformations
                    };
                    if (this.multiple) {
                      this.uploadedFiles.push(uploadedFile);
                    } else {
                      this.uploadedFiles = [uploadedFile];
                    }
                    this.nbUploadedFilesChangeEmitter.emit(this.uploadedFiles.length);
                    this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1);
                    this.primeFileuploadComponent.removeFile(file);
                    this.fileAttachedEmitter.emit(this.uploadedFiles);
                    this.uploadEnd.emit(uploadedFile);
                    this.dataChange(this.uploadedFiles[0].src);
                    s.unsubscribe();
                  });
              } else {
                const uploadedFile = {
                  createdDate: new Date().toLocaleDateString(),
                  src: docServerData,
                  name: file.name,
                  id: id,
                  docId: docServerData.match(/^(?:\/download\/)(.*)(?:\/.*$)/)[1],
                  extension: file.name
                    ? file.name.split('.').length > 1
                      ? file.name.split('.')[file.name.split('.').length]
                      : undefined
                    : undefined,
                  isDeletable: true,
                  tags: this.additionalUploadInformations
                };
                if (this.multiple) {
                  this.uploadedFiles.push(uploadedFile);
                } else {
                  this.uploadedFiles = [uploadedFile];
                }
                this.nbUploadedFilesChangeEmitter.emit(this.uploadedFiles.length + this.linkedFiles.length);
                this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1);
                this.primeFileuploadComponent.removeFile(file);
                this.fileAttachedEmitter.emit(this.uploadedFiles);
                this.uploadEnd.emit(uploadedFile);
                this.dataChange(this.uploadedFiles[0].src);
              }
            })
        );
      }
    }
  }

  /**
   * Télécharge le fichier depuis le docserver
   * OK
   * @param {string}docId
   * @param {number}index
   */
  downloadFile(file: UploadedFile) {
    if (!this.isLocal) {
      const sub = this.docServerService.downloadDocument(file.src as string).subscribe((response) => {
        Global.downloadFile(response, file.name as string);
        sub.unsubscribe();
      });
    } else {
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.href = file.src as string;
      link.download = file.name as string;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  /**
   * Supprime un fichier uploadé
   * OK
   * @param {any}docId
   * @param {number}index
   */
  deleteFile(file: UploadedFile) {
    this.subscriptions.push(
      this.docServerService.deleteDocument(file, this.isAttachment).subscribe((res) => {
        if (this.uploadedFiles.find((fileTmp) => fileTmp.id === file.id)) {
          this.uploadedFiles.splice(
            this.uploadedFiles.findIndex((fileTmp) => fileTmp.id === file.id),
            1
          );
        }
        if (this.linkedFiles.find((fileTmp) => fileTmp.id === file.id)) {
          this.linkedFiles.splice(
            this.linkedFiles.findIndex((fileTmp) => fileTmp.id === file.id),
            1
          );
        }
        this.nbUploadedFilesChangeEmitter.emit(this.uploadedFiles.length + this.linkedFiles.length);
        this.delete.emit(file);
      })
    );
  }

  onFileSelect(event) {
    if (this.auto) {
      // console.log(event);
      const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      this.uploadFiles(files);
    }
  }

  reset() {
    this.primeFileuploadComponent.files = [];
    this.localFiles = undefined;
    this.uploadedFiles = [];
    this.uploadingFiles = [];
    this.linkedFiles = [];
  }
}
