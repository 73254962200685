<fieldset
  [attr.id]="id"
  [ngClass]="[
    'p-fieldset ui-widget ui-widget-content ui-corner-all',
    toggleable ? 'p-fieldset-toggleable' : '',
    styleClass ? styleClass : ''
  ]"
  [ngStyle]="style"
  [attr.data-cy-tablename]="tableName ? tableName : legend"
  [attr.aria-expanded]="!collapsed"
>
  <legend *ngIf="isLegendVisible" [ngClass]="['p-fieldset-legend ui-corner-all ui-state-default ui-unselectable-text']">
    <ng-container>
      <a
        tabindex="0"
        (click)="handleLegendEvent($event)"
        (keydown.enter)="handleLegendEvent($event)"
        [attr.aria-controls]="id + '-content'"
        [attr.aria-expanded]="!collapsed"
        data-cy="fieldset"
      >
        <ng-container *ngTemplateOutlet="legendContent"></ng-container>
      </a>
    </ng-container>
    <ng-template #legendContent>
      <span class="p-fieldset-legend-text" [style.font-size]="overridedCSS?.legendFontSize">{{ legend }}</span>
      <span
        *ngIf="toggleable && (!isSingleRow || isReadOnly)"
        [ngClass]="['p-fieldset-toggler pi', collapsed ? 'pi-plus' : 'pi-minus']"
      ></span>
      <span
        class="icon-ico-next-info"
        tooltipPosition="top"
        *ngIf="isSingleRow && !isReadOnly"
        [showDelay]="250"
        [life]="3"
        data-cy="openNewBlade"
        pTooltip="{{ 'menuBarDetails.openNewBlade' | translate }}"
      ></span>
      <ng-content select="p-header"></ng-content>
    </ng-template>
  </legend>
  <div
    [attr.id]="id + '-content'"
    [@fieldsetContent]="
      collapsed
        ? { value: 'hidden', params: { transitionParams: transitionOptions } }
        : { value: 'visible', params: { transitionParams: transitionOptions } }
    "
    [ngClass]="[collapsed || animating ? 'p-fieldset-content-wrapper-overflown' : '', 'p-fieldset-content-wrapper']"
    [attr.aria-hidden]="collapsed"
    (@fieldsetContent.done)="onToggleDone($any($event))"
    role="region"
  >
    <div class="p-fieldset-content"><ng-content></ng-content></div>
  </div>
</fieldset>
