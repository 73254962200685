import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import {
  ApizGanttStatic,
  GanttCalendar,
  GanttData,
  GanttDragConfig,
  GanttDragMode,
  GanttDurationFormatter,
  GanttGridColumn,
  GanttLightboxSection,
  GanttTaskOpenedEvent,
  GanttZoomConfig,
  GanttZoomLevel,
  Resource,
  Task
} from '@iupics-components/models/gantt.model';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { Gantt, GanttPlugins, GanttStatic } from 'dhtmlx-gantt';
import { isNil } from 'lodash';
import { Observable, Subscription } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'iu-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss']
})
export class GanttComponent implements OnInit, OnDestroy, OnChanges {
  public readonly IupicsMenuType = IupicsMenuType;

  @ViewChild('ganttDiv', { static: true }) ganttDiv: ElementRef<HTMLDivElement>;
  private _id_iterate = 0;
  private subscriptions: Subscription[] = [];

  paginationInProgress = false;
  gantt: ApizGanttStatic = Gantt.getGanttInstance() as ApizGanttStatic;
  private hourFormatter: GanttDurationFormatter;
  private mandatoryColumns: GanttGridColumn[] = [
    {
      name: 'duration',
      label: this.translateService.instant('planning-window.columns.duration'),
      width: 60,
      align: 'center',
      template: (task: Task) => {
        return this.hourFormatter.format(task.duration);
      }
    }
  ];
  private resourceConfig: any;
  private resourcesStore: any;
  private zoomConfig: GanttZoomConfig;

  //#region universal-filter-related
  @Input() uf_columns: any[];
  @Input() uf_formId: number;
  @Input() uf_isStandalone = false;
  @Input() hasUniversalFilter = false;
  @Output() filterChange = new EventEmitter<{
    filterToApply: CompiereDataGridRequestJSON;
    isNotFromUF: boolean;
    gantt: GanttComponent;
  }>();
  @Input() setFilterEmitter: EventEmitter<CompiereDataGridRequestJSON>;
  //#endregion

  @Output() pageChange = new EventEmitter<{ gantt: GanttComponent; type: 'next' | 'prev' }>();
  @Output() taskOpened = new EventEmitter<GanttTaskOpenedEvent<Task>>();

  @Input() ganttHeight = 600;
  @Input('gridWidth') grid_width = 500;
  @Input() columns: GanttGridColumn[] = [
    {
      name: 'text',
      label: 'Task name',
      tree: true,
      width: '*',
      template: (task: Task) => {
        return `<div title="${task.text}">${task.text}</div>`;
      }
    },
    { name: 'description', label: 'Description', width: 100 },
    { name: 'start_date', label: 'Start time', width: 80, align: 'center' }
  ];
  @Input() ganttData: GanttData = { data: [], links: [] };
  @Input() ganttData$: Observable<GanttData>;
  @Input() levelZoom: GanttZoomLevel = GanttZoomLevel.DAY;
  @Input() dateFormat = '%Y-%m-%d %H:%i';
  @Input() canZoom = true;
  @Input() dragConfig: GanttDragConfig = {};
  @Input() paginationEnable = false;
  @Input() actualPage = 0;
  @Input() maximumPage = 0;
  @Input() showTodayMarker = true;
  @Input() calendarConfig: GanttCalendar = {
    id: 'cal_01',
    worktime: {
      hours: [8, 17],
      days: [0, 1, 1, 1, 1, 1, 0]
    }
  };
  @Input() worktime = false;
  @Input() worktimes: { hours: (string | number)[] } = { hours: [8, 12, 13, 17] };

  @Input() lightboxEnable: boolean | ((task: Task) => boolean) = false;
  @Input() lightboxSections: GanttLightboxSection[] = [];
  @Input() lightboxLabels: Map<string, string>;

  @Input() onAfterTaskDrag: (task: Task, gantt: GanttStatic, mode: GanttDragMode, e: Event) => Task[];
  @Input() onBeforeTaskDrag: (task: Task, gantt: GanttStatic, mode: GanttDragMode, e: Event) => boolean;
  @Input('taskText') task_text: (start: Date, end: Date, task: Task) => string = (start: Date, end: Date, task: Task) =>
    task.text;
  @Input('taskClass') task_class: (start: Date, end: Date, task: Task) => string = (start: Date, end: Date, task: Task) => '';
  @Input('gridRowClass') grid_row_class: (start: Date, end: Date, task: any) => string = (start: Date, end: Date, task: Task) =>
    '';
  @Input('taskRowClass') task_row_class: (start: Date, end: Date, task: any) => string = (start: Date, end: Date, task: any) =>
    '';

  constructor(private connectorService: SecurityManagerService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.initGanttPrerequisites();
    this.initGanttEvents();
    this.initGanttResourceManagement();
    this.initGanttConfig();
    if (this.lightboxEnable) {
      this.initLightbox();
    }

    this.gantt.plugins({
      auto_scheduling: true,
      undo: false,
      redo: false,
      zoom: this.canZoom,
      grouping: true,
      multiselect: true,
      marker: this.showTodayMarker
    } as GanttPlugins);

    if (this.showTodayMarker) {
      this.gantt.addMarker({
        start_date: new Date(),
        text: 'Now'
      });
    }

    this.initGanttZoom();
    this.initGanttStyle();
    this.initGanttLayout();

    this.gantt.init(this.ganttDiv.nativeElement);
  }

  private initGanttPrerequisites() {
    this.hourFormatter = this.gantt.ext.formatters.durationFormatter({
      enter: 'minute',
      store: 'minute',
      format: 'hour',
      short: true
    });
    this.columns.push(
      ...this.mandatoryColumns.filter((mc: GanttGridColumn) =>
        isNil(this.columns.find((c: GanttGridColumn) => c.name === mc.name))
      )
    );
  }

  private initGanttResourceManagement() {
    this.gantt.config.resource_store = 'resource';
    this.gantt.config.resource_property = 'owner_id';

    this.gantt.templates.resource_cell_value = (start_date, end_date, resource, tasks, assignments) => {
      return `<div>${tasks.length}</div>`;
    };

    // this.gantt.templates.resource_cell_class = (start_date, end_date, resource, tasks, assignments) => {
    //   if (tasks.length <= 1) {
    //     return 'workday_ok';
    //   } else {
    //     return 'workday_over';
    //   }
    // };

    this.resourceConfig = {
      columns: [
        {
          name: 'name',
          label: 'Name',
          tree: true,
          template: (resource: Resource) => {
            return resource.text;
          }
        },
        {
          name: 'workload',
          label: 'Workload',
          align: 'center',
          template: (resource: Resource) => {
            let tasks: any[];
            const store = this.gantt.getDatastore(this.gantt.config.resource_store),
              field = this.gantt.config.resource_property;

            if (store.hasChild(resource.id)) {
              tasks = this.gantt.getTaskBy(field, store.getChildren(resource.id));
            } else {
              tasks = this.gantt.getTaskBy(field, resource.id);
            }

            let totalDuration = 0;
            for (let i = 0; i < tasks.length; i++) {
              totalDuration += tasks[i].static_duration;
            }

            return totalDuration + 'h';
          }
        }
      ]
    };

    this.resourcesStore = this.gantt.createDatastore({
      name: this.gantt.config.resource_store,
      type: 'treeDatastore',
      initItem: (item: Resource) => {
        item.parent = item.parent || this.gantt.config.root_id;
        item[this.gantt.config.resource_property] = item.parent;
        item.open = true;
        return item;
      }
    });
    console.log(this.resourcesStore);
    // this.resourcesStore = this.gantt.getDatastore(this.gantt.config.resource_store);
  }

  private initLightbox() {
    this.gantt.config.lightbox.sections = this.lightboxSections;
    this.gantt.templates.lightbox_header = (start_date: Date, end_date: Date, task: Task) => {
      return `${task.reference || ''}`;
    };
    if (this.lightboxLabels?.size) {
      for (const entry of this.lightboxLabels) {
        this.gantt.locale.labels[entry[0]] = entry[1];
      }
    }
    this.gantt.config.wide_form = true;
  }

  private initGanttConfig() {
    this.gantt.config.open_split_tasks = false;
    this.gantt.config.branch_loading = true;

    this.gantt.config.duration_unit = 'minute';
    // this.gantt.config.duration_step = 60;
    this.gantt.config.round_dnd_dates = false;
    this.gantt.config.time_step = 1;

    this.gantt.i18n.setLocale(this.connectorService.getIupicsDefaultLanguage().iso_code.split('_')[0].toLowerCase());

    this.gantt.config.drag_lightbox = this.dragConfig.hasOwnProperty('drag_lightbox') ? this.dragConfig.drag_lightbox : false;
    this.gantt.config.drag_links = this.dragConfig.hasOwnProperty('drag_links') ? this.dragConfig.drag_links : true;
    this.gantt.config.drag_move = this.dragConfig.hasOwnProperty('drag_move') ? this.dragConfig.drag_move : true;
    this.gantt.config.drag_multiple = this.dragConfig.hasOwnProperty('drag_multiple') ? this.dragConfig.drag_multiple : false;
    this.gantt.config.drag_progress = this.dragConfig.hasOwnProperty('drag_progress') ? this.dragConfig.drag_progress : true;
    this.gantt.config.drag_resize = this.dragConfig.hasOwnProperty('drag_resize') ? this.dragConfig.drag_resize : true;
    this.gantt.config.drag_timeline = this.dragConfig.hasOwnProperty('drag_timeline') ? this.dragConfig.drag_timeline : true;

    this.gantt.config.date_format = this.dateFormat;
    // const dateToStr = this.gantt.date.date_to_str(this.dateFormat);
    // this.gantt.templates.format_date = (date) => {
    //   return dateToStr(date);
    // };

    if (this.worktime) {
      this.gantt.config.work_time = true;
      this.gantt.setWorkTime(this.worktimes);
    } else {
      this.gantt.config.work_time = false;
      this.gantt.addCalendar(this.calendarConfig);
      this.gantt.config.calendar_property = 'calendar_id';
    }

    this.gantt.config.columns = this.columns;
  }

  private initGanttZoom() {
    this.zoomConfig = {
      activeLevelIndex: 1,
      levels: [
        {
          name: 'hour',
          scale_height: 75,
          min_column_width: 25,
          scales: [
            { unit: 'hour', step: 1, format: '%Gh' },
            { unit: 'day', step: 1, format: '%j %F, %l' },
            { unit: 'minute', step: 15, format: '%i' }
          ]
        },
        {
          name: 'day',
          scale_height: 50,
          min_column_width: 25,
          scales: [
            { unit: 'month', step: 1, format: '%M' },
            { unit: 'day', step: 1, format: '%d' }
          ]
        },
        {
          name: 'week',
          scale_height: 50,
          min_column_width: 50,
          scales: [
            {
              unit: 'week',
              step: 1,
              format: (date: Date) => {
                const dateToStr = this.gantt.date.date_to_str('%d %M');
                const endDate = this.gantt.date.add(date, -6, 'day');
                const weekNum = this.gantt.date.date_to_str('%W')(date);
                return '#' + weekNum + ', ' + dateToStr(date) + ' - ' + dateToStr(endDate);
              }
            },
            { unit: 'day', step: 1, format: '%j %D' }
          ]
        },
        {
          name: 'month',
          scale_height: 50,
          min_column_width: 120,
          scales: [
            { unit: 'month', format: '%F, %Y' },
            { unit: 'week', format: 'Week #%W' }
          ]
        },
        {
          name: 'quarter',
          scale_height: 50,
          min_column_width: 90,
          scales: [
            { unit: 'month', step: 1, format: '%M' },
            {
              unit: 'quarter',
              step: 1,
              format: (date: Date) => {
                const dateToStr = this.gantt.date.date_to_str('%M');
                const endDate = this.gantt.date.add(this.gantt.date.add(date, 3, 'month'), -1, 'day');
                return dateToStr(date) + ' - ' + dateToStr(endDate);
              }
            }
          ]
        },
        {
          name: 'year',
          scale_height: 25,
          min_column_width: 30,
          scales: [{ unit: 'year', step: 1, format: '%Y' }]
        }
      ]
    };

    this.gantt.ext.zoom.init(this.zoomConfig);
    this.gantt.ext.zoom.setLevel(this.levelZoom);
  }

  private initGanttEvents() {
    this.gantt.attachEvent(
      'onGanttReady',
      () => {
        this.initGanttData();
      },
      { id: `event-${this._id_iterate++}` }
    );

    this.gantt.attachEvent(
      'onParse',
      () => {
        // const now = moment();
        // this.gantt.showDate(now.toDate());
        console.log(this.gantt.serialize());
        // const { data } = this.ganttData;
        // const taskStart = new Date(
        //   Math.min.apply(
        //     null,
        //     data.map((d) => d.start_date as Date)
        //   )
        // );
        // const taskEnd = new Date(
        //   Math.max.apply(
        //     null,
        //     data.map((d) => d.end_date as Date)
        //   )
        // );
        // const scaleStart = this.gantt.config.start_date || new Date();
        // const scaleEnd = this.gantt.config.end_date || new Date();
        // if the task is out of the range
        // if (scaleStart > taskEnd || scaleEnd < taskStart) {
        // update the time scale range
        // this.gantt.config.end_date = new Date(Math.max(taskEnd.valueOf(), scaleEnd.valueOf()));
        // this.gantt.config.end_date = new Date(taskEnd.valueOf());
        // this.gantt.config.start_date = new Date(Math.min(taskStart.valueOf(), scaleStart.valueOf()));
        // this.gantt.config.start_date = new Date(taskStart.valueOf());
        // this.gantt.render();
        // }
        return true;
      },
      { id: `event-${this._id_iterate++}` }
    );

    if (this.onAfterTaskDrag) {
      this.gantt.attachEvent(
        'onAfterTaskDrag',
        (id: string, mode: GanttDragMode, e: Event) => {
          const updatedTasks = this.onAfterTaskDrag(this.gantt.getTask(id), this.gantt, mode, e);
          for (let i = 0; i < updatedTasks.length; i++) {
            const updatedTask = updatedTasks[i];
            this.gantt.updateTask(updatedTask.id as string, updatedTask);
          }
          this.gantt.render();
        },
        { id: `event-${this._id_iterate++}` }
      );
    }

    if (this.onBeforeTaskDrag) {
      this.gantt.attachEvent(
        'onBeforeTaskDrag',
        (id: number, mode: GanttDragMode, e: Event) => {
          return this.onBeforeTaskDrag(this.gantt.getTask(id), this.gantt, mode, e);
        },
        { id: `event-${this._id_iterate++}` }
      );
    }

    this.gantt.attachEvent(
      'onTaskOpened',
      (id: string | number) => {
        this.taskOpened.emit({ task: this.gantt.getTask(id), gantt: this.gantt });
      },
      { id: `event-${this._id_iterate++}` }
    );

    this.gantt.attachEvent(
      'onBeforeLightbox',
      (task_id: number | string) => {
        const task: Task = this.gantt.getTask(task_id);
        const lightboxEnable =
          (typeof this.lightboxEnable === 'boolean' && this.lightboxEnable) ||
          (typeof this.lightboxEnable !== 'boolean' && this.lightboxEnable(task));
        if (lightboxEnable) {
          return this.lightboxEnable;
        }
        return false;
      },
      { id: `event-${this._id_iterate++}` }
    );

    this.gantt.attachEvent(
      'onAfterTaskAdd',
      (id: string | number, task: Task) => {
        console.log(task);
      },
      { id: `event-${this._id_iterate++}` }
    );
  }

  private initGanttData() {
    if (this.ganttData$) {
      this.subscriptions.push(
        this.ganttData$.subscribe((ganttData) => {
          this.ganttData = ganttData;
          const taskStore = this.gantt.getDatastore('task');
          taskStore.clearAll();
          this.gantt.parse(this.ganttData);
          this.resourcesStore.parse([
            { id: 1, text: 'QA', parent: null },
            { id: 2, text: 'Development', parent: null },
            { id: 3, text: 'Sales', parent: null },
            { id: 4, text: 'Other', parent: null },
            { id: 5, text: 'Unassigned', parent: 4 },
            { id: 6, text: 'John', parent: 1 },
            { id: 7, text: 'Mike', parent: 2 },
            { id: 8, text: 'Anna', parent: 2 },
            { id: 9, text: 'Bill', parent: 3 },
            { id: 10, text: 'Floe', parent: 3 }
          ]);
          this.gantt.render();
        })
      );
    } else {
      const taskStore = this.gantt.getDatastore('task');
      taskStore.clearAll();
      this.gantt.parse(this.ganttData);
      this.resourcesStore.parse([
        { id: 1, text: 'QA', parent: null },
        { id: 2, text: 'Development', parent: null },
        { id: 3, text: 'Sales', parent: null },
        { id: 4, text: 'Other', parent: null },
        { id: 5, text: 'Unassigned', parent: 4 },
        { id: 6, text: 'John', parent: 1 },
        { id: 7, text: 'Mike', parent: 2 },
        { id: 8, text: 'Anna', parent: 2 },
        { id: 9, text: 'Bill', parent: 3 },
        { id: 10, text: 'Floe', parent: 3 }
      ]);
      this.gantt.render();
    }
  }

  private initGanttStyle() {
    this.gantt.config.grid_width = this.grid_width;
    this.gantt.templates.task_text = this.task_text;
    this.gantt.templates.task_class = this.task_class;
    this.gantt.templates.grid_row_class = this.grid_row_class;
    this.gantt.templates.task_row_class = this.task_row_class;
    this.gantt.templates.grid_folder = () => '';
    this.gantt.templates.grid_file = () => '';
    this.gantt.config.row_height = 30;
    this.gantt.config.task_height = 25;
    this.gantt.templates.timeline_cell_class = (task, date) => {
      if (!this.gantt.isWorkTime({ date, unit: this.zoomConfig.levels[this.gantt.ext.zoom.getCurrentLevel()].name })) {
        return 'day-off';
      }
      return '';
    };
  }

  private initGanttLayout() {
    this.gantt.config.layout = {
      css: 'gantt_container',
      rows: [
        {
          cols: [
            { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
            { resizer: true, width: 1 },
            { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' }
          ],
          gravity: 2
        },
        { resizer: true, width: 1 },
        {
          config: this.resourceConfig,
          cols: [
            { view: 'resourceGrid', group: 'grids', width: 435, scrollY: 'resourceVScroll' },
            { resizer: true, width: 1 },
            { view: 'resourceTimeline', scrollX: 'scrollHor', scrollY: 'resourceVScroll' },
            { view: 'scrollbar', id: 'resourceVScroll', group: 'vertical' }
          ],
          gravity: 1
        },
        { view: 'scrollbar', id: 'scrollHor' }
      ]
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns && !changes.columns.firstChange) {
      this.gantt.config.columns = this.columns;
      this.gantt.config.grid_width = this.columns.map((c) => c.width).reduce((sum: number, curr: number) => sum + curr) as number;
      this.gantt.render();
    }
    if (changes.ganttData$ && !changes.ganttData$.firstChange) {
      this.initGanttData();
    }
  }

  handlePageBtn(event: Event, type: 'next' | 'prev') {
    event.stopPropagation();
    this.paginationInProgress = true;
    this.pageChange.emit({ gantt: this, type });
  }

  onPageChange(ganttData$?: Observable<GanttData>) {
    if (ganttData$) {
      this.ganttData$ = ganttData$;
      this.subscriptions.push(
        this.ganttData$.subscribe((ganttData) => {
          this.gantt.clearAll();
          this.gantt.parse(ganttData);
          this.ganttData = ganttData;
          this.paginationInProgress = false;
        })
      );
    } else {
      this.paginationInProgress = false;
    }
  }

  onFilterChange(result: { filterToApply: CompiereDataGridRequestJSON; isNotFromUF: boolean }) {
    this.filterChange.emit({ ...result, ...{ gantt: this } });
  }

  ngOnDestroy() {
    // for (let i = 0; i < this._id_iterate; i++) {
    //   this.gantt.detachEvent(`event-${i}`);
    // }
    this.gantt.detachAllEvents();
    this.resourcesStore?.detachAllEvents();
    this.gantt?.destructor();

    for (let i = 0; i < this.subscriptions.length; i++) {
      const sub = this.subscriptions[i];
      sub.unsubscribe();
    }
  }
}
