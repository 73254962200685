import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompiereDataGridFilterType, CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { PrimeContextMenuComponent } from '@iupics-components/overrided/prime-contextmenu/prime-contextmenu.component';
import { InfoDialogComponent } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { Global } from '@iupics-manager/models/global-var';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'wd-desktop-ui',
  templateUrl: './desktop-ui.component.html',
  styleUrls: ['./desktop-ui.component.scss']
})
export class DesktopComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PrimeContextMenuComponent, { static: true })
  cm: PrimeContextMenuComponent;
  @ViewChild(InfoDialogComponent, { static: true })
  infoDialog: InfoDialogComponent;
  menuItems: MenuItem[];
  subscriptions: any[] = [];
  urlParams = {
    id: null,
    type: null,
    viewType: null,
    recordId: null,
    viewData: null,
    processParamsMap: null,
    othersRecordId: null,
    dataGridRequest: null,
    ctx: null
  };
  @Output()
  validateLogoutEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private cmService: ContextMenuService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    private themeService: ThemeService,
    private progressService: ProcessInProgressService
  ) {}

  ngOnInit() {
    this.subscription();
    this.handleParamsFromUrl();
  }
  ngAfterViewInit() {
    this.themeService.setActiveTheme();
    Global.infoDialog = this.infoDialog;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  subscription() {
    this.subscriptions.push(
      this.cmService.emitter.subscribe((res) => {
        this.menuItems = res.menuItems;
        this.cm.show(res.originalEvent);
      })
    );
  }

  onContextMenu(event: MouseEvent) {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    event.preventDefault();
  }
  /**
   * @description check if any params from url are specified and set them to the component
   */
  handleParamsFromUrl() {
    this.route.paramMap.subscribe((data) => {
      if (data.get('windowId')) {
        this.urlParams.id = +data.get('windowId');
        this.urlParams.recordId = data.get('recordId') ? data.get('recordId') : null;
        this.urlParams.type = 'Window';
        // check if params exist
        this.route.queryParams.subscribe((queryParam) => {
          if (queryParam['viewType']) {
            switch (queryParam['viewType'].toLowerCase()) {
              case ViewType.GRID:
                this.urlParams.viewType = ViewType.GRID;
                break;
              case ViewType.CALENDAR:
                this.urlParams.viewType = ViewType.CALENDAR;
                break;
              case ViewType.KANBAN:
                this.urlParams.viewType = ViewType.KANBAN;
                break;
              case ViewType.CHART:
                this.urlParams.viewType = ViewType.CHART;
                break;
              case ViewType.TREE:
                this.urlParams.viewType = ViewType.TREE;
                break;
              default:
                break;
            }
          }
          let i = 0;
          const dataGridRequest: CompiereDataGridRequestJSON = {
            filterModel: {},
            sortModel: [],
            rowGroupCols: [],
            valueCols: [],
            pivotCols: []
          };
          while (queryParam['column_' + i]) {
            if (
              queryParam['column_' + i] &&
              queryParam['filterOperator_' + i] &&
              queryParam['filterValue_' + i] &&
              queryParam['filterType_' + i]
            ) {
              dataGridRequest.filterModel[queryParam['column_' + i]] = {
                operators: queryParam['filterOperator_' + i].split('|'),
                values:
                  ((queryParam['filterType_' + i] === CompiereDataGridFilterType.SET ||
                    queryParam['filterType_' + i] === CompiereDataGridFilterType.NUMBER) &&
                    queryParam['filterValue_' + i].indexOf(',')) > 0
                    ? queryParam['filterValue_' + i].split('|').map((v) =>
                        v.split(',').map((toObject) => {
                          return toObject;
                        })
                      )
                    : queryParam['filterType_' + i] === CompiereDataGridFilterType.SET ||
                      queryParam['filterType_' + i] === CompiereDataGridFilterType.NUMBER
                    ? queryParam['filterValue_' + i].split('|').map((toObject) => {
                        return toObject;
                      })
                    : queryParam['filterValue_' + i].split('|').map((toObject) => {
                        if (queryParam['filterType_' + i] === CompiereDataGridFilterType.DATE) {
                          return moment(toObject).toDate();
                        } else {
                          return toObject;
                        }
                      }),
                filterType: queryParam['filterType_' + i]
              };
            }
            i++;
          }
          i = 0;
          while (queryParam['sortColId_' + i]) {
            if (queryParam['sortType_' + i]) {
              dataGridRequest.sortModel.push({
                sort: queryParam['sortType_' + i],
                colId: queryParam['sortColId_' + i]
              });
            }
            i++;
          }
          i = 0;
          while (queryParam['groupId_' + i]) {
            if (
              queryParam['groupAggFunc_' + i] !== null &&
              queryParam['groupAggFunc_' + i] !== undefined &&
              queryParam['groupDisplayName_' + i] &&
              queryParam['groupField_' + i]
            ) {
              dataGridRequest.rowGroupCols.push({
                aggFunc: queryParam['groupAggFunc_' + i],
                displayName: queryParam['groupDisplayName_' + i],
                field: queryParam['groupField_' + i],
                id: queryParam['groupId_' + i]
              });
            }
            i++;
          }
          i = 0;
          while (queryParam['valueId_' + i]) {
            if (
              queryParam['valueAggFunc_' + i] !== null &&
              queryParam['valueAggFunc_' + i] !== undefined &&
              queryParam['valueDisplayName_' + i] &&
              queryParam['valueField_' + i]
            ) {
              dataGridRequest.valueCols.push({
                aggFunc: queryParam['valueAggFunc_' + i],
                displayName: queryParam['valueDisplayName_' + i],
                field: queryParam['valueField_' + i],
                id: queryParam['valueId_' + i]
              });
            }
            i++;
          }
          i = 0;
          while (queryParam['pivotId_' + i]) {
            if (queryParam['pivotDisplayName_' + i] && queryParam['pivotField_' + i]) {
              dataGridRequest.pivotCols.push({
                displayName: queryParam['pivotDisplayName_' + i],
                field: queryParam['pivotField_' + i],
                id: queryParam['pivotId_' + i]
              });
            }
            i++;
          }
          if (queryParam['pivotMode'] !== null && queryParam['pivotMode'] !== undefined) {
            dataGridRequest.pivotMode = /true/i.test(queryParam['pivotMode']);
          }
          this.urlParams.dataGridRequest = dataGridRequest;
          if (queryParam['path']) {
            const othersRecordId: string = queryParam['path'];
            const othersRecordIdSplitted = othersRecordId.split('/');
            othersRecordIdSplitted.splice(0, 1);
            if (othersRecordIdSplitted && othersRecordIdSplitted.length > 1) {
              this.urlParams.othersRecordId = [];
              for (let j = 0; j < othersRecordIdSplitted.length; j += 2) {
                if (othersRecordIdSplitted[j + 1]) {
                  this.urlParams.othersRecordId.push({
                    tabId: othersRecordIdSplitted[j],
                    recordId: othersRecordIdSplitted[j + 1]
                  });
                }
              }
            }
          }
        });
      }
      if (data.get('processId')) {
        this.urlParams.id = +data.get('processId');
        this.urlParams.type = 'Process' || 'Report';
        // get every params from url
        this.route.queryParams.subscribe((queryParam) => {
          if (Object.keys(queryParam).length) {
            this.urlParams.processParamsMap = new Map<string, any>();
            Object.keys(queryParam).forEach((paramKey) => {
              this.urlParams.processParamsMap.set(paramKey, queryParam[paramKey]);
            });
          }
        });
      }
      if (data.get('formId')) {
        this.urlParams.id = +data.get('formId');
        this.urlParams.type = 'Form';
      }
      if (data.get('reportId')) {
        this.urlParams.id = +data.get('reportId');
        this.urlParams.type = 'Report';
        // get every params from url
        this.route.queryParams.subscribe((queryParam) => {
          if (Object.keys(queryParam).length) {
            this.urlParams.processParamsMap = new Map<string, any>();
            Object.keys(queryParam).forEach((paramKey) => {
              this.urlParams.processParamsMap.set(paramKey, queryParam[paramKey]);
            });
          }
        });
      }
    });
  }

  showKeyBindLegend() {
    const spanElement = this.renderer.createElement('span') as HTMLSpanElement;
    this.renderer.addClass(spanElement, 'iupics-badge-legend');

    spanElement.innerHTML = `
      <span class="iupics-badge-not-shift">Primary Keybind: ALT+[KEY_SHORTCUT]</span>
      <span class="iupics-badge-not-shift">Tab navigation: ALT + [ & | é | " | \' | ( | § | è | ! | ç ]</span>
      <span class="iupics-badge-shift">Secondary Keybind: ALT+SHIFT+[KEY_SHORTCUT]</span>
    `;

    this.renderer.insertBefore(this.el.nativeElement, spanElement, this.el.nativeElement.firstChild);
    return spanElement;
  }

  isLoginModalDisplayed() {
    return Global.isLoginAsModalVisible;
  }

  hideLoginModal() {
    Global.isLoginAsModalVisible = false;
  }
}
