import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridType,
  DataStore,
  DataStoreKey,
  DataStoreName,
  DataStoreRequest,
  DataStoreStatus
} from '@compiere-ws/models/compiere-data-json';
import { CallInProgressService } from '@compiere-ws/services/call-in-progress/call-in-progress.service';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { GridTabInfinityScrollUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { GridUiComponent } from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import { MenuBarDetailUiComponent } from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import { MenuSmartbuttonsUiComponent } from '@iupics-components/standard/menu/menu-smartbuttons-ui/menu-smartbuttons-ui.component';
import {
  EditViewGetFormIDByTabID,
  EditViewGetFormIDByWindowID,
  EditViewGetProcessIDByTabID,
  EditViewGetProcessIDByWindowID
} from '@iupics-components/standard/menu/utils/menu.utils';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { KeybindStateManagerService } from '@iupics-manager/managers/keybind-state-manager/keybind-state-manager.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { PrintReportManager } from '@iupics-manager/managers/print-report/print-report-manager.service';
import { RecentItemsManagerService } from '@iupics-manager/managers/recent-items-manager/recent-items-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { AbstractDynamicView } from '@iupics-manager/models/abstract-dynamic-view';
import { DataConflict } from '@iupics-manager/models/data-conflict';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsZoomTarget } from '@iupics-manager/models/iupics-data';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { MongoSearchQueryCombination } from '@iupics-manager/models/mongo-search';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep, has } from 'lodash';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { ScrollPanel } from 'primeng/scrollpanel';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AdditionalInfoUiComponent } from '../additional-info-ui/additional-info-ui.component';
import { BladeUiComponent } from '../blade-ui/blade-ui.component';
import { ModalContentType, ModalUiComponent } from '../modal-ui/modal-ui.component';
import { EditViewUtils } from './utils/edit-view.utils';
@Component({
  selector: 'iu-edit-view-ui',
  templateUrl: './edit-view-ui.component.html',
  styleUrls: ['./edit-view-ui.component.scss']
})
export class EditViewUiComponent extends AbstractDynamicView implements OnInit, AfterViewInit, OnDestroy {
  /**
   * utilisé pour modifier le style quand une formModal est ouverte par un composant autre que bouton
   */
  customFormModalBodyCss = undefined;
  submitted: boolean;
  editTabs: EditTabUiComponent[] = [];
  noData = false;
  scrollPanelid: string;
  isLoading = false;
  private _conflictsResult: DataConflict = {
    refreshAuto: false,
    mustRefresh: false,
    hasConflicts: false,
    dataChanged: {},
    dataConflict: {}
  };
  public get conflictsResult(): DataConflict {
    return this._conflictsResult;
  }
  public set conflictsResult(conflictsResult: DataConflict) {
    this._conflictsResult = conflictsResult;
    if (conflictsResult.refreshAuto) {
      setTimeout(() => {
        this.refreshData();
      }, 2000);
    }
  }
  get nbConflicts() {
    const nb = Object.keys(this.conflictsResult.dataConflict).length;
    if (nb <= 0) {
      this.conflictsResult.hasConflicts = false;
    }
    return nb;
  }
  wantToMergeConflicts = false;
  oldDataStoreKey: DataStoreKey;
  displayJoinFilesPanel = false;
  isZoomEditView = false;
  isStepperVisible = false;
  additionalInfoComponent: AdditionalInfoUiComponent;
  nbUploadedFiles = 0;
  windowType = IupicsMenuType.WINDOW;
  /*keep last event to update the state of additionalInfo if he isnt created yet*/
  lastEvent: IupicsEvent;
  @ViewChild('vcrSpecific', { read: ViewContainerRef, static: true })
  vcrSpecific: ViewContainerRef;
  @ViewChild('specificModal', { read: ModalUiComponent, static: true })
  specificModal: ModalUiComponent;
  @ViewChild('exportDataModal')
  exportDataModal: ModalUiComponent;
  @ViewChild('vcrAudit', { read: ViewContainerRef })
  vcrAudit: ViewContainerRef;
  @Input()
  linkedComponents;
  @Input()
  isAdditionalGenerated = false;
  /*permet de savoir si l'edit attend un scroll call par le resizede la grid */
  isWaitingScroll = false;
  isAdditionalOpened: boolean;
  zoomTarget: IupicsZoomTarget;
  isZoomTarget = false;
  filterZoomTarget = [];
  zoomTargetData: any[];
  GridViewVisibility = 'hidden';
  displaySearch = false;
  searchLinkedComponent;

  specificDisplaySearch = false;
  specificSearchLinkedComponent;

  @ViewChild(MenuBarDetailUiComponent, { static: true })
  menuBarDetailComponent: MenuBarDetailUiComponent;
  @ViewChild('closeButton')
  closeButtonElement: ElementRef;
  @ViewChild('openButton')
  openButton: ElementRef;
  @ViewChild('editViewElement', { static: true })
  editViewElement: ElementRef;
  @ViewChild('gridUi', { static: true })
  gridUiElement: ElementRef;
  @ViewChild('additionalInfoVcr', { read: ViewContainerRef })
  additionalInfoVcr: ViewContainerRef;

  @ViewChild(GridUiComponent, { static: true })
  editTabcomponent: GridUiComponent;

  @ViewChild(ScrollPanel, { static: true })
  scrollPanel: ScrollPanel;

  @ViewChild(MenuSmartbuttonsUiComponent, { static: true })
  smartButtons: MenuSmartbuttonsUiComponent;

  @ViewChild('gridViewUi') gridViewUi: any;

  changingStepper: Subject<any> = new Subject();
  changingMenuBar: BehaviorSubject<any> = new BehaviorSubject(DataStoreStatus.NEWRECORD);

  private _currentDataStoreKey: DataStoreKey;
  get currentDataStoreKey(): DataStoreKey {
    return this._currentDataStoreKey;
  }
  set currentDataStoreKey(currentDataStoreKey: DataStoreKey) {
    this._currentDataStoreKey = currentDataStoreKey;
    this.container.notifyUrlChange();
    /*mettre à jour le datastorekey des editTabs */
    if (this.editTabs && this.editTabs.length > 0) {
      this.editTabs.forEach((editTab) => (editTab.dataStoreKey = currentDataStoreKey));
    }
  }
  gridTabIdSelected: number;
  stepItems: MenuItem[] = [];

  displayEmailEditor = false;
  displayExportDataModal = false;
  exportDataOptions: any[] = [];
  showAuditsPanel = false;

  displayProcessUI = false;
  displayProcessUI_sourceComponentData: any;
  displayFormUI = false;
  processId: number;
  formId: number;

  isStepperEmpty: boolean;

  isSplitView = false;
  specificWindowTitle: string;
  table_id: number;
  isProcessLaunch = false;
  priceHistoryFormId: number;
  modalClass = 'p-col-10 p-md-10 p-lg-10';

  searchPanelValidation: string;

  //#region enum for template
  ModalContentType = ModalContentType;
  DatastoreStatus = DataStoreStatus;
  //#endregion

  constructor(
    private store: DataStoreService,
    private socketService: SocketService,
    private printReportManager: PrintReportManager,
    private renderer: Renderer2,
    private resolver: ComponentFactoryResolver,
    private connectorService: SecurityManagerService,
    private translateService: TranslateService,
    private uiCreatorService: UICreatorService,
    private keybindStateManager: KeybindStateManagerService,
    private docserverService: DocServerService,
    private messageManager: MessageManagerService,
    private riManager: RecentItemsManagerService,
    private config: AppConfig,
    private callInProgressService: CallInProgressService
  ) {
    super();
  }
  ngOnInit() {
    this.cssClass += ' ui-g';
    this.scrollPanelid = 'scrollPanelEditView' + this.tabId;
    this.keybindStateManager.splitViewEmitter.subscribe((value: boolean) => (this.isSplitView = value));
    moment.locale(this.connectorService.getIupicsDefaultLanguage().iso_code.replace(/_/g, '-'));
  }

  openAdditionalInfo() {
    const item: DynamicComponent = {
      container: this.container,
      DOMParentComponent: this,
      linkedComponents: this.linkedComponents,
      component: 'AdditionalInfoUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: true,
      tabId: this.tabId
    };
    this.componentEmitter.emit({
      type: IupicsTypeEvent.showAdditionalInfo,
      item: item
    });
  }

  getCurrentDataStoreKey() {
    return this.currentDataStoreKey;
  }

  minimize() {
    this.isAdditionalOpened = false;
    this.additionalInfoWidth = '2em';
    this.container.setBreadCrumbWidth(
      parseInt(this.additionalInfoWidth.slice(0, this.additionalInfoWidth.length - 2), 10) * 16,
      this.additionalInfoWidthExpanded * 16
    );
    (<BladeUiComponent>this.container).updateScrollingPosition(this.editViewElement.nativeElement);
    this.smartButtons.refreshSmartButtons(this.tabId, this.currentDataStoreKey.recordId);
  }

  resize() {
    this.isAdditionalOpened = true;
    if (Global.isMobile()) {
      this.additionalInfoWidth = '100%';
    } else {
      this.additionalInfoWidth = this.additionalInfoWidthExpanded + 'em';
      this.container.setBreadCrumbWidth(
        parseInt(this.additionalInfoWidth.slice(0, this.additionalInfoWidth.length - 2), 10) * 16,
        2 * 16
      );
    }
    if (this.DOMChildrenComponent.length < 4) {
      setTimeout(() => {
        (<BladeUiComponent>this.container).updateScrollingPosition(this.editViewElement.nativeElement);
      }, 500);
    } else {
      (<BladeUiComponent>this.container).updateScrollingPosition(this.editViewElement.nativeElement);
    }
    if (!this.isAdditionalGenerated) {
      this.openAdditionalInfo();
      this.isAdditionalGenerated = true;
    } else {
      this.additionalInfoComponent.editTabs.forEach((editTab) => {
        if (editTab.DOMChildrenComponent[0] && editTab.DOMChildrenComponent[0].data) {
          const gridTabInfinityScroll = (<GridViewUiComponent>editTab.DOMChildrenComponent[0]).GridTabInfinityScrollUiComponent;
          if (gridTabInfinityScroll) {
            setTimeout(() => {
              gridTabInfinityScroll.agGrid.api.refreshHeader();
              gridTabInfinityScroll.columnResize();
            }, 0);
          }
        }
      });
    }
    this.smartButtons.refreshSmartButtons(this.tabId, this.currentDataStoreKey.recordId);
  }

  ngAfterViewInit() {
    if (this.zoomInfo) {
      this.generateZoom();
    }
    if (this.zoomTarget) {
      this.filterZoomTarget.push(this.zoomTarget.record_id);
      this.isZoomTarget = true;
      const request: DataStoreRequest = {
        windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
        parent_constraint: this.zoomTarget.record_id.replace(/,/g, '='),
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.tabId,
          startRow: 0,
          endRow: 1
        }
      };
      this.subscriptions.push(
        this.store.getWindowSingleData(request, this.isZoomTarget).subscribe((dataStore) => {
          this.currentDataStoreKey = this.store.generateDataStoreKey(
            (<BladeUiComponent>this.container).infoComponent.windowId,
            this.tabId,
            dataStore.data['Data_UUID'],
            this.zoomTarget.record_id.replace(/,/g, '=')
          );
          this.smartButtons.refreshSmartButtons(this.tabId, this.currentDataStoreKey.recordId);
          this.updateNbFileAttached();
          this.buildChildren();
        })
      );
    }
    if (!this.zoomTarget && !this.zoomInfo) {
      this.buildChildren();
    }
    document.body.style.cursor = 'auto';
  }
  buildChildren() {
    this.children.forEach((child) => {
      WindowFactoryUtils.addContainerComponent(this, child, this.resolver, child.container ? child.isCssOnComponent : false);
    });
  }
  addTabToEditView(editTabUi: EditTabUiComponent) {
    this.editTabs.push(editTabUi);
    if (this.editTabs.length === 1) {
      // cacher la légende du premier edittab
      this.editTabs[0].fieldset.isLegendVisible = false;
      this.editTabs[0].isTopEditTab = true;
      this.isStepperVisible = this.editTabs[0].data.isDocumentTab;
      if (
        (<EditViewUiComponent>this.DOMParentComponent.DOMChildrenComponent.find((child) => child instanceof EditViewUiComponent))
          .isStepperVisible === true &&
        <EditViewUiComponent>(
          this.DOMParentComponent.DOMChildrenComponent.find((child) => child instanceof EditViewUiComponent)
        ) !== this
      ) {
        this.isStepperVisible = true;
        this.isStepperEmpty = true;
      }
    }
  }

  checkData(): boolean {
    return this.store.isWindowNewData(this.currentDataStoreKey) ? false : this.store.isWindowDataSYNC(this.currentDataStoreKey);
  }

  checkBeforeClose(event?: Event, onlyEditView = true, callback?: Function) {
    let isOK = false;
    try {
      isOK = this.checkData();
    } catch (e) {
      console.log(e);
      isOK = true;
    }
    if (!this.IsReadOnly && !isOK) {
      const ctx = this.connectorService.getIupicsUserContext();
      if (has(ctx, 'AutoCommit') && ctx.AutoCommit === 'Y' && !this.store.isWindowNewData(this.currentDataStoreKey)) {
        this.beforeSave(event).then(() => {
          if (this.conflictsResult && this.conflictsResult.hasConflicts === false && this.conflictsResult.mustRefresh === false) {
            if (onlyEditView) {
              this.removeComponent();
            } else {
              if (callback) {
                callback();
              }
            }
          }
        });
      } else {
        Global.infoDialog.message = {
          summary: this.translateService.instant('infodialog.dialogs.checkBefore.close.title'),
          detail: this.translateService.instant('infodialog.dialogs.checkBefore.close.message')
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
        Global.infoDialog.showInfoDialog();
        const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
          this.beforeSave(event).then(() => {
            if (
              this.conflictsResult &&
              this.conflictsResult.hasConflicts === false &&
              this.conflictsResult.mustRefresh === false
            ) {
              if (onlyEditView) {
                this.removeComponent();
              } else {
                if (callback) {
                  callback();
                }
              }
            }
          });
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
        const cancel = Global.infoDialog.cancel.subscribe((e) => {
          if (this.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
            this.subscriptions.push(
              this.store.syncWithRemoteWindowData(this.currentDataStoreKey).subscribe((res) => {
                this.notifierLinkedComponent.next({
                  type: IupicsTypeEvent.REFRESH_GRID
                });
                if (onlyEditView) {
                  this.removeComponent();
                } else {
                  if (callback) {
                    callback();
                  }
                }
              })
            );
          } else {
            this.store.deleteDataFromStoreOnly(this.currentDataStoreKey);
            if (onlyEditView) {
              this.removeComponent();
            } else {
              if (callback) {
                callback();
              }
            }
          }
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
      }
    } else {
      if (onlyEditView) {
        this.removeComponent();
      } else {
        if (callback) {
          callback();
        }
      }
    }
    event?.stopPropagation();
  }

  removeComponent() {
    const itemToRemove: DynamicComponent = {
      container: this.container,
      DOMParentComponent: this.DOMParentComponent,
      component: this,
      tabId: this.tabId
    };
    this.componentEmitter.emit({
      type: IupicsTypeEvent.removeChildEvent,
      item: itemToRemove
    });
    this.notifierLinkedComponent.next({
      type: IupicsTypeEvent.removeChildEvent
    });
  }

  onChildUpdate(event): void {
    if (event.type === IupicsTypeEvent.collapseEvent) {
      this.DOMChildrenComponent.forEach((child) => child.onChildUpdate(event));
      this.collapseElement(event);
    }
    if (event.type === IupicsTypeEvent.expandEvent) {
      this.DOMChildrenComponent.forEach((child) => child.onChildUpdate(event));
      this.expandElement(event);
    }
    if (event.type === IupicsTypeEvent.showAdditionalInfo) {
      const additionalInfo = this.DOMChildrenComponent.find((child) => child instanceof AdditionalInfoUiComponent);
      event.item.dataStoreKey = this.currentDataStoreKey;
      additionalInfo.onChildUpdate(event);
    }
  }

  onSiblingUpdate(event: IupicsEvent) {
    if (event.type === IupicsTypeEvent.collapseEvent) {
      if (!this.isAdditionalGenerated) {
        // keep last change so additionnalInfo can have the same state as edit
        this.lastEvent = cloneDeep(event);
      }
      for (let i = 1; i < this.editTabs.length; i++) {
        if (<AbstractDynamicComponent>this.editTabs[i].DOMChildrenComponent[0]) {
          (<AbstractDynamicComponent>this.editTabs[i].DOMChildrenComponent[0]).onSiblingUpdate(event);
        }
      }
    } else if (event.type === IupicsTypeEvent.expandEvent) {
      if (!this.isAdditionalGenerated) {
        this.lastEvent = cloneDeep(event);
      }
      for (let i = 1; i < this.editTabs.length; i++) {
        if (<AbstractDynamicComponent>this.editTabs[i].DOMChildrenComponent[0]) {
          (<AbstractDynamicComponent>this.editTabs[i].DOMChildrenComponent[0]).onSiblingUpdate(event);
        }
      }
    } else if (event.type === IupicsTypeEvent.selectZoomChange) {
      this.refreshZoom(event.item.zoomInfo);
    } else if (event.type === IupicsTypeEvent.selectDataChange) {
      this.retrieveData(event.item.dataStoreKey);
    } else if (event.type === IupicsTypeEvent.checkBeforeChange) {
      if (this.currentDataStoreKey) {
        this.checkBeforeChange(event);
      } else {
        this.notifierLinkedComponent.next({
          type: IupicsTypeEvent.triggerAfterChange,
          item: event.item
        });
      }
    }
    this.onChildUpdate(event);
  }

  checkBeforeChange(event: IupicsEvent) {
    const isOK = this.checkData();
    if (!isOK) {
      const ctx = this.connectorService.getIupicsUserContext();
      if (ctx['AutoCommit'] === 'Y') {
        this.beforeSave(document.createEvent('Event')).then(() => {
          if (this.conflictsResult && this.conflictsResult.hasConflicts === false && this.conflictsResult.mustRefresh === false) {
            this.notifierLinkedComponent.next({
              type: IupicsTypeEvent.triggerAfterChange,
              item: event.item
            });
          }
        });
      } else {
        Global.infoDialog.message = {
          summary: this.translateService.instant('infodialog.dialogs.checkBefore.close.title'),
          detail: this.translateService.instant('infodialog.dialogs.checkBefore.close.message')
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
        Global.infoDialog.showInfoDialog();
        const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
          this.beforeSave(document.createEvent('Event')).then(() => {
            if (
              this.conflictsResult &&
              this.conflictsResult.hasConflicts === false &&
              this.conflictsResult.mustRefresh === false
            ) {
              this.notifierLinkedComponent.next({
                type: IupicsTypeEvent.triggerAfterChange,
                item: event.item
              });
            }
          });
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
        const cancel = Global.infoDialog.cancel.subscribe((e) => {
          if (this.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
            this.subscriptions.push(this.store.syncWithRemoteWindowData(this.currentDataStoreKey).subscribe());
          } else {
            this.store.deleteDataFromStoreOnly(this.currentDataStoreKey);
          }
          this.notifierLinkedComponent.next({
            type: IupicsTypeEvent.triggerAfterChange,
            item: event.item
          });
          confirm.unsubscribe();
          cancel.unsubscribe();
        });
      }
    } else {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.triggerAfterChange,
        item: event.item
      });
    }
  }

  collapseElement(event: IupicsEvent) {
    this.editTabs.forEach((tab) => {
      tab.collaspTab();
      if (event.item && tab.tabId !== event.item.tabId) {
        tab.isCollapsed = true;
      }
    });
    /*dont close if additional is just opened for the first time */
    if (this.closeButtonElement && (!event.item || !(event.item.DOMParentComponent instanceof AdditionalInfoUiComponent))) {
      this.closeButtonElement.nativeElement.click();
    }
    this.smartButtons.resizeSmartButton(IupicsTypeEvent.collapseEvent);
  }
  expandElement(event: IupicsEvent) {
    this.editTabs.forEach((tab) => {
      tab.expandTab();
    });
    this.smartButtons.resizeSmartButton(IupicsTypeEvent.expandEvent);
  }
  updateNbFileAttached() {
    const samples = this.createDocQueryInfo();
    const sub = this.docserverService.getNbDocuments(samples, MongoSearchQueryCombination.OR).subscribe((nb) => {
      this.nbUploadedFiles = nb;
      sub.unsubscribe();
    });
  }
  createDocQueryInfo() {
    const recordId = this.currentDataStoreKey.recordId.split(',');
    const filterFromKey = this.docserverService.createfilterFromKey(this.currentDataStoreKey);

    let samples: any[] = [
      {
        ...filterFromKey,
        'META|TABLE_ID': this.table_id,
        'META|RECORD_ID': recordId.length > 1 ? (isNaN(parseInt(recordId[1], 10)) ? recordId[1] : parseInt(recordId[1], 10)) : -1
      }
    ];
    // On check les colonnes indiquées dans la config comme nécessitant la récup d'attachment
    if (this.data) {
      const docFilters = this.data.DocFilters;
      if (docFilters) {
        samples = samples.concat(this.docserverService.createDocFiltersInfo(docFilters, this.currentDataStoreKey));
      }
    }
    return samples;
  }
  // clic on grid: OLD and WS
  retrieveData(dataStoreKey: DataStoreKey) {
    if (this.currentDataStoreKey !== undefined && Global.mergeLevel !== 0) {
      this.socketService.closeDataChannel(this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId);
    }
    this.currentDataStoreKey = cloneDeep(dataStoreKey);
    const request: DataStoreRequest = {
      windowId: this.currentDataStoreKey.windowId,
      record_id: this.currentDataStoreKey.recordId,
      parent_constraint: this.currentDataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.currentDataStoreKey.tabId,
        startRow: 0,
        endRow: 1
      }
    };

    new Promise((resolve) => {
      this.subscriptions.push(
        this.store.getWindowSingleData(request).subscribe((dataStored) => {
          this.updateEditTabsData(dataStored);
          this.smartButtons.refreshSmartButtons(this.tabId, dataStored.key.recordId);

          resolve(undefined);
        })
      );
    })
      .then(() => {
        if (!this.store.isWindowNewData(this.currentDataStoreKey)) {
          this.store.setStateVisibleOnWindowData(this.currentDataStoreKey);
          if (this.editTabs && this.editTabs.length > 0 && this.editTabs[0].data && !this.editTabs[0].data.isView) {
            this.subscriptions.push(
              this.store
                .syncWithRemoteWindowData(this.currentDataStoreKey, true, this, this.connectorService.getIupicsUserContext())
                .subscribe()
            );
          }
        }
      })
      .then(() => {
        if (!this.store.isWindowNewData(this.currentDataStoreKey) && Global.mergeLevel !== 0) {
          (async () => {
            for await (const response of this.socketService.openDataChannel<any>(
              this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId
            )) {
              if (!(response.from === this.socketService.getSocketId())) {
                this.subscriptions.push(
                  this.store
                    .syncWithRemoteWindowData(this.currentDataStoreKey, true, this, this.connectorService.getIupicsUserContext())
                    .subscribe()
                );
              }
            }
          })();
        }
      });
  }

  updateEditTabsData(dataStored: DataStore, tabIdToExlude?) {
    this.updateNbFileAttached();
    const tabsToUpdate = this.editTabs.filter(
      (tab, index) => index === 0 || !tabIdToExlude || (tabIdToExlude !== undefined && tabIdToExlude !== tab.tabId)
    );
    tabsToUpdate.forEach((tab, i) => {
      if (i > 0) {
        if (tab.DOMChildrenComponent[0]) {
          if (!tab.data.isSingleRow) {
            EditViewUtils.updateEditViewData(tab, dataStored, this.container, this.tabId);
          } else {
            // Si on est en SingleRow (vue Edit) il faut récupérer les données
            if (tab.updateDisplayLogic(dataStored)) {
              if (dataStored.key.tabId !== tab.tabId) {
                let mapfilter = LogicEvaluator.parseLogic(
                  dataStored.data,
                  tab.gridTabFilter[0],
                  this.connectorService.getIupicsUserContext()
                );
                if (mapfilter.replace(/=/g, ',') === dataStored.key.recordId.toString()) {
                  mapfilter = undefined;
                }
                const request: DataStoreRequest = {
                  windowId: dataStored.key.windowId,
                  record_id: dataStored.key.recordId.toString(),
                  parent_constraint: mapfilter,
                  compiereRequest: {
                    windowType: CompiereDataGridType.WINDOW,
                    entityId: tab.tabId,
                    startRow: 0,
                    endRow: 1
                  }
                };
                this.subscriptions.push(
                  this.store.getWindowSingleData(request).subscribe((data) => {
                    tab.updateData(data);
                  })
                );
              }
            }
          }
          // retrieve Record_ID for the component select/order
          if (tab.DOMChildrenComponent[0].data) {
            tab.DOMChildrenComponent[0].data.RecordID = dataStored.key.recordId;
          }
        } else if (tab.children && tab.children.length > 0) {
          EditViewUtils.checkDisplayTabCollapse(
            tab,
            tab.getCurrentContext(dataStored),
            tab.data ? tab.data.DisplayLogic : tab.children[0].data.DisplayLogic
          );
          tab.updateData(dataStored);
        }
      } else {
        tab.updateData(dataStored);

        // if (dataStored.status !== DataStoreStatus.NEWRECORD) {
        this.changingStepper.next(dataStored);
        // } else {
        // this.changingStepper.next();
        // }
      }
    });
    EditViewUtils.updateOtherEditView(this.container, this.tabId, this.editTabs);
  }
  /**
   * change la visibilité de l'onglet et de ses champs en fonction du nouveau datastore
   * @param dataStored
   */
  updateEditTabsVisibility(dataStored: DataStore) {
    let i = 0;
    this.editTabs.forEach((tab) => {
      if (i > 0) {
        if (tab.DOMChildrenComponent[0]) {
          tab.updateDisplayLogic(dataStored);
        }
      }
      i++;
    });
  }

  onRemoveComponent(event: IupicsEvent) {
    if (event.type === IupicsTypeEvent.removeChildEvent) {
      event.type = IupicsTypeEvent.removeBreadcrumbItem;
      this.DOMParentComponent.onChildUpdate(event);
      // notify parent about the closure of this edit view
      this.linkedComponents.forEach((linkedComponent) => {
        if (linkedComponent instanceof AbstractDynamicComponent) {
          linkedComponent.deleteLinkedEditView();
        }
      });
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.expandEvent,
        item: undefined
      });
    }
  }

  transmitDataChange(dataStoreKey: DataStoreKey, tabId: number): boolean {
    let find = false;
    let myIndex = -1;
    // on cherche l'index de son parent
    myIndex = this.container.DOMChildrenComponent.findIndex((component) => {
      if ((<EditViewUiComponent>component.DOMComponent.instance).currentDataStoreKey) {
        const recordIdSplit = (<EditViewUiComponent>component.DOMComponent.instance).currentDataStoreKey.recordId;
        if (recordIdSplit === dataStoreKey.parentId) {
          return component;
        }
      }
    });
    let i = 1;
    while (!find && this.container.DOMChildrenComponent.length > i) {
      if (
        this.container.DOMChildrenComponent[i].DOMComponent.instance instanceof EditViewUiComponent &&
        this.container.DOMChildrenComponent[i].tabId === tabId
      ) {
        (<EditViewUiComponent>this.container.DOMChildrenComponent[i].DOMComponent.instance).retrieveData(dataStoreKey);
        find = true;
      } else if (this.container.DOMChildrenComponent[i].tabId === tabId) {
        myIndex = i;
      }
      i++;
    }
    if (!find && myIndex + 1 < this.container.DOMChildrenComponent.length) {
      (<EditViewUiComponent>this.container.DOMChildrenComponent[myIndex + 1].DOMComponent.instance).removeComponent();
    }
    return find;
  }

  // on save: WS
  /**
   * On prépare la vérification des données avant la sauvegarde
   * @param event
   */
  beforeSave(event: Event): Promise<any> {
    event?.stopPropagation();
    return new Promise((resolve, reject) => {
      if (
        this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.beforesave') &&
        this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.saveData')
      ) {
        if (this.editTabs.length > 0) {
          const fieldsMandatory = this.editTabs[0].isConstraintMandatoryRespected();
          if (fieldsMandatory.length > 0) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.warning'),
                this.translateService.instant('editView.fillMandatoryField') + ' : [ ' + fieldsMandatory + ' ]',
                'error'
              )
            );
          } else {
            if (this.currentDataStoreKey.recordId.split(',').length > 1) {
              this.callInProgressService.setSubscription(
                this.uuid,
                'editview.beforesave',
                this.store
                  .syncWithRemoteWindowData(
                    this.currentDataStoreKey,
                    true,
                    this,
                    this.connectorService.getIupicsUserContext(),
                    this.checkDataBeforeSave.bind(this),
                    resolve
                  )
                  .subscribe()
              );
            } else {
              this.checkDataBeforeSave(resolve);
            }
          }
        } else {
          if (this.currentDataStoreKey.recordId.split(',').length > 1) {
            this.callInProgressService.setSubscription(
              this.uuid,
              'editview.beforesave',
              this.store
                .syncWithRemoteWindowData(
                  this.currentDataStoreKey,
                  true,
                  this,
                  this.connectorService.getIupicsUserContext(),
                  this.checkDataBeforeSave.bind(this),
                  resolve
                )
                .subscribe()
            );
          } else {
            this.checkDataBeforeSave(resolve);
          }
        }
      }
    });
  }

  /**
   * On vérifie les données avant de les sauver
   */
  checkDataBeforeSave(resolve?: Function): void {
    if (!this.conflictsResult || (!this.conflictsResult.hasConflicts && !this.conflictsResult.mustRefresh)) {
      this.saveData(resolve);
    }
  }

  /**
   * On sauve les données en DB et on mets à jour le OLD
   */
  saveData(resolve?: Function): void {
    let status: DataStoreStatus;
    const request: DataStoreRequest = {
      windowId: this.currentDataStoreKey.windowId,
      record_id: this.currentDataStoreKey.recordId,
      parent_constraint: this.currentDataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.currentDataStoreKey.tabId,
        startRow: 0,
        endRow: 1
      }
    };
    if (this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.saveData')) {
      this.callInProgressService.setSubscription(
        this.uuid,
        'editview.saveData',
        this.store
          .getWindowSingleData(request)
          .pipe(
            switchMap((datastore: DataStore) => {
              status = datastore.status;
              this.isLoading = true;
              if (datastore.calloutStates.size > 0) {
                return datastore.calloutStackEmptied.pipe(
                  switchMap((response) => {
                    return this.store.saveWindowData([this.currentDataStoreKey], this.getCurrentContext());
                  })
                );
              } else {
                return this.store.saveWindowData([this.currentDataStoreKey], this.getCurrentContext());
              }
            })
          )
          .subscribe(
            (response: Map<DataStoreKey, DataStore>) => {
              this.isLoading = false;
              if (response) {
                const res = Array.from(response.values())[0];
                const prevKey = { ...this.currentDataStoreKey };
                if (res.key) {
                  this.currentDataStoreKey = res.key;
                }
                if (this.editTabs[0].data.tabLevel === 0) {
                  this.DOMParentComponent.notifyUrlChange(res.data.Data_UUID);
                }
                if (res && res.data) {
                  const gridElement: GridViewUiComponent = this.linkedComponents[0];
                  if (gridElement && this.currentDataStoreKey.recordId && prevKey.recordId.split(',').length === 1) {
                    gridElement.selectedRecordId = this.currentDataStoreKey.recordId;
                  }
                  if (this.isZoomEditView) {
                    this.notifierLinkedComponent.next({
                      refreshZoom: true,
                      id: res.data['Data_UUID'].split(',')[1]
                    });
                  } else {
                    if (status === DataStoreStatus.NEWRECORD) {
                      if (prevKey) {
                        this.store.deleteDataFromStoreOnly(prevKey);
                      }
                      this.notifierLinkedComponent.next({
                        type: IupicsTypeEvent.REFRESH_GRID
                      });
                      if (this.data?.tabLevel === 0) {
                        const recentItem_request: DataStoreRequest = {
                          windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
                          record_id: res.key.recordId,
                          parent_constraint: this.currentDataStoreKey.parentId,
                          compiereRequest: {
                            windowType: CompiereDataGridType.WINDOW,
                            entityId: this.tabId,
                            startRow: 0,
                            endRow: 1
                          }
                        };
                        this.subscriptions.push(
                          this.riManager
                            .addRecentItem(
                              recentItem_request,
                              this.editTabs.find((editTab) => editTab.tabId === this.tabId).data.label
                            )
                            .subscribe()
                        );
                      }
                    } else {
                      this.notifierLinkedComponent.next({
                        type: IupicsTypeEvent.UPDATE_ROWSAVED,
                        item: { dataStoreKey: prevKey }
                      });
                    }
                  }
                  this.updateEditTabsData(res);
                  if (resolve) {
                    resolve();
                  }
                  if (Global.mergeLevel !== 0) {
                    this.socketService.broadcast(this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId, {
                      from: this.socketService.getSocketId(),
                      windowId: this.currentDataStoreKey.windowId,
                      recordId: this.currentDataStoreKey.recordId
                    });
                  }
                }
              }
              this.callInProgressService.completeSubscription(this.uuid, 'editview.saveData');
            },
            (err) => {
              this.callInProgressService.completeSubscription(this.uuid, 'editview.saveData');
            }
          )
      );
    }
  }

  deleteData(event: MouseEvent): void {
    if (!this.checkAndExecuteOverride('delete')) {
      Global.infoDialog.message = {
        summary: this.translateService.instant('infodialog.dialogs.delete.title'),
        detail: this.translateService.instant('infodialog.dialogs.delete.message')
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();
      const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
        const dataUUIDs = this.store.getDataUUIDFromTabID(this.currentDataStoreKey.tabId);
        const recordIds = [];
        const recordId = {};
        dataUUIDs.forEach((dataUUID) => {
          const data = this.editTabs[0].dataStored.data[dataUUID];
          if (data instanceof Object) {
            recordId[dataUUID] = data.id;
          } else {
            recordId[dataUUID] = data;
          }
        });
        recordIds.push(recordId);
        this.isLoading = true;
        this.subscriptions.push(
          this.store
            .deleteWindowData(this.currentDataStoreKey, recordIds)
            .pipe(
              switchMap((res) =>
                res.success > 0 && this.data?.tabLevel === 0
                  ? this.riManager.deleteRecentItems(this.currentDataStoreKey, recordIds).pipe(map(() => res))
                  : of(res)
              )
            )
            .subscribe(
              (res) => {
                if (res) {
                  if (this.isZoomEditView) {
                    this.notifierLinkedComponent.next({
                      refreshZoom: true,
                      id: null
                    });
                  }
                  this.notifierLinkedComponent.next({
                    type: IupicsTypeEvent.REFRESH_GRID
                  });
                  this.removeComponent();
                  this.messageManager.newMessage(
                    new IupicsMessage(
                      this.translateService.instant('editView.deleteMessageTitle'),
                      res.success > 0
                        ? this.translateService.instant('editView.deleteMessage')
                        : res.errors.length > 0
                        ? res.errors[0].message
                        : this.translateService.instant('editView.deleteErrorMessage'),
                      res.success > 0 ? 'success' : 'error'
                    )
                  );
                } else {
                  throw new Error(this.translateService.instant('editView.deleteErrorMessage'));
                }
              },
              (error) => {
                this.isLoading = false;
                if (error && error.error) {
                  throw new Error(error.error.message);
                } else {
                  throw new Error(error.message);
                }
              }
            )
        );
        confirm.unsubscribe();
        cancel.unsubscribe();
      });
      const cancel = Global.infoDialog.cancel.subscribe((e) => {
        confirm.unsubscribe();
        cancel.unsubscribe();
      });
    }
    event.stopPropagation();
  }

  /**
   * On refresh OLD dans WS
   * @param event
   */
  refreshData(showMessage: boolean = true, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.checkAndExecuteOverride('refresh')) {
      if (this.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
        this.updateAll(showMessage);
      } else {
        this.openNew();
      }
    }
  }
  refreshGrids() {
    this.editTabs.forEach((editTab) => editTab.refreshGrid());
  }
  updateAll(showMessage: boolean, tabToExlude = null, callBack?: Function) {
    if (this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.updateAll')) {
      this.callInProgressService.setSubscription(
        this.uuid,
        'editview.updateAll',
        this.store.syncSingleDataWithRemote(this.currentDataStoreKey).subscribe(
          (dataStore) => {
            this.notifierLinkedComponent.next({
              type: IupicsTypeEvent.UPDATE_ROWSAVED,
              item: { dataStoreKey: this.currentDataStoreKey }
            });
            this.updateEditTabsData(dataStore, tabToExlude);
            this.conflictsResult.mustRefresh = false;
            this.conflictsResult.hasConflicts = false;
            this.conflictsResult.refreshAuto = false;
            this.smartButtons.refreshSmartButtons(this.tabId, this.currentDataStoreKey.recordId);
            if (showMessage) {
              this.messageManager.newMessage(
                new IupicsMessage(
                  this.translateService.instant('editView.refreshMessageTitle'),
                  this.translateService.instant('editView.refreshMessage'),
                  'success'
                )
              );
            }
            this.callInProgressService.completeSubscription(this.uuid, 'editview.updateAll');
            if (callBack) {
              callBack();
            }
          },
          ({ error, message }) => {
            this.messageManager.newMessage(
              new IupicsMessage(this.translateService.instant('editView.refreshMessageTitle'), message, 'error', error)
            );
            this.callInProgressService.completeSubscription(this.uuid, 'editview.updateAll');
            if (callBack) {
              callBack();
            }
          }
        )
      );
    }
  }

  onMerge(mergedData: { dataMerged: {}; mergedInfo: {} }): void {
    this.wantToMergeConflicts = false;
    this.conflictsResult.hasConflicts = false;
    const request: DataStoreRequest = {
      windowId: this.currentDataStoreKey.windowId,
      record_id: this.currentDataStoreKey.recordId,
      parent_constraint: this.currentDataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.currentDataStoreKey.tabId,
        startRow: 0,
        endRow: 1
      }
    };
    this.subscriptions.push(
      this.store.getWindowSingleData(request).subscribe((data) => {
        new Promise((resolve) => {
          this.store.syncDataChanges(data, mergedData.dataMerged, true);
          this.store.copyWindowDataToOldStore(data);
          Object.keys(mergedData.mergedInfo).forEach((columnName) => {
            if (mergedData.mergedInfo[columnName].who === 'local') {
              this.store.copyRemoteWindowDataToOldStore(data.key, columnName);
            }
          });
          resolve(undefined);
        }).then(() => {
          this.beforeSave(document.createEvent('MouseEvent'));
        });
      })
    );
  }

  /**
   * Lance l'impression du report et gestion du retour
   * @param event
   */
  printReport(event: MouseEvent) {
    // this.subscriptions.push(this.printReportManager.printAndDownloadReport(this.currentDataStoreKey));
    if (this.callInProgressService.isSubscriptionDone(this.uuid, 'editview.printReport')) {
      this.callInProgressService.setSubscription(
        this.uuid,
        'editview.printReport',
        this.printReportManager.printAndDownloadReport(
          this.currentDataStoreKey,
          this.connectorService.getIupicsUserAccount().session_id,
          this.store.getStore(this.editTabs[0].dataStoreKey, DataStoreName.CURRENT).data,
          () => {
            this.updateNbFileAttached();
          }
        )
      );
    }
    event.stopPropagation();
  }

  /**
   * On déplace la vue au point d'ancrage
   * @param editTab
   */
  goToAnchor(event: MouseEvent, editTab: EditTabUiComponent) {
    event.stopPropagation();
    const shouldWaitResizeBeforeScroll =
      !editTab.childrenCreated && editTab.children[0] && editTab.children[0].component === 'GridViewUiComponent';
    if (editTab.collapsed) {
      editTab.collapsed = false;
    }
    if (shouldWaitResizeBeforeScroll) {
      this.isWaitingScroll = true;
    } else {
      const elem = editTab.elementRef.nativeElement;
      const topPos = elem.offsetTop;
      this.scrollPanel.scrollTop(topPos);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.isZoomEditView && this.linkedComponents[0] && this.linkedComponents[0].isZoom) {
      this.linkedComponents[0].isZoom = false;
    }
    if (Global.mergeLevel !== 0) {
      if (this.currentDataStoreKey) {
        this.socketService.closeDataChannel(this.currentDataStoreKey.windowId + '-' + this.currentDataStoreKey.recordId);
      }
    }
  }

  changeGridViewVisibility($event) {
    this.GridViewVisibility = 'visible';
    this.gridViewUi.onSiblingUpdate({
      type: IupicsTypeEvent.collapseEvent
    } as IupicsEvent);
    if (this.gridViewUi.GridTabInfinityScrollUiComponent.agGrid.api.getSelectedNodes().length === 0) {
      // si Aucune ligne n'est sélectionné, on sélectionne la première par défault()
      this.gridViewUi.GridTabInfinityScrollUiComponent.agGrid.api.getDisplayedRowAtIndex(0).setSelected(true);
    }
    // cache la checkbox
    this.gridViewUi.GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnVisible('Data_UUID', false);
  }

  onSmartButtonClick($event) {
    this.subscriptions.push(
      this.uiCreatorService.getWindow($event.val.key).subscribe((tabs) => {
        const item: DynamicComponent = {
          container: this.container,
          DOMParentComponent: this.container,
          linkedComponents: [this],
          component: 'EditViewUiComponent',
          cssClass: 'iupics-blade-content',
          isCssOnComponent: false,
          tabId: tabs[0].tabId,
          zoomTarget: { record_id: this.currentDataStoreKey.recordId },
          zoomTargetData: tabs
        };
        this.componentEmitter.emit({
          type: IupicsTypeEvent.showEditView,
          item: item
        });
      })
    );
  }

  gridViewCellClicked($event) {
    const request: DataStoreRequest = {
      windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
      record_id: $event.recordId,
      parent_constraint: this.zoomTarget.record_id.replace(/,/g, '='),
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 1
      }
    };
    this.subscriptions.push(
      this.store.getWindowSingleData(request).subscribe((dataStore) => {
        this.currentDataStoreKey = this.store.generateDataStoreKey(
          (<BladeUiComponent>this.container).infoComponent.windowId,
          this.tabId,
          dataStore.data['Data_UUID'],
          this.zoomTarget.record_id.replace(/,/g, '=')
        );
        this.updateEditTabsData(dataStore);
        this.GridViewVisibility = 'hidden';
      })
    );
  }

  openExportDataModal(event: Event) {
    event.stopPropagation();
    /* this.exportDataService.getExportDataFormats(this.tabId, 3).subscribe(exportDataOptions => {
      this.exportDataOptions = exportDataOptions;
    }); */
    if (!this.checkAndExecuteOverride('export')) {
      this.displayExportDataModal = true;
    }
  }

  viewRecordChangeLog(e: MouseEvent) {
    const formID = this.config.getConstant('ChangeLogWindowUIComponent#FormID');
    this.showAuditsPanel = true;
    setTimeout(() => {
      this.createSpecificWindow(formID, undefined, this.vcrAudit, () => {
        this.showAuditsPanel = false;
      });
    }, 5);
  }

  openNew(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.checkAndExecuteOverride('new')) {
      let i = 1;
      let find = false;
      while (i < this.container.DOMChildrenComponent.length && !find) {
        if (this.container.DOMChildrenComponent[i] === this && ++i < this.container.DOMChildrenComponent.length) {
          (<EditViewUiComponent>this.container.DOMChildrenComponent[i]).removeComponent();
          find = true;
        } else {
          i++;
        }
      }
      const gridElement = <GridViewUiComponent>this.linkedComponents[0];
      if (gridElement && gridElement.checkEditViewCreated && gridElement.checkEditViewCreated()) {
        const parentComp = gridElement.getFirstEditViewParent(gridElement.DOMParentComponent);
        if (parentComp) {
          (<EditViewUiComponent>parentComp).gridTabIdSelected = this.tabId;
        }
      }
      setTimeout(() => {
        if (this.checkData()) {
          // check si c'est l'edit view de premier niveau
          if (this.currentDataStoreKey.parentId === '') {
            this.DOMParentComponent.notifyUrlChange('newRecord');
          }
          const key = this.store.newWindowData(
            this.container.infoComponent.windowId,
            this.tabId,
            null,
            EditViewUtils.getParentDatastoreKey(this)
          ).key;
          this.retrieveData(key);
        } else {
          const ctx = this.connectorService.getIupicsUserContext();
          if (ctx['AutoCommit'] === 'Y') {
            this.beforeSave(document.createEvent('Event')).then(() => {
              if (
                this.conflictsResult &&
                this.conflictsResult.hasConflicts === false &&
                this.conflictsResult.mustRefresh === false
              ) {
                const key = this.store.newWindowData(
                  this.container.infoComponent.windowId,
                  this.tabId,
                  null,
                  EditViewUtils.getParentDatastoreKey(this)
                ).key;
                this.retrieveData(key);
              }
            });
          } else {
            Global.infoDialog.message = {
              summary: this.translateService.instant('infodialog.dialogs.checkBefore.change.title'),
              detail: this.translateService.instant('infodialog.dialogs.checkBefore.change.message')
            };
            Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
            Global.infoDialog.showInfoDialog();
            const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
              this.beforeSave(document.createEvent('Event')).then(() => {
                if (
                  this.conflictsResult &&
                  this.conflictsResult.hasConflicts === false &&
                  this.conflictsResult.mustRefresh === false
                ) {
                  const key = this.store.newWindowData(
                    this.container.infoComponent.windowId,
                    this.tabId,
                    null,
                    EditViewUtils.getParentDatastoreKey(this)
                  ).key;
                  this.retrieveData(key);
                }
              });
              confirm.unsubscribe();
              cancel.unsubscribe();
            });
            const cancel = Global.infoDialog.cancel.subscribe((e) => {
              if (this.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
                this.subscriptions.push(this.store.syncWithRemoteWindowData(this.currentDataStoreKey).subscribe());
              } else {
                this.store.deleteDataFromStoreOnly(this.currentDataStoreKey);
              }
              const key = this.store.newWindowData(
                this.container.infoComponent.windowId,
                this.tabId,
                null,
                EditViewUtils.getParentDatastoreKey(this)
              ).key;
              this.retrieveData(key);
              confirm.unsubscribe();
              cancel.unsubscribe();
            });
          }
        }
      }, 0);
    }
  }

  /*
   * emailEditor: { display: { key: 'displayEmailEditor', value: displayEmailEditor }}
   * joinFilesPanel: { display: { key: 'displayJoinFilesPanel', value: displayJoinFilesPanel }}
   * processModal: { display: { key: 'displayProcessUI', value: displayProcessUI }, id: { key: 'processId', value: processId } }
   * formModal: { display: { key: 'displayFormUI', value: displayFormUI }, id: { key: 'formId', value: formId } }
   */
  updateModalDisplay(display: { key: string; value: boolean; sourceComponent?: any }, id?: { key: string; value: number }) {
    if (display.sourceComponent && display.sourceComponent.columnName) {
      this.modalClass = 'p-col-10 p-md-6 p-lg-4';
    } else {
      this.modalClass = 'p-col-10 p-md-10 p-lg-10';
    }
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    const isButton =
      display.sourceComponent &&
      display.sourceComponent.itemData &&
      display.sourceComponent.itemData.component === 'ButtonUiComponent';
    if (isButton && !this.store.checkDataBeforeNewLine(this.currentDataStoreKey)) {
      this.beforeSave(null).then((result) => {
        this.displayModal(display, id);
      });
    } else {
      this.displayModal(display, id);
    }
    if (!display.value && this.isProcessLaunch) {
      // this.refreshData();
      this.refreshEditViews(true);
      this.isProcessLaunch = false;
    }
  }

  displayModal(display: { key: string; value: boolean; sourceComponent?: any }, id?: { key: string; value: number }) {
    this[display.key] = display.value;
    this[`${display.key}_sourceComponentData`] = display.sourceComponent;
    // Récupération du table_id pour les fichiers joints
    if (this.editTabs.length > 0) {
      this.table_id = this.editTabs[0].data.ADTableID;
    }
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value, display.sourceComponent);
      }
    } else {
      this.customFormModalBodyCss = undefined;
    }
  }
  /**
   * Copie les data de l'enregistrement courant, les vérifie et les enregistre dans le datastore
   * @param {Event}event
   */
  copyData(event: Event) {
    if (!this.checkAndExecuteOverride('copy')) {
      let i = 1;
      let find = false;
      while (i < this.container.DOMChildrenComponent.length && !find) {
        if (this.container.DOMChildrenComponent[i] === this && ++i < this.container.DOMChildrenComponent.length) {
          (<EditViewUiComponent>this.container.DOMChildrenComponent[i]).removeComponent();
          find = true;
        } else {
          i++;
        }
      }

      event.stopPropagation();
      let dataStore = this.store.newWindowData(
        this.container.infoComponent.windowId,
        this.tabId,
        null,
        EditViewUtils.getParentDatastoreKey(this)
      );

      const request: DataStoreRequest = {
        windowId: this.currentDataStoreKey.windowId,
        record_id: this.currentDataStoreKey.recordId,
        parent_constraint: this.currentDataStoreKey.parentId,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.currentDataStoreKey.tabId,
          startRow: 0,
          endRow: 1
        }
      };

      this.subscriptions.push(
        this.store.getWindowSingleData(request).subscribe((dataStoreSource) => {
          /*pour récupérer les champs invisible dans le store */
          dataStore['data'] = Object.assign(dataStore['data'], dataStoreSource.data);
          const isOneKeyCol = dataStore.data.Data_UUID.split(',').length == 2;
          dataStore.data.Data_UUID = dataStore.data.Data_UUID.split(',')[0] + ',' + dataStore.key.recordId;
          dataStore = this.editTabs[0].checkForCopy(dataStore);
          if (isOneKeyCol) {
            dataStore.data[dataStore.data.Data_UUID.split(',')[0]] = null;
          }
          dataStore.isCopied = true;
          this.updateEditTabsData(dataStore);
          this.currentDataStoreKey = dataStore.key;
          this.smartButtons.refreshSmartButtons(this.tabId, this.currentDataStoreKey.recordId);
          this.DOMParentComponent.notifyUrlChange(dataStore.data.Data_UUID);
        })
      );
    }
  }
  startAnimation(event: any, state: string) {
    // only possible on mobile devices
    if (Global.isMobile() && state !== '' && this.DOMParentComponent === this.container) {
      const breadCrumb = (<BladeUiComponent>this.DOMParentComponent).breadcrumbComponent;
      const model = breadCrumb.model;
      const index = model.findIndex((item) => +item.id === this.tabId);
      let move = 0;
      if (state === 'slideOutLeft') {
        move = 1;
      } else {
        move = -1;
      }
      if (model[index + move]) {
        let timeout = 0;
        if (Global.getDeviceWidth() > 640) {
          timeout = 100;
        }
        setTimeout(() => {
          breadCrumb.itemClick(null, model[index + move]);
        }, timeout);
      }
    }
  }

  createSpecificWindow(formId: number, sourceComponent?: any, vcr = this.vcrSpecific, closeModal?: Function) {
    this.subscriptions.push(
      this.uiCreatorService.getSpecificWindow(formId).subscribe((specificWindow) => {
        let component;
        if (specificWindow.angularClass && specificWindow.angularClass.length > 0 && specificWindow.angularClass !== 'default') {
          component = Global.iupics_specific_window.get(specificWindow.angularClass);
        }
        if (!component) {
          component = Global.iupics_specific_window.get('default');
        }
        const factory = this.resolver.resolveComponentFactory(component);
        vcr.clear();
        const componentRef = vcr.createComponent(factory);
        this.specificWindowTitle = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).name = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).title = specificWindow.title;
        (<SpecificWindowUiComponent>componentRef.instance).description = specificWindow.description;
        (<SpecificWindowUiComponent>componentRef.instance).help = specificWindow.help;
        (<SpecificWindowUiComponent>componentRef.instance).componentRef = componentRef;
        (<SpecificWindowUiComponent>componentRef.instance).isModal = true;
        (<SpecificWindowUiComponent>componentRef.instance).formId = formId;
        (<SpecificWindowUiComponent>componentRef.instance).vcrwindow = vcr;
        (<SpecificWindowUiComponent>componentRef.instance).parentComponent = this;
        (<SpecificWindowUiComponent>componentRef.instance).index = vcr.length - 1;
        (<SpecificWindowUiComponent>componentRef.instance).sourceModal = this.specificModal;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponentData =
          sourceComponent && sourceComponent.itemData ? sourceComponent.itemData : sourceComponent;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponent =
          sourceComponent; /**dupliqué si pas d'itemdata mais trop de refactoring sur scout */
        (<SpecificWindowUiComponent>componentRef.instance).closeModalEmitter.subscribe(() => {
          if (closeModal) {
            closeModal();
          } else {
            this.updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this });
          }
        });
      })
    );
  }
  /**
   * permet de passer à la ligne suivante ou précedente
   * @param step avancement ou recul à faire
   */
  changeGridElement(step: number) {
    const gridElement = <GridViewUiComponent>this.linkedComponents[0];
    if (gridElement) {
      const api = gridElement.GridTabInfinityScrollUiComponent.agGrid.api;
      const currentNodeSelected = api.getSelectedNodes()[0];
      if (currentNodeSelected) {
        this.simulateClickOnCellUsingArrow(gridElement, step);
      }
    }
  }
  /**
   * simule un clic sur la ligne précedant ou suivant la ligne courante
   * @param gridElement grid sur laquelle on simule un clic
   * @param step avancement ou recul à faire
   */
  simulateClickOnCellUsingArrow(gridElement: GridViewUiComponent, step: number) {
    const api = gridElement.GridTabInfinityScrollUiComponent.agGrid.api;
    const currentNodeSelected = api.getSelectedNodes()[0];
    if (currentNodeSelected) {
      const nbRowPerPage = api.paginationGetPageSize();
      const pageId = Math.trunc((+currentNodeSelected.id + step) / nbRowPerPage);
      // set des données de la ligne courante
      gridElement.GridTabInfinityScrollUiComponent.currentSelectIndex = +currentNodeSelected.id + step;
      gridElement.GridTabInfinityScrollUiComponent.currentSelectPageIndex = pageId;
      const nodeToSelect = api.getRowNode(+currentNodeSelected.id + step + '');
      if (nodeToSelect) {
        api.deselectAll();
        api.paginationGoToPage(pageId);
        const cell = { colDef: { field: 'name' }, data: nodeToSelect.data, node: nodeToSelect, origin: 'edit' };
        gridElement.GridTabInfinityScrollUiComponent.onCellClicked(cell);
      } else {
        // simulation du clic après que le loading soit fini
        setTimeout(() => {
          const loadedNode = api.getRowNode(+currentNodeSelected.id + step + '');
          if (loadedNode) {
            api.deselectAll();
            api.paginationGoToPage(pageId);
            const cell = { colDef: { field: 'name' }, data: loadedNode.data, node: loadedNode, origin: 'edit' };
            gridElement.GridTabInfinityScrollUiComponent.onCellClicked(cell);
          }
        }, 1000);
      }
    }
  }

  showSearchPanel(linkedComponent: any) {
    let url = null;
    if (linkedComponent.data.urlSearch) {
      url = linkedComponent.data.urlSearch;
    } else if (linkedComponent.data && linkedComponent.data.details && linkedComponent.data.details.entityID) {
      url = '/Field/Search?id=' + linkedComponent.data.details.entityID + '&type=0';
    }
    if (url) {
      this.subscriptions.push(
        this.uiCreatorService.getFieldsData(url).subscribe((data) => {
          this.searchPanelValidation = linkedComponent.data.details.tableName + ".IsActive='Y'";
          linkedComponent.data.searchColumns = data;
          this.displaySearch = true;
          this.searchLinkedComponent = linkedComponent;
        })
      );
    } else {
      this.messageManager.newMessage(new IupicsMessage(this.translateService.instant('generic.error'), 'No data'));
    }
  }

  showSpecificSearchPanel(linkedComponent: any) {
    let url = null;
    if (linkedComponent.data.urlSearch) {
      url = linkedComponent.data.urlSearch;
    } else if (linkedComponent.data && linkedComponent.data.details && linkedComponent.data.details.entityID) {
      url = '/Field/Search?id=' + linkedComponent.data.details.entityID + '&type=0';
    }
    if (url) {
      this.subscriptions.push(
        this.uiCreatorService.getFieldsData(linkedComponent.data.urlSearch).subscribe((data) => {
          this.searchPanelValidation = linkedComponent.data.details.tableName + ".IsActive='Y'";
          linkedComponent.data.searchColumns = data;
          this.specificDisplaySearch = true;
          this.specificSearchLinkedComponent = linkedComponent;
        })
      );
    }
  }

  generateZoom() {
    this.isZoomEditView = true;
    let newData;
    if (this.zoomInfo.record_id === -1) {
      newData = this.store.newWindowData(this.container.infoComponent.windowId, this.tabId);
      this.currentDataStoreKey = newData.key;
    } else {
      this.zoomInfo.dataUUID =
        this.zoomInfo.dataUUID && this.zoomInfo.dataUUID.split('.').length > 1
          ? this.zoomInfo.dataUUID.split('.')[1]
          : this.zoomInfo.dataUUID;
      this.currentDataStoreKey = this.store.generateDataStoreKey(
        this.zoomInfo.windowId,
        this.tabId,
        this.zoomInfo.dataUUID,
        this.zoomInfo.parentId ? this.zoomInfo.parentId : null
      );
    }
    const request: DataStoreRequest = {
      windowId: this.zoomInfo.windowId,
      record_id: this.zoomInfo.dataUUID,
      parent_constraint: this.zoomInfo.parentId ? this.zoomInfo.parentId : '',
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 1
      }
    };
    this.subscriptions.push(
      this.store.getWindowSingleData(request, false, true).subscribe((data) => {
        this.smartButtons.refreshSmartButtons(this.tabId, this.currentDataStoreKey.recordId);
        this.buildChildren();
        if (newData) {
          this.updateEditTabsData(newData);
        } else {
          this.updateNbFileAttached();
        }
      })
    );
  }

  refreshZoom(zoomInfo) {
    this.zoomInfo = zoomInfo;
    this.currentDataStoreKey = this.store.generateDataStoreKey(
      (<BladeUiComponent>this.container).infoComponent.windowId,
      this.tabId,
      this.zoomInfo.dataUUID
    );
    this.retrieveData(this.currentDataStoreKey);
  }

  getCurrentContext(withCtx = true): any {
    let currentParent;
    if (
      this.linkedComponents &&
      this.linkedComponents[0] &&
      this.linkedComponents[0].editViewParent &&
      this.linkedComponents[0].editViewParent.editTabs[0].dataStored.data
    ) {
      currentParent = this.linkedComponents[0].editViewParent;
    }
    let store;
    if (this.editTabs && this.editTabs[0] && this.editTabs[0].dataStored) {
      store = this.editTabs[0].dataStored;
    }
    return EditViewUtils.getCurrentContext(currentParent, store, withCtx ? this.connectorService.getIupicsUserContext() : {});
  }
  showPriceHistory(gridTabInfinityScrollUiComponent: GridTabInfinityScrollUiComponent) {
    if (!this.priceHistoryFormId) {
      const request_form: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'AD_Form',
          filterModel: {
            NAME: {
              filterType: CompiereDataGridFilterType.SET,
              values: ['Price history'],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };
      const $sub = this.store.getDataGrid(request_form, true).subscribe((formWS) => {
        this.priceHistoryFormId = formWS.data[0]['AD_FORM_ID'];
        this.showPriceHistoryForm(gridTabInfinityScrollUiComponent);
        $sub.unsubscribe();
      });
    } else {
      this.showPriceHistoryForm(gridTabInfinityScrollUiComponent);
    }
  }
  showPriceHistoryForm(gridTabInfinityScrollUiComponent: GridTabInfinityScrollUiComponent) {
    if (
      gridTabInfinityScrollUiComponent &&
      gridTabInfinityScrollUiComponent.agGrid &&
      gridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length > 0
    ) {
      this.updateModalDisplay(
        { key: 'displayFormUI', value: true, sourceComponent: gridTabInfinityScrollUiComponent },
        { key: 'formId', value: this.priceHistoryFormId }
      );
    } else {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('infodialog.dialogs.noLineSelected.title'),
          this.translateService.instant('infodialog.dialogs.noLineSelected.message'),
          'warning'
        )
      );
    }
  }

  /**
   * permet d'overrider l'action si il existe un process ou une form. La méthode retourne true si c'est le cas.
   * @param source indique quelle action est à overrider
   */
  checkAndExecuteOverride(source: string) {
    let formIdToOpen;
    let processIdToOpen;
    const ad_window_id = this.currentDataStoreKey ? '' + this.currentDataStoreKey.windowId : '';
    switch (source) {
      case 'new':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.OPEN_NEW + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.OPEN_NEW + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.OPEN_NEW + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.OPEN_NEW + ad_window_id);
        }
        break;
      case 'copy':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.COPY_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.COPY_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.COPY_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.COPY_DATA + ad_window_id);
        }
        break;
      case 'export':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.EXPORT_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.EXPORT_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.EXPORT_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.EXPORT_DATA + ad_window_id);
        }
        break;
      case 'refresh':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.REFRESH_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.REFRESH_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.REFRESH_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.REFRESH_DATA + ad_window_id);
        }
        break;
      case 'delete':
        formIdToOpen = this.config.getConstant(EditViewGetFormIDByTabID.DELETE_DATA + this.tabId);
        processIdToOpen = this.config.getConstant(EditViewGetProcessIDByTabID.DELETE_DATA + this.tabId);
        if (formIdToOpen === undefined && processIdToOpen === undefined) {
          formIdToOpen = this.config.getConstant(EditViewGetFormIDByWindowID.DELETE_DATA + ad_window_id);
          processIdToOpen = this.config.getConstant(EditViewGetProcessIDByWindowID.DELETE_DATA + ad_window_id);
        }
        break;
      default:
        break;
    }
    if (formIdToOpen !== undefined) {
      this.updateModalDisplay(
        { key: 'displayFormUI', value: true, sourceComponent: { columnName: source } },
        { key: 'formId', value: formIdToOpen }
      );
    } else if (processIdToOpen !== undefined) {
      this.updateModalDisplay(
        { key: 'displayProcessUI', value: true, sourceComponent: source },
        { key: 'processId', value: processIdToOpen }
      );
    }
    return formIdToOpen !== undefined || processIdToOpen !== undefined;
  }
  refreshEditViews(showMessage = true) {
    const editViews: EditViewUiComponent[] = this.container.DOMChildrenComponent.slice().filter(
      (child) => child instanceof EditViewUiComponent
    ) as EditViewUiComponent[];
    this.subscriptions.push(
      this.store.syncWithRemoteWindowData(editViews[0].currentDataStoreKey).subscribe((dataStore) => {
        for (let i = 0; i < editViews.length; i++) {
          if (i !== 0) {
            editViews[i].menuBarDetailComponent.isReadOnly = dataStore && dataStore.data && dataStore.data.Processed === 'Y';
            editViews[i].menuBarDetailComponent.updateButtonLists();
            editViews[i].refreshData(showMessage);
          } else {
            editViews[i].refreshData(showMessage);
          }
        }
      })
    );
  }
}
