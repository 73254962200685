<div [ngClass]="[isMobile ? 'mobile-device' : '', 'blade-container']" [ngStyle]="{ overflow: getOverFlow() }">
  <div
    class="breadCrumb"
    #breadRef
    [style.width]="this.breadCrumbWidth"
    iuKeybinding
    [iuKeybinding-active]="true"
    iuKeybinding-code="blade.breadcrumb"
    [iuKeybinding-behavior-context]="this"
    [iuKeybinding-behavior-function]="handleKeyBind"
  >
    <iu-prime-breadcrumb-ui #breadcrumb (breadcrumbEventEmitter)="onBreadCrumbEvent($event)"></iu-prime-breadcrumb-ui>
  </div>
  <div class="bladeContent" #scrollableElt (scroll)="updateScroll($event)"><ng-container iuDynamicContainer></ng-container></div>
</div>
