import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { WidgetCompiereJson, WidgetFavoriteCompiereJson } from '@compiere-ws/models/widget-center-json';
import { ChartType, ViewType } from '@iupics-components/models/view-type.enum';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { KeyCode } from '@iupics-config/keycode.enum';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { DashboardManagerService } from '@web-desktop/components/workspace/controllers/dashboard-manager/dashboard-manager.service';
import { DashboardMenuItem, DashboardRemoveMenuItem } from '@web-desktop/models/dashboard-menu-item';
import { IupicsWidget } from '@web-desktop/models/iupics-widget';
import { MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DragDropGroup } from '../drag-drop-group';

// tslint:disable: deprecation

@Component({
  selector: 'iu-widget-group-ui',
  templateUrl: './widget-group-ui.component.html',
  styleUrls: ['./widget-group-ui.component.scss']
})
export class WidgetGroupUiComponent extends DragDropGroup implements OnInit {
  @Output()
  widgetClicked = new EventEmitter<any>();

  isMobile = Global.isMobile();
  isMobileWidth = Global.isMobileWidth;

  widgetMenu$: Observable<MenuItemUI[]>;
  widgetCompiere$: Observable<WidgetCompiereJson[]>;
  objectKeys = Object.keys;
  indexGroupDrag: number;
  indexGroupOver: number;
  showMenuWidget = false;

  // widget
  currentItemList$: Observable<any[]>;
  currentGroupID = 0;
  groups: MenuFavoritesCategoryUI[] = [];
  itemsList = {};
  widgetsCompiere: WidgetCompiereJson[];

  searchWidgetValue: string;
  arrowFocusedWidget: MenuItemUI;

  //#region draggable v2
  widgetCssClass = 'p-col-12 p-md-6 ui-g';
  protected _currentItemList: { menuItem: CompiereMenu; widget: IupicsWidget }[] = [];
  //#endregion
  @ViewChild('searchInput', { read: ElementRef }) searchInputEl: ElementRef;
  constructor(
    private uiCreatorService: UICreatorService,
    private dashboardManager: DashboardManagerService,
    private translator: TranslateService,
    protected renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit() {
    this.createEmptyDiv('p-col-12 p-md-6 p-lg-4');
    const sub = this.uiCreatorService.getWidgetList().subscribe((widgetsCompiere) => {
      this.widgetCompiere$ = of(widgetsCompiere);
      this.widgetMenu$ = this.dashboardManager.getDashboardIupicsWidgetMenu(this.widgetCompiere$);
      this.getWidgets();
      sub.unsubscribe();
    });
  }

  openTab(favorite: MenuItemUI) {
    this.widgetClicked.emit(favorite);
  }

  openWidgetMenu(event: Event) {
    event.preventDefault();
    if (this.searchInputEl && this.searchInputEl.nativeElement) {
      setTimeout(() => {
        this.searchInputEl.nativeElement.focus();
      }, 100);
    }
    this.searchWidgetValue = '';
    this.arrowFocusedWidget = undefined;
  }

  // afficher la liste d'une autre catégorie
  changeMenuItemList(id: number) {
    if (id !== this.currentGroupID) {
      this.showMenuWidget = false;
      this.currentGroupID = id;
      this._currentItemList = [];
      this.itemsList[this.currentGroupID].forEach((widget: any) => {
        this.createWidgetComponent(widget);
      });
      this.currentItemList$ = of(this._currentItemList);
    }
  }

  // ajoute une catégorie
  addGroupItem(widget?: MenuItemUI) {
    const name = this.getDefaultName();
    const arrayToSave = [];
    const dashboardGroupItem: DashboardMenuItem = {
      cssColor: null,
      name: name.trim(),
      isSummary: true,
      isEditable: true,
      tags: [],
      parentId: 0,
      seqNo: this.groups.length > 0 ? this.groups[this.groups.length - 1].seqNo + 10 : 10
    };
    arrayToSave.push(dashboardGroupItem);
    const sub = this.uiCreatorService.setIupicsWidgetFavorites(arrayToSave).subscribe((res) => {
      if (Object.keys(this.itemsList).length === 0) {
        this.currentGroupID = res[0].menu.menuId;
      }
      this.itemsList[res[0].menu.menuId] = [];
      this.groups.push({ id: res[0].menu.menuId, name: name, seqNo: res[0].menu.seqNo });
      if (widget) {
        this.addWidget(widget);
      }
      sub.unsubscribe();
    });
  }

  private getDefaultName(): string {
    const base = this.translator.instant('dashboard.newTab.widgets');
    if (this.groups.length > 0) {
      return (base + ' ' + (this.groups.length + 1)).trim();
    }
    return base.trim();
  }

  // renome une catégorie
  renameGroupItem(elem: { name: string; id: number; seqNo: number }) {
    const arrayToSave = [];

    const dashboardGroupItem: DashboardMenuItem = {
      isSummary: true,
      menuId: elem.id,
      isEditable: true,
      tags: [],
      name: elem.name,
      seqNo: elem.seqNo
    };
    arrayToSave.push(dashboardGroupItem);
    const sub = this.uiCreatorService.setIupicsWidgetFavorites(arrayToSave).subscribe(() => {
      this.groups[this.groups.findIndex((group) => group.id === elem.id)].name = elem.name;
      sub.unsubscribe();
    });
  }

  // supprime une catégorie
  removeGroupItem(elem: { id: number }) {
    const dashboardGroupItem: DashboardRemoveMenuItem = {
      isSummary: true,
      menuId: elem.id,
      parentId: 0
    };
    this.uiCreatorService.deleteIupicsWidgetFavorites(dashboardGroupItem).subscribe(() => {
      this.groups.splice(
        this.groups.findIndex((group) => group.id === elem.id),
        1
      );
      delete this.itemsList[elem.id];
      if (this.groups.length > 0) {
        this.changeMenuItemList(this.groups[0].id);
      } else {
        this._currentItemList = [];
        this.currentItemList$ = of(this._currentItemList);
      }
    });
  }

  switchMenuGroup({ from, to }: { from: number; to: number }) {
    const menuToMove = this.groups.splice(from, 1)[0];
    this.groups.splice(to, 0, menuToMove);
    let seqNo = 10;
    const arrayToSave = [];
    this.groups.forEach((groupFavoris) => {
      const dashboardGroupItem: DashboardMenuItem = {
        cssColor: null,
        menuId: groupFavoris.id,
        name: groupFavoris.name,
        isSummary: true,
        isEditable: true,
        tags: [],
        parentId: 0,
        seqNo: seqNo
      };
      seqNo += 10;
      arrayToSave.push(dashboardGroupItem);
    });
    const sub = this.uiCreatorService.setIupicsWidgetFavorites(arrayToSave).subscribe(() => {
      sub.unsubscribe();
    });
  }

  addWidget(widgetItem: MenuItemUI) {
    if (this.groups.length === 0) {
      this.addGroupItem(widgetItem);
    } else {
      const exist =
        this.itemsList[this.currentGroupID].findIndex(
          (widget: WidgetFavoriteCompiereJson) => widget.menu.menuId === widgetItem.menu_id
        ) !== -1;
      if (!exist) {
        const dashboardMenuItem: DashboardMenuItem = {
          angularClass: widgetItem.angularClass,
          description: widgetItem.description,
          menuId: widgetItem.menu_id,
          parentId: this.currentGroupID,
          isSummary: false,
          isEditable: true,
          tags: [],
          cssColor: '',
          name: widgetItem.name,
          seqNo:
            this._currentItemList.length > 0 ? this._currentItemList[this._currentItemList.length - 1].menuItem.seqNo + 10 : 10
        };

        const arrayToSave = [];
        arrayToSave.push(dashboardMenuItem);
        widgetItem.seqNo = dashboardMenuItem.seqNo;
        widgetItem.parentId = this.currentGroupID;
        widgetItem.color = '';
        const sub = this.uiCreatorService.setIupicsWidgetFavorites(arrayToSave).subscribe((widget) => {
          this.createWidgetComponent(widget[0]);
          this.itemsList[this.currentGroupID].push(widget[0]);
          this.currentItemList$ = of(this._currentItemList);
          sub.unsubscribe();
        });
      }
    }
  }

  private createWidgetComponent(widget: WidgetFavoriteCompiereJson) {
    let type: ChartType;
    if (widget === undefined || !widget.widgetData) {
      return;
    }
    switch (widget.widgetData.dashboardFormatVO.dashboardType) {
      case 'ARC':
        type = ChartType.POLARAREA;
        break;
      case 'BRC':
        type = ChartType.HORIZONTAL_BAR;
        break;
      case 'CLC':
        type = ChartType.BAR;
        break;
      case 'LIC':
        type = ChartType.LINE;
        break;
      case 'PIC':
        type = ChartType.PIE;
        break;
      case 'GEN':
        type = ChartType.OTHER;
        this._currentItemList.push({
          menuItem: widget.menu,
          widget: new IupicsWidget(
            widget.menu.name,
            widget.menu.description,
            ViewType.LIST,
            type,
            null,
            widget.menu ? (widget.menu.cssColor ? widget.menu.cssColor : '#27586B') : '#27586B',
            widget.widgetData.dashboardFormatVO.width,
            widget.menu.angularClass
          )
        });
        break;
    }

    if (type) {
      if (type === ChartType.BAR || type === ChartType.HORIZONTAL_BAR || type === ChartType.LINE) {
        const labelChart = [];
        if (widget.widgetData.dataTable.columns.length > 1) {
          labelChart.push(widget.widgetData.dataTable.columns[1].label);
        }
        const datasets = [];
        for (let i = 0; i < widget.widgetData.labels.length; i++) {
          datasets.push({
            data: widget.widgetData.dataSet[i],
            label: widget.widgetData.labels[i],
            backgroundColor: [],
            borderColor: null,
            pointBorderColor: null,
            pointBackgroundColor: null,
            hoverBackgroundColor: []
          });
        }
        this._currentItemList.push({
          widget: new IupicsWidget(
            widget.menu.name,
            widget.menu.description,
            ViewType.CHART,
            type,
            {
              labels: labelChart,
              datasets: datasets
            },
            widget.menu ? (widget.menu.cssColor ? widget.menu.cssColor : '#27586B') : '#27586B',
            widget.widgetData.dashboardFormatVO.width === 2 ? 2 : widget.widgetData.dashboardFormatVO.width === 3 ? 3 : 1
          ),
          menuItem: widget.menu
        });
      } else if (type !== ChartType.OTHER) {
        this._currentItemList.push({
          widget: new IupicsWidget(
            widget.menu.name,
            widget.menu.description,
            ViewType.CHART,
            type,
            {
              labels: widget.widgetData.labels,
              datasets: [
                {
                  data: widget.widgetData.dataSet,
                  backgroundColor: [],
                  borderColor: null,
                  pointBorderColor: null,
                  pointBackgroundColor: null,
                  hoverBackgroundColor: []
                }
              ]
            },
            widget.menu ? (widget.menu.cssColor ? widget.menu.cssColor : '#27586B') : '#27586B',
            widget.widgetData.dashboardFormatVO.width === 2 ? 2 : widget.widgetData.dashboardFormatVO.width === 3 ? 3 : 1
          ),
          menuItem: widget.menu
        });
      }
    }
  }

  onDelete(index: number, widgetItem: any) {
    let confirm: any, cancel: any;
    Global.infoDialog.message = {
      summary: this.translator.instant('infodialog.dialogs.delete.title'),
      detail: this.translator.instant('infodialog.dialogs.delete.message')
    };
    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      this.deleteWidget(index, widgetItem);
      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });
    cancel = Global.infoDialog.cancel.subscribe((e: any) => {
      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });
  }

  private deleteWidget(index: number, widgetItem: CompiereMenu) {
    const dashboardMenuItem: DashboardMenuItem = {
      menuId: widgetItem.menuId,
      parentId: widgetItem.parentId,
      isEditable: true,
      tags: [],
      isSummary: widgetItem.isSummary,
      cssColor: widgetItem.cssColor,
      name: widgetItem.name
    };
    this.uiCreatorService.deleteIupicsWidgetFavorites(dashboardMenuItem).subscribe((res) => {
      this._currentItemList.splice(index, 1);
      this.itemsList[this.currentGroupID].splice(
        this.itemsList[this.currentGroupID].findIndex(
          (widget: WidgetFavoriteCompiereJson) => widget.menu.menuId === widgetItem.menuId
        ),
        1
      );
      this.currentItemList$ = of(this._currentItemList);
    });
  }

  changeWidgetColor(elem: any, widgetItem: any) {
    const dashboardMenuItem: DashboardMenuItem = {
      angularClass: widgetItem.angularClass,
      description: widgetItem.description,
      menuId: widgetItem.menu_id,
      parentId: widgetItem.parentId,
      isSummary: false,
      isEditable: true,
      tags: [],
      cssColor: elem.color,
      name: widgetItem.name,
      seqNo: widgetItem.seqNo
    };
    const arrayToSave = [];
    arrayToSave.push(dashboardMenuItem);
    const sub = this.uiCreatorService.setIupicsWidgetFavorites(arrayToSave).subscribe(() => {
      sub.unsubscribe();
    });
  }

  refreshWidgets() {
    this.getWidgets(true);
  }

  handleInputKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KeyCode.LEFT_ARROW:
        event.preventDefault();
        break;
      case KeyCode.UP_ARROW:
        event.preventDefault();
        break;
      case KeyCode.RIGHT_ARROW:
        event.preventDefault();
        break;
      case KeyCode.DOWN_ARROW:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  handleInputKeyup(event: KeyboardEvent, groups: MenuItemUI[]) {
    switch (event.keyCode) {
      case KeyCode.LEFT_ARROW:
        this.getNextWidget(-1, groups);
        this.scrollToWidget(this.arrowFocusedWidget);
        break;
      case KeyCode.UP_ARROW:
        this.getNextWidget(-1, groups);
        this.scrollToWidget(this.arrowFocusedWidget);
        break;
      case KeyCode.RIGHT_ARROW:
        this.getNextWidget(1, groups);
        this.scrollToWidget(this.arrowFocusedWidget);
        break;
      case KeyCode.DOWN_ARROW:
        this.getNextWidget(1, groups);
        this.scrollToWidget(this.arrowFocusedWidget);
        break;
      case KeyCode.ENTER:
        this.selectItem();
        break;
      default:
        this.arrowFocusedWidget = undefined;
        break;
    }
  }

  private getNextWidget(offset: number, groups: MenuItemUI[]) {
    this.arrowFocusedWidget = this.dashboardManager.getNextWidget(
      offset,
      groups,
      this.searchWidgetValue,
      this.arrowFocusedWidget
    );
  }

  private scrollToWidget(selectedWidget: MenuItemUI) {
    this.dashboardManager.scrollToItem('iu_widget_menu_' + selectedWidget.menu_id, 'iu_widget_container');
  }

  private selectItem() {
    if (this.arrowFocusedWidget !== undefined) {
      this.addWidget(this.arrowFocusedWidget);
    }
  }
  private getWidgets(forced = false) {
    this.widgetCompiere$
      .pipe(
        switchMap((widgets) => {
          this.widgetsCompiere = widgets;
          return this.uiCreatorService.getIupicsWidgetFavorites(forced);
        })
      )
      .subscribe((widgetFavorites) => {
        this.groups = this.uiCreatorService.getIupicsWidgetFavoritesCategories();
        for (let i = 0; i < this.groups.length; i++) {
          const group = this.groups[i];
          this.itemsList[group.id] = [];
        }
        if (this.groups[0] && this.currentGroupID === 0) {
          this.currentGroupID = this.groups[0].id;
        }
        for (let i = 0; i < widgetFavorites.length; i++) {
          const favorite = widgetFavorites[i];
          if (favorite.menu) {
            this.itemsList[favorite.menu.parentId].push(favorite);
          }
        }
        this._currentItemList = [];
        if (this.itemsList[this.currentGroupID]) {
          for (let i = 0; i < this.itemsList[this.currentGroupID].length; i++) {
            const widget = this.itemsList[this.currentGroupID][i];
            this.createWidgetComponent(widget);
          }
          this.currentItemList$ = of(this._currentItemList);
        }
      });
  }

  protected saveItemPos() {
    if (!this.isListSorted()) {
      let seqNo = 10;
      const arrayToSave = [];
      this._currentItemList.forEach((widgetComp) => {
        widgetComp.menuItem.seqNo = seqNo;
        const dashboardMenuItem: DashboardMenuItem = {
          angularClass: widgetComp.menuItem.angularClass,
          description: widgetComp.menuItem.description,
          menuId: widgetComp.menuItem.menuId,
          parentId: widgetComp.menuItem.parentId,
          isSummary: false,
          isEditable: true,
          tags: [],
          cssColor: widgetComp.menuItem.cssColor,
          name: widgetComp.menuItem.name,
          seqNo: widgetComp.menuItem.seqNo
        };
        seqNo += 10;
        arrayToSave.push(dashboardMenuItem);
      });
      const sub = this.uiCreatorService.setIupicsWidgetFavorites(arrayToSave).subscribe(() => {
        this.currentItemList$ = of(this._currentItemList);
        sub.unsubscribe();
      });
    }
  }

  private isListSorted(): boolean {
    for (let i = 0; i < this._currentItemList.length - 1; i++) {
      if (this._currentItemList[i].menuItem.seqNo > this._currentItemList[i + 1].menuItem.seqNo) {
        return false;
      }
    }
    return true;
  }
}
