<div class="app-version-panel-wrapper">
  <a class="version-logo-link" href="https://www.apizsolutions.com/" target="_blank">
    <img class="version-logo" src="assets/themes/iupics/img/logo-apiz.svg" alt="Apiz" />
  </a>
  <div *ngIf="roleId !== 0; else appVersionPanel" class="version-description">
    <span class="versionName">© APIZ Advanced ERP Solutions</span>
    <span class="versionName" data-cy="app-version-number">— {{ version }}</span>
  </div>
</div>
<ng-template #appVersionPanel>
  <div class="version-description">
    <span class="versionName">© APIZ Advanced ERP Solutions</span>
    <div>
      <span class="versionName">— </span>
      <span class="versionName clickable" (click)="showPanel($event)" data-cy="show-appVersion-btn">{{ version }}</span>
    </div>
  </div>
  <div class="appVersionPanelContainer">
    <iu-modal-ui
      [appendTo]="'body'"
      title="{{ 'profil.appVersionPanel.title' | translate }}"
      [isModalDisplay]="isShown"
      [hasCloseBtn]="true"
      (closeModalEmitter)="isShown = false"
      [angularStyle]="{ 'iu-modal-body': { overflow: 'hidden' } }"
    >
      <div *ngIf="isShown" style="padding-top: 20px">
        <p-table #dt [columns]="cols" [value]="wsList" sortMode="multiple" [scrollable]="true" scrollHeight="60vh">
          <ng-template pTemplate="caption">
            <div style="text-align: center">
              <input
                type="text"
                pInputText
                size="50"
                placeholder="{{ 'profil.appVersionPanel.globalFilter' | translate }}"
                (input)="dt.filterGlobal($event.target.value, 'contains')"
                style="width: 40vw; background: var(--app-version-panel-bg-color-p-table-input)"
                data-cy="appVersionSearchInput"
              />
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                class="appVersionHeader"
                data-cy="appVersionHeader"
                scope="col"
              >
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                ></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" data-cy="appVersionLine">
              <td
                *ngFor="let col of columns"
                class="appVersionLine"
                [ngClass]="{
                  replicaZero:
                    col.field === 'replicas' &&
                    ((rowData[col.field] === 0 && rowData['runningTasks'] !== rowData[col.field]) ||
                      rowData['runningTasks'] < rowData[col.field])
                }"
                [ngSwitch]="col.field"
                [attr.data-cy-headerName]="col.field"
              >
                <span *ngSwitchCase="'replicas'"
                  ><code>{{ rowData['runningTasks'] }}</code
                  >/<code>{{ rowData[col.field] }}</code></span
                >
                <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </iu-modal-ui>
  </div>
</ng-template>
