import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';

export class ProcessPingInfo {
  AD_PInstance_ID: IAutocomplete;
  AD_Process_ID: IAutocomplete;
  averageTime: number;
  Created: string;
  Name: string;
  Status: 'pending' | 'start' | 'running' | 'finish';
  channel_id: string;

  AD_User_ID: IAutocomplete;
  AD_Org_ID: IAutocomplete;
  params?: ProcessPingInfoPara[];

  constructor(input: any) {
    this.AD_PInstance_ID = { id: input.AD_PInstance_ID, displayValue: '' };
    this.AD_Process_ID = { id: input.AD_Process_ID, displayValue: '' };
    this.averageTime = input.averageTime;
    this.AD_User_ID = { id: input.AD_User_ID, displayValue: '' };
    this.AD_Org_ID = { id: input.AD_Org_ID, displayValue: '' };
    this.Created = input.Created;
    this.Name = input.Name;
    this.Status = input.Status;
    this.channel_id = input.channel_id;
  }

  setFromProcessPingInfoPara(params: ProcessPingInfoPara[]) {
    if (!(params.length > 0)) {
      return;
    }
    const first = params[0];

    this.AD_Org_ID.id = first.AD_ORG_ID;
    this.AD_Org_ID.displayValue = first.AD_ORG_NAME;

    this.AD_User_ID.id = first.AD_USER_ID;
    this.AD_User_ID.displayValue = first.AD_USER_NAME;

    this.AD_Process_ID.id = first.AD_PROCESS_ID;
    this.AD_Process_ID.displayValue = first.AD_PROCESS_NAME;

    this.params = params;
  }
}

export interface ProcessPingInfoPara {
  AD_PINSTANCE_ID: number;
  AD_PROCESS_ID: number;
  averageTime: number;
  AD_PROCESS_NAME: string;
  AD_LANGUAGE: string;
  RECORD_ID: number;
  ISPROCESSING: string;
  CREATED: string;
  AD_USER_ID: number;
  AD_USER_NAME: string;
  AD_CLIENT_ID: number;
  AD_CLIENT_NAME: string;
  AD_ORG_ID: number;
  AD_ORG_NAME: string;
  AD_ROLE_ID: number;
  AD_ROLE_NAME: string;
  SEQNO: number;
  PARAMETERNAME: string;
  P_NUMBER?: number;
  P_NUMBER_TO?: number;
  P_STRING?: number;
  P_STRING_TO?: number;
  P_DATE?: number;
  P_DATE_TO?: number;
}
