import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { ValuePreference, ValuePreferencesService } from '@compiere-ws/services/value-preference/value-preference.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { InputSwitchUiComponent } from '../fields/input-switch-ui/input-switch-ui.component';
@Component({
  selector: 'iu-value-preference-panel',
  templateUrl: './value-preference-panel.component.html',
  styleUrls: ['./value-preference-panel.component.scss']
})
export class ValuePreferencePanelComponent implements OnInit {
  @ViewChild('adClientRef', { static: true })
  adClientRef: InputSwitchUiComponent;
  @ViewChild('adOrgRef', { static: true })
  adOrgRef: InputSwitchUiComponent;
  @ViewChild('adUserRef', { static: true })
  adUserRef: InputSwitchUiComponent;
  @ViewChild('adWindowRef', { static: true })
  adWindowRef: InputSwitchUiComponent;
  attribute: string;
  attributeDisplayValue: string;
  value: string;
  displayValue: string;
  adClient = 'N';
  adClientReadOnly: boolean = false;
  adOrg = 'N';
  adOrgReadOnly: boolean = false;
  adUser = 'N';
  adUserReadOnly: boolean = false;
  adWindow = 'N';
  adWindowValue: number;
  isDeletable = false;
  @Input()
  fieldValue: any;
  @Input()
  data: any;
  @Input()
  dataStored: DataStore;
  @Input()
  sourceComponent: any;
  @Input()
  enableSize: number;
  @Output()
  closeEmitter: EventEmitter<any> = new EventEmitter<any>();
  description: string = '';
  constructor(
    private valuePreferenceService: ValuePreferencesService,
    private connectorService: SecurityManagerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const preferenceType = this.connectorService.getIupicsUserAccount().current_role.preferenceType;
    this.adClient = 'Y';
    this.adClientReadOnly = true;
    if (preferenceType != 'C') {
      this.adOrg = 'Y';
      this.adOrgReadOnly = true;
    }
    if (preferenceType != 'C' && preferenceType != 'O') {
      this.adUser = 'Y';
      this.adUserReadOnly = true;
    }
    this.value = this.fieldValue
      ? this.fieldValue.id != null && this.fieldValue.id != undefined
        ? this.fieldValue.id + ''
        : this.fieldValue
      : this.fieldValue;
    this.displayValue = this.fieldValue
      ? this.fieldValue.displayValue
        ? this.fieldValue.displayValue + ''
        : this.fieldValue
      : this.fieldValue;
    if (this.displayValue && this.sourceComponent.isDateField) {
      moment.locale(this.connectorService.getIupicsDefaultLanguage().iso_code);
      this.displayValue = moment(this.displayValue).format(Global.display_dateFormat);
      this.value = moment(this.value).valueOf() + '';
    }
    this.attribute = this.data.columnName;
    this.attributeDisplayValue = this.data.label;
    this.adWindowValue = this.dataStored.key.windowId;
    this.updateDescription();
    this.checkDeleteAvaillable();
  }
  updateDescription() {
    if (this.adClient === 'Y' && this.adOrg === 'Y') {
      this.description = this.translateService.instant('valuePreference.thisOrg');
    } else {
      this.description = this.translateService.instant('valuePreference.allOrg');
    }
    if (this.adUser === 'Y') {
      this.description += this.translateService.instant('valuePreference.thisUser');
    } else {
      this.description += this.translateService.instant('valuePreference.allUser');
    }
    if (this.adWindow === 'Y') {
      this.description += this.translateService.instant('valuePreference.thisWindow');
    } else {
      this.description += this.translateService.instant('valuePreference.allWindow');
    }
    this.description += '.';
  }
  delete() {
    const valuepref = this.createValuePref();
    this.valuePreferenceService.deleteValuePreference(valuepref).subscribe(
      (response) => {
        if (response) {
          const ctx = this.connectorService.getIupicsUserContext();
          const entryKey = `P${valuepref.ad_Window_ID > 0 ? valuepref.ad_Window_ID : ''}|${valuepref.attribute}`;
          delete ctx[entryKey];
          this.connectorService.setIupicsUserContext(ctx);
        }
        this.closeEmitter.emit();
      },
      (error) => {
        console.log(error);
        this.closeEmitter.emit();
      }
    );
  }
  save() {
    const valuepref = this.createValuePref();
    this.valuePreferenceService.saveValuePreference(valuepref).subscribe(
      (response) => {
        if (response) {
          const ctx = this.connectorService.getIupicsUserContext();
          const entryKey = `P${valuepref.ad_Window_ID > 0 ? valuepref.ad_Window_ID : ''}|${valuepref.attribute}`;
          ctx[entryKey] = valuepref.value;
          this.connectorService.setIupicsUserContext(ctx);
        }
        this.closeEmitter.emit();
      },
      (error) => {
        console.log(error);
        this.closeEmitter.emit();
      }
    );
  }
  cancel() {
    this.closeEmitter.emit();
  }
  createValuePref() {
    const ctx = this.connectorService.getIupicsUserContext();
    const ctxAdClient = isNaN(parseInt(ctx['#AD_Client_ID'])) ? 0 : parseInt(ctx['#AD_Client_ID']);
    const ctxAdOrg = isNaN(parseInt(ctx['#AD_Org_ID'])) ? 0 : parseInt(ctx['#AD_Org_ID']);
    const ctxAdUser = isNaN(parseInt(ctx['##AD_User_ID'])) ? 0 : parseInt(ctx['##AD_User_ID']);
    return {
      ad_Client_ID: this.adClientRef.fieldValue === 'Y' ? ctxAdClient : 0,
      ad_Org_ID: this.adOrgRef.fieldValue === 'Y' ? ctxAdOrg : 0,
      ad_User_ID: this.adUserRef.fieldValue === 'Y' ? ctxAdUser : 0,
      ad_Window_ID: this.adWindowRef.fieldValue === 'Y' ? this.adWindowValue : 0,
      attribute: this.attribute,
      value: this.value + ''
    } as ValuePreference;
  }
  checkDeleteAvaillable() {
    const ctx = this.connectorService.getIupicsUserContext();
    const valuePref = this.createValuePref();
    const entryKey = `P${valuePref.ad_Window_ID > 0 ? valuePref.ad_Window_ID : ''}|${valuePref.attribute}`;
    if (ctx[entryKey] !== undefined && ctx[entryKey] !== null) {
      this.isDeletable = true;
    } else {
      this.isDeletable = false;
    }
  }
  updateToggleValue(property: string, value: string) {
    this[property] = value;
    this.updateDescription();
    this.checkDeleteAvaillable();
  }
}
