import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConfig } from '@iupics-config/app.config';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import { KeyCode } from '@iupics-config/keycode.enum';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { RoleUI } from '@login-page/models/user-account.';
import { TranslateService } from '@ngx-translate/core';
import { MenuUiComponent } from '@web-desktop/components/menu-top/components/menu-ui/menu-ui.component';
import { UserProfileComponent } from '@web-desktop/components/menu-top/components/user-profile/user-profile.component';
import { WebDesktopService } from '@web-desktop/controllers/web-desktop.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { v4 as uuid } from 'uuid';
import { TabGroupUiComponent } from '../tab-group-ui/tab-group-ui.component';
import { TabUiComponent } from '../tab-ui/tab-ui.component';
@Component({
  selector: 'wd-menu-top-ui',
  templateUrl: './menu-top-ui.component.html',
  styleUrls: ['./menu-top-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuTopComponent implements OnInit, OnDestroy {
  static id = 'TabBar';
  visible = true;
  isTrusted = false;
  @Input()
  isDashboardVisible = true;
  lastRemovedIndex;
  nbNotifications = 0;
  isMobile = false;
  isMobileWidth = false;
  isNotificationCenterDisplay = false;
  isMenuDisplay = false;
  subscriptions: any[] = [];
  widthAvailable;
  hasEnoughWidth = true;
  scrollInterval;
  isScrolling = false;
  scrollableStyle = {
    height: '40%'
  };

  @Input()
  activeTab: any = null;
  @Output()
  changeDisplay: EventEmitter<any> = new EventEmitter();
  @Output()
  openTargetSearch: EventEmitter<any> = new EventEmitter();
  @Output()
  validateLogoutEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  changeRoleEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  setActiveTabEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  generateWindowEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  openWindowEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  openGroupTabEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  closeWindowEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  swapToDashboardEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  changePreviousRoleEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  searchEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild('overlayPanel')
  overlayPanel: OverlayPanel;
  @ViewChild('userProfile', { static: true })
  userProfile: UserProfileComponent;
  @ViewChild('menu', { static: true })
  menuView: MenuUiComponent;
  @ViewChild('profilBtn', { static: true })
  profilBtn: ElementRef;
  @ViewChild('tabContainer', { read: ViewContainerRef, static: true })
  entry: ViewContainerRef;
  @ViewChild('tabContainerMoreVCR', { read: ViewContainerRef, static: true })
  tabContainerMoreVCR: ViewContainerRef;
  @ViewChild('tabsBar', { read: ElementRef, static: true })
  tabsBarRef: ElementRef;
  isMoreTab = false;
  @ViewChild('wrapper', { read: ElementRef, static: true })
  wrapper: ElementRef;

  components: ComponentRef<TabUiComponent | TabGroupUiComponent>[] = [];
  mapTabsByRole: Map<RoleUI, any> = new Map();
  showSearchContainer = false;

  dashboardLabel: string;

  isProfileOpen = false;

  // @start-custo
  get fullname(): string {
    return this.connectorService.getIupicsUserAccount().fullname;
  }

  get orgName(): string {
    return this.connectorService.getIupicsUserContext()['#AD_Org_Name'];
  }
  // @end-custo

  constructor(
    private webDesktopService: WebDesktopService,
    public connectorService: SecurityManagerService,
    public translateService: TranslateService,
    private resolver: ComponentFactoryResolver,
    private title: Title,
    private config: AppConfig,
    private keybindConfig: KeybindConfigService,
    private router: Router
  ) {}
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    event.stopPropagation();
    this.updateScrollTabs();
  }

  ngOnInit() {
    this.isMobile = Global.isMobile();
    this.isMobileWidth = Global.isMobileWidth();
    this.dashboardLabel = this.config.getConstant('dashboardLabel');
    this.subsription();
    this.widthAvailable = this.tabsBarRef.nativeElement.clientWidth;
    this.updateScrollTabs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  subsription() {
    this.subscriptions.push(
      this.webDesktopService.emitter.subscribe((event) => {
        if (event.type === IupicsTypeEvent.ADD_PIN_WINDOW) {
          this.createTab(event.item);
        }
      })
    );
  }

  updateNbNotification(nbNotif: number): void {
    this.nbNotifications = nbNotif;
    this.nbNotifications !== 0
      ? this.title.setTitle('(' + this.nbNotifications + ') - ' + Global.defaultTitle)
      : this.title.setTitle(Global.defaultTitle);
  }

  onContextMenu(event: MouseEvent): void {
    event.preventDefault();
    if (event.stopPropagation) {
      event.stopPropagation();
    }
  }

  toggleNotificationCenter(event: Event): void {
    this.isNotificationCenterDisplay = !this.isNotificationCenterDisplay;
    if (event) {
      event.stopPropagation();
    }
  }

  toggleMenu(event: Event): void {
    this.isMenuDisplay = !this.isMenuDisplay;
    event.stopPropagation();
  }

  logout(event: MouseEvent): void {
    this.connectorService.disconnect().subscribe((isPreviousUser) => {
      if (isPreviousUser) {
        const baseHref = document.getElementsByTagName('base')[0].href;
        window.history.pushState({}, '', `${baseHref}dashboard`);
        location.reload();
      } else {
        this.router.navigate(['/login']);
      }
    });
    event.stopPropagation();
  }

  toggleProfile(event) {
    this.isProfileOpen = !this.isProfileOpen;
    this.userProfile.display();
    this.overlayPanel.toggle(event);
  }

  changeRole(role: RoleUI) {
    this.overlayPanel.hide();
    this.changeRoleEmitter.emit(role);
    // this.menuView.initData();
    const baseHref = document.getElementsByTagName('base')[0].href;
    window.history.pushState({}, '', `${baseHref}dashboard`);
    this.router.navigate(['/dashboard']);
  }

  swapToDashBoard() {
    this.swapToDashboardEmitter.emit();
  }

  createTab(tab: any = null, index: any = null) {
    if (index && this.entry.length < index) {
      index = null;
    }
    const tabFactory = this.resolver.resolveComponentFactory(TabUiComponent);
    const tabRef = this.entry.createComponent(tabFactory, index);
    if (!tab.domWinId) {
      TabUiComponent.cpt++;
      tabRef.instance.id = 'tab' + TabUiComponent.cpt.toString();
      tabRef.instance.isLoading = true;
      tabRef.instance.domWinId = tab.action_id + uuid();
      tabRef.instance.windowId = tab.action_id;
    } else {
      tabRef.instance.id = tab.id;
      tabRef.instance.domWinId = tab.domWinId;
      tabRef.instance.windowId = tab.windowId;
      tabRef.instance.splitViewRef = tab.splitViewRef;
    }
    tabRef.instance.name = tab.name;
    // les données d'ouverture de la premiere editview active
    tabRef.instance.openedRecordId = tab.openedRecordId;
    // les données d'ouverture des sous editviews
    tabRef.instance.othersRecordId = tab.othersRecordId;
    // les données d'ouverture des sous editviews
    tabRef.instance.dataGridRequest = tab.dataGridRequest;
    // les données à ajouter au ctx de la Form ouverte via url
    tabRef.instance.ctx = tab.ctx;
    // les données d'ouverture des sous editviews
    tabRef.instance.zoomInfo = tab.zoomInfo;
    tabRef.instance.viewType = tab.viewType;
    tabRef.instance.processParamsMap = tab.processParamsMap;
    tabRef.instance.iconClass = tab.iconClass;
    tabRef.instance.appName = tab.appName;
    tabRef.instance.parentId = MenuTopComponent.id;
    tabRef.instance.menu_type = tab.menu_type;
    tabRef.instance.action_id = tab.action_id;
    tabRef.instance.destroyTabEmitter.subscribe((data) => {
      this.destroyTab(data);
    });
    tabRef.instance.moveTabEmitter.subscribe((data) => {
      this.moveTab(data.tab, data.move);
    });

    tabRef.instance.createTabContainerEmitter.subscribe((data) => {
      this.createMultiTab(data.draggedTab, data.droppedInTab);
    });
    tabRef.instance.setActiveTabEmitter.subscribe((data) => {
      this.setActive(data);
    });
    tabRef.instance.closeWindowEmitter.subscribe((data) => {
      if (!this.isDashboardVisible && this.activeTab && this.activeTab.id === data.tabId) {
        if (!data.tabGroup && this.components.length > 0) {
          if (this.components.length > 1) {
            const i = this.components.findIndex((c) => c.instance.id === this.activeTab.id);
            const n = i > 0 ? -1 : 1;
            this.setActive(this.components[i + n].instance);
          }
        }
      }
      this.closeWindowEmitter.emit(data);
    });
    if (index === null) {
      this.components.push(tabRef);
      const isNewTab = tab.domWinId === null || tab.domWinId === undefined;
      this.updateScrollTabs(isNewTab);
    } else {
      this.components.splice(index, 0, tabRef);
    }
    tab.id = tabRef.instance.domWinId;
    this.generateWindowEmitter.emit(tabRef.instance);
    this.isDashboardVisible = false;
  }
  getIndexOf(tab) {
    const tabFound = this.components.find((component) => component.instance.id === tab.id);
    return this.entry.indexOf(tabFound.hostView);
  }

  moveTab(tab, move) {
    const tabFound = this.components.find((component) => component.instance.id === Global.draggedComponent.id);
    // get the position of the moved tab
    const indexOfTab = this.getIndexOf(tab);
    let indexToDrop = indexOfTab + move;
    indexToDrop = indexToDrop > this.entry.length - 1 ? this.entry.length : indexToDrop;
    this.entry.move(tabFound.hostView, indexToDrop);
    const deletedElements = this.components.splice(this.components.indexOf(tabFound), 1);
    this.components.splice(indexToDrop, 0, deletedElements[0]);
  }

  createMultiTab(draggedTab: TabUiComponent = null, droppedInTab: TabUiComponent = null) {
    const tabContainerFactory = this.resolver.resolveComponentFactory(TabGroupUiComponent);
    const droppedInTabFound = this.components.find((component) => component.instance.id === droppedInTab.id);
    const tabContainerRef = this.entry.createComponent(tabContainerFactory, this.entry.indexOf(droppedInTabFound.hostView));
    TabGroupUiComponent.cpt++;
    tabContainerRef.instance.id = 'tabContainer' + TabGroupUiComponent.cpt;
    tabContainerRef.instance.destroyTabEmitter.subscribe((tab) => this.destroyTab(tab));
    tabContainerRef.instance.destroyTabContainerEmitter.subscribe((tabContainer) => this.destroyTabContainer(tabContainer));
    tabContainerRef.instance.createTabEmitter.subscribe((tab) => this.createTab(tab));
    // permet de notifier le changement de la tabsbar
    tabContainerRef.instance.updateScrollTabsEmitter.subscribe(() => this.updateScrollTabs());
    tabContainerRef.instance.setActiveTabEmitter.subscribe((data) => {
      this.setActive(data);
    });
    tabContainerRef.instance.closeWindowEmitter.subscribe((data) => this.closeWindowEmitter.emit(data));
    tabContainerRef.instance.moveTabEmitter.subscribe((data) => {
      this.moveTab(data.tab, data.move);
    });
    tabContainerRef.instance.createTab(droppedInTab);
    tabContainerRef.instance.createTab(draggedTab);
    const index = this.components.findIndex(
      (tab: ComponentRef<TabUiComponent | TabGroupUiComponent>) => tab.instance.id === droppedInTab.id
    );
    this.components.splice(index, 0, tabContainerRef);
    // removes tabs which have been fuzed
    this.destroyTab(draggedTab);
    this.destroyTab(droppedInTab);
    this.setActive(tabContainerRef.instance);
  }

  destroyTab(tab: TabUiComponent) {
    let tabFound = this.components.find((component) => component.instance.id === tab.id);
    if (tabFound) {
      const tabIndex = this.components.indexOf(tabFound);
      if (tabIndex !== -1) {
        // Remove component from both view and array
        this.lastRemovedIndex = this.entry.indexOf(tabFound.hostView);
        this.entry.remove(this.entry.indexOf(tabFound.hostView));
        this.components.splice(tabIndex, 1);
      }
    } else {
      // find tab of multiTab
      const components = this.components.filter(
        (cmp) => (cmp.instance as TabGroupUiComponent).components != null && cmp.instance.id === tab.parentId
      );
      let found = false;
      for (let i = 0; i < components.length && !found; i++) {
        const element = components[i];
        tabFound = (element.instance as TabGroupUiComponent).components.find((comp) => comp.instance.id === tab.id);
        if (tabFound) {
          (tabFound.instance as TabUiComponent).destroyTab();
          found = true;
        }
      }
    }
    this.updateScrollTabs();
  }

  destroyTabContainer(tabContainer: TabGroupUiComponent) {
    const tabContainerFound = this.components.find((component) => component.instance.id === tabContainer.id);
    const tabContainerIndex = this.components.indexOf(tabContainerFound);
    if (tabContainerIndex !== -1) {
      // Remove component from both view and arrays
      this.lastRemovedIndex = this.entry.indexOf(tabContainerFound.hostView);
      this.entry.remove(this.entry.indexOf(tabContainerFound.hostView));
      this.components.splice(tabContainerIndex, 1);
      this.createTab(tabContainer.components[0].instance, this.lastRemovedIndex + 1);
    }
    this.updateScrollTabs();
  }

  drop(ev) {
    ev.preventDefault();
    if (Global.draggedComponent === null || Global.draggedComponent instanceof TabGroupUiComponent) {
      return false;
    }
    // check if tab doesnt exist yet
    if (Global.draggedComponent instanceof TabUiComponent && Global.draggedComponent.parentId !== MenuTopComponent.id) {
      const draggedTabFound = this.components.find((component) => component.instance.id === Global.draggedComponent.id);
      if (!draggedTabFound) {
        const tabContainerFound = this.components.find((component) => component.instance.id === Global.draggedComponent.parentId);
        const tabContainerIndex = this.components.indexOf(tabContainerFound);
        if (tabContainerFound && tabContainerIndex !== -1) {
          this.createTab(Global.draggedComponent, this.entry.indexOf(tabContainerFound.hostView) + 1);
          if ((tabContainerFound.instance as any).destroy) {
            (tabContainerFound.instance as any).destroy(Global.draggedComponent);
          }
        }
      }
      ev.stopPropagation();
      Global.draggedComponent = null;
    }
  }
  allowDrop(ev) {
    ev.preventDefault();
    if (Global.draggedComponent === null || Global.draggedComponent instanceof TabGroupUiComponent) {
      return false;
    }
  }
  setActive(component: any) {
    if (component === null) {
      component = this.components[0];
    }
    this.isDashboardVisible = false;
    this.setActiveTabEmitter.emit(component);
  }
  unactiveAll() {
    this.components.forEach((element) => (element.instance.isActive = false));
  }

  restoreTabsBar() {
    this.entry.clear();
    this.components = [];
  }

  /**
   * alt + one the nine number in the main keyboard to switch to the tab of this position
   * @param {KeyboardEvent}event
   */
  chooseTab(event: KeyboardEvent) {
    if (event.keyCode > 48 && event.keyCode < 58) {
      const index = parseInt(KeyCode[event.keyCode].replace('KEY_', ''), 10) - 1;
      const component = this.components[index];
      if (component) {
        component.instance.ref.nativeElement.click();
      }
    }
  }

  /**
   * alt + w to close the active tab
   * @param {KeyboardEvent}event
   * @param {string}key
   */
  handleKeybind(event: KeyboardEvent, key: string) {
    const keyCode = this.keybindConfig.getKeyCode(key);
    if (keyCode !== undefined && keyCode !== null && keyCode !== -1 && event.keyCode === keyCode) {
      if (this.components.length > 0) {
        const tab = this.components.find((tabRef) => tabRef.instance.isActive === true);
        if (tab !== undefined && tab.instance instanceof TabUiComponent) {
          tab.instance.removeBtn.nativeElement.click();
        }
      }
    }
  }

  updateScrollTabs(isNew = false) {
    const scrollWidth = this.getScrollWidth();
    this.hasEnoughWidth = this.wrapper.nativeElement.clientWidth - scrollWidth > 0;
    if (isNew) {
      this.wrapper.nativeElement.scrollLeft = this.getScrollWidth() - this.wrapper.nativeElement.clientWidth;
    } else {
      // si on revient sur une taille d'écran suffisante pour afficher l'entiereté des onglets
      if (this.hasEnoughWidth) {
        this.wrapper.nativeElement.scrollLeft = 0;
      }
      if (this.wrapper.nativeElement.scrollLeft + this.wrapper.nativeElement.clientWidth > this.getScrollWidth()) {
        this.wrapper.nativeElement.scrollLeft = this.getScrollWidth() - this.wrapper.nativeElement.clientWidth;
      }
    }
  }

  widthOfList() {
    let nbTabs = 0;
    this.components.forEach((tab) => {
      if (tab.instance instanceof TabGroupUiComponent) {
        nbTabs += tab.instance.components.length;
      } else {
        nbTabs++;
      }
    });
    return nbTabs * 150;
  }

  scrollLeft(event) {
    event.preventDefault();
    event.stopPropagation();
    // check si l'event n'a pas été arrêté
    if (!this.isScrolling) {
      this.isScrolling = true;
      this.scrollInterval = setInterval(() => {
        if (this.wrapper.nativeElement.scrollLeft === 0) {
          clearInterval(this.scrollInterval);
        }
        this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollLeft - 5 });
      }, 1);
    }
  }

  scrollRight(event) {
    event.preventDefault();
    event.stopPropagation();
    // check si l'event n'a pas été arrêté
    if (!this.isScrolling) {
      this.isScrolling = true;
      this.scrollInterval = setInterval(() => {
        // on vérifie si on a atteint la limite de la scroll
        if (this.getScrollWidth() - this.wrapper.nativeElement.scrollLeft - this.wrapper.nativeElement.clientWidth + 5 > 0) {
          this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollLeft + 5 });
        } else {
          clearInterval(this.scrollInterval);
        }
      }, 1);
    }
  }

  stopScroll(event) {
    event.preventDefault();
    clearInterval(this.scrollInterval);
    this.isScrolling = false;
  }

  /**
   * permet de calculer la taille de la scroll
   */
  getScrollWidth() {
    let nbTabs = 0;
    let nbTabsInTabGroup = 0;
    let nbTabGroups = 0;
    this.components.forEach((tab) => {
      if (tab.instance instanceof TabGroupUiComponent) {
        nbTabsInTabGroup += tab.instance.components.length;
        nbTabGroups++;
      } else {
        nbTabs++;
      }
    });
    // 2 et 7 sont les valeurs des margins et padding
    const spaceWidth = 2 * nbTabs + 27 * nbTabGroups;
    const tabSize = Global.isMobileWidth()
      ? this.config.getConstant('TabUiComponent#tabWidth_mobile')
      : this.config.getConstant('TabUiComponent#tabWidth_not-mobile');
    return (nbTabs + nbTabsInTabGroup) * tabSize + spaceWidth + 12;
  }

  handleWheel(event: WheelEvent) {
    if (event.deltaY > 0) {
      if (this.getScrollWidth() - this.wrapper.nativeElement.scrollLeft - this.wrapper.nativeElement.clientWidth + 25 > 0) {
        this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollLeft + 25 });
      }
    } else {
      if (this.wrapper.nativeElement.scrollLeft !== 0) {
        this.wrapper.nativeElement.scrollTo({ left: this.wrapper.nativeElement.scrollLeft - 25 });
      }
    }
    event.preventDefault();
  }
}
