import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Component, Input } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar-renderer',
  template: `
  <iu-calendar-ui
  [fieldValue]="this.fieldValue"
  [isLabelDisplay]="false"
  [cssClass]="'defaultDateCalendar p-col-12 calendarRenderer'"
  [isStandalone]="true"
  [data]="{ needTime: this.templates.needTime}"
  [isGridRenderer]="true"
  (fieldValueModified)="onFieldModified($event)"
  (checkGridRendererEmitter)="onCalendar($event)"
  [isReadOnly]="isReadOnly"
  ></iu-calendar-ui>
`
})

export class CalendarRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;
  templates: any;
  fieldValue = null;
  activeGridView: GridViewUiComponent;
  dataStored: DataStore;
  readOnlyLogicCells: any[] = [];

  @Input()
  isReadOnly: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    this.activeGridView = this.params.activeGridView;
    this.dataStored = new DataStore();

    if (params.value) {
      this.fieldValue = moment(params.value).toDate();
    }

    if (params.data) {
      this.dataStored.data = params.data;
    }

    if (this.activeGridView && this.activeGridView.GridTabInfinityScrollUiComponent && this.activeGridView.GridTabInfinityScrollUiComponent.data && this.activeGridView.GridTabInfinityScrollUiComponent.data.columnsDetails) {
      const values = (this.activeGridView.GridTabInfinityScrollUiComponent.data.columnsDetails as Map<string, any>).values();
      for (let i = values.next(); !i.done ; i = values.next()) {
        if (i.value.IsEditable && i.value.ReadOnlyLogic) {
          this.readOnlyLogicCells.push(i.value.ReadOnlyLogic);
        }
      }

    }

    if (this.dataStored && this.dataStored.data && this.readOnlyLogicCells) {
      this.readOnlyLogicCells.forEach((readOnlyLogicCell) => {
        this.isReadOnly = LogicEvaluator.evaluateLogic(this.dataStored.data, readOnlyLogicCell);
      });
    }
  }

  refresh(params?: ICellRendererParams): boolean {
    return true;
  }

  onFieldModified($event) {
    const oldValue = this.params.node.data[this.templates.columnName];
    let newValue: string;
    if ($event) {
      newValue = moment($event).format('YYYY-MM-DDTHH:mm:ss.SSS');
      newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
    }
    this.params.node.data[this.templates.columnName] = newValue;
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field,
        oldValue,
        newValue
        // ...something
      };
      this.params.onClick({ ...this.params, ...params });
    }
  }

  onCalendar(event) {
    this.params.onCalendar(event);
  }
}
