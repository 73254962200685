import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { PrimeBreadcrumbComponent } from '@iupics-components/overrided/prime-breadcrumb/prime-breadcrumb.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import { KeyCode } from '@iupics-config/keycode.enum';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { InfoComponent } from '@iupics-manager/models/iupics-data';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { Utils } from '@iupics-util/tools/util';
import { TabUiComponent } from '@web-desktop/components/menu-top/components/tab-ui/tab-ui.component';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';

@Component({
  selector: 'iu-blade-ui',
  templateUrl: './blade-ui.component.html',
  styleUrls: ['./blade-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BladeUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scrollableElt', { read: ElementRef, static: true })
  scrollableElt: ElementRef;
  scrollWidth: string;
  breadCrumbWidth: string;
  breadCrumbWidthNum: number;
  private tabSize = [];
  readonly editTabSize = 40;
  private gridViewReduce = 35.2;
  gridViewOverFlow = 'hidden';
  scrollPosition: number;
  sizeGrid: number;
  isMobile = Global.isMobile();
  isMobileWidth = Global.isMobileWidth;
  @Input()
  infoComponent: InfoComponent;
  @Input()
  activeTab: TabUiComponent;
  @ViewChild(PrimeBreadcrumbComponent, { static: true })
  breadcrumbComponent: PrimeBreadcrumbComponent;
  @ViewChild('breadRef', { static: true })
  breadRef: ElementRef;
  windowType = IupicsMenuType.WINDOW;
  constructor(
    private uiCreatorService: UICreatorService,
    private windowFactory: WindowFactoryService,
    private config: AppConfig,
    private keybindConfig: KeybindConfigService
  ) {
    super();
  }
  @HostListener('window:orientationchange', ['$event'])
  orientationChange(event: KeyboardEvent) {
    if (this.breadcrumbComponent) {
      const itemFound = this.breadcrumbComponent.model.find((item) => !item.disabled);
      if (itemFound) {
        setTimeout(() => {
          this.DOMChildrenComponent.slice(1).forEach((child, index) => {
            if (child instanceof EditViewUiComponent) {
              this.tabSize[index] = child.editViewElement.nativeElement.width;
            } else if (child instanceof GridViewUiComponent) {
              this.tabSize[index] = child.divContent.nativeElement.width;
            }
          });
          this.onBreadCrumbEvent({
            event: IupicsTypeEvent.clickBreadCrumbItem,
            tabId: itemFound.id
          });
        }, 100);
      }
    }

    event.preventDefault();
  }
  ngOnInit() {
    Global.workspace.linkedComponentToTabMap[this.activeTab.id] = this;
    this.subscriptions.push(
      this.uiCreatorService.getWindow(this.infoComponent.windowId).subscribe((tabs) => {
        const item: DynamicComponent = {
          container: this,
          DOMParentComponent: this,
          cssClass: 'iupics-blade-content-first',
          isCssOnComponent: false,
          tabId: tabs[0].tabId,
          gridPaginator: false,
          initRequest: this.activeTab.dataGridRequest
        };
        this.windowFactory.newEventHandler({
          type: IupicsTypeEvent.showGridView,
          item: item
        });
      })
    );
    this.scrollWidth = this.config.getConstant('BladeUiComponent#scrollWidth');
  }

  ngAfterViewInit() {
    this.breadcrumbComponent.addItem({
      tabId: 0,
      label: this.infoComponent.name
    });
  }

  /**
   * Calcul de la taille du blade total
   * @param viewSize
   */
  updateContainerZone(viewSize: number): void {
    const childrenLength = this.DOMChildrenComponent.length;
    let tabLength = this.tabSize.length;
    if (viewSize === 0) {
      this.tabSize.splice(childrenLength - 1, tabLength - childrenLength + 1);
    } else {
      this.tabSize.push(viewSize);
    }
    tabLength = this.tabSize.length;
    if (childrenLength > 1) {
      if (this.DOMChildrenComponent[this.DOMChildrenComponent.length - 2].DOMComponent.instance instanceof GridViewUiComponent) {
        if (viewSize === 0) {
          this.scrollWidth = this.gridViewReduce + this.tabSize[this.tabSize.length - 1] + 'em';
        } else {
          this.scrollWidth = this.gridViewReduce + viewSize + 'em';
        }
      } else {
        if (viewSize === 0) {
          this.scrollWidth = this.gridViewReduce + this.editTabSize * (tabLength - 1) + this.tabSize[tabLength - 1] + 'em';
        } else {
          this.scrollWidth = this.gridViewReduce + (tabLength - 1) * this.editTabSize + viewSize + 'em';
        }
      }
    } else {
      this.scrollWidth = this.config.getConstant('BladeUiComponent#scrollWidth');
    }
    if (viewSize === 0) {
      viewSize = this.tabSize[this.tabSize.length - 1];
    }
    this.setBladeRatio(viewSize);
    this.scrollableElt.nativeElement.scrollLeft = this.scrollableElt.nativeElement.scrollWidth;
    this.updateScroll();
  }

  /**
   *
   * @param bladeSize
   */
  setBladeRatio(bladeSize?: number) {
    let newBigRatio = this.DOMChildrenComponent.length <= 1 ? '100%' : bladeSize + 'em';

    // this.breadCrumbWithNum = bladeSize * 16;
    for (let index = 0; index < this.DOMChildrenComponent.length - 1; index++) {
      if (this.DOMChildrenComponent[index].DOMComponent.instance instanceof GridViewUiComponent) {
        const columnState = (<GridViewUiComponent>(
          this.DOMChildrenComponent[index].DOMComponent.instance
        )).GridTabInfinityScrollUiComponent.agGrid.columnApi.getColumnState();
        this.sizeGrid = 23;
        columnState.forEach((col) => {
          if (!col.hide) {
            this.sizeGrid += col.width;
          }
        });
        if (this.sizeGrid < 240) {
          this.sizeGrid = this.config.getConstant('BladeUiComponent#sizeGrid_minimum');
        }
        if (this.sizeGrid > this.gridViewReduce * 16) {
          this.sizeGrid = this.gridViewReduce * 16;
        }
        (<AbstractDynamicComponent>this.DOMChildrenComponent[index].DOMComponent.instance).cssWidth = this.sizeGrid + 'px';
        // this.breadCrumbWithNum += this.sizeGrid;
        let scrollWidthNum = +this.scrollWidth.slice(0, this.scrollWidth.length - 2);
        scrollWidthNum = scrollWidthNum - this.gridViewReduce + this.sizeGrid / 16;
        this.scrollWidth = scrollWidthNum + 'em';
      } else {
        if (this.isMobile) {
          (<AbstractDynamicComponent>this.DOMChildrenComponent[index].DOMComponent.instance).cssWidth =
            Global.getDeviceWidth() + 'px';
        } else {
          (<AbstractDynamicComponent>this.DOMChildrenComponent[index].DOMComponent.instance).cssWidth = this.editTabSize + 'em';

          // this.breadCrumbWithNum += this.editTabSize * 16;
        }
      }
    }
    if (this.isMobile) {
      newBigRatio = Global.getDeviceWidth() + 'px';
    }
    (<AbstractDynamicComponent>this.DOMChildrenComponent[this.DOMChildrenComponent.length - 1].DOMComponent.instance).cssWidth =
      newBigRatio;

    // this.breadCrumbWith =
    //   this.DOMChildrenComponent.length <= 1 || this.breadCrumbWithNum >= this.breadRef.nativeElement.parentElement.clientWidth
    //     ? '100%'
    //     : this.breadCrumbWithNum + 'px';
    this.breadCrumbWidthNum = 0;
    let cpt = 1;
    this.tabSize.forEach((size) => {
      if (this.DOMChildrenComponent[cpt].additionalInfoWidthExpanded) {
        this.breadCrumbWidthNum +=
          this.DOMChildrenComponent[cpt].additionalInfoWidth === '2em'
            ? 33
            : this.DOMChildrenComponent[cpt].additionalInfoWidthExpanded * 16;
      }
      // check si le blade courant n'est pas le dernier
      if (cpt !== this.DOMChildrenComponent.length - 1) {
        this.breadCrumbWidthNum += 40 * 16;
      } else {
        this.breadCrumbWidthNum += size * 16;
      }
      cpt++;
    });
    /*ajout de la taille du grid */
    this.breadCrumbWidthNum += this.sizeGrid;
    this.setBreadCrumbWidth(0, 0);
    this.scrollToInBlade();
  }

  /**
   * Mets à jour la position de la scroll
   */
  updateScrollingPosition(element: HTMLElement): void {
    setTimeout(() => {
      //   // la ligne de code commentée suivante va uniquement scroller par rapport à la taille de la div, pas tout à droite.
      //   // this.scrollableElt.nativeElement.scrollLeft = this.scrollableElt.nativeElement.getBoundingClientRect().width;
      //   this.scrollableElt.nativeElement.scrollLeft = this.scrollableElt.nativeElement.scrollWidth;
      if (this.isMobile) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center'
        });
      } else {
        this.scrollableElt.nativeElement.scrollLeft = this.scrollableElt.nativeElement.scrollWidth;
      }

      this.updateScroll();
    }, 50);
  }
  updateScroll(event?) {
    this.scrollPosition = (this.scrollableElt.nativeElement.scrollLeft / this.scrollableElt.nativeElement.scrollWidth) * 100;
  }
  setScroll() {
    setTimeout(() => {
      this.scrollableElt.nativeElement.scrollLeft = (this.scrollPosition / 100) * this.scrollableElt.nativeElement.scrollWidth;
    }, 50);
  }

  onChildUpdate(event: IupicsEvent): void {
    if (event.type === IupicsTypeEvent.addBreadcrumbItem) {
      this.breadcrumbComponent.addItem({
        tabId: event.item.tabId,
        label: event.item.data.label
      });
    } else if (event.type === IupicsTypeEvent.removeBreadcrumbItem) {
      this.breadcrumbComponent.removeItem({
        tabId: event.item.tabId,
        label: ''
      });
    }
  }
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}

  /**
   *
   * @param event
   */
  onBreadCrumbEvent(event) {
    if (event.event === IupicsTypeEvent.clickBreadCrumbItem) {
      let indexBig = 0;
      if (event.tabId === '0' && this.DOMChildrenComponent[0]) {
        this.DOMChildrenComponent[0].onSiblingUpdate({
          type: IupicsTypeEvent.expandEvent,
          item: undefined
        });
      } else {
        if (this.DOMChildrenComponent[0]) {
          this.DOMChildrenComponent[0].onSiblingUpdate({
            type: IupicsTypeEvent.collapseEvent,
            item: undefined
          });
        }
      }
      for (let i = 1; i < this.DOMChildrenComponent.length; i++) {
        const view = this.DOMChildrenComponent[i];
        if ('' + view.tabId === event.tabId) {
          view.onChildUpdate({
            type: IupicsTypeEvent.expandEvent,
            item: undefined
          });
          indexBig = i;
        } else {
          view.onChildUpdate({
            type: IupicsTypeEvent.collapseEvent,
            item: undefined
          });
        }
      }

      this.updateBladeSize(indexBig - 1);
    } else if (event.event === IupicsTypeEvent.closeBreadCrumbItem) {
      for (let i = 1; i < this.DOMChildrenComponent.length; i++) {
        const view = this.DOMChildrenComponent[i];
        if ('' + view.tabId === event.tabId) {
          (<EditViewUiComponent>view).checkBeforeClose();
          break;
        }
      }
    }
  }

  /**
   * Change la taille du blade et met à jour l'affichage de la taille du GridViewComponent
   * @param index
   */
  updateBladeSize(index: number) {
    let sizeGrid = 23;
    // this.breadCrumbWithNum = 0;
    for (let i = 0; i < this.DOMChildrenComponent.length; i++) {
      // keep grid on multiple columns on mobile devices
      if (i === 0 && !this.isMobile) {
        (<GridViewUiComponent>(
          this.DOMChildrenComponent[0].DOMComponent.instance
        )).GridTabInfinityScrollUiComponent.onlyOneColumn();
        const columnState = (<GridViewUiComponent>(
          this.DOMChildrenComponent[i].DOMComponent.instance
        )).GridTabInfinityScrollUiComponent.agGrid.columnApi.getColumnState();

        columnState.forEach((col) => {
          if (!col.hide) {
            sizeGrid += col.width;
          }
        });
        if (sizeGrid < 240) {
          sizeGrid = this.config.getConstant('BladeUiComponent#sizeGrid_minimum');
        }
        if (sizeGrid > this.gridViewReduce * 16) {
          sizeGrid = this.gridViewReduce * 16;
        }
        (<AbstractDynamicComponent>this.DOMChildrenComponent[i].DOMComponent.instance).cssWidth = sizeGrid + 'px';
        // this.breadCrumbWithNum += this.sizeGrid;
      } else {
        if (this.isMobile) {
          // sur mobile on veut que ca prenne toute la fenêtre.
          (<AbstractDynamicComponent>this.DOMChildrenComponent[i].DOMComponent.instance).cssWidth =
            Global.getDeviceWidth() + 'px';
        } else {
          (<AbstractDynamicComponent>this.DOMChildrenComponent[i].DOMComponent.instance).cssWidth = this.editTabSize + 'em';
          // this.breadCrumbWithNum += this.editTabSize * 16;
        }
      }
    }
    if (index > -1) {
      // this.gridViewOverFlow = 'auto';
      this.scrollWidth = sizeGrid / 16 + this.editTabSize * (this.tabSize.length - 1) + this.tabSize[index] + 'em';
      if (this.isMobile) {
        (<AbstractDynamicComponent>this.DOMChildrenComponent[index + 1].DOMComponent.instance).cssWidth =
          Global.getDeviceWidth() + 'px';
      } else {
        (<AbstractDynamicComponent>this.DOMChildrenComponent[index + 1].DOMComponent.instance).cssWidth =
          this.tabSize[index] + 'em';
        // this.breadCrumbWithNum -= this.editTabSize * 16;
        // this.breadCrumbWithNum += this.tabSize[index] * 16;
      }
      // this.breadCrumbWith =
      //   this.DOMChildrenComponent.length <= 1 || this.breadCrumbWithNum >= this.breadRef.nativeElement.parentElement.clientWidth
      //     ? '100%'
      //     : this.breadCrumbWithNum + 'px';
      if (index + 1 === this.DOMChildrenComponent.length - 1) {
        this.DOMChildrenComponent[index + 1].onSiblingUpdate({
          type: IupicsTypeEvent.expandEvent,
          item: null
        });
      } else {
        this.DOMChildrenComponent[index + 1].onSiblingUpdate({
          type: IupicsTypeEvent.expandEvent,
          item: {
            data: {
              label: 'select'
            },
            container: null
          }
        });
      }
    } else {
      this.scrollWidth =
        this.config.getConstant('BladeUiComponent#scrollWidth_compute') + this.editTabSize * this.tabSize.length + 'em';
      const gridViewComponent = <GridViewUiComponent>this.DOMChildrenComponent[index + 1].DOMComponent.instance;
      gridViewComponent.cssWidth = gridViewComponent.oldWidth + 'px';
      (<GridViewUiComponent>(
        this.DOMChildrenComponent[index + 1].DOMComponent.instance
      )).GridTabInfinityScrollUiComponent.allColumns(true, false);
      // this.breadCrumbWith = '100%';
    }
    if (this.isMobile) {
      let timeout = 0;
      if (Global.isLandscape()) {
        timeout = 10;
      }
      setTimeout(() => {
        this.scrollIntoViewByIndex(index + 1);
      }, timeout);
    } else {
      this.scrollToInBlade();
    }
    this.breadCrumbWidthNum = 0;
    let cpt = 1;
    this.tabSize.forEach((size) => {
      // on rajoute chaque taille des blades
      if (this.DOMChildrenComponent[cpt].additionalInfoWidthExpanded) {
        this.breadCrumbWidthNum +=
          this.DOMChildrenComponent[cpt].additionalInfoWidth === '2em'
            ? 32
            : this.DOMChildrenComponent[cpt].additionalInfoWidthExpanded * 16;
      }
      // check si le blade courant n'est pas l'actif
      if (cpt !== index + 1) {
        this.breadCrumbWidthNum += 40 * 16;
      } else {
        this.breadCrumbWidthNum += size * 16;
      }
      cpt++;
    });
    // on check si le blade actif est la grid
    if (index > -1) {
      this.breadCrumbWidthNum += sizeGrid;
    } else {
      this.breadCrumbWidthNum += Global.getDeviceWidth();
    }
    this.setBreadCrumbWidth(0, 0);
  }

  getOverFlow() {
    return this.gridViewOverFlow;
  }

  notifyUrlChange() {
    const gridFirstLevel = <GridViewUiComponent>this.DOMChildrenComponent[0].DOMComponent.instance;
    const viewType = gridFirstLevel.viewType;
    let filterModel;
    let groupColumn;
    let sortModel;
    let valueCols;
    let pivotCols;
    let pivotMode;
    if (gridFirstLevel && gridFirstLevel.filter) {
      filterModel = gridFirstLevel.filter.filterModel;
      groupColumn = gridFirstLevel.filter.rowGroupCols;
      sortModel = gridFirstLevel.filter.sortModel;
      if (
        (gridFirstLevel.viewType === ViewType.GRID && gridFirstLevel.GridTabInfinityScrollUiComponent) ||
        (gridFirstLevel.viewType === ViewType.CHART && gridFirstLevel.chartUiComponent)
      ) {
        pivotCols = gridFirstLevel.filter.pivotCols;
        pivotMode = gridFirstLevel.filter.pivotMode;
        valueCols = gridFirstLevel.filter.valueCols;
      }
    }

    const arrayEdit: EditViewUiComponent[] = <EditViewUiComponent[]>(
      this.DOMChildrenComponent.filter(
        (child) =>
          child.DOMComponent.instance instanceof EditViewUiComponent &&
          !child.DOMComponent.instance.zoomInfo &&
          !child.DOMComponent.instance.zoomTarget
      )
    );
    // console.log(arrayEdit);
    const othersRecordId = [];
    arrayEdit.forEach((edit) => {
      if (edit.currentDataStoreKey) {
        othersRecordId.push({
          tabId: edit.tabId,
          recordId: edit.currentDataStoreKey.recordId.includes(',') ? edit.currentDataStoreKey.recordId : 'newRecord'
        });
      }
    });
    Utils.onTabChange(
      this.activeTab,
      viewType,
      null,
      {
        filterModel: filterModel,
        sortModel: sortModel,
        rowGroupCols: groupColumn,
        valueCols: valueCols,
        pivotCols: pivotCols,
        pivotMode: pivotMode
      },
      othersRecordId
    );
  }
  scrollIntoViewByIndex(index: number) {
    if (this.DOMChildrenComponent[index] instanceof GridViewUiComponent) {
      (<GridViewUiComponent>this.DOMChildrenComponent[index]).divContent.nativeElement.scrollIntoView({
        behavior: 'smooth',
        inline: 'start'
      });
    } else {
      (<EditViewUiComponent>this.DOMChildrenComponent[index]).editViewElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
        inline: 'start'
      });
    }
  }
  scrollToInBlade() {
    setTimeout(() => {
      let currentWindowIndex = -1;
      // le breadcrum indique la fenêtre actuelle
      this.breadcrumbComponent.model.forEach(function (m, index) {
        if (m.disabled === false) {
          currentWindowIndex = index - 1;
        }
      });
      let extraScroll = 0;
      if (this.scrollableElt) {
        if (currentWindowIndex >= 0) {
          if (currentWindowIndex >= 1) {
            // parce qu'il y a une bordure de 1px à droite;
            extraScroll = currentWindowIndex * 1;
          }
          let iupucs_blade_content_width = 0;
          let iupucs_blade_content_decalage = 0;
          if (this.isMobile) {
            // taille de la fenêtre
            iupucs_blade_content_width = Global.getDeviceWidth();
          } else {
            // taille d'un balde en px (leur taille est exprimer en em)
            iupucs_blade_content_width = this.scrollableElt.nativeElement.getElementsByClassName('iupics-blade-content')[0]
              ? this.scrollableElt.nativeElement.getElementsByClassName('iupics-blade-content')[0].offsetWidth
              : Global.getDeviceWidth();
            // On calcule la taille du décalage pour centrer le blade, sinon il serait collé à gauche.
            // (taille total de la window - la taille du blade) / 2 (pour centrer)
            iupucs_blade_content_decalage = (this.scrollableElt.nativeElement.offsetWidth - iupucs_blade_content_width) / 2;
          }
          this.scrollableElt.nativeElement.scrollTo(
            currentWindowIndex * iupucs_blade_content_width - iupucs_blade_content_decalage + this.sizeGrid + extraScroll,
            0
          );
        } else {
          this.scrollableElt.nativeElement.scrollTo(0, 0);
        }
      }
    }, 100);
  }

  setBreadCrumbWidth(sizeToAdd: number, sizeToRemove: number) {
    this.breadCrumbWidthNum -= sizeToRemove;
    this.breadCrumbWidthNum += sizeToAdd;
    // on ajoute ou retire une taille qui a été changé dynamiquement
    this.breadCrumbWidth =
      this.DOMChildrenComponent.length <= 1 ||
      this.isMobile ||
      this.breadCrumbWidthNum >= this.breadRef.nativeElement.parentElement.clientWidth
        ? '100%'
        : this.breadCrumbWidthNum + 'px';
  }

  closeInMobile(event: Event) {
    (<TabUiComponent>this.activeTab).handleRemove();
    event.stopPropagation();
  }

  handleKeyBind(event: KeyboardEvent, key: string) {
    event.preventDefault();
    const keyCode = this.keybindConfig.getKeyCode(key);
    // tslint:disable-next-line: deprecation
    if (keyCode !== undefined && keyCode !== null && keyCode !== KeyCode.NONE && event.keyCode === keyCode) {
      event.shiftKey === true
        ? this.breadcrumbComponent.itemClick(null, this.breadcrumbComponent.model[this.breadcrumbComponent.model.length - 1])
        : this.breadcrumbComponent.itemClick(null, this.breadcrumbComponent.model[0]);
    }
  }
  getWindowContext() {
    let lastEdit;
    if (this.DOMChildrenComponent[0]) {
      const windowId = (<GridViewUiComponent>this.DOMChildrenComponent[0]).data.AD_window_ID;
      lastEdit = <EditViewUiComponent>this.DOMChildrenComponent.slice()
        .reverse()
        .find(
          (child) =>
            child instanceof EditViewUiComponent && child.currentDataStoreKey && windowId == child.currentDataStoreKey.windowId
        );
    }
    if (lastEdit) {
      return lastEdit.getCurrentContext();
    } else {
      return {};
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
