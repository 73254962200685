import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompiereNotification, CompiereNotificationType } from '@compiere-ws/models/compiere-notification-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class CompiereNotificationService {
  private url: string;

  constructor(private http: ApiService, private config: AppConfig) {}

  getNotifications(
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<HttpResponse<CompiereNotification[]>> {
    this.url = this.config.getBackendResource(notificationType === CompiereNotificationType.NOTIF ? 'notifications' : 'alerts');
    return this.http.get<CompiereNotification[]>(this.url, { observe: 'response' });
  }
  sendNotification(
    notification: CompiereNotification,
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource(notificationType === CompiereNotificationType.NOTIF ? 'notifications' : 'alerts');
    return this.http.post<CompiereNotification>(this.url, notification);
  }
  handleNotification(
    notification: CompiereNotification,
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<HttpResponse<CompiereNotification>> {
    this.url = this.config.getBackendResource(notificationType === CompiereNotificationType.NOTIF ? 'notifications' : 'alerts');
    return this.http.put<CompiereNotification>(this.url + '/' + notification.request_id, {}, { observe: 'response' });
  }

  handleAllNotification(
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<HttpResponse<CompiereNotification>> {
    this.url = this.config.getBackendResource(notificationType === CompiereNotificationType.NOTIF ? 'notifications' : 'alerts');
    return this.http.put<CompiereNotification>(this.url, {}, { observe: 'response' });
  }

  closeNotification(
    notification: CompiereNotification,
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource(notificationType === CompiereNotificationType.NOTIF ? 'notifications' : 'alerts');
    return this.http.delete<CompiereNotification>(this.url + '/' + notification.request_id);
  }
  closeAllNotification(
    notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF
  ): Observable<CompiereNotification> {
    this.url = this.config.getBackendResource(notificationType === CompiereNotificationType.NOTIF ? 'notifications' : 'alerts');
    return this.http.delete<CompiereNotification>(this.url);
  }
}
