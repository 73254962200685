<div *ngIf="opened && currentUserAccount" #userProfile class="profileView">
  <i
    class="fa fa-2x fa-cog user-profile-gear-icon"
    [pTooltip]="'profil.update' | translate"
    tooltipPosition="right"
    (click)="openUpdateProfil($event)"
    data-cy="modify-profile"
  ></i>
  <div class="p-col-row profileContainer">
    <div class="profil-header">
      <div class="ui-row account">
        <div style="width: 100%; text-align: left">
          <span
            class="account-name"
            [pTooltip]="
              connectorService.oauth2Access
                ? currentUserAccount.fullname +
                  ' ( Expiration : ' +
                  (connectorService.oauth2Access.expires_in | timeFormat: 's') +
                  ')'
                : ''
            "
            tooltipPosition="right"
            >{{ currentUserAccount.fullname }}
          </span>
          <span class="account-role" data-cy="role-selected">{{ roleSelected.name }} </span>
          <span class="account-email">{{ currentUserAccount.email }} ({{ currentUserAccount.login }}) </span>
        </div>
      </div>
    </div>

    <div *ngIf="!isUpdateProfil" class="separatordiv"><span class="separator"> </span></div>

    <div *ngIf="isUpdateProfil" style="width: 100%; text-align: center"><span class="separator"> </span></div>
    <ul class="user-profile-dropdown-list-container">
      <li #roleList [ngClass]="['user-profile-dropdown-content', rolesIsDisplay ? 'opened' : 'closed']">
        <a class="user-profile-dropdown-title" data-cy="display-roles" (click)="displayDropdownList('roles')">Roles</a>
        <ul class="user-profile-dropdown-list" data-cy="roles-list">
          <li
            *ngFor="let role of currentUserAccount.roles"
            [ngClass]="[role.isSelected ? 'user-profile-dropdown-item-active' : '', 'user-profile-dropdown-item']"
            (click)="changeRole(role)"
          >
            <div>
              <span *ngIf="role.isSelected"><i class="icon-ico-next" aria-hidden="true"></i></span> <a>{{ role.name }}</a>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ul *ngIf="roleSelected.role_id > 0" class="user-profile-dropdown-list-container">
      <li [ngClass]="['user-profile-dropdown-content', organisationsIsDisplay ? 'opened' : 'closed']">
        <a class="user-profile-dropdown-title" data-cy="display-organisations" (click)="displayDropdownList('organisations')"
          >Organisations</a
        >
        <ul class="user-profile-dropdown-list" data-cy="organisations-list">
          <li
            *ngFor="let organisation of organisations$ | async as organisations"
            [ngClass]="[organisation.isSelected ? 'user-profile-dropdown-item-active' : '', 'user-profile-dropdown-item']"
            (click)="updateOrg(organisation, organisations)"
          >
            <div>
              <span *ngIf="organisation.isSelected"><i class="icon-ico-next" aria-hidden="true"></i></span>
              <a>{{ organisation.displayValue }}</a>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ul *ngIf="roleSelected.role_id > 0" class="user-profile-dropdown-list-container">
      <li [ngClass]="['user-profile-dropdown-content', warehousesIsDisplay ? 'opened' : 'closed']">
        <a class="user-profile-dropdown-title" data-cy="display-warehouses" (click)="displayDropdownList('warehouses')"
          >Magasins</a
        >
        <ul class="user-profile-dropdown-list" data-cy="warehouses-list">
          <li
            *ngFor="let warehouse of warehouses$ | async as warehouses"
            [ngClass]="[warehouse.isSelected ? 'user-profile-dropdown-item-active' : '', 'user-profile-dropdown-item']"
            (click)="updateWarehouse(warehouse, warehouses)"
          >
            <div>
              <span *ngIf="warehouse.isSelected"><i class="icon-ico-next" aria-hidden="true"></i></span>
              <a>{{ warehouse.displayValue }}</a>
            </div>
          </li>
        </ul>
      </li>
    </ul>

    <div class="ui-row clear apiz-p-grid actions">
      <a class="p-col-12 secondary" (click)="showUserContext($event)">
        {{ 'profil.showUserContext' | translate }}
      </a>
      <a
        *ngIf="currentUserAccount.current_role.isAdministrator && !isUpdateProfil"
        class="p-col-12 secondary"
        (click)="loginAs()"
        data-cy="login-as-btn"
      >
        {{ 'profil.loginas' | translate }}
      </a>
      <a
        *ngIf="roleSelected.role_id === 0 && !isUpdateProfil"
        class="p-col-12 secondary"
        (click)="reloadConfiguration($event, false)"
      >
        {{ 'profil.reload-server' | translate }}
      </a>
      <a *ngIf="!isUpdateProfil" class="p-col-12 secondary user-profile-logoff-btn" (click)="logout($event)" data-cy="logout-btn">
        {{ 'profil.logoff' | translate }}
      </a>
      <a
        *ngIf="roleSelected.role_id === 0 && !isUpdateProfil"
        class="p-col-12 secondary"
        (click)="reloadConfiguration($event, true)"
      >
        {{ 'profil.reload-user' | translate }}
      </a>
      <iu-app-version-panel [roleId]="roleSelected.role_id"></iu-app-version-panel>
    </div>
  </div>
</div>
<p-sidebar
  [(visible)]="isUpdateProfil"
  appendTo="body"
  position="right"
  (contextmenu)="$event.preventDefault()"
  [styleClass]="isMobile || isMobileWidth ? 'iu-fullscreen-sidebar' : 'p-sidebar-md'"
>
  <div *ngIf="isUpdateProfil" class="user-profile-edition-zone">
    <div class="user-profile-edition-zone-content">
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.my-profile' | translate">
        <div class="user-profile-fieldset">
          <div class="apiz-p-grid">
            <iu-autocomplete-ui
              #languagesSelector
              data-cy="language-selector"
              [label]="'profil.language' | translate"
              [isStandalone]="true"
              columnName="Profile-AD_Language"
              [data]="languages"
              cssGrid="p-col-12"
              cssClass="p-col-12"
            ></iu-autocomplete-ui>
          </div>
          <div class="apiz-p-grid">
            <iu-autocomplete-ui
              #printerNameField
              [label]="'profil.printerName' | translate"
              [isStandalone]="true"
              columnName="Profile-printerName"
              [fieldValue]="this.printerName"
              [data]="printerNames"
              cssGrid="p-col-12"
              cssClass="p-col-12"
            ></iu-autocomplete-ui>
          </div>
        </div>
      </iu-prime-fieldset>
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.my-settings' | translate">
        <div class="apiz-p-grid">
          <iu-calendar-ui
            class="defaultDateCalendar p-col-12"
            label="{{ 'profil.date' | translate }}"
            [fieldValue]="currentDefaultDate"
            [isStandalone]="true"
            [locale]="currentUserAccount.default_language.iso_code"
            cssGrid="p-col-12"
            (fieldValueModified)="changeDefaultDate($event)"
          ></iu-calendar-ui>
          <iu-input-switch-ui
            #autoCommitField
            label="{{ 'profil.autoCommitPref' | translate }}"
            [fieldValue]="this.isAutoCommit"
            cssGrid="p-col-12"
            style="text-align: left"
          >
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #advancedShowField
            label="{{ 'profil.oneuserpref' | translate }}"
            [fieldValue]="this.isShowAdvanced"
            cssGrid="p-col-12"
            style="text-align: left"
          >
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #acctShowField
            label="{{ 'profil.twouserpref' | translate }}"
            [fieldValue]="this.isShowAcct"
            cssGrid="p-col-12"
            style="text-align: left"
            columnName="Profile-AcctNote"
          >
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #trlShowField
            label="{{ 'profil.threeuserpref' | translate }}"
            [fieldValue]="this.isShowTrl"
            cssGrid="p-col-12"
            style="text-align: left"
          >
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #previewField
            label="{{ 'profil.fouruserpref' | translate }}"
            [fieldValue]="this.isPrintPreview"
            cssGrid="p-col-12"
            style="text-align: left"
          ></iu-input-switch-ui>
          <iu-autocomplete-ui
            #notificationReminderTimeoutField
            data-cy="autocomplete-notifTimeout"
            label="{{ 'profil.fiveuserpref' | translate }}"
            [isStandalone]="true"
            columnName="Profile-NotificationTimeout"
            [data]="NotificationDelay"
            [fieldValue]="this.notificationReminderTimeout"
            cssGrid="p-col-12"
            cssClass="p-col-12"
          ></iu-autocomplete-ui>
        </div>
      </iu-prime-fieldset>
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.appearence' | translate">
        <div class="apiz-p-grid">
          <iu-autocomplete-ui
            #themeSelector
            data-cy="autocomplete-themes"
            label="{{ 'profil.theme' | translate }}"
            [isStandalone]="true"
            columnName="Profile-UIThemes"
            [data]="UIThemes"
            [fieldValue]="this.currentUITheme"
            (fieldValueModified)="changeTheme($event)"
            cssGrid="p-col-12"
            cssClass="p-col-12"
          ></iu-autocomplete-ui>
          <!-- {AD_Role_ID: 100, AD_User_ID:100} -->
        </div>
      </iu-prime-fieldset>
      <div class="user-profile-edition-zone-buttons">
        <button
          class="p-button p-button-secondary"
          data-cy="cancel-update-profile"
          (click)="isUpdateProfil = false; $event.stopPropagation()"
        >
          {{ 'generic.cancel' | translate }}
        </button>
        <button class="p-button primary" data-cy="update-profile" (click)="validateProfile(); $event.stopPropagation()">
          {{ 'profil.valid' | translate }}
        </button>
        <button class="p-button p-button-error" (click)="resetDashboard($event)">
          {{ 'profil.reset-dashboard' | translate }}
        </button>
      </div>
    </div>
  </div>
</p-sidebar>
