<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container operation-management-window no-overflow-x no-overflow-y apiz-p-grid"
  [attr.data-cy-specific-window-name]="name"
>
  <!-- <i
    *ngIf="isFullScreenEnabled"
    [ngClass]="[isFullScreen ? 'icon-fullscreen-off' : 'icon-fullscreen-on', 'operation-management-container-icon-fullscreen']"
    (click)="toggleFullscreen()"
  ></i> -->

  <div id="left-panel" [ngClass]="isExpended ? 'p-col-4' : 'p-col-2'">
    <div class="operation-management-legend">
      <div class="operation-management-title">{{ ofGridTitle }}</div>
      <div>
        <button class="p-button operation-management-title-button" (click)="isExpended = !isExpended">
          <i [ngClass]="[isExpended ? 'icon-ico-back' : 'icon-ico-next']"></i>
        </button>
      </div>
    </div>
    <ng-template #grid></ng-template>
  </div>
  <div id="right-panel" [ngClass]="isExpended ? 'p-col-8' : 'p-col-10'">
    <div id="field-panel" class="apiz-p-grid">
      <div class="p-col-12 p-grid">
        <ng-template #top></ng-template>
      </div>

      <div class="p-col-12 p-grid">
        <ng-template #bottomLeft></ng-template>
        <div class="p-col-6">
          <iu-input-file-ui
            class="p-col-12"
            [canUpload]="false"
            [isStandalone]="true"
            [mustGet]="false"
            [uploadedFiles]="uploadedFiles"
            [canDelete]="false"
            [canPreview]="true"
            [multiple]="true"
            [uploadedFileList_title]="'operation-management.input-file.title' | translate"
            [uploadedFileList_noFiles]="'operation-management.input-file.no-files' | translate"
          ></iu-input-file-ui>
        </div>
      </div>
    </div>
    <hr id="hr" />
    <div class="apiz-p-grid">
      <div id="products-table-panel" class="p-col-6">
        <!-- ? produits fabriqués -->
        <h2 class="operation-management-legend">{{ 'operation-management.products' | translate }}</h2>
        <div class="products-table" [style.maxHeight.px]="productsTableHeight">
          <ng-container *ngIf="products; else fakeProductTpl">
            <ng-container
              *ngTemplateOutlet="productListTpl; context: { products: this.products, $implicit: false }"
            ></ng-container>
          </ng-container>
          <ng-template #fakeProductTpl>
            <ng-container
              *ngTemplateOutlet="
                productListTpl;
                context: {
                  products: fakeProducts,
                  $implicit: true
                }
              "
            ></ng-container>
          </ng-template>
        </div>
      </div>

      <div id="components-table-panel" class="p-col-6">
        <h2 class="operation-management-legend">{{ 'operation-management.components' | translate }}</h2>
        <div class="components-table" [style.maxHeight.px]="componentsTableHeight">
          <ng-container *ngIf="components; else fakeComponentTpl">
            <ng-container
              *ngTemplateOutlet="componentListTpl; context: { components: this.components, $implicit: false }"
            ></ng-container>
          </ng-container>
          <ng-template #fakeComponentTpl>
            <ng-container
              *ngTemplateOutlet="
                componentListTpl;
                context: {
                  components: fakeComponents,
                  $implicit: true
                }
              "
            ></ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ? Zone des templates -->
<ng-template #componentListTpl let-components="components" let-readOnly>
  <ng-container *ngFor="let component of components; let i = index">
    <tr [ngClass]="[readOnly ? 'read-only' : '', 'operation-management-row']">
      <td>{{ component.M_Product_ID.displayValue }}</td>
      <td>{{ component.QtyConsumed }}/{{ component.QtyToConsume }} {{ component.C_UOM_ID.displayValue }}</td>
      <td style="width: 15%">
        <iu-input-number-ui
          [isStandalone]="true"
          [fieldValue]="component.QtyConsumed"
          (fieldValueModified)="updateComponentsQty($event, i)"
          [isReadOnly]="readOnly"
        >
        </iu-input-number-ui>
      </td>
      <td style="width: 25%" class="btn-cell">
        <button
          class="p-button p-button-secondary big-btn"
          [disabled]="readOnly"
          (click)="updateComponentsQty(component.QtyConsumed - 1, i)"
        >
          -
        </button>
        <button
          class="p-button primary big-btn"
          [disabled]="readOnly"
          (click)="updateComponentsQty(component.QtyConsumed + 1, i)"
        >
          +
        </button>
      </td>
    </tr>
  </ng-container>
</ng-template>

<ng-template #productListTpl let-products="products" let-readOnly>
  <ng-container *ngFor="let product of products; let i = index">
    <tr [ngClass]="[readOnly ? 'read-only' : '', 'operation-management-row']">
      <td>{{ product.M_Product_ID.displayValue }}</td>
      <td>{{ product.M_AttributeSetInstance_ID.displayValue }}</td>
      <td>{{ product.QtyProduced }}/{{ product.QtyEntered }} {{ product.C_UOM_ID.displayValue }}</td>
      <td style="width: 15%">
        <iu-input-number-ui
          [isStandalone]="true"
          [fieldValue]="product.QtyProduced"
          (fieldValueModified)="updateProductQty($event, i)"
        ></iu-input-number-ui>
      </td>
      <td style="width: 25%" class="btn-cell">
        <button
          class="p-button p-button-secondary big-btn"
          [disabled]="readOnly"
          (click)="updateProductQty(product.QtyProduced - 1, i)"
        >
          -
        </button>
        <button class="p-button primary big-btn" [disabled]="readOnly" (click)="updateProductQty(product.QtyProduced + 1, i)">
          +
        </button>
      </td>
    </tr>
  </ng-container>
</ng-template>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
