import { AfterViewInit, Component, ComponentFactoryResolver, OnDestroy, OnInit } from '@angular/core';
import { CompiereProcess } from '@compiere-ws/models/compiere-process-json';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsField, IupicsSpecificWindow, IupicsTableDataHeader } from '@iupics-manager/models/iupics-data';
import { TranslateService } from '@ngx-translate/core';
import { has, isNil } from 'lodash';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-replenishment-tool-window-ui',
  templateUrl: './replenishment-tool-window-ui.component.html',
  styleUrls: ['./replenishment-tool-window-ui.component.scss']
})
export class ReplenishmentToolWindowUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit(): void {
    if (!this.isModal) {
      Global.workspace.linkedComponentToTabMap[this.activeTab.id] = this;
    }
    this.iniNewStore();

    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items = specificWindow.items.map((i) => {
        if (isNil(i.processId) && i.component !== 'GridViewUiComponent') {
          i.cssClass = 'p-col-12 p-md-3 p-lg-2';
        }
        return i;
      });
      const table = specificWindow.items.find(
        (item) => item.component === 'GridViewUiComponent' && item.data.columnName === 'Replenishment tool table'
      );
      const processingBtn: IupicsField = specificWindow.items.find(
        (item) => item.component === 'ButtonUiComponent' && item.data.columnName === 'Processing'
      );

      this.shouldForceSelection[processingBtn.processId] = true;

      for (let i = 0; i < table.data.columnsTableHeader.length; i++) {
        const header: IupicsTableDataHeader = table.data.columnsTableHeader[i];
        switch (header.field) {
          case 'Value':
            header.headerName = 'Article';
            break;
          case 'Z_Sensible':
            header.headerName = 'S';
            break;
          case 'QtyOnHand':
            header.headerName = 'Stock';
            break;
          case 'QtyOrdered':
            header.headerName = 'OA / OF';
            break;
          case 'QtyReserved':
            header.headerName = 'Qté mob';
            break;
          case 'qtyeconomique':
            header.headerName = 'Qté éco';
            break;

          default:
            break;
        }
      }
      table.data.canSelectAll = false;
      this.buildWindow(specificWindow);
    });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  buildWindow(specific: IupicsSpecificWindow, isCssOnComponent = true) {
    if (specific.AD_FormDetail_ID) {
      this.parentFormID = specific.AD_FormDetail_ID;
    }
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    if (specific.title !== undefined && specific.title !== null) {
      this.title = specific.title;
    }
    if (!this.customDesignArray || this.customDesignArray.length === 0) {
      this.fields = specific.items;
      const transformedFields = this.transformFields(specific.items);
      transformedFields.mainItems.forEach((item) => {
        const vcr = this.vcr;
        this.addComponent(item, isCssOnComponent, vcr);
      });
      transformedFields.optionalItems.forEach((item) => {
        this.addComponent(item, isCssOnComponent, this.vcrOptional, 'p-col-12');
      });
    } else {
      this.specificData = specific;
      this.customDesignArray.forEach((customDesignItem) => {
        this.createCustomDesignItem(customDesignItem, isCssOnComponent);
      });
    }
  }

  public log() {
    console.groupCollapsed('ReplenishmentToolWindowUiComponent');
    console.log(this);
    console.groupEnd();
    console.groupCollapsed('coldDefs');
    console.log(this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getColumnDefs());
    console.groupEnd();
  }

  protected transformFields(items: any[]): { mainItems: any[]; optionalItems: any[] } {
    const itemsOrdered = [];
    const optionalItems = [];
    const processingItems = [];
    let currentIndex = -1;
    items.forEach((item) => {
      if (item.component === 'GridViewUiComponent') {
        item.data['frameworkComponents'] = this.frameworkComponents;
        item.data.autosizeAllColumns = true;
      }
      if (currentIndex < 0 || (!item.data.isSameLine && !item.data.IsOptional)) {
        itemsOrdered.push({
          cssClass: '',
          component: 'RowUiComponent',
          children: []
        });
        currentIndex++;
      }
      if (item.data.IsOptional) {
        optionalItems.push(item);
      } else {
        itemsOrdered[currentIndex].children.push(item);
      }
    });
    return { mainItems: [...itemsOrdered, ...processingItems], optionalItems: optionalItems };
  }

  protected checkBeforeProcessing(dataContainer: any): boolean {
    if (dataContainer.data.columnName === 'traking') {
      return Boolean(this.dataStore.data.selections[0].selection?.length);
    }
    return this.hasAtLeastOneQtyToOrder();
  }

  protected executeProcess(processId: number, additionalParams?: any) {
    const dataContainer = this.dataContainers.find((dc) => dc.data.columnName === 'traking');
    if (dataContainer && dataContainer.data['processId'] === processId) {
      additionalParams = {
        M_Product_ID: this.dataStore.data.selections[0].selection[0]['M_Product_ID']
      };
    }
    super.executeProcess(processId, additionalParams);
  }

  private hasAtLeastOneQtyToOrder() {
    const n = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getLastDisplayedRow();
    for (let i = 0; i < n; i++) {
      const node = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getDisplayedRowAtIndex(i);
      if (has(node.data, 'QtyToOrder') && node.data['QtyToOrder'] > 0) {
        return true;
      }
    }
    return false;
  }

  protected getTablesForProcess(processEntity: CompiereProcess) {
    const tables = [];
    if (this.shouldForceSelection[processEntity.process.AD_Process_ID]) {
      const gridTab = this.gridViews[0].GridTabInfinityScrollUiComponent;
      const rows = [];
      gridTab.agGrid.api.forEachNode((node) => {
        if (has(node.data, 'QtyToOrder') && node.data['QtyToOrder'] > 0) {
          const splittedDataUUID = node.data.Data_UUID.split(',');
          const row = {
            record_ID: splittedDataUUID.length > 1 ? splittedDataUUID[1] : splittedDataUUID[0],
            columns: node.data
          };
          rows.push(row);
        }
      });

      tables.push({
        ad_FormDetail_ID: gridTab.data['AD_FormDetail_ID'],
        rows: rows
      });

      return tables;
    } else {
      return super.getTablesForProcess(processEntity);
    }
  }

  notifyFromGridAfterViewInit(gridView: GridViewUiComponent) {
    super.notifyFromGridAfterViewInit(gridView);
    gridView.GridTabInfinityScrollUiComponent.rowSelection = 'single';
    gridView.GridTabInfinityScrollUiComponent.getAdditionnalMenuItems = (params) => {
      if (params.node) {
        return [
          'separator',
          {
            name: "Zoom sur l'article",
            action: () => {
              const zoomInfo = {
                windowId: 140,
                dataUUID: params.node.data.Data_UUID,
                record_id: params.node.data.Data_UUID.split(',')[1],
                children: null
              };
              Global.workspace.openTargetSearch({
                zoomInfo: zoomInfo,
                cat: { id: 140 },
                source: {
                  id: params.node.data.Data_UUID
                }
              });
            },
            icon: '<i class="fas fa-external-link-alt"></i>'
          }
        ];
      } else {
        return [];
      }
    };
  }

  onEndProcess(ping?: ProcessPingInfo): void {
    if (ping && ping.AD_Process_ID && ping.AD_Process_ID.id) {
      setTimeout(() => {
        this.updateButtonReadOnly(ping.AD_Process_ID.id, false);
      }, 3000);
    }

    const processingBtn = this.dataContainers.find((dc) => dc.data.columnName === 'Processing');
    if (processingBtn.data['processId'] === ping.AD_Process_ID.id) {
      this.refreshGrids(this.dataStore, false);
    }
  }
}
