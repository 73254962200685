<span
  [style.display]="displayCss"
  [pTooltip]="tooltip"
  life="3"
  showDelay="500"
  tooltipPosition="top"
  tooltipZIndex="2100"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  [style.backgroundColor]="backgroundColorCss"
>
  <label
    *ngIf="this.inputRef && isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0"
    [style.font-size]="overridedCSS?.labelFontSize"
    >{{ label | textLimit: this.inputRef.nativeElement.clientWidth / 7 }}</label
  >
  <div class="input-number-body">
    <input
      #input
      *ngIf="!multiple"
      class="iu-number-input"
      onkeydown="javascript: return event.keyCode == 69 ? false : true"
      pInputText
      [attr.value]="fieldValue"
      (keypress)="isNumber($event)"
      (change)="!multiple && dataChange(_parseFloat(input.value))"
      (contextmenu)="!multiple && onContextMenu($event)"
      (keydown.enter)="!multiple && enterKey.emit($event)"
      (press)="!multiple && onContextMenu($event)"
      (blur)="!multiple && inputBlur.emit($event)"
      [ngModel]="fieldValue | number: numberFormat:lang"
      [readonly]="isReadOnly ? 'disabled' : null"
      [tabindex]="isReadOnly ? '-1' : null"
      [max]="this.max"
      [min]="this.min"
      [ngClass]="[hasConflict ? 'iu-field-conflict-text' : '', 'quantity', 'ui-corner-all', mandatoryCss || '']"
      [step]="fieldStep"
      [style.font-size]="overridedCSS?.inputFontSize"
      [style.height]="overridedCSS?.inputHeight"
      autocomplete="no"
      [attr.data-cy-columnName]="data ? data.columnName : columnName"
      data-cy="input-text"
      type="text"
      placeholder="{{ placeHolder }}"
      lang="{{ lang }}"
      (wheel)="OnWheel($event)"
    />
    <iu-input-chips-ui
      #inputChips
      *ngIf="multiple"
      [cssClass]="'iu-number-input'"
      [inputType]="'number'"
      [addOnSpace]="true"
      [addOnEnter]="true"
      [isLabelDisplay]="false"
      [addOnBlur]="true"
      [editOnBackspace]="true"
      (fieldValueChange)="dataChange($event)"
      [(fieldValue)]="fieldValue"
      [matchPattern]="regexNB"
      [columnName]="data ? data.columnName : columnName"
    ></iu-input-chips-ui>
    <i
      [attr.disabled]="isReadOnly ? 'disabled' : null"
      *ngIf="!this.isCurrencyDisplay"
      #currencySpan
      class="icon-ico-number iconCurrency"
      [style.height]="overridedCSS?.inputHeight"
    >
    </i>
    <i [attr.disabled]="isReadOnly ? 'disabled' : null" *ngIf="this.isCurrencyDisplay" #currencySpan class="iconCurrency">{{
      this.currency
    }}</i>
  </div>
  <i
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="ui-inputgroup-addon rtconflict fas fa-exclamation-triangle iconClass"
    (click)="showConflictPanel($event)"
  ></i>
  <p-overlayPanel #opValuePref *ngIf="showValuePrefPanel" [baseZIndex]="6000" [dismissable]="true" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"
    ></iu-value-preference-panel>
  </p-overlayPanel>
</span>

<p-overlayPanel #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide($event)">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  | <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide($event)">{{ conflictedData }}</a>
</p-overlayPanel>
