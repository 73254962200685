import { Injectable } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { ProcessPingInfo, ProcessPingInfoPara } from '@compiere-ws/models/process-ping-info';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import * as AGChannel from 'ag-channel';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { SocketService } from '../socket/socket.service';

@Injectable()
export class ProcessInProgressService {
  private openedWindow: string[] = [];
  private timeouts: NodeJS.Timeout[] = [];
  private progressSocket: AGChannel<any>;
  private pings: Map<any, ProcessPingInfo> = new Map();
  private pings$: Subject<ProcessPingInfo[]> = new Subject();

  constructor(private socketService: SocketService, private store: DataStoreService, private smService: SecurityManagerService) {}

  public init() {
    this.socketService.initSocket();
    this.progressSocket = this.socketService.enableProcessInProgressChannel();
    if (!this.progressSocket.isSubscribed()) {
      (async () => {
        for await (const dataStr of this.progressSocket) {
          try {
            const ping = new ProcessPingInfo(JSON.parse(dataStr));
            if (!this.pings.has(ping.AD_PInstance_ID.id)) {
              this.pings.set(ping.AD_PInstance_ID.id, ping);
            } else {
              const p = this.pings.get(ping.AD_PInstance_ID.id);
              if (p.Status !== ping.Status) {
                p.Status = ping.Status;
              }
              if (
                p.AD_Org_ID !== undefined &&
                p.AD_Org_ID !== null &&
                p.AD_User_ID !== undefined &&
                p.AD_User_ID !== null &&
                ping.Status !== 'finish'
              ) {
                this.pings$.next(Array.from(this.pings.values()));
              }

              if (ping.Status === 'finish') {
                this.pings$.next(cloneDeep(Array.from(this.pings.values())));
                if (this.openedWindow.length === 0) {
                  this.removeFinishedProcess(p);
                }
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      })();
    }
  }

  public updateSocket() {
    this.socketService.disableProcessInProgressChannel();
    this.init();
  }

  public getProcessInfo(ping: ProcessPingInfo) {
    const language = this.smService.getIupicsUserContext()['#AD_Language'];
    const request_instance_v: DataStoreRequest = {
      windowId: null,
      parent_constraint: null,
      compiereRequest: {
        tableName: 'INSTANCE_PARA_PING_V',
        startRow: 0,
        filterModel: {
          AD_PInstance_ID: {
            filterType: CompiereDataGridFilterType.NUMBER,
            operators: [OperatorFilterType.EQUALS],
            values: [ping.AD_PInstance_ID.id]
          },
          AD_Language: {
            filterType: CompiereDataGridFilterType.TEXT,
            operators: [OperatorFilterType.EQUALS],
            values: [language]
          }
        }
      }
    };

    const sub = this.store.getDataGrid(request_instance_v, true).subscribe(({ data }) => {
      const p = this.pings.get(ping.AD_PInstance_ID.id);
      p.setFromProcessPingInfoPara(data as ProcessPingInfoPara[]);
      sub.unsubscribe();
    });
  }

  /*
   * Retourne un Observable avec la référence du tableau de pings.
   * La référence peut être modifiée car le subscribe qui écoute sera trigger à chaque changement.
   */
  public watchProcessInProgress(): Observable<ProcessPingInfo[]> {
    this.init();
    return this.pings$.asObservable();
  }

  private removeFinishedProcess(ping: ProcessPingInfo) {
    const timeout = setTimeout(() => {
      this.pings.delete(ping.AD_PInstance_ID.id);
      this.pings$.next(Array.from(this.pings.values()));
      this.timeouts = this.timeouts.filter((t) => t !== timeout);
    }, 5000);
    this.timeouts.push(timeout);
  }

  public openWindow(activeTabID: any): void {
    this.openedWindow.push(activeTabID);
    this.timeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
  }

  public closeWindow(activeTabID: any): void {
    this.openedWindow = this.openedWindow.filter((id) => id !== activeTabID);
    if (this.openedWindow.length === 0) {
      this.pings.forEach((ping) => {
        if (ping.Status === 'finish') {
          this.removeFinishedProcess(ping);
        }
      });
    }
  }
}
