import { Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
// import 'tracking';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-cruise-situation-ui',
  templateUrl: './cruise-situation-ui.component.html',
  styleUrls: ['./cruise-situation-ui.component.scss']
})
export class CruiseSituationUiComponent extends SpecificWindowUiComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ElementRef, static: true }) containerRef: ElementRef<HTMLDivElement>;
  @ViewChild('shipIMG', { read: ElementRef, static: true }) shipRef: ElementRef<HTMLImageElement>;

  private cabinTypeColor = new Map([
    [`rgb(${253},${235},${105})`, 'deluxe'],
    [`rgb(${216},${169},${194})`, 'classique'],
    [`rgb(${147},${211},${237})`, 'solo']
  ]);
  // private tracker: tracking.ColorTracker;
  // private trackerTask: tracking.TrackerTask;
  private listeners: { who: HTMLDivElement; listener: Function }[] = [];

  ship: any;
  displayShip: {
    cabinNo: number;
    nbBed: number;
    status: string;
    type: string;
    cabinOnShip: HTMLDivElement;
  };
  booking_name: string;
  booking_firstname: string;
  booking_dateOfBirth: string;
  indice = 0;
  isSidebarOpen = false;

  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected progressService: ProcessInProgressService,
    protected translateService: TranslateService,
    private renderer: Renderer2
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.initColors();

    // this.tracker = new tracking.ColorTracker([
    //   `rgb(${253},${235},${105})`,
    //   `rgb(${216},${169},${194})`,
    //   `rgb(${147},${211},${237})`
    // ]);

    // this.tracker.on('track', event => {
    //   event.data.forEach(data => {
    //     this.buildRectDiv(data);
    //   });
    // });
    // this.tracker.on('stop', event => {
    //   console.log(event);
    // });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.listeners.forEach((l) => l.listener());
  }

  private initColors() {
    // tracking.ColorTracker.registerColor(
    //   `rgb(${253},${235},${105})`,
    //   (r, g, b) => r > 253 - 5 && r < 253 + 5 && g > 235 - 5 && g < 235 + 5 && b > 105 - 5 && b < 105 + 5
    // );
    // tracking.ColorTracker.registerColor(
    //   `rgb(${216},${169},${194})`,
    //   (r, g, b) => r > 216 - 5 && r < 216 + 5 && g > 169 - 5 && g < 169 + 5 && b > 194 - 5 && b < 194 + 5
    // );
    // tracking.ColorTracker.registerColor(
    //   `rgb(${147},${211},${237})`,
    //   (r, g, b) => r > 147 - 5 && r < 147 + 5 && g > 211 - 5 && g < 211 + 5 && b > 237 - 5 && b < 237 + 5
    // );
  }

  notifyFromDataChange(item: any) {
    // const cruiseId_item = this.dataContainers.find(datacontainer => datacontainer.data.columnName === 'XX_Cruise_ID');
    // const created_item = this.dataContainers.find(datacontainer => datacontainer.data.columnName === 'Created');
    // if (this.containerRef) {
    //   this.containerRef.nativeElement.innerHTML = '';
    // }
    // if (
    //   cruiseId_item.fieldValue !== undefined &&
    //   cruiseId_item.fieldValue !== null &&
    //   created_item.fieldValue !== undefined &&
    //   created_item.fieldValue !== null
    // ) {
    //   const cruise_request: DataStoreRequest = {
    //     windowId: this.formId,
    //     record_id: cruiseId_item.data.columnName.toUpperCase() + ',' + cruiseId_item.fieldValue.id,
    //     parent_constraint: '',
    //     compiereRequest: {
    //       startRow: 0,
    //       endRow: 1,
    //       tableName: 'XX_Cruise'
    //     }
    //   };
    //   this.store
    //     .getDataGrid(cruise_request)
    //     .pipe(
    //       switchMap(cruise => {
    //         const ship_request: DataStoreRequest = {
    //           windowId: this.formId,
    //           record_id: 'XX_SHIP_ID,' + cruise.data[0]['XX_SHIP_ID'],
    //           parent_constraint: '',
    //           compiereRequest: {
    //             startRow: 0,
    //             endRow: 1,
    //             tableName: 'XX_Ship'
    //           }
    //         };
    //         return this.store.getDataGrid(ship_request);
    //       })
    //     )
    //     .subscribe(ship => {
    //       if (
    //         cruiseId_item.fieldValue !== undefined &&
    //         cruiseId_item.fieldValue !== null &&
    //         created_item.fieldValue !== undefined &&
    //         created_item.fieldValue !== null
    //       ) {
    //         this.ship = ship.data[0];
    //         const sub = timer(50).subscribe(() => {
    //           this.shipRef.nativeElement.addEventListener('load', e => {
    //             if (this.shipRef.nativeElement.naturalWidth > 1000) {
    //               this.shipRef.nativeElement.height = Math.floor(this.shipRef.nativeElement.naturalHeight / 2);
    //               this.shipRef.nativeElement.width = Math.floor(this.shipRef.nativeElement.naturalWidth / 2);
    //             } else {
    //               this.shipRef.nativeElement.height = this.shipRef.nativeElement.naturalHeight;
    //               this.shipRef.nativeElement.width = this.shipRef.nativeElement.naturalWidth;
    //             }
    //             this.shipRef.nativeElement.style.opacity = '1';
    //             this.trackerTask = tracking.track(this.shipRef.nativeElement, this.tracker);
    //             sub.unsubscribe();
    //           });
    //         });
    //       }
    //     });
    // } else {
    //   this.indice = 0;
    //   if (this.shipRef && this.shipRef.nativeElement.style.opacity !== '0') {
    //     this.shipRef.nativeElement.style.opacity = '0';
    //   }
    //   if (this.trackerTask !== undefined) {
    //     this.trackerTask.stop();
    //     this.trackerTask = undefined;
    //   }
    //   if (this.ship !== undefined) {
    //     this.ship = undefined;
    //   }
    // }
  }

  // private buildRectDiv(data: tracking.TrackRect) {
  // const text: HTMLDivElement = this.renderer.createElement('div');
  // this.renderer.appendChild(text, this.renderer.createText(`${400 + this.indice}`));
  // this.renderer.setStyle(text, 'flex', 'auto');
  // this.renderer.setStyle(text, 'margin', 'auto');
  // this.renderer.setStyle(text, 'text-align', 'center');
  // const rect: HTMLDivElement = this.renderer.createElement('div');
  // this.renderer.appendChild(rect, text);
  // this.renderer.appendChild(this.containerRef.nativeElement, rect);
  // this.renderer.addClass(rect, 'rect');
  // this.renderer.setStyle(rect, 'border', `1px solid ${data.color}`);
  // this.renderer.setStyle(rect, 'width', `${data.width}px`);
  // this.renderer.setStyle(rect, 'height', `${data.height}px`);
  // this.renderer.setStyle(rect, 'left', `${this.shipRef.nativeElement.offsetLeft + data.x}px`);
  // this.renderer.setStyle(rect, 'top', `${this.shipRef.nativeElement.offsetTop + data.y}px`);
  // this.renderer.setStyle(rect, 'position', 'absolute');
  // this.renderer.setStyle(rect, 'display', 'flex');
  // this.renderer.setStyle(rect, 'background-color', data.color);
  // this.renderer.setStyle(rect, 'cursor', 'pointer');
  // if (this.indice % 5 === 0) {
  //   this.renderer.addClass(rect, 'taken');
  //   const rgb = this.getRgbFromString(data.color);
  //   this.renderer.setStyle(
  //     rect,
  //     'background-color',
  //     `rgb(${rgb.r - 50 < 0 ? 0 : rgb.r - 50},${rgb.g - 50 < 0 ? 0 : rgb.g - 50},${rgb.b - 50 < 0 ? 0 : rgb.b - 50})`
  //   );
  // }
  // this.listeners.push({
  //   who: rect,
  //   listener: this.renderer.listen(rect, 'click', e => {
  //     this.handleClick(e, rect);
  //   })
  // });
  // this.indice++;
  // }

  private handleClick(event: MouseEvent, me: HTMLDivElement) {
    // event.stopPropagation();
    // this.setDisplayShip(me);
    // this.isSidebarOpen = true;
  }

  private setDisplayShip(div: HTMLDivElement) {
    // this.displayShip = {
    //   cabinNo: parseInt(div.firstChild.textContent, 10),
    //   cabinOnShip: div,
    //   nbBed: parseInt(div.firstChild.textContent, 10) % 2 === 0 ? 1 : 2,
    //   status: div.classList.contains('taken') ? 'taken' : 'free',
    //   type: this.cabinTypeColor.get(div.style.borderColor.replace(/ /g, ''))
    // };
  }

  bookCabin(event: MouseEvent, me: HTMLDivElement) {
    // event.stopPropagation();
    // if (me.classList.contains('taken')) {
    //   return;
    // }
    // if (
    //   this.booking_name === undefined ||
    //   this.booking_name === null ||
    //   this.booking_name === '' ||
    //   this.booking_firstname === undefined ||
    //   this.booking_firstname === null ||
    //   this.booking_firstname === '' ||
    //   this.booking_dateOfBirth === undefined ||
    //   this.booking_dateOfBirth === null ||
    //   this.booking_dateOfBirth === ''
    // ) {
    //   return;
    // }
    // this.booking_name = undefined;
    // this.booking_firstname = undefined;
    // this.booking_dateOfBirth = undefined;
    // const rgb = this.getRgbFromString(me.style.backgroundColor.replace(/ /g, ''));
    // this.renderer.setStyle(
    //   me,
    //   'background-color',
    //   `rgb(${rgb.r - 50 < 0 ? 0 : rgb.r - 50},${rgb.g - 50 < 0 ? 0 : rgb.g - 50},${rgb.b - 50 < 0 ? 0 : rgb.b - 50})`
    // );
    // this.renderer.addClass(me, 'taken');
    // this.setDisplayShip(me);
  }

  /**
   * Permet d'obtenir un objet rgb à partir d'une string
   * @param {string}str valeur rgb en string
   * @returns {{r:number;g:number;b:number}} objet RGB
   */
  private getRgbFromString(str: string): { r: number; g: number; b: number } {
    // const result = /([0-9]{1,3}),([0-9]{1,3}),([0-9]{1,3})/i.exec(str);
    // return result
    //   ? {
    //       r: parseInt(result[1], 10),
    //       g: parseInt(result[2], 10),
    //       b: parseInt(result[3], 10)
    //     }
    //   : null;
    return null;
  }
}
