export class IupicsMessage {
  name: string;
  message: string;
  type?: IupicsMessageType;
  stack?: string;

  constructor(name: string, message: string, type: IupicsMessageType = 'error', stack?: string) {
    this.name = name;
    this.message = message;
    this.type = type;
    this.stack = stack;
  }
}

export type IupicsMessageType = 'error' | 'success' | 'message' | 'warning';
