import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { PrimeCalendarComponent } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';

@Component({
  selector: 'iu-input-time-ui',
  templateUrl: './input-time-ui.component.html',
  styleUrls: ['./input-time-ui.component.scss']
})
export class InputTimeUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @ViewChild('calendar', { static: true })
  calendar: PrimeCalendarComponent;

  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  dataContainers: AbstractDataContainerCallout;
  @Input('fieldValue')
  set fieldValue(value: any) {
    this.calendar.value = value;
    this.calendar.setFieldValue();
  }

  get fieldValue() {
    return this.calendar.inputFieldValue;
  }

  @Input()
  hourFormat = 24;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    renderer: Renderer2,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
    this.isDateField = true;
  }

  ngOnInit() {
    super.ngOnInit();
    this.cssGrid = this.cssClass;
    this.setFieldMandatory();
    this.calendar.setMandatoryCss(this.mandatoryCss);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
    if (this.data && this.data.callouts && this.data.callouts.callouts) {
      this.data.callouts.callouts.forEach((callout) => {
        this.calendar.inputRef.nativeElement.addEventListener(callout.calloutevent, () => {
          const calloutFn = new Function('Field', 'Fields', 'DBSelect', callout.script);
          calloutFn(this, this.dataContainers, this.uiCreatorService);
        });
      });
    }
    if (this.fieldValue) {
      this.calendar.setFieldValue();
    }
  }

  setFieldMandatory() {
    if (this.data && this.data.mandatoryLogic) {
      this.data.isMandatory = LogicEvaluator.evaluateLogic(
        this.getCurrentContext(this.dataStored, false),
        this.data.mandatoryLogic
      );
    }
    // if (this.fieldValue) {
    //   this.mandatoryCss = ' iu-field ';
    // } else {
    if (this.data && this.data.isMandatory) {
      this.mandatoryCss = ' iu-field-mandatory ';
    } else {
      this.mandatoryCss = ' iu-field ';
    }
    // }
  }

  fieldChange(event: any) {
    this.dataChange(event);
  }

  setNewData(dataStored: DataStore, isInit = false) {
    super.setNewData(dataStored, isInit);
    this.calendar.setMandatoryCss(this.mandatoryCss);
  }
}
